import React, { Component } from "react";
import { connect } from "react-redux";

import { checkRefreshToken } from "../../store/actions";
class CheckAuthExpire extends Component {
  constructor(props) {
    super(props);
    this.props.checkRefreshToken();
  }
  componentWillUnmount() {}
  render() {
    const { children } = this.props;
    return <>{children}</>;
  }
}

export default connect(() => ({}), {checkRefreshToken})(CheckAuthExpire) ;
