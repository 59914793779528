import React, {useState, useEffect} from "react";
import { Row, Col, Form, Card, Offcanvas, Button } from "react-bootstrap";
import { ReactComponent as BackSvg } from "../../assets/images/icon_svg/back.svg";
import moment from "moment";
import "./submissions.scss"

export const DraftSlide = ({ onClose, show, modalContent, editDraft }) => {

    const [sortBy, setSortBy] = useState({
        sort: "newest",
        finalArr: modalContent
    });
    useEffect(()=>{
        setSortBy({
            ...sortBy,
            finalArr: sortList(modalContent, "newest")
        })
    },[modalContent])

     const sortList = (dateArr, sortingBy) => {
        return dateArr?.sort((a,b)=>{
            // Turn your strings into dates, and then subtract them
            // to get a value that is either negative, positive, or zero.
            if(sortingBy === "newest") {
                return new Date(b.created_at) - new Date(a.created_at);
            } else {
                return new Date(a.created_at) - new Date(b.created_at);
            }
          })
     }

    const onSortByChanged = (e) => {
        setSortBy({
            ...sortBy,
            sort: e.target.value,
            finalArr: sortList(sortBy?.finalArr, e.target.value)
        })
    }

    return (
        <Offcanvas
            show={show}
            onHide={onClose}
            placement="end"
            style={{ width: "40pc" }}
        >
            <Offcanvas.Header className="">
                <div className="d-flex align-items-center main-title p-l-3" style={{ gap: "10px" }}>
                    <BackSvg onClick={onClose} style={{ cursor: "pointer" }} />
                    <span>Back</span>
                </div>
            </Offcanvas.Header>

            <Row className="m-0">
                <Col lg={12}>
                    <div className="top-header-box d-flex align-items-center justify-content-start" >
                        <Offcanvas.Title style={{ cursor: "pointer" }}>Drafts : Associate Weekly Metrics</Offcanvas.Title>
                    </div>
                </Col>
            </Row>
            <Offcanvas.Body className="scroller">
                <Row className="m-0">
                    <Col>
                        <div className="d-flex w-75 align-items-center">
                            <span style={{marginRight: "6px"}}> Sort by : </span>
                            <Form.Select
                                aria-label="Default select example"
                                className="per-page-item w-50"
                                onChange={onSortByChanged}
                                value={sortBy?.sort}
                            >
                                <option value="newest">Newest</option>
                                <option value="oldest">Oldest</option>
                            </Form.Select>
                        </div>
                    </Col>
                </Row>
                <Row className="m-0 mt-4">
                    <Col xs={12}>{sortBy?.finalArr?.length >0 && sortBy?.finalArr?.map((i, idx)=> {
                        return (
                            <div key={idx} className="d-flex justify-content-between align-items-center mb-2 pt-3 pb-3 pr-2 pl-2" style={{border: "1px solid #ccc", borderRadius: "4px", padding: "6px 12px"}} > 
                            <span>
                                <span className="" style={{marginRight: "12px"}}>{moment.utc(i?.created_at).local().format("MM/DD/YYYY")}</span>
                                <span>Last edited @{moment.utc(i?.modified_at).local().format("MMM DD YYYY LT")}</span>
                            </span>
                            <span>
                                <div variant="default" className="view-draft" onClick={()=>editDraft(i?.draft_id)} style={{cursor: "pointer"}}>View Draft</div>
                            </span>
                            </div>
                        )
                    })}</Col>
                </Row>
            </Offcanvas.Body>
        </Offcanvas>
    );
};
