import React, { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import './carousel.scss';
import UpArrowIcon from '../../../assets/images/icon_svg/up-arrow.svg';
import DownArrowIcon from '../../../assets/images/icon_svg/down-arrow.svg';
import RightArrowIcon from '../../../assets/images/icon_svg/right-arrow.svg';
import GrowthCircleIcon from '../../../assets/images/icon_svg/circle-arrow.svg';
import GrowthDownIcon from '../../../assets/images/icon_svg/growth-down.svg';
import GrowthUpIcon from '../../../assets/images/icon_svg/growth-up.svg';
import CarousalLeftIcon from '../../../assets/images/icon_svg/carousal-left.svg';
import CarousalRightIcon from '../../../assets/images/icon_svg/carousal-right.svg';

const Carousel = ({ seoCompetitors, ppcCompetitors }) => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const combinedCompetitors = [...seoCompetitors.map(comp => ({ ...comp, type: 'SEO' })), ...ppcCompetitors.map(comp => ({ ...comp, type: 'PPC' }))];
    const slidesToShow = 2;
    const totalSlides = combinedCompetitors.length;
    const totalDots = Math.ceil(totalSlides / slidesToShow);

    const goToPrevSlide = () => {
        setCurrentIndex(prevIndex => (prevIndex <= 0 ? totalSlides - slidesToShow : prevIndex - slidesToShow));
    };

    const goToNextSlide = () => {
        setCurrentIndex(prevIndex => (prevIndex >= totalSlides - slidesToShow ? 0 : prevIndex + slidesToShow));
    };
    const goToSlide = (index) => {
        setCurrentIndex(index * slidesToShow);
    };

    const getGrowthIcon = (growth) => {
        if (growth === 0) return GrowthCircleIcon;
        return growth < 0 ? GrowthDownIcon : GrowthUpIcon;
    };

    const getItemIcon = (growth) => {
        if (growth === 0) return RightArrowIcon;
        return growth < 0 ? DownArrowIcon : UpArrowIcon;
    };

    function formatInteger(number) {
        if(number === "n/a") {
            return "n/a"
        }
        if (number >= 1000000000) {
            return (number / 1000000000).toFixed(0) + 'B';
        } else if (number >= 1000000) {
            return (number / 1000000).toFixed(0) + 'M';
        } else if (number >= 1000) {
            return (number / 1000).toFixed(0) + 'K';
        } else {
            return number.toString();
        }
    }

    const handleOpenCompany = (company) => {
        window.open(`https://www.${company.domain}`, '_blank');
    }

    return (
        <div className="carousel-parent">
            <div className="carousel-container">
                <div className="stats-subheading d-flex mb-2">
                    <span>SEO and PPC Companies ({totalSlides})</span>
                    <div className="btn-box">
                        <div onClick={goToPrevSlide} className="carousel-button" id='prevButton'>
                            <img height="14px" width="14px" src={CarousalLeftIcon} alt="Prev" />
                        </div>
                        <div onClick={goToNextSlide} className="carousel-button" id='nextButton'>
                            <img height="14px" width="14px" src={CarousalRightIcon} alt="Next" />
                        </div>
                    </div>
                </div>
                <div className="carousel-wrapper" style={{ transform: `translateX(-${currentIndex * (100 / slidesToShow)}%)` }}>
                    {combinedCompetitors.map((competitor, index) => (
                        <Col xs={12} md={6} key={index}>
                            <div className="carousel-slide" onClick={()=> handleOpenCompany(competitor)}>
                                <div className="carousel-slide-inner">
                                    <div className="header-box d-flex justify-content-between align-items-center">
                                        <div className="d-flex align-items-center">
                                            <span className="comp-logo">
                                                <img alt="logo" src={competitor.logo_url} height="100%" width="100%" />
                                            </span>
                                            <div>
                                            <div className="comp-title">
                                                {competitor.name && competitor.name !== 'n/a' ? competitor.name : competitor.domain}
                                            </div>
                                            </div>
                                        </div>
                                        <div className="comp-head-detail">
                                            <div className="small-text">
                                                {competitor.city && competitor.city !== 'n/a' ? (
                                                    <>
                                                        {competitor.city}
                                                        {competitor.state_code && competitor.state_code !== 'n/a' ? `, ${competitor.state_code}` : null}
                                                    </>
                                                ) : (competitor.state_code && competitor.state_code !== 'n/a' ? competitor.state_code : null)}
                                            </div>
                                            {competitor.employee_count && competitor.employee_count !== 'n/a' ? (
                                                <div className="small-text">{competitor.employee_count} Employees</div>
                                            ) : null}
                                        </div>
                                    </div>
                                    <div className="f-row card-bottom">
                                        <div className="rhs col-12">
                                            <div className="head d-flex justify-content-between align-items-center">
                                                {competitor.type && competitor.type != 'n/a' ? <><span className="emp-growth-head">Employee Growth</span><div className="comp-type"><b>{competitor.type}</b></div></> : 'N/A'}
                                            </div>
                                            <div className="d-flex align-items-center growth-graph-count">
                                                {competitor.employee_count && competitor.employee_count !== 'n/a' ? (
                                                    <>
                                                        <span>
                                                            <img 
                                                                src={getGrowthIcon(competitor.growth_1y)} 
                                                                height="24px" 
                                                                width="22px" 
                                                                alt="Growth Icon"
                                                            />
                                                        </span>
                                                        <span className="growth-title">
                                                            <span>{formatInteger(competitor.employee_count)}</span>
                                                        </span>
                                                    </>
                                                ) : (
                                                    <span className="growth-title-na">N/A</span>
                                                )}
                                            </div>
                                            <div className="growth-container d-flex justify-content-between">
                                                {['1m', '6m', '1y', '2y'].map((period, idx) => {
                                                    const growth = competitor[`growth_${period}`];
                                                    return (
                                                        <div className="growth-item" key={idx}>
                                                            <span className="growth-header">{`${period?.replace(/m/, ' Month')?.replace(/y/, ' Year')}`}</span>
                                                            <div className="d-flex">
                                                                {growth === 'n/a' ? (
                                                                    <div className="growth-value">n/a</div>
                                                                ) : (
                                                                    <>
                                                                        <div className="growth-icon">
                                                                            <span>
                                                                                <img 
                                                                                    src={getItemIcon(growth)} 
                                                                                    height="16px" 
                                                                                    width="16px" 
                                                                                    alt="Growth Icon"
                                                                                />
                                                                            </span>
                                                                        </div>
                                                                        <div className="growth-value">{growth}%</div>
                                                                    </>
                                                                )}
                                                            </div>
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    ))}
                </div>
                <div className="carousel-dots">
                    {Array.from({ length: totalDots }).map((_, dotIndex) => (
                        <span 
                            key={dotIndex} 
                            className={`carousel-dot ${dotIndex === Math.floor(currentIndex / slidesToShow) ? 'active' : ''}`}
                            onClick={() => goToSlide(dotIndex)}
                        ></span>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default Carousel;
