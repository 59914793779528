import * as actionTypes from '../actionTypes'

const initialState = {
  notes: {},
  details: {}
};

const notesReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_NOTES:
      return {
        ...state,
        notes: action.data,
      };
    case actionTypes.SET_NOTES_DETAILS:
      return {
        ...state,
        details: action.data,
      };
    case actionTypes.RESET_NOTES_DETAILS:
      return {
        ...state,
        details: {},
      };

    default:
      return state;
  }
};

export { notesReducer };
