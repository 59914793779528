import React, { useEffect, useState, useRef, useCallback } from "react";
import { Row, Col, Form } from "react-bootstrap";
import { Token, Typeahead } from "react-bootstrap-typeahead";
import { useDispatch, useSelector } from "react-redux";

import { getHQLookup } from "../../../store/actions";
import { getAddress } from "../../../utils";

import "./locationFilter.scss";

const hq_location = [
  {
    name: "Countries",
    title: "Select Countries",
    type: "countries",
  },
  {
    name: "States",
    title: "Select States",
    type: "states",
  },
  {
    name: "Cities",
    title: "Select Cities",
    type: "cities",
  },
];

let initialCall = false;

const LocationFilter = ({
  onChange,
  filteredLocation,
  onCountriesChange,
  filteredCountry,
  populateLocationData
}) => {
  const dispatch = useDispatch();
  const asyncTypeaheadRef = useRef(null);
  // const countryTypeaheadRef = useRef(null);
  const [countries, states, cities] = useSelector(({ discoveryReducer }) => [
    discoveryReducer.metadata.countries,
    discoveryReducer.metadata.states,
    discoveryReducer.metadata.cities,
  ]);

  const [form, setForm] = useState({});

  const [normalCountries, setNormalCountries] = useState({
    isHidden: true,
    selected: [],
    option: [],
  });

  useEffect(() => {
    if (countries?.length) {
      initializeForm();
      setNormalCountries({
        ...normalCountries,
        option: [...countries],
      });
    }
  }, [countries]);

  useEffect(() => {
    onChange(form);
    if (
      (initialCall || populateLocationData.length) &&
      (form["countries"]?.selected?.length ||
        form["states"]?.selected?.length ||
        form["cities"]?.selected?.length)
    ) {
      getLocationBasedOnSelection();
    } else if (initialCall) {
      initializeForm();
      initialCall = false;
    }
  }, [
    form["countries"]?.selected,
    form["states"]?.selected,
    form["cities"]?.selected,
  ]);

  useEffect(() => {
    if (
      filteredLocation &&
      Object.values(filteredLocation)?.some((i) => i === "all")
    ) {
      initializeForm();
    } else if (
      filteredLocation &&
      Object.values(filteredLocation)?.some((i) => i != "")
    ) {
      setForm({
        ...form,
        [filteredLocation?.keys]: {
          ...form[filteredLocation?.keys],
          selected: form[filteredLocation?.keys]["selected"]?.filter(
            (i) => i !== filteredLocation?.value
          ),
        },
      });
    }
  }, [filteredLocation]);

  useEffect(() => {
    onCountriesChange(normalCountries);
  }, [normalCountries]);

  useEffect(() => {
    if (filteredCountry === "all") {
      setNormalCountries((prevForm) => ({
        ...prevForm,
        selected: [],
      }));
    } else if (filteredCountry) {
      setNormalCountries((prevForm) => ({
        ...prevForm,
        selected: prevForm?.selected?.filter((i) => i != filteredCountry),
      }));
    }
  }, [filteredCountry]);

  useEffect(()=> {
    if (populateLocationData.length) {
      const updatedForm = { ...form };
      populateLocationData.forEach(location => {
        updatedForm[location.type] = {
          ...updatedForm[location.type],
          selected: location.val,
          isHidden: true,
        };
      });
      setForm(updatedForm);
      initialCall = true;
    }
  },[populateLocationData])

  const initializeForm = useCallback(() => {
    let finalObject = {};
    hq_location?.forEach((i, inx) => {
      finalObject[i?.type] = {
        isHidden: true,
        selected: [],
        option:
          i?.type === "countries"
            ? [...countries]
            : i?.type === "states"
            ? [...states]
            : i?.type === "cities"
            ? [...cities]
            : [],
      };
    });
    setForm(finalObject);
  }, [countries, states, cities]);

  function handleChange(val, type) {
    initialCall = true;
    if (!val.every((element) => form[type].selected.includes(element))) {
      setForm({
        ...form,
        [type]: {
          ...form[type],
          selected: val,
          isHidden: true,
        },
      });
    }
  }

  const handlePlus = (i) => {
    setForm((prevSelected) => {
      const formObj = { ...prevSelected };
      // Close all other dropdowns and open the current one
      Object.keys(formObj).forEach((key) => {
        formObj[key].isHidden = key !== i.type;
      });

      // Delay setting focus after the component is likely rendered
      setTimeout(() => {
        if (!formObj[i.type]?.isHidden) {
          asyncTypeaheadRef?.current?.focus();
        }
      }, 10);
      return formObj;
    });
  };

  const handleBlur = (type) => {
    if (!form[type]?.isHidden) {
      setForm((prevForm) => ({
        ...prevForm,
        [type]: {
          ...prevForm[type],
          isHidden: true,
        },
      }));
    }
  };

  const handleRemove = (type, itemToRemove) => {
    setForm((prevSelected) => {
      const formObj = { ...prevSelected };
      // Ensure that formObj[itemToRemove.type] is an object before accessing its properties
      if (formObj[type] && formObj[type].selected) {
        const updatedSelected = formObj[type].selected.filter(
          (item) => item !== itemToRemove
        );
        formObj[type] = {
          ...formObj[type],
          selected: updatedSelected,
        };
      }
      return formObj;
    });
  };

  const getLocationBasedOnSelection = () => {
    let params = "";
    // check check box
    if (form["countries"]?.selected?.length) {
      params =
        `${params && params + "&"}` +
        form["countries"]?.selected
          ?.map((value) => `hq_country=${value}`)
          .join("&");
    }
    if (form["states"]?.selected?.length) {
      params =
        `${params && params + "&"}` +
        form["states"]?.selected?.map((value) => `hq_state=${value}`).join("&");
    }
    if (form["cities"]?.selected?.length) {
      params =
        `${params && params + "&"}` +
        form["cities"]?.selected?.map((value) => `hq_city=${value}`).join("&");
    }

    const url = `${
      getAddress() + `/discovery/hq-lookup${params && "?" + params}`
    }`;
    if (
      form["countries"]?.selected?.length ||
      form["states"]?.selected?.length ||
      form["cities"]?.selected?.length
    ) {
      dispatch(getHQLookup(url)).then((res) => {
        if (res?.status === "success") {
          setForm((prevForm) => {
            const updatedForm = { ...prevForm };

            Object.keys(res?.data).forEach((key) => {
              if (updatedForm.hasOwnProperty(key)) {
                updatedForm[key] = {
                  ...updatedForm[key],
                  option: res?.data[key] || updatedForm[key]?.option,
                };
              }
            });

            return updatedForm;
          });
        }
      });
    }
  };

  
  return (
    <Row className="mb-1 location-container mt-1">
      <Col xs={12} className="p-0">
        <div className="heading mb-2 f-s-15">
          <b>Head Quartered In</b>{" "}
        </div>
        <div className="">
          {hq_location?.length &&
            hq_location?.map((i, ind) => {
              const isCountryType = i.type === 'countries';
              const isCountrySelected = form[i.type]?.selected?.length > 0;
              return (
                <Form.Group
                  key={ind}
                  className="mb-3 m-r-5 "
                  controlId="formBasicEmail"
                >
                  <div className="mb-1 title">{i?.title}</div>
                  <div className="d-flex flex-wrap">
                    <div
                      className={`plusBtn d-flex justify-content-center align-items-center ${isCountryType && isCountrySelected ? 'no-drop' : ''}`}
                      onClick={() => (!isCountryType || !isCountrySelected) &&  handlePlus(i)}
                    >
                      {" "}
                      +{" "}
                    </div>
                    {form[i?.type]?.selected?.length
                      ? form[i?.type]?.selected?.map((item, index) => (
                          <span
                            key={index}
                            className="selectedItem  d-flex justify-content-between align-items-center"
                          >
                            {item}{" "}
                            <span
                              className="closeBtn"
                              onClick={() => handleRemove(i?.type, item)}
                            >
                              {" "}
                              x{" "}
                            </span>
                          </span>
                        ))
                      : null}
                    {!form[i?.type]?.isHidden ? (
                      <Typeahead
                        ref={asyncTypeaheadRef}
                        id={`${ind}`}
                        multiple
                        flip={true}
                        onBlur={() => handleBlur(i?.type)}
                        clearButton={false}
                        options={form[i?.type]?.option || []}
                        onChange={(val) => handleChange(val, i?.type)}
                        selected={form[`${i.type}`]?.selected}
                        placeholder={`Select ${i?.type}`}
                        className="mb-3 hq-typeahead-dropdown"
                        renderToken={(option, props, idx) => (
                          <Token className={idx > 0 ? "hidden" : "hidden"}>
                            <></>
                          </Token>
                        )}
                        filterBy={(option, props) => {
                          const searchTerm = props.text.toLowerCase();
                          return option.toLowerCase().includes(searchTerm);
                        }}
                        maxResults={15}
                        renderMenuItemChildren={(option, props) => (
                          <div>{option}</div>
                        )}
                      />
                    ) : null}
                  </div>
                </Form.Group>
              );
            })}
        </div>
      </Col>
      
    </Row>
  );
};

export default LocationFilter;
