import React, { useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as actionTypes from "../../../store/actionTypes";

import "./referenceFilter.scss"

let filters = {};

const RefFilterComponent = forwardRef((props, ref) => {

    // Expose AG Grid Filter Lifecycle callbacks
  useImperativeHandle(ref, () => {
    return {
      doesFilterPass(params) {
        return true
      },
      isFilterActive() {
        return false;
      },
      // This example isn't using getModel() and setModel(),
      // so safe to just leave these empty. Don't do this in your code!!!
      getModel() {},

      setModel() {},
    };
  });

    const [columnValues, setColumnValues] = useState([]);
    const { filterParams } = props.colDef;
    const { callBackFunc } = filterParams;
    const [selectedFilterValue, setSelectedFilterValue] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [userSelectedCheckbox, setUserSelectedCheckbox] = useState(false);

    const dispatch = useDispatch()
    const [allAppliedFilters, refDropdown] = useSelector(({workboardReducer, referenceReducer}) => [workboardReducer?.allAppliedFilters, referenceReducer?.refDropdown])


    useEffect(()=>{
        if(refDropdown?.reference_types) {
            setColumnValues(refDropdown?.reference_types);
        }
    },[refDropdown])


    useEffect(() => {
        filters = {
            type: "reference_types",
            selectedFilterValue: selectedFilterValue
        }
        document.addEventListener('resetFiltersClicked', resetFilterAll);
        return () => {
            document.removeEventListener('resetFiltersClicked', resetFilterAll);
            filters = {};
        };
    }, []);

    useEffect(()=> {
        if(userSelectedCheckbox) {
            let finalObject = {...allAppliedFilters}
            filters = {
                type: "reference_types",
                selectedFilterValue: selectedFilterValue
            }
            finalObject[`reference_types`] = filters
            // callBackFunc(finalObject);
            dispatch({ type: actionTypes.SET_WORKBOARDS_FILTERS, data: {...finalObject}});
        }
    },[selectedFilterValue])

    const handleCheckboxChange = (item) => {
        setSelectedFilterValue((prevCheckedItems) => {
            const isChecked = prevCheckedItems.some((checkedItem) => checkedItem.server_value === item.server_value);
            if (isChecked) {
                // If the item is already in checkedItems, remove it
                return prevCheckedItems.filter((checkedItem) => checkedItem.server_value !== item.server_value);
            } else {
                // Otherwise, add the item to checkedItems
                return [...prevCheckedItems, item];
            }
        });
        setUserSelectedCheckbox(true)
    };

    const applyFilter = () => {
        let finalObject = {...allAppliedFilters}
            filters = {
                type: "reference_types",
                selectedFilterValue: []
            }
            finalObject[`reference_types`] = filters
            callBackFunc(finalObject);
            dispatch({ type: actionTypes.SET_WORKBOARDS_FILTERS, data: {...finalObject}});
    };

    const resetFilterAll = () => {
        setSelectedFilterValue([]);
        setSearchTerm('');
        let finalObject = {}
        filters = {
            type: "reference_types",
            selectedFilterValue: []
        }
        finalObject["reference_types"] = filters
        callBackFunc(finalObject);
        dispatch({ type: actionTypes.SET_WORKBOARDS_FILTERS, data: {}});
    };

    const resetFilter = () => {
        setSelectedFilterValue([]);
        setSearchTerm('');
        applyFilter();
    };

    const applyFn = () => {
        let finalObject = { ...allAppliedFilters }
        filters = {
            type: "reference_types",
            selectedFilterValue: selectedFilterValue
        }
        finalObject[`reference_types`] = filters
        callBackFunc(finalObject);
        dispatch({ type: actionTypes.SET_WORKBOARDS_FILTERS, data: { ...finalObject } });
    }

    return (
        <div className='custom-ref-filter'>
            <input
                className='ag-input-field-input ag-text-field-input mb-2 inputStyle'
                type="text"
                placeholder="Search..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
            />
            <div className='checkbox-box'>
                {columnValues?.length ? columnValues?.filter((item) => item?.server_value?.toLowerCase()?.includes(searchTerm?.toLowerCase()))?.map((value) => (
                    <label className='d-flex' key={value?.server_value}>
                        <input
                            className='checkbox-input'
                            type="checkbox"
                            value={value}
                            checked={selectedFilterValue.some((checkedItem) => checkedItem.server_value === value.server_value)}
                            onChange={()=>handleCheckboxChange(value)}
                        />{' '}
                        <span>{value?.display_name}</span>
                    </label>
                )) : `No Options Available`}
            </div>
            <div className='d-flex justify-content-end'>
                <button onClick={applyFn} className='ag-standard-button ag-filter-apply-panel-button'>Apply</button>
                <button onClick={resetFilter} className='ag-standard-button ag-filter-apply-panel-button'>Reset</button>
            </div>
        </div>
    );
});

export default RefFilterComponent;