import * as actionTypes from '../actionTypes';
import {getAuthToken} from "../../utils"
import axios from "axios";

export const getNotes = (axiosInstance, filters) => (dispatch) => {
    return axiosInstance({
      method: "GET",
      data: {},
      params: {...filters},
      headers: {
        "Authorization": getAuthToken()
      }
    })
    .then((res) => {
      dispatch({ type: actionTypes.SET_NOTES, data: res?.data });
      return { status: 'success', data: res?.data?.results};
    })
    .catch((err) => {
      console.warn('Response - error -> ', err?.response?.data);
      if (err?.response?.data?.hasOwnProperty('code') && err?.response?.data?.code === 422) {
        return { status: 'failed', errMsg: err?.response?.data?.name };
      } else {
        return { status: 'failed' };
      }
    });
}


export const getFilteredNotes =
  (url) => (dispatch) => {
    return axios.get(url, {
      headers: {
        Authorization: getAuthToken()
      }
    })
      .then((res) => {
        dispatch({ type: actionTypes.SET_NOTES, data: res?.data });
        return { status: 'success', data: res?.data?.results };
      })
      .catch((err) => {
        console.warn('Response - error -> ', err?.response?.data);
        if (err?.response?.data?.hasOwnProperty('code') && err?.response?.data?.code === 422) {
          return { status: 'failed', errMsg: err?.response?.data?.name };
        } else {
          return { status: 'failed' };
        }
      });
  };

  export const getNotesDetails = (axiosInstance, id) => (dispatch) => {
    return axiosInstance({
      method: "GET",
      data: {},
      params: {fathom_id: id},
      headers: {
        "Authorization": getAuthToken()
      }
    })
    .then((res) => {
      dispatch({ type: actionTypes.SET_NOTES_DETAILS, data: res?.data });
      return { status: 'success', data: res?.data?.results};
    })
    .catch((err) => {
      console.warn('Response - error -> ', err?.response?.data);
      if (err?.response?.data?.hasOwnProperty('code') && err?.response?.data?.code === 422) {
        return { status: 'failed', errMsg: err?.response?.data?.name };
      } else {
        return { status: 'failed' };
      }
    });
}

// const notesDetail = {
//   "id": 123,
//   "meeting_name": "Telescope <> Domusu",
//   "description": "This is note 1",
//   "created_at": "2021-09-21T06:00:00Z",
//   "created_by": "John Doe",
//   "Scoper_email": "John@telescopepartners.com",
//   "isAssociated": true,
//   "associated_company": "Company Telescope",
//   "notes": `<div><h1 class="wow">Heading 1</h1>
//     <h2 id="head1">Sub Heading 1</h2>
//     <p>
//       Lorem, <a href="https://www.lipsum.com/">ipsum</a>
//       dolor sit amet consectetur adipisicing elit.
//     </p>
//     <ul>
//       <li>List item 1</li>
//       <li class="wow">List item 2</li>
//       <li>List item 3</li>
//     </ul>
//     <img src="images/lorem-ipsum1.jpg" />
//     <h2 id="head2">Sub Heading 2</h2>
//     <p>
//       Lorem ipsum dolor sit amet consectetur adipisicing elit. Cum debitis
//       molestias <span class="wow">omnis</span> voluptas, consequatur ex cumque
//       velit libero quidem est?
//     </p></div>`,
//     "people": [
//         {
//             "email": "brian.klaiss@hertz.com",
//             "ignore": false,
//             "person_id": "100122095",
//             "projects": [
//                 "No Project Required"
//             ],
//             "type_of_reference": [],
//             "functions": [],
//             "rating": 0,
//             "full_name": "Brian Klaiss"
//         },
//         {
//             "email": "anna.niles91@gmail.com",
//             "ignore": false,
//             "person_id": "207954711",
//             "projects": [
//                 "James Networking"
//             ],
//             "type_of_reference": [
//                 "Recruiting"
//             ],
//             "functions": [
//                 "Product Marketing"
//             ],
//             "rating": 1,
//             "full_name": "anna.niles91@gmail.com"
//         },
//         {
//           "email": "anna.niles91@gmail.com",
//           "ignore": false,
//           "person_id": "207954711",
//           "projects": [
//               "James Networking"
//           ],
//           "type_of_reference": [
//               "Recruiting"
//           ],
//           "functions": [
//               "Product Marketing"
//           ],
//           "rating": 1,
//           "full_name": "anna.niles91@gmail.com"
//       },
//       {
//         "email": "prathmesh.niles91@gmail.com",
//         "ignore": false,
//         "person_id": "207954711",
//         "projects": [
//             "James Networking"
//         ],
//         "type_of_reference": [
//             "Recruiting"
//         ],
//         "functions": [
//             "Product Marketing"
//         ],
//         "rating": 1,
//         "full_name": "anna.niles91@gmail.com"
//     },
//     {
//       "email": "anna.niles91@gmail.com",
//       "ignore": false,
//       "person_id": "207954711",
//       "projects": [
//           "James Networking"
//       ],
//       "type_of_reference": [
//           "Recruiting"
//       ],
//       "functions": [
//           "Product Marketing"
//       ],
//       "rating": 1,
//       "full_name": "anna.niles91@gmail.com"
//   },
//   {
//     "email": "anna.niles91@gmail.com",
//     "ignore": false,
//     "person_id": "207954711",
//     "projects": [
//         "James Networking"
//     ],
//     "type_of_reference": [
//         "Recruiting"
//     ],
//     "functions": [
//         "Product Marketing"
//     ],
//     "rating": 1,
//     "full_name": "anna.niles91@gmail.com"
//   }
//     ],
//   "companies":[
//     {
//       "id": 1,
//       "name": "Thinkbridge",
//       "description": "Lorem Ipsum is simply dummy text of the code and type of industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
//       "linkedin_url": "https://www.linkedin.com/company/domuso",
//       "website": "https://www.google.com",
//       "tpStatus": "MQL",
//       "tpOwner": "Ram",
//       "affinity_url": "https://www.affinity.com/company/company1",
//       "growth_1m": 5,
//       "growth_1y": -10,
//       "growth_6m": 0,
//       "growth_2y": 20,
//     },
//     {
//       "id": 2,
//       "name": "Domusu",
//       "description": "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
//       "linkedin_url": "https://www.linkedin.com/company/domuso",
//       "website": "https://www.google.com",
//       "tpStatus": "SQL",
//       "tpOwner": "Drew",
//       "affinity_url": "https://www.affinity.com/company/company2",
//       "growth_1m": 5,
//       "growth_1y": 10,
//       "growth_6m": 15,
//       "growth_2y": 20,
//     },
//     {
//       "id": 3,
//       "name": "Company 3",
//       "description": "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
//       "linkedin_url": "https://www.linkedin.com/company/domuso",
//       "website": "https://www.google.com",
//       "tpStatus": "CLOSED",
//       "tpOwner": "Nicole",
//       "affinity_url": "https://www.affinity.com/company/company3",
//       "growth_1m": 5,
//       "growth_1y": 10,
//       "growth_6m": 15,
//       "growth_2y": 20,
//     },
//     {
//       "id": 4,
//       "name": "Company 4",
//       "description": "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
//       "linkedin_url": "https://www.linkedin.com/company/domuso",
//       "website": "https://www.google.com",
//       "tpStatus": "OQL",
//       "tpOwner": "Mickey",
//       "affinity_url": "https://www.affinity.com/company/company4",
//       "growth_1m": 5,
//       "growth_1y": 10,
//       "growth_6m": 15,
//       "growth_2y": 20,
//     },
//   ],
//   "meetings_attendees": [
//     {
//       "attendee_name": "Ram",
//       "Company": "Telescope", 
//       "Project": "Project 1",
//       "Rating": 4,
//       "Reference": "Reference 1",
//       "Function": "Function 1",
//     },
//     {
//       "attendee_name": "Shyam",
//       "Company": "Telescope", 
//       "Project": "Project 2",
//       "Rating": 5,
//       "Reference": "Reference 2",
//       "Function": "Function 2",
//     },
//     {
//       "attendee_name": "Hari",
//       "Company": "Telescope", 
//       "Project": "Project 3",
//       "Rating": 3,
//       "Reference": "Reference 3",
//       "Function": "Function 3",
//     },  
//     {
//       "attendee_name": "Manoj",
//       "Company": "Thinkbridge", 
//       "Project": "Project 4",
//       "Rating": 5,
//       "Reference": "Reference 4",
//       "Function": "Function 4",
//     }
//   ],
// }

// export const getNotesDetails = (axiosInstance, id) => (dispatch) => {
//   console.log('getNotesDetails - id -> ', id);
  
//  dispatch({ type: actionTypes.SET_NOTES_DETAILS, data: notesDetail });

// }

export const updatePersonInNotes =
(axiosInstance, apiPayload, fathomData ) => (dispatch) => {
  return axiosInstance({
    method: "POST",
    data: {
      ...apiPayload,
    },
    headers: {
      Authorization: getAuthToken()
    },
  })
    .then((res) => {
      if (res.hasOwnProperty("error")) {
        return { status: "failed", errMsg: res?.error };
      } else if (res.hasOwnProperty("code") && res?.code === 422) {
        return { status: "failed", errMsg: res?.name };
      } else {
      
        const updatedData = { ...fathomData };
        const personIndex = updatedData.people.findIndex(person => person.person_id === apiPayload?.person_id);

        if (personIndex !== -1) {
          updatedData.people[personIndex] = {
            ...updatedData?.people[personIndex],
            functions: apiPayload.functions || updatedData?.people[personIndex]?.functions,
            projects: apiPayload.projects || updatedData?.people[personIndex]?.projects,
            rating: apiPayload.rating !== undefined ? apiPayload.rating : updatedData.people[personIndex].rating,
            type_of_reference: apiPayload.reference_types || updatedData.people[personIndex].type_of_reference,
          }
        }
        dispatch({ type: actionTypes.SET_NOTES_DETAILS, data: updatedData });
        return { status: "success", };
      }
    })
    .catch((err) => {
      console.warn("Response - error -> ", err?.response?.data);
      if(err?.response?.data?.hasOwnProperty("code") && err?.response?.data?.code === 422){
        return { status: "failed", errMsg:err?.response?.data?.name};
      } else {
        return { status: "failed" };
      }
    });
};

export const postAssociateFathomNote = (axiosInstance, payload) => (dispatch) => {
  return axiosInstance({
    method: "POST",
    data: payload,
    headers: {
      "Authorization": getAuthToken()
    }
  })
  .then((res) => {
    dispatch({ type: actionTypes.ASSOCIATE_FATHOM_NOTE_SUCCESS, data: res?.data });
    return { status: 'success', data: res?.data };
  })
  .catch((err) => {
    console.warn('Response - error -> ', err?.response?.data);
    if (err?.response?.data?.hasOwnProperty('code') && err?.response?.data?.code === 422) {
      return { status: 'failed', errMsg: err?.response?.data?.name };
    } else {
      return { status: 'failed' };
    }
  });
};
