import React, { useEffect, useState, useCallback } from 'react'
import moment from "moment"
import { useDispatch, useSelector } from 'react-redux'
import { Row, Col, Card, Form, Button, Modal } from "react-bootstrap";
import Highcharts from "highcharts";
import * as amplitude from '@amplitude/analytics-browser';
import { getScopers, searchReferences, getProjects } from "../../store/actions";
import { axiosReferenceSearchInstance, axiosScoperInstance, axiosProjectsInstance} from "../../config/appConfig/axiosInstance";
import Loader from "../../commonComponents/Loader/Loader";
import NoFilter from "../../commonComponents/NoFilter/NoFilter";
import CommonError from "../../commonComponents/CommonError/CommonError";
import Chart from "../../commonComponents/Chart/Chart";
import { useCancelRequests } from "../../customHooks";
import { Typeahead } from 'react-bootstrap-typeahead';
// import {CustomDate} from "../../commonComponents/Datepicker/CustomDatePicker"
import {RangePickerWithCustomOptions} from "../../commonComponents/Datepicker/RangePickerWithCustomOptions"

import { ReactComponent as ListViewSvg } from "../../assets/images/icon_svg/listView.svg";
import { ReactComponent as CardViewSvg } from "../../assets/images/icon_svg/cardView.svg";
import "react-datepicker/dist/react-datepicker.css";
import "./references.scss"
import 'react-bootstrap-typeahead/css/Typeahead.css';
import SideNavbar from '../../commonComponents/SideNav/SideNavbar';
import { TableContent } from '../../commonComponents/TableView/TableContent';
import { removeUnderScore } from '../../utils/removeUnderScore';
let options, startDays, callMore=false, isSubmitClicked=false;

const References = () => {
    useCancelRequests(axiosReferenceSearchInstance, axiosScoperInstance);
    const dispatch = useDispatch();
    const [refSearchData, projectOptions, isRangePickerOpen] = useSelector(({ referenceReducer }) => [referenceReducer.searchResult?.results, referenceReducer.projects, referenceReducer?.isRangePickerOpen])
    const [scopers, isSliderOpen] = useSelector(({ commonReducer }) => [commonReducer.scopers, commonReducer.isSliderOpen])

    const [view, setView] = useState({
        viewType: "listView",
        isLoading: false,
        isError: false,
        errorMessage: "",
        btnFlag: false,
        showModal : false,
        modalContent: {}
    });

    const [form, setForm] = useState({
        startDate: moment(new Date().setHours(0, 0, 0, 0)),
        endDate: moment(new Date().setHours(23, 59, 59, 0)),
        dates: [moment(new Date().setHours(0, 0, 0, 0)), moment(new Date().setHours(23, 59, 59, 0))],
        keyboardText: "",
        selectedScoper: "",
        selectedScoperArr: [],
        selectedProjects: [],
        conversation_type: "All",
        errors: {},
        endDateIndex: null,
        isViewMoreLoading: false,
        isViewMoreBtnDisable : false,
        isExtraFilterAdded: false
    });

  const [legend,setLegend] = useState({}) 
  const [rowData,setRowData] = useState([])


  useEffect(() => {
    if(refSearchData){
      let headers = refSearchData[0]
      let final={}
      for (let key in headers){
        final[key] = removeUnderScore(key)
      }
      setLegend({
        ...final
      })
    }
    
  },[refSearchData])
  
    useEffect(() => {
      if(refSearchData){
          setRowData([
            ...refSearchData
          ])
      }
      
    },[refSearchData])


    useEffect(() => {
        dispatch(getScopers(axiosScoperInstance));
        setView({
            ...view,
            isLoading: true,
            btnFlag: true
        })
        loadReferenceList();
    }, [])

    useEffect(()=>{
        if(!projectOptions.length && form?.isExtraFilterAdded) {
            dispatch(getProjects(axiosProjectsInstance))
        }
    },[form?.isExtraFilterAdded])


    useEffect(() => {
        options = {
            title: {
                text: ''
            },
            subtitle: {
                text: ''
            },
            xAxis: {
                categories: []
            },
            series: [{
                type: 'column',
                color: "#303c42",
                data: [],
                showInLegend: false,
                name: '',
            }]
        }
        updateGraphOption()
    }, [refSearchData])

    const updateGraphOption = () => {
        const noOfDays = moment(form?.endDate).diff(moment(form?.startDate), 'days')
        let groups
        if (noOfDays <= 7) {
            groups = refSearchData?.reduce((groups, data) => {
                let date;
                for (var i = 0; i <= noOfDays; i++) {
                    date = moment(form?.startDate).add(i, 'days');
                    date = date.format("MMM DD")
                    if (!groups[date]) {
                        groups[date] = [];
                    }
                    if (moment(data.date).format("MMM DD") === date) {
                        groups[date].push(data);
                    }
                }
                return groups;
            }, {})
        } else if (noOfDays > 7) {
            let daysLength = Math.trunc(noOfDays / 7)
            groups = refSearchData?.reduce((groups, data) => {
                let startDate, endDate, finalDateRange;
                for (var i = 0; i <= daysLength; i++) {
                    if (i === 0) {
                        startDate = moment(form?.startDate);
                        endDate = moment(startDate).add(6, 'days');
                        finalDateRange = `${startDate.format("L")}-${endDate.format("L")}`;
                    } else if (i > 0) {
                        startDate = moment(endDate).add(1, 'days');
                        if (i === daysLength) {
                            let remainingDays = noOfDays % 7
                            endDate = moment(startDate).add(remainingDays, 'days');
                        } else {
                            endDate = moment(startDate).add(6, 'days');
                        }
                        finalDateRange = `${startDate.format("L")}-${endDate.format("L")}`;
                    }
                    if (!groups[finalDateRange]) {
                        groups[finalDateRange] = [];
                    }
                    if (new Date(data.date).getTime() >= new Date(startDate).getTime() && new Date(data.date).getTime() <= new Date(new Date(moment(endDate).add(1, 'days')).setHours(0, 0, 0, 0)).getTime()) {
                        groups[finalDateRange].push(data);
                    }
                }
                return groups;
            }, {})
        }
        for (const keys in groups) {
            options?.xAxis?.categories?.push(keys);
            options?.series[0]?.data?.push(groups[keys]?.length)
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        e.stopPropagation()
        if (isInvaild()) {
            return
        }
        setView({
            ...view,
            isLoading: true,
            btnFlag: true,
        })
        callMore= false
        isSubmitClicked = true;
        loadReferenceList();

        const payload = {
            start_date: moment(form.startDate).format('MMM DD YYYY'),
            end_date: moment(form.endDate).format('MMM DD YYYY'),
            conversation_type: form?.conversation_type
        };
        if(form?.keyboardText) payload.keyboard_search = form?.keyboardText;
        if(form?.selectedProjects?.length) payload.projects = form[`selectedProjects`]?.map(function(item) {
            return item['server_value'];
          });
        if(form[`selectedScoperArr`]?.length) payload.scopers = form[`selectedScoperArr`]?.map(function(item) {
            return item['email_address'];
          })
        amplitude.logEvent(`Submitted Converstaion Clicks`, {...payload});
    }

    useEffect(() => { 
        if(!isSliderOpen) {
            window.addEventListener("keydown", keyBoardEvent)
            return () => {
                window.removeEventListener("keydown", keyBoardEvent);
            };
        }
    })

    const keyBoardEvent = (event) => {
        if(!isRangePickerOpen && (event.key ==="Enter" || event.code==="Enter")){
            // event.preventDefault()
            // event.stopPropagation()
            handleSubmit(event)
        }
    }

   
    const loadReferenceList = () => {
        let requestObject= {}, toDateIndex, fromDateIndex,  daysDiff, isMore = false;
        requestObject = {
            text: form?.keyboardText ? form?.keyboardText : null,
            conversation_type: form?.conversation_type
        }
        if(form?.isExtraFilterAdded) {
            let projectVal = form?.selectedProjects?.length ? form[`selectedProjects`]?.map(function(item) {
                return item['server_value'];
              }) : null
            let scoperVal = form[`selectedScoperArr`]?.length ? form[`selectedScoperArr`]?.map(function(item) {
                return item['email_address'];
              }) : null
            requestObject[`scopers`] =  scoperVal?.length ? String(scoperVal) : null
            requestObject[`projects`] = projectVal?.length ? String(projectVal) : null
        }
        const noOfDays = moment(form?.endDate).diff(moment(form?.startDate), 'days')
        if(noOfDays <= 7) {
            startDays = moment(form.startDate).format("MMM DD, YYYY")
            requestObject[`start_time`] = form?.startDate && moment(form.startDate).format();
            requestObject[`end_time`] = form?.endDate && moment(form.endDate).format();
            setView({
                ...view,
                isLoading: true,
                btnFlag: true,
            })
        } else {
            if(form?.endDateIndex && !view?.isLoading && !form?.isViewMoreLoading) {
                setForm({
                    ...form,
                    isViewMoreLoading: true,
                })
            }
            isMore = form?.endDateIndex ? true : false
            toDateIndex =  form?.endDateIndex && !isSubmitClicked ? moment(form.endDateIndex) : moment(form.endDate);
            daysDiff = moment(toDateIndex).diff(moment(form?.startDate), 'days')
            if(daysDiff > 7) {
                callMore= true
                fromDateIndex =   moment(toDateIndex).subtract(6, 'days')
            } else {
                fromDateIndex =   moment(toDateIndex).subtract(daysDiff, 'days')
                callMore = false
            }
            startDays = moment(new Date(fromDateIndex).setHours(0, 0, 0, 0)).format("MMM DD, YYYY")
            requestObject[`start_time`] = fromDateIndex && moment(new Date(fromDateIndex).setHours(0, 0, 0, 0)).format();
            requestObject[`end_time`] = toDateIndex && moment(toDateIndex).format()
        }
        dispatch(searchReferences(axiosReferenceSearchInstance, requestObject, isMore, isSubmitClicked)).then(resp => {
            isSubmitClicked = false
            if (resp.status === "success") {
                setView({
                    ...view,
                    isError: false,
                    errorMessage: "",
                    isLoading: false,
                    btnFlag: false,
                })
                setForm({
                    ...form,
                    endDateIndex: callMore ? moment(fromDateIndex).subtract(1, 'days') : null,
                    isViewMoreLoading: callMore && resp?.resLength == 0  ? true :  false,
                    isViewMoreBtnDisable: daysDiff && daysDiff > 7 ? true : false
                })
                isMore = false
            } else {
                setView({
                    ...view,
                    isError: true,
                    errorMessage: "Something went wrong!",
                    isLoading: false,
                    btnFlag: false,
                    endDateIndex: null
                })
            }
        })
    }

    useEffect(()=> {
       if(refSearchData?.length < 10 && form?.endDateIndex && callMore) {
        loadMoreData()
       }
    },[form?.endDateIndex])

    const loadMoreData = () => {
        loadReferenceList()
    }
    const isInvaild = () => {
        let isError = false;;
        let errors = { ...form.errors };
        if (!form.dates[0] || !form.dates[1]) {
            isError = true
            errors["dates"] = "Please select date range";
        } 
        // else if (!form.endDate) {
        //     isError = true
        //     errors["endDate"] = "Please enter end date";
        // }
        setForm({
            ...form,
            errors: errors
        })
        return isError
    }
    const handleOnChange = (e) => {
        const { name, value } = e.target;
        setForm({
            ...form,
            [name]: value,
            endDateIndex: null,
        })
        // amplitude.logEvent(`Keyboard Search, {Type:'Input', Page:'Conversations'}`);
    }
    const handleDateChange = (dates) => {
        let isError = { ...form.errors };
        let start = "", end=""; 
        if(dates) {
            [start, end] = dates;
        }
        isError["dates"] = !start && !end ? "Please select date range" : "";
        setForm({
            ...form,
            ["dates"]: [start, end],
            startDate: start,
            endDate: end,
            errors: isError
        })
        // amplitude.logEvent(`Date Range, {Type:'Input', Page:'Conversations'}`);
    }

    const handleModal = (event) => {
        setView({
            ...view,
            showModal: true,
            modalContent: event?.data
        })
    }

    const handleOpen = (item) => {
        setView({
            ...view,
            showModal: true,
            modalContent: item
        })
    }

    const closeModal = () => {
        setView({
            ...view,
            showModal: false,
            modalContent: {}
        })
    }

    const handleProjectChanges = (value) => {
        setForm({
            ...form,
            [`selectedProjects`]: value,
        })
    }
    const handleScoperChanges = (value) => {
        setForm({
            ...form,
            [`selectedScoperArr`]: value,
        })
    }

    const handleCoversationChange = (e) => {
        setForm({
            ...form,
            [`conversation_type`]: e.target.value,
        })
    }

    const handleFilterRemove = () => {
        setForm({
            ...form,
            [`selectedProjects`]: [],
            [`selectedScoper`] : "",
            [`conversation_type`] : "All",
            [`isExtraFilterAdded`] : false
        })
    }

    const HandleCustomDates = (number, period) => {
        handleDateChange([moment().subtract(number, period), moment(new Date().setHours(23, 59, 59, 0))])
    }


    return (
        <>  
        <Row className='m-0 conversation-box'>
            <Col>
           
           {view?.showModal &&  <SideNavbar show={view?.showModal} onHide={() => setView({...view,showModal:false})} modalData={view?.modalContent}/>}
            <Row className="m-0">
                <Col xs={12} className="m-b-20">
                    <div className="main-heading">All Conversations</div>
                </Col>
            </Row>
            <Row className="m-0 ">
                <Form className="top-header-box p-r-20 p-l-20 " autoComplete="off">
                    <Row className="m-0 ">
                        <Col xs={12} md={4} lg={4} xl={3}>
                            <Form.Group className="mb-3 m-r-10 w-100 position-relative" controlId="formBasicEmail">
                                <Form.Label>Date Range:</Form.Label>
                                <RangePickerWithCustomOptions selectedDate={form?.dates} handleDateRangeChange={date => handleDateChange(date)} HandleCustomDates={HandleCustomDates}/>
                                {form?.errors && form?.errors["dates"] && <span className="warningText card-body-paragraph">{form.errors["dates"]}</span>}
                            </Form.Group>
                        </Col>
                        <Col xs={12} md={4} lg={3} xl={3}>
                            <Form.Group className="mb-3 m-r-10 w-100" controlId="formBasicEmail">
                                <Form.Label>Keyboard Search:</Form.Label>
                                <Form.Control name="keyboardText" type="text" placeholder="" value={form?.keyboardText} onChange={handleOnChange} />
                            </Form.Group>
                        </Col>
                        {!form?.isExtraFilterAdded && <Col xs={12} md={4} lg={3} xl={2}>
                            <div
                              className="d-flex justify-content-start w-100 "
                            >
                              <div
                                className="black-box m-r-10 top-margin"
                                onClick={() =>{
                                     setForm({...form, isExtraFilterAdded : true})
                                    //  amplitude.logEvent(`Add Extra Filter, {Type:'Button', Page:'Conversations'}`);
                                    }}
                                 >
                                +
                              </div>
                            <button variant="secondary" className="black-box w-50" style={{marginTop:"34px"}} onClick={(e)=>handleSubmit(e)} disabled={view?.btnFlag}>Submit</button>
                            </div>
                        </Col>}
                    </Row>
                    {form?.isExtraFilterAdded && <div className=" m-b-20">
                    <Row className="m-0">
                        <Col xs={12} md={4} lg={3} xl={3}>
                            <Form.Group className="mb-3 m-r-10 w-100" controlId="formBasicEmail">
                                <Form.Label>Scoper:</Form.Label>
                               <Typeahead
                                    labelKey="full_name"
                                    id="dropdown"
                                    className="typeAhead"
                                    name={`scopers`}
                                    onChange={(val)=> handleScoperChanges(val)}
                                    options={scopers?.data || []}
                                    placeholder={`Select Scopers`}
                                    selected={form[`selectedScoperArr`]}
                                    useCache={false}
                                    multiple={true}
                                />
                            </Form.Group>
                        </Col>
                        <Col xs={12} md={4} lg={3} xl={3}>
                        <Form.Group className="mb-4" controlId="formBasicEmail">
                                <Form.Label>Projects:</Form.Label>
                                <Typeahead
                                    labelKey="display_name"
                                    id="dropdown"
                                    className="typeAhead"
                                    name={`projects`}
                                    onChange={(val)=> handleProjectChanges(val)}
                                    options={projectOptions || []}
                                    placeholder={`Select Projects`}
                                    selected={form[`selectedProjects`]}
                                    useCache={false}
                                    multiple
                                />
                                {/* {form[`errors`]?.[item?.name] && <span className="warningText card-body-paragraph">{form[`errors`]?.[item?.name] }</span>} */}
                            </Form.Group>
                        </Col>
                        <Col xs={12} md={4} lg={3} xl={3}>
                            <Form.Group className="mb-3 m-r-10 w-100" controlId="formBasicEmail">
                                <Form.Label>Conversation Type:</Form.Label>
                                <Form.Select name="selectedScoper" aria-label="Default select example" className="" onChange={(val) => handleCoversationChange(val)} >
                                    <option  value="all">All</option>
                                    <option  value="Reference Call">Reference call</option>
                                    <option value="Company Call">Company call</option>
                                </Form.Select>
                            </Form.Group>
                        </Col>
                       <Col xs={12} md={4} lg={3} xl={2}>
                            <div
                              className="d-flex justify-content-start w-100 "
                            >
                              <div
                                className="black-box m-r-10 top-margin"
                                onClick={() => handleFilterRemove()}
                              >
                                -
                              </div>
                            <button variant="secondary" className="top-margin black-box w-50 button-h" onClick={(e)=>handleSubmit(e)} disabled={view?.btnFlag}>Submit</button>
                            </div>
                        </Col> 
                    </Row>
                </div>
                }
                </Form>
            </Row>
            {view.isLoading || (form?.isViewMoreLoading && refSearchData?.length === 0) ? <Loader scale={30} message={"Loading Conversations..."} customStyle={"child"} /> : view?.isError ? <CommonError errorMessage={view?.errorMessage} isBlurredRequired={true}/> : refSearchData === null ? <NoFilter message={null}/> : <>

                <Row className="m-b-10 mt-4">
                    <Col xs={12}>
                        <div className="d-flex justify-content-end">
                            {refSearchData?.length ?<span className='summaryBox'>{refSearchData?.length} results</span>:""}
                            <span className="filter" style={{ cursor: "pointer" }}><ListViewSvg onClick={() =>{ 
                                setView({ viewType: "cardView" })
                                amplitude.logEvent(`Conversations Card View`);
                                }} /><CardViewSvg onClick={() => {
                                    setView({ viewType: "listView" })
                                    amplitude.logEvent(`Conversations List View`);
                                    }} /></span>
                        </div>
                    </Col>
                </Row>
                {view && view?.viewType === "cardView" ? <Row className="m-b-20">
                    {refSearchData?.length ? refSearchData.map((item, idex) => {
                        const contents  = item?.content?.replace(/\n|<.*?>/g,'')
                        return (
                            <Col xs={12} md={6} lg={4} className="mb-3">
                                <Card className="p-20 custom-card-style" key={idex} style={{ height: "360px" }} onClick={()=>handleOpen(item)}  >
                                    <div className="d-flex justify-content-between ">
                                        <div className="main-title w-75 singleLineTxt">{item?.conversation_type}</div>
                                           <span> <div className="lightGrey">Scoper</div>
                                            <div style={{color:"black",fontSize:"18px"}}>{item?.creator_name}</div>
                                            </span>
                                    </div>
                                    <div className="divider mt-2 mb-3"></div>
                                    <div className="card-body-paragraph mb-2" style={{ height: "100%", overflowY: "hidden" }}>
                                    <div className="primaryGrey mb-2">Projects:</div>
                                        {item?.project?.length ? 
                                            <div className="lightGrey singleLineTxt mb-2">{item?.project?.length ? item?.project?.toString() : null}</div>
                                       : <div className="lightGrey mb-2">-</div>}
                                        <div className="primaryGrey mb-2">Reference:</div>
                                         <div className="lightGrey singleLineTxt mb-2" >{contents}</div>
                                            <div className="primaryGrey mb-2">People Involved:</div>
                                            {item?.people_names?.length ? <div className="lightGrey mb-2">{item?.people_names?.length && item?.people_names?.toString()}</div>
                                         : <div className="lightGrey mb-2">-</div>}
                                        <div className="primaryGrey mb-2">Date:</div>
                                        <div className="lightGrey mb-2">{moment(item?.date).format("MMM DD YYYY")}</div>
                                    </div>
                                </Card>
                            </Col>
                        )
                    }) : <Col xs={12} md={6} lg={4} className="text-center">
                            <Card className="p-20 d-flex align-items-center justify-content-center secondaryGrey" style={{ height: "300px" }}>
                                No data available to preview
                        </Card>
                        </Col>}
                { form?.isViewMoreBtnDisable ?<>{form?.isViewMoreLoading ? <Col xs={12}>
                    <Loader scale={30} message={"Loading More..."} customStyle={"child"} />
                </Col> : null}<Col xs={12}><Button variant="primary" onClick={loadMoreData} className="w-25 p-2 mt-3" disabled={view?.isLoading}>View More</Button>&nbsp;</Col> </>: null}
                </Row> :
                    <Row className="m-b-20">
                        <Col xs={12} className="m-b-20">
                        <Card className="p-3 ag-grid-icon" style={{maxHeight:"650px"}}>
                            <TableContent
                                dataSet={rowData}
                                legends={legend}
                                handleRowClick={(event)=>handleModal(event)}
                                height={"400px"}
                            />
                            </Card>
                    
                        </Col>
                        {form?.isViewMoreBtnDisable ?<>{form?.isViewMoreLoading ? <Col xs={12}>
                    <Loader scale={30} message={"Loading More ..."} customStyle={"child"} />
                </Col> : null}<Col xs={12}><Button variant="primary" onClick={loadMoreData} className="w-25 p-2" >View More</Button>&nbsp;</Col> </>: null}
                    </Row>
                }
                <Row className="m-b-0">
                    <Col md={12}>
                        <div className="main-heading lightGrey">Analytics</div>
                    </Col>
                    <hr />
                    {refSearchData?.length ? <>
                        <Col xs={12} md={6} lg={6}>
                            <Card>
                                <div className="lightGrey main-title mb-3 p-l-15">References WoW</div>
                                <Card.Body className="p-10">
                                    {<Chart options={options} highcharts={Highcharts} />}
                                </Card.Body>
                            </Card>
                        </Col>
                    </> :
                        <Col xs={12} md={6} lg={4} className="text-center">
                            <Card className="p-20 fs-6 d-flex align-items-center justify-content-center secondaryGrey" style={{ height: "300px" }}>
                                No data available to preview
                        </Card>
                        </Col>
                        }
                </Row>
            </>}
            </Col>
        </Row>
        </>
    )
}
export default References;
