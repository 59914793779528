import * as actionTypes from "../actionTypes";
import { getAuthToken } from "../../utils"
import axios from "axios";

export const workboardUploadFile =
  (axiosInstance, data = {}) =>
    (dispatch) => {
      return axiosInstance({
        method: "POST",
        data: data,
        headers: {
          Authorization: getAuthToken(),
        },
        onUploadProgress: (progress) => {
          dispatch({ type: actionTypes.SET_UPLOAD_PERCENTAGE, data: Math.round((progress.loaded / progress.total) * 100) })

        }
      })
        .then((res) => {
          return { status: "success", data: res?.data }
        })
        .catch((err) => {
          console.log("Company document types response - error -> ", err);
          return { status: "failed", message: typeof err?.response?.data?.errors === "string" ? err?.response?.data?.errors : `Something went wrong!` };
        });
    };

export const getCompaniesForWorkboard = (axiosInstance, params) => (dispatch) => {
  return axiosInstance({
    method: "GET",
    data: {},
    params: {...params},
    headers: {
      "Authorization": getAuthToken()
    }
  })
    .then((res) => {
      const companies = res.data.results.map((item) => item.name)
      dispatch({ type: actionTypes.SET_COMPANIES_WORKBOARD, data: companies })
    })
    .catch((err) => {
      console.log(err)
    })
}

export const getWorkboardCategories = (axiosInstance, params) => (dispatch) => {
  return axiosInstance({
    method: "GET",
    data: {},
    params: {...params},
    headers: {
      "Authorization": getAuthToken()
    }
  })
    .then((res) => {
      const categories = res.data.results.map((item) => item.name)
      dispatch({ type: actionTypes.SET_CATEGORIES_WORKBOARD, data: categories })
    })
    .catch((err) => {
      console.log(err)
    })
}

export const getWorkboardPeople = (axiosInstance, params) => (dispatch) => {
  return axiosInstance({
    method: "GET",
    data: {},
    params: {...params},
    headers: {
      "Authorization": getAuthToken()
    }
  })
    .then((res) => {
      const people = res?.data?.data.map((item) => item.full_name)
      dispatch({ type: actionTypes.SET_PEOPLE_WORKBOARD, data: people })
    })
    .catch((err) => {
      console.log(err)
    })
}

export const createWorkbookFn = (axiosInstance, payload) => (dispatch) => {
  // return axiosInstance({
  //   method: "POST",
  //   data: {...payload},
  //   params: {},
  //   headers: {
  //     "Authorization": getAuthToken()
  //   }
  // })
  //   .then((res) => {
  //     dispatch({ type: actionTypes.SET_MY_WORKBOARDS, data: payload })
  //     dispatch(setWorkboardItem('myWorkboards'))
  //   })
  //   .catch((err) => {
  //     console.log(err)
  //   })

  dispatch({ type: actionTypes.SET_MY_WORKBOARDS, data: payload })
}

export const getMyWorkboard = (axiosInstance) => (dispatch) => {
  return axiosInstance({
    method: "GET",
    data: {},
    params: {},
    headers: {
      "Authorization": getAuthToken()
    }
  })
    .then((res) => {
      if (res.hasOwnProperty('error')) {
				return { status: "failed", errorMsg: "Something went wrong. Please try again later" }
			} else {
        dispatch({ type: actionTypes.SET_MY_WORKBOARDS, data: res?.data })
				return { status: "success" }
			}
    })
    .catch((err) => {
      return { status: "failed", errorMsg: "Something went wrong. Please try again later" }
      console.log(err)
    })
}

export const getWorkboardCompanies =
(url) => (dispatch) => {
    return axios.get(url, {headers: {
              Authorization: getAuthToken()
            }})
    .then((res) => {
      if (res.hasOwnProperty("error")) {
        return { status: "failed", errMsg:res?.error};
      } else if (res.hasOwnProperty("code") && res?.code === 422) {
        return { status: "failed", errMsg:res?.name};
      } else {
        dispatch({ type: actionTypes.SET_WORKBOARDS_COMPANY, data: res?.data});
        return { status: "success",};
      }
    })
    .catch((err) => {
      console.warn("Response - error -> ", err?.response?.data);
      if(err?.response?.data?.hasOwnProperty("code") && err?.response?.data?.code === 422){
        return { status: "failed", errMsg:err?.response?.data?.name};
      } else {
        return { status: "failed" };
      }
    });
};

