import * as actionTypes from '../actionTypes'

const INITIAL_STATE = {
    uploadPercentage: 0,
    uploadContent: [{}],
    uploadedFile: [],
    myWorkboards: {},
    myWorkboardsCompanies: {},
    upgradedWorkboards: [],
    companies: [],
    categories: [],
    people: [],
    dataTemplates: [],
    allAppliedFilters: {}
}

const workboardReducer = function (state = INITIAL_STATE, action) {
    switch (action.type) {
        // case actionTypes.SET_UPLOAD_PERCENTAGE:
        //     return {
        //         ...state,
        //         uploadPercentage: action.data
        //     }

        // case actionTypes.SET_UPLOAD_CONTENT:
        //     return {
        //         ...state,
        //         uploadContent: action.data
        //     }

        // case actionTypes.SET_UPLOADED_FILE:
        //     return {
        //         ...state,
        //         uploadedFile: action.data
        //     }

        case actionTypes.SET_MY_WORKBOARDS:
            return {
                ...state,
                myWorkboards: { ...action.data }
            }
        // case actionTypes.SET_UPGRADED_WORKBOARDS:
        //     return {
        //         ...state,
        //         upgradedWorkboards: [...state.myWorkboards, action.data]
        //     }
        // case actionTypes.SET_COMPANIES_WORKBOARD: {
        //     return {
        //         ...state,
        //         companies: action.data
        //     }
        // }

        // case actionTypes.SET_CATEGORIES_WORKBOARD: {
        //     return {
        //         ...state,
        //         categories: action.data
        //     }
        // }

        // case actionTypes.SET_PEOPLE_WORKBOARD: {
        //     return {
        //         ...state,
        //         people: action.data
        //     }
        // }

        // case actionTypes.SET_WORKBOARD_DATA_TEMPLATES:
        //     {
        //         return {
        //             ...state,
        //             dataTemplates: action.data
        //         }
        //     }

        case actionTypes.SET_WORKBOARDS: {
            return {
                ...state,
                myWorkboards: action.data
            }
        }
        case actionTypes.SET_WORKBOARDS_COMPANY: {
            return {
                ...state,
                myWorkboardsCompanies: action.data
            }
        }

        case actionTypes.SET_WORKBOARDS_FILTERS: {
            return {
                ...state,
                allAppliedFilters: action.data
            }
        }

        case actionTypes.UPDATE_MY_WORKBOARDS: {
            return {
                ...state,
                myWorkboards: {
                    ...state?.myWorkboards,
                    results: [...action.data]
                }
            }
        }
        
        default:
            return {
                ...state,
            }
    }
}

export { workboardReducer }