const appConfig = {
    appName: "Orion",
    poweredby: "",
    api: {
        DEV: {
            SERVER_ADDRESS: 'https://data-dev.telescope.vc/api/v1',
        },
        STAGING: {
            SERVER_ADDRESS: 'https://data-dev.telescope.vc/api/v1',
        },
        PROD: {
            SERVER_ADDRESS: 'https://data.telescope.vc/api/v1',
        }
    },
    redirect_uri: {
        DEV: {
            CALLBACK_URL: "http://localhost:3000/login"
        },
        STAGING: {
            CALLBACK_URL: "https://orion-dev.telescope.vc/login"
        },
        PROD: {
            CALLBACK_URL: "https://orion.telescope.vc/login"
        }
    }
}
export default appConfig;