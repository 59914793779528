import React, { useState } from 'react';
import moment from 'moment';
import { DatePicker } from 'antd';

import 'antd/dist/antd.css';
import "./customDate.scss"
const {RangePicker} = DatePicker;

const CustomRangePicker = ({handleDateRangeChange, selectedDate, placeholderText }) => {
  let placeholderFormatedText = placeholderText ? placeholderText : "MMM DD, YYYY"
  const disabledDate = (current) => {
    // Can not select days after today 
    return current && current > moment().endOf('day');
  };
  return (
      <RangePicker className='w-100 custome-date' value={selectedDate} onChange={handleDateRangeChange} format={"MMM DD, YYYY"} disabledDate={disabledDate}/>
  );
};
 export {CustomRangePicker}