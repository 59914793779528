'use strict';

import React, { useMemo, useRef, useState, useEffect, useCallback } from 'react';
import { AgGridReact } from 'ag-grid-react';
import { useDispatch } from 'react-redux';
import moment from "moment";

import CustomFilter from "../../commonComponents/TableView/CustomFilter";
import * as actionTypes from '../../store/actionTypes';

import { Downloader, ModuleRegistry } from 'ag-grid-community';
import { ClientSideRowModelModule } from 'ag-grid-community';

import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import "./tableContent.scss"



// Register the required feature modules with the Grid
ModuleRegistry.registerModules([ClientSideRowModelModule]);

var filterParams = {
    comparator: function (filterLocalDateAtMidnight, cellValue) {
        let cellDate = new Date(moment?.utc(cellValue).local().format("MMM DD YYYY")).getTime();
        if (filterLocalDateAtMidnight.getTime() === cellDate) {
            return 0;
        }
        if (cellDate < filterLocalDateAtMidnight.getTime()) {
            return -1;
        }
        if (cellDate > filterLocalDateAtMidnight.getTime()) {
            return 1;
        }
    },
    browserDatePicker: true,
};


const TableContent = ({ dataSet, legends, handleRowClick, handlePeoplesClicks }) => {
    const [viewAllProjects, setViewAllProjects] = useState({
        index: [],
    })
    const [viewAllFunction, setViewAllFunction] = useState({
        index: [],
    })
    const [viewAllReference, setViewAllReference] = useState({
        index: [],
    })
    const [viewAllPeoples, setViewAllPeoples] = useState({
        index: [],
    })
    const gridRef = useRef(null);
    const dispatch = useDispatch();
    const containerStyle = useMemo(() => ({ width: '100%', height: '100%' }), []);
    const [rowData, setRowData] = useState(null);
    const [columnDefs, setColumnDefs] = useState(null);
    const [finalColumnDefs, setFinalColumnDefs] = useState(null);
    const [appliedCol, setAppliedCol] = useState(null);

    useEffect(() => {
        return () => {
            dispatch({ type: actionTypes?.SET_APPLIED_COLUMN, data: [] })
        }
    }, [])

    const defaultColDef = useMemo(() => {
        return {
            flex: 1,
            minWidth: 180,
            sortable: true,
            suppressMenu: true,
            autoHeaderHeight: true,
            unSortIcon: true,
            filterParams: {
                buttons: ["reset"]
            }
        };
    }, []);

    const sideBar = useMemo(() => {
        return {
            toolPanels: [
                {
                    id: 'columns',
                    labelDefault: 'Columns',
                    labelKey: 'columns',
                    iconKey: 'columns',
                    toolPanel: CustomFilter,
                    toolPanelParams: {
                        apply: (callback, column) => applyFn(callback, column),
                        columnDefs: columnDefs,
                        moveColumn: (dragItemIndex, dragOverIndex, newArr) => moveColumnFn(dragItemIndex, dragOverIndex, newArr)
                    }
                },
            ],
            defaultToolPanel: 'customStats',
        };
    }, [columnDefs]);

    const applyFn = useCallback((item, column) => {
        gridRef?.current?.api?.setColumnDefs(item);
        gridRef?.current?.api?.closeToolPanel();
        setAppliedCol(item)
    }, []);

    const moveColumnFn = useCallback((dragItemIndex, dragOverIndex, newArr) => {
        gridRef?.current?.columnApi?.moveColumnByIndex(dragItemIndex, dragOverIndex);
        setFinalColumnDefs(newArr)
    }, []);

    const handleVisibleChange = (e) => {
        if (gridRef?.current?.api?.isToolPanelShowing()) {
            let checkedColumn = finalColumnDefs?.map(i => {
                let filteredItem = appliedCol?.filter(item => item?.field === i?.field)
                return {
                    ...i,
                    ["checked"]: (appliedCol === null ? true : filteredItem?.length ? filteredItem[0]?.checked : false)
                }
            })
            dispatch({ type: actionTypes?.SET_APPLIED_COLUMN, data: checkedColumn })
        }
    }

    useEffect(() => {
        let headers = legends
        let item = [];
        for (let key in headers) {
            if (key === "creator") {
                item?.push({ field: key, headerName: "Creator", minWidth: 220, cellRenderer: tableValue, filter: "agTextColumnFilter", })
            } else if (key?.includes("date") || key?.includes("time") || key?.includes("created_at")) {
                item?.push({ field: key, headerName: "Created At", valueFormatter: dateFormatter, filter: "agDateColumnFilter", filterParams: filterParams, maxWidth: 170 })
            } else if (key === "content") {
                item?.push({ field: key, headerName: headers[key], minWidth: 340, cellRenderer: textFormatter, filter: "agTextColumnFilter", })
            } else if (key === "people") {
                item?.push({ field: key, headerName: "People Involved", minWidth: 300, cellRenderer: renderPeoples, filter: "agTextColumnFilter", wrapText: true, autoHeight: true, })
            } else if (key === "all_projects") {
                item?.push({ field: key, headerName: headers[key], minWidth: 220, cellRenderer: renderProjetcs, filter: "agTextColumnFilter", wrapText: true, autoHeight: true, })
            } else if (key === "all_functions") {
                item?.push({ field: key, headerName: headers[key], minWidth: 220, cellRenderer: renderFunction, filter: "agTextColumnFilter", wrapText: true, autoHeight: true })
            } else if (key === "all_reference_types") {
                item?.push({ field: key, headerName: headers[key], minWidth: 220, cellRenderer: renderReference, filter: "agTextColumnFilter", wrapText: true, autoHeight: true })
            }
        }

       

        setColumnDefs(item);

        if (dataSet?.length) {
            let finalArray = dataSet?.map(item => {
                return {
                    ...item,
                    [`project`]: String(item?.project),
                }
            })
            setRowData(finalArray);
        } else {
            setRowData(dataSet);
        }
        setFinalColumnDefs(item)
    }, [dataSet, viewAllProjects, viewAllPeoples, viewAllFunction, viewAllReference])

    const dateFormatter = (params) => {
        let fieldName = params?.colDef?.field
        return `${moment?.utc(params.data[`${fieldName}`]).local().format("MMM DD YYYY")}`
    }

    const textFormatter = (value) => {
        const htmlParser = value?.valueFormatted ? value?.valueFormatted : value.value?.replace(/\n|<.*?>/g, '')
        return <span>
            {htmlParser}
        </span>
    }

    const tableValue = (props) => {
        const cellValue = props.valueFormatted ? props.valueFormatted : props.value;
        return <div className='project-container'><span className="projetcsCss"> {cellValue ? cellValue : "-"}</span></div>
    }

    const renderProjetcs = (props) => {
        const cellValue = props.value;
        return <div className='project-container'>
            {cellValue?.length > 1 && viewAllProjects?.index?.includes(props?.rowIndex) ? cellValue?.map(item => {
                return <span className="projetcsCss"
                >{item}</span>
            }) : cellValue?.length >= 1 ? <><span className="projetcsCss"
            >{cellValue[0]}</span> </> : "NA"}
            {cellValue?.length > 1 && !viewAllProjects?.index?.includes(props?.rowIndex) ? <span className="projetcsCss" ref={(ref) => {
                if (!ref) return;
                ref.onclick = (e) => {
                    e.stopPropagation();
                    let newArr = [...viewAllProjects?.index]
                    newArr.push(props?.rowIndex)
                    setViewAllProjects({ ...viewAllProjects, index: [...newArr] })
                };
            }}>+{cellValue?.length - 1}</span>
                : cellValue?.length > 1 && viewAllProjects?.index?.includes(props?.rowIndex) ? <span className="projetcsCss" ref={(ref) => {
                    if (!ref) return;
                    ref.onclick = (e) => {
                        e.stopPropagation();
                        let newArr = [...viewAllProjects?.index]
                        const newFilteredArray = newArr.filter(item => item !== props?.rowIndex);
                        setViewAllProjects({ ...viewAllProjects, index: [...newFilteredArray] })
                    };
                }}><b>Show Less</b> </span> : null}
        </div>
    }

    const renderFunction = (props) => {
        const cellValue = props.value;
        return <div className='project-container'>
            {cellValue?.length > 1 && viewAllFunction?.index?.includes(props?.rowIndex) ? cellValue?.map(item => {
                return <span className="projetcsCss"
                >{item}</span>
            }) : cellValue?.length >= 1 ? <><span className="projetcsCss"
            >{cellValue[0]}</span> </> : "NA"}
            {cellValue?.length > 1 && !viewAllFunction?.index?.includes(props?.rowIndex) ? <span className="projetcsCss" ref={(ref) => {
                if (!ref) return;
                ref.onclick = (e) => {
                    e.stopPropagation();
                    let newArr = [...viewAllFunction?.index]
                    newArr.push(props?.rowIndex)
                    setViewAllFunction({ ...viewAllFunction, index: [...newArr] })
                };
            }}>+{cellValue?.length - 1}</span>
                : cellValue?.length > 1 && viewAllFunction?.index?.includes(props?.rowIndex) ? <span className="projetcsCss" ref={(ref) => {
                    if (!ref) return;
                    ref.onclick = (e) => {
                        e.stopPropagation();
                        let newArr = [...viewAllFunction?.index]
                        const newFilteredArray = newArr.filter(item => item !== props?.rowIndex);
                        setViewAllFunction({ ...viewAllFunction, index: [...newFilteredArray] })
                    };
                }}><b>Show Less</b> </span> : null}
        </div>
    }

    const renderReference = (props) => {
        const cellValue = props.value;
        return <div className='project-container'>
            {cellValue?.length > 1 && viewAllReference?.index?.includes(props?.rowIndex) ? cellValue?.map(item => {
                return <span className="projetcsCss"
                >{item}</span>
            }) : cellValue?.length >= 1 ? <><span className="projetcsCss"
            >{cellValue[0]}</span> </> : "NA"}
            {cellValue?.length > 1 && !viewAllReference?.index?.includes(props?.rowIndex) ? <span className="projetcsCss" ref={(ref) => {
                if (!ref) return;
                ref.onclick = (e) => {
                    e.stopPropagation();
                    let newArr = [...viewAllReference?.index]
                    newArr.push(props?.rowIndex)
                    setViewAllReference({ ...viewAllReference, index: [...newArr] })
                };
            }}>+{cellValue?.length - 1}</span>
                : cellValue?.length > 1 && viewAllReference?.index?.includes(props?.rowIndex) ? <span className="projetcsCss" ref={(ref) => {
                    if (!ref) return;
                    ref.onclick = (e) => {
                        e.stopPropagation();
                        let newArr = [...viewAllReference?.index]
                        const newFilteredArray = newArr.filter(item => item !== props?.rowIndex);
                        setViewAllReference({ ...viewAllReference, index: [...newFilteredArray] })
                    };
                }}><b>Show Less</b> </span> : null}
        </div>
    }

    const renderPeoples = (props) => {

        const cellValue = props.value;

        return <div className='project-container'>

            {cellValue?.length > 2 && viewAllPeoples?.index?.includes(props?.rowIndex) ? cellValue?.map((item, idx) => {

                return <span className="projetcsCss"

                    ref={(ref) => {

                        if (!ref) return;

                        ref.onclick = (e) => {

                            e.stopPropagation();

                            handlePeoplesClicks(cellValue[idx], props?.rowIndex, idx)

                        };

                    }}

                ><span className={item?.incomplete ? 'redDot' : 'greenDot'}></span> {item?.name}</span>

            }) : cellValue?.length >= 1 ? <> <span className="projetcsCss"

                ref={(ref) => {

                    if (!ref) return;

                    ref.onclick = (e) => {

                        e.stopPropagation(); // this works

                        handlePeoplesClicks(cellValue[0], props?.rowIndex, 0)

                    };

                }}

            ><span className={cellValue[0]?.incomplete ? 'redDot' : 'greenDot'}></span> {cellValue[0].name}</span> {cellValue?.length >= 2 && (<span className="projetcsCss"

                ref={(ref) => {

                    if (!ref) return;

                    ref.onclick = (e) => {

                        e.stopPropagation(); // this works

                        handlePeoplesClicks(cellValue[1], props?.rowIndex, 1)

                    };

                }}

            > <span className={cellValue[1]?.incomplete ? 'redDot' : 'greenDot'}></span> {cellValue[1].name}</span>)} </> : "NA"}

            {cellValue?.length > 2 && !viewAllPeoples?.index?.includes(props?.rowIndex) ? <span className="projetcsCss" ref={(ref) => {

                if (!ref) return;

                ref.onclick = (e) => {

                    e.stopPropagation();

                    let newArr = [...viewAllPeoples?.index]

                    newArr.push(props?.rowIndex)

                    setViewAllPeoples({ ...viewAllPeoples, showAll: true, index: [...newArr] })

                };

            }}>+{cellValue?.length - 2}</span> : cellValue?.length > 2 && viewAllPeoples?.index?.includes(props?.rowIndex) ? <span className="projetcsCss" ref={(ref) => {

                if (!ref) return;

                ref.onclick = (e) => {

                    e.stopPropagation();

                    let newArr = [...viewAllPeoples?.index]

                    const newFilteredArray = newArr.filter(item => item !== props?.rowIndex);

                    setViewAllPeoples({ ...viewAllPeoples, index: [...newFilteredArray] })



                };

            }}><b>Show Less</b></span> : null}

        </div>

    }

    useEffect(() => {
        if (gridRef && gridRef?.current) {
            gridRef?.current?.api?.showLoadingOverlay()
        }
    }, [])

    return (
        <div style={containerStyle} className="ag-grid-box-ref">
            <div className="ag-theme-alpine position-relative" style={{ textTransform: "capitalize" }}>
                <AgGridReact
                    className={`custom-ag-grid ${rowData?.length < 12 ? "smallerHeight" : ""}`}
                    ref={gridRef}
                    rowData={rowData}
                    columnDefs={columnDefs}
                    defaultColDef={defaultColDef}
                    pagination={false}
                    onRowClicked={handleRowClick}
                    overlayLoadingTemplate={`<span class="ag-overlay-loading-center">Please wait while we are fetching the data...</span>`}
                    overlayNoRowsTemplate={`<span style="padding: 10px; color:#5f646a; font-size:16px; text-transform:initial" >No data available to preview.</span>`}
                    onToolPanelVisibleChanged={handleVisibleChange}
                    sideBar={rowData?.length ? sideBar : null}
                    rowHeight={42}
                    suppressScrollOnNewData ={true}
                >
                </AgGridReact>
            </div>
        </div>
    );
};

export { TableContent };
