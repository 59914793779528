import * as actionTypes from "../actionTypes";
import axios from 'axios';
import {getAuthToken} from "../../utils"
import * as amplitude from '@amplitude/analytics-browser';

export const getLoggedInUser = (axiosInstance, data) => (dispatch) => {
  return axiosInstance({
    method: "GET",
    params: { ...data },
    headers: {
      Authorization: localStorage.getItem("accessToken")
        ? "Bearer " + localStorage.getItem("accessToken")
        : null,
    },
  })
    .then((res) => {
      localStorage.setItem("user_email", JSON.stringify(res?.data?.email))
      amplitude.setUserId(JSON.stringify(res?.data?.email))
      dispatch(setLoggedInUser(res.data));
      
    })
    .catch((err) => {
      console.log("Company Updates response -  error -> ", err);
      return { status: "failed" };
    });
};

const setLoggedInUser = (data) => ({
  type: actionTypes.SET_LOGGED_IN_USER_INFO,
  data,
});

export const getDashboardInsightsCard = (axiosInstance, params) => (dispatch) => {
  return axiosInstance({
    method: "GET",
    data: {},
    params: {...params},
    headers: {
      Authorization: getAuthToken()
    },
  })
    .then((res) => {
      dispatch(setDashboardInsightsCard(res?.data));
      return { status: "success" }
    })
    .catch((err) => {
      console.warn("Response - error -> ", err?.response?.data?.Message);
      return { status: "failed", errorMessage: err?.response?.data?.message || "Something went wrong!" }
    });
}

const setDashboardInsightsCard = (data, type) => ({
  type: actionTypes.DASHBOARD_INSIGHTS_CARD,
  data,
});

export const getAllDashboardResponse = (arr) => (dispatch)=> {
  return Promise.allSettled([...arr]).then(axios.spread((...responses) => {
    if(responses[0]?.status === "fulfilled") {
      dispatch(setDashboardInsightsCard(responses[0]?.value?.data));
    }
    if(responses[1]?.status === "fulfilled") {
      dispatch({ type: actionTypes.SET_TP_PIPELINE, data: responses[1]?.value?.data });
    }
    return {res: responses}
  })).catch(errors => {
    console.log("errors", errors)
  })
}

export const getDashboardPipelineManagement = (axiosInstance, params) => (dispatch) => {
  return axiosInstance({
    method: "GET",
    data: {},
    params: { ...params },
    headers: {
      Authorization: getAuthToken()
    },
  })
    .then((res) => {
      dispatch(setDashboardPipelineManagement(res?.data?.results));
      return { status: "success" }
    })
    .catch((err) => {
      console.warn("Response - error -> ", err?.response?.data?.Message);
      return { status: "failed", errorMessage: err?.response?.data?.message || "Something went wrong!" }
    });
}

const setDashboardPipelineManagement = (data, type) => ({
  type: actionTypes.DASHBOARD_PIPELINE_MANAGEMENT,
  data,
});

export const getDashboardPipelineManagementViewAllLink = (axiosInstance, params) => (dispatch) => {
  return axiosInstance({
    method: "GET",
    data: {},
    params: { ...params },
    headers: {
      Authorization: getAuthToken()
    },
  })
    .then((res) => {
      dispatch(setDashboardPipelineManagementViewAllLink(res?.data));
      return { status: "success" }
    })
    .catch((err) => {
      console.warn("Response - error -> ", err?.response?.data?.Message);
      return { status: "failed", errorMessage: err?.response?.data?.message || "Something went wrong!" }
    });
}

const setDashboardPipelineManagementViewAllLink = (data, type) => ({
  type: actionTypes.DASHBOARD_PIPELINE_MANAGEMENT_VIEWALL_LINK,
  data,
});

export const getEmployeePopulation= (axiosInstance, params) => (dispatch) => {
  return axiosInstance({
    method: "GET",
    data: {},
    params: {...params},
    headers: {
      Authorization: getAuthToken()
    },
  })
    .then((res) => {
      dispatch({type: actionTypes.SET_EMPLOYEE_POPULATION, data: res?.data?.results});
      return { status: "success" }
    })
    .catch((err) => {
      console.warn("Response - error -> ", err?.response?.data?.Message);
      return { status: "failed", errorMessage: err?.response?.data?.message || "Something went wrong!" }
    });
}
