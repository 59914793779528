import React, { useState, useEffect } from 'react'
import { Button } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux';
import * as actionTypes from "../../../store/actionTypes";
import { useNavigate, useParams, useLocation } from 'react-router-dom';


import { WorkboardTable } from "./WorkboardTable"
import { getAddress } from "../../../utils/getServerAddress";
import { getWorkboardCompanies } from "../../../store/actions";
import LoaderTable from "../../../commonComponents/LoadingAnimation/LoaderTable";

import {getTpStatusOptions, getPipelineTpOwner, getFunction} from "../../../store/actions";
import {axiosCompanyPageTpStatusOptions, axiosCompanyPipelineTpOwner, axiosGetFunction} from "../../../config/appConfig/axiosInstance";

import { ReactComponent as BackIcon } from "../../../assets/images/icon_svg/back.svg";
import './myWorkboard.scss'

const MyWorkboard = ({ onHide, selectedWorkboard }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const {id} = useParams();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const selectedCategory = queryParams.get('param');
    const workboard_name = queryParams.get('name');
    const [myWorkboardsCompanies, companyTpStatus, companyTpOwner, refDropdown] = useSelector(({ workboardReducer, companyProfileReducer, referenceReducer }) => [workboardReducer?.myWorkboardsCompanies, companyProfileReducer?.companyPageTpStatusOptions, companyProfileReducer?.companyPagePipelineTpOwner, referenceReducer?.refDropdown])


    useEffect(()=> {
        if(!id) {
            dispatch({ type: actionTypes.SET_WORKBOARDS_COMPANY, data: {}});
            setTotalLen({
                ...totalLen,
                count: null
            })
            onHide();
        }
    },[id])

    const [totalLen, setTotalLen] = useState({
        count: null,
        isFilterApplied: false
    })
    const [tableView, setTableView] = useState({
        isLoading: false,
        hasError: false,
        errorMsg: ""
    });

    useEffect(()=> {
        if(!companyTpStatus || !companyTpStatus?.data) {
            dispatch(getTpStatusOptions(axiosCompanyPageTpStatusOptions))
        }

        // if(!companyTpOwner || !companyTpOwner?.results) {
        //     dispatch(getPipelineTpOwner(axiosCompanyPipelineTpOwner))
        // }
        if(!(refDropdown && Object.keys(refDropdown)?.length)) {
            dispatch(getFunction(axiosGetFunction));
        }

    },[])

    useEffect(() => {
        setTotalLen({
            ...totalLen,
            count: selectedCategory === "People" ? myWorkboardsCompanies?.counts : myWorkboardsCompanies?.total_count
        })
    }, [myWorkboardsCompanies])


    useEffect(() => {
        if (id) {
            const url = selectedCategory === "People" ? `${getAddress() + `/workboards/${id}/people`}` : `${getAddress() + `/workboards/${id}/companies`}`
            setTableView({ ...tableView, isLoading: true })
            dispatch(getWorkboardCompanies(url)).then((res) => {
                if (res?.status === "success") {
                    setTableView({ ...tableView, isLoading: false, hasError: false, errorMsg: "" })
                } else {
                    setTableView({ ...tableView, isLoading: false, hasError: false, errorMsg: "Something went wrong. Please try again later!" })
                }

            })
        }
    }, [id])

    const handleCountUpdate = (count) => {
        setTotalLen({
            ...totalLen,
            count: count
        })
    }

    const handleback = () => {
        // localStorage.removeItem('currentWorkboardPage');
        navigate('/workboard');
        dispatch({ type: actionTypes.SET_WORKBOARDS_COMPANY, data: {}});
        dispatch({ type: actionTypes.SET_WORKBOARDS_FILTERS, data: {}});
        setTotalLen({
            ...totalLen,
            count: null
        })
        onHide();
    }

    return <>
        <div className='my-workboard-view'>
            {/* <div className='horizontal-line'></div> */}
            <div className='d-flex justify-content-between align-items-center mb-2 p-10'>
                <div className='center-item main-title'>
                    <div className='center-item'>
                        <span className='back-icon' onClick={() => handleback()}><BackIcon /></span>
                        {/* <span className='card-body-paragraph'>My Workboard</span> */}
                    </div>
                    <span>{workboard_name}</span>
                </div>
                {totalLen?.count ? <div className='comp-count'><b >{totalLen?.count}</b> results</div> : null}
            </div>
            <div className='ag-grid-box'>
                {tableView?.isLoading ? <LoaderTable /> : <WorkboardTable selectedWorkboard={{category: selectedCategory, workboard_id:id}} handleCountUpdate={handleCountUpdate} />}
            </div>
        </div>
    </>
}

export default MyWorkboard