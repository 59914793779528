import React, { useState } from 'react';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import { ReactComponent as CompleteIcon } from '../../assets/images/icon_svg/complete.svg';
import { ReactComponent as IncompleteIcon } from '../../assets/images/icon_svg/incomplete.svg';
import { ReactComponent as SnoozeIcon } from '../../assets/images/icon_svg/snooze.svg';
import { ReactComponent as CancelIcon } from '../../assets/images/icon_svg/cancel.svg';
import { ReactComponent as UpComingIcon } from '../../assets/images/icon_svg/upcoming.svg';
import './statusTooltip.scss';

const statusIcons = {
  delete: <CancelIcon />,
  completed: <CompleteIcon />,
  snooze: <SnoozeIcon />,
  incomplete: <IncompleteIcon />,
  upcoming: <UpComingIcon />,
  pending: <UpComingIcon />,
  passed: <IncompleteIcon />,
};

const statusTexts = {
  delete: 'Cancelled',
  completed: 'Complete',
  snooze: 'Snooze',
  incomplete: 'Incomplete',
  upcoming: 'Upcoming',
  pending: 'Upcoming',
  passed: 'Incomplete',
};

const StatusTooltip = ({ currentStatus, onChangeStatus }) => {
  const [showTooltip, setShowTooltip] = useState(false);

  const handleMouseEnter = () => setShowTooltip(true);
  const handleMouseLeave = () => setShowTooltip(false);
  const handleTooltipMouseEnter = () => setShowTooltip(true);
  const handleTooltipMouseLeave = () => setShowTooltip(false);

  const handleStatusChange = (status) => {
    setShowTooltip(false);
    onChangeStatus(status);
  };

  const renderTooltipContent = () => (
    <div
      className="tooltip-content"
      onMouseEnter={handleTooltipMouseEnter}
      onMouseLeave={handleTooltipMouseLeave}
    >
      {Object.keys(statusIcons)
        ?.filter((status) => ['completed', 'delete', 'snooze'].includes(status))
        ?.map((status) => (
          <div
            key={status}
            className={`status-option d-flex align-items-center ${status === 'snooze' && currentStatus === 'snooze' ? 'active' : ''}`}
            onClick={() => handleStatusChange(status)}
          >
            {statusIcons[status]}
            <span>{statusTexts[status]}</span>
          </div>
        ))}
    </div>
  );

  return (
    <div
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      className="status-tooltip-wrapper"
    >
      <OverlayTrigger
        placement="top"
        show={showTooltip}
        overlay={
          <Popover id="status-tooltip">
            <Popover.Body>
              <div className="tooltip-content">
                {(currentStatus === 'passed' || currentStatus === 'incomplete' || currentStatus === 'snooze') ? (
                  <>
                    {renderTooltipContent()}
                  </>
                ) : (
                  <div className="status-option d-flex align-items-center">
                    {statusIcons[currentStatus?.toLowerCase()]}
                    <span>{statusTexts[currentStatus?.toLowerCase()]}</span>
                  </div>
                )}
              </div>
            </Popover.Body>
          </Popover>
        }
        rootClose={true}
      >
        <span className={`status-icon d-flex align-items-center ${['passed', 'incomplete', 'snooze']?.includes(currentStatus?.toLowerCase()) ? '' : 'disabled-icon'}`}>
          {statusIcons[currentStatus?.toLowerCase()]}
        </span>
      </OverlayTrigger>
    </div>
  );
};

export default StatusTooltip;
