import * as actionTypes from "../actionTypes";

const INITIAL_STATE = {
  favResult: null,
  fileUploads: {},
  documentTypes: null,
  fileUploadStatus: null,
  companyFavourite: null,
  isCompanyProfileLoading: false,
  companyPageDetails: {},
  companyPageMeetingStat: {},
  companyTpStatusHistory: {},
  companyPageTpStatusOptions: {},
  companyPagePipelineTpOwner: {},
  companyProject: {},
  addTpOwner:{},
  addTpStatus:{},
  emailChart:{},
  emailDetails: {},
  affinityList: [],
  selectedCompanyAffinityList: {},
};
const companyProfileReducer = function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case actionTypes.COMPANY_PROFILE_FAVORITE:
      return {
        ...state,
        favResult: { ...action.data },
      };
    case actionTypes.COMPANY_PROFILE_FILE_UPLOADS:
      return {
        ...state,
        fileUploads: { ...action.data },
      };
    case actionTypes.COMPANY_PROFILE_DOCUMENT_TYPES:
      return {
        ...state,
        documentTypes: { ...action.data },
      };
    case actionTypes.COMPANY_PROFILE_FILE_UPLOADED:
      return {
        ...state,
        fileUploadStatus: { ...action.data },
      };
    case actionTypes.COMPANY_PROFILE_ALERT:
      return {
        ...state,
        companyAlerts: { ...action.data },
      };
    case actionTypes.COMPANY_PROFILE_FAVOURITE:
      return {
        ...state,
        companyFavourite: { ...action.data },
      };
    case actionTypes.COMPANY_PROFILE_LOADING:
      return {
        ...state,
        isCompanyProfileLoading: action.data,
      };
    case actionTypes.COMPANY_PAGE_DETAILS:
      return {
        ...state,
        companyPageDetails: { ...action.data },
      }
    case actionTypes.COMPANY_PAGE_MEETING_STAT:
      return {
        ...state,
        companyPageMeetingStat: { ...action.data }
      }
    case actionTypes.COMPANY_PAGE_TP_STATUS_HISTORY:
      return {
        ...state,
        companyTpStatusHistory: { ...action.data }
      }
    case actionTypes.COMPANY_PAGE_TP_STATUS_OPTIONS:
      return {
        ...state,
        companyPageTpStatusOptions: { ...action.data }
      }
    case actionTypes.COMPANY_PAGE_PIPELINE_TP_OWNER:
      return {
        ...state,
        companyPagePipelineTpOwner: { ...action.data }
      }
    case actionTypes.COMPANY_PROFILE_PROJECT:
      return {
        ...state,
        companyProject: { ...action.data }
      }
    case actionTypes.COMPANY_ADD_TP_OWNER:
      return {
        ...state,
        addTpOwner:{...action.data}
      }
    case actionTypes.COMPANY_ADD_TP_STATUS:
      return {
        ...state,
        addTpStatus:{...action.data}
      }
    case actionTypes.COMPANY_EMAIL_CHART:
      return {
        ...state,
        emailChart:{...action.data}
      }
    case actionTypes.COMPANY_EMAIL_DETAILS:
      return {
        ...state,
        emailDetails:{...action.data}
      }
    case actionTypes.GET_AFFINITY_LIST:
      return {
        ...state,
        affinityList:[...action.data]
      }
    case actionTypes.GET_SELECTED_LIST:
      return {
        ...state,
        selectedCompanyAffinityList:{...action.data}
      }
    case actionTypes.SET_PEOPLE_WORKBOARD:
      return {
        ...state,
        tpOwners: action.data,
      };
      
    default:
      return {
        ...state,
      };
  }
};

export { companyProfileReducer };
