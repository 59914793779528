import React, { useState, useRef, useLayoutEffect, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import moment from "moment";
import { Typeahead } from 'react-bootstrap-typeahead';
import { Col, Row, Button, Offcanvas, Form } from 'react-bootstrap';
import axios from 'axios';
import * as amplitude from '@amplitude/analytics-browser';

import { ReactComponent as BackSvg } from "../../assets/images/icon_svg/back.svg";
import { getAddress } from "../../utils/getServerAddress";

import {updateReferences, addPersonToRef, getLoggedInUser} from "../../store/actions";
import { axiosAddPersonToRef, axiosUserInfoInstance } from "../../config/appConfig/axiosInstance";
import { LoadingBlock } from "../../commonComponents/LoadingAnimation/LoaderComponents";
import SubmitCard from "../../commonComponents/SubmitCard/SubmitCard";
import imgLoader from "../../assets/images/spinner.gif";
import { getAuthToken } from '../../utils';


import './conversationDetails.scss'

const defaultVal = {display_name: "All", server_value: "all"}

const SideNavbar = ({ show, onHide, modalData, selectedRowIndex,  selectedRefType, callbackFn }) => {
    const dispatch = useDispatch();
    const [refDropdown, scopers, userInfo] = useSelector(({ referenceReducer, commonReducer, dashBoardReducer }) => [referenceReducer?.refDropdown, commonReducer.scopers, dashBoardReducer?.userInfo])

    const [loader, setLoader] = useState({
        isloadingPage: false,
        openModal: false,
        isSuccess: null,
        message: "",
    });
    const [isAddRefContactLoading, setIsAddRefContactLoading] = useState({})
    const [currentPage, setCurrentPage] = useState({
        selectedView: "reference_details_page",
        editContent: {...modalData},
    })
    const [dropdowns, setDropdowns] = useState({
        peoples: [],
        projects:[],
        creator: []
    })

    const [editForm, setEditForm] = useState({
        selectedProjects: [defaultVal],
        selectedScoperArr: [{full_name: "All", email_address : "all", first_name:"all"}],
        selectedPeoples: [{name: "All", email:"all", functions: [], rating: [],reference_types: [], projects: []}]
    })

    // useEffect(()=>{
    //     dispatch(getLoggedInUser(axiosUserInfoInstance));
    // },[])

    useEffect(()=>{
        if(scopers?.data.length || (refDropdown && Object.keys(refDropdown).length) || modalData?.people?.length){
            setDropdowns({
                ...dropdowns,
                creator: [...scopers?.data],
                projects: [{display_name: "All", server_value: "all"}, ...refDropdown?.projects],
                peoples: [...modalData?.people]
            })
        }
    },[scopers?.data, refDropdown])

    useEffect(()=>{
        if(currentPage?.selectedView === "edit_reference_page" ){
            let allPeoplesArr = [...currentPage?.editContent?.people];
            let selectedProjects = refDropdown?.projects?.filter((i, idx) => currentPage?.editContent?.all_projects?.some(it => it === i.display_name))
            let selectedScopers =  scopers?.data?.filter((i) => i?.full_name === currentPage?.editContent?.creator)
            let selectedPeoples = allPeoplesArr?.length ? allPeoplesArr?.map(item => {
                return {
                    ...item,
                    projects: item?.projects?.length ? refDropdown?.projects.filter(item1 =>
                        item?.projects.some(item2 => item2 === item1.server_value)
                      ) : refDropdown?.projects?.length ? [refDropdown?.projects[0]]  :[],
                    functions: item?.functions?.length ? refDropdown?.functions.filter(item1 =>
                        item?.functions.some(item2 => item2 === item1.server_value)
                      ) : [],
                    rating: item?.rating ? refDropdown?.ratings.filter(item1 => item?.rating === item1.server_value) : refDropdown?.ratings?.length ? [refDropdown?.ratings[0]] : [],
                    reference_type: item?.reference_type?.length ? refDropdown?.reference_types.filter(item1 =>
                        item?.reference_type.some(item2 => item2 === item1.server_value)
                      ) : []
                }
            })  : []
            setEditForm({
                ...editForm,
                selectedProjects : [...selectedProjects],
                selectedScoperArr: selectedScopers?.length ? [...selectedScopers] : currentPage?.editContent?.creator ? [{full_name: `${currentPage?.editContent?.creator}`, email_address : `${currentPage?.editContent?.creator}`, first_name:`${currentPage?.editContent?.creator}`}] : [],
                selectedPeoples: [...selectedPeoples]
            })
        }
    },[currentPage?.selectedView])

    const handleScoperChanges = (value) => {}

    const handlePeoplsChanges = (value) => {}

    const handleOverallProjectChanges = (value) => {}

    const getRatingValue = (ratingNumber) => {
        let rating = "NA";
        if(refDropdown && Object?.keys(refDropdown).length) {
            let filteredItem = refDropdown?.ratings?.filter(i => Number(i.server_value) === Number(ratingNumber))
            rating = filteredItem?.length ? filteredItem[0].display_name : `NA`
        }
        return rating;
    }

    function handleDropdownChange(value, index, name){
        let originialPeoplsClone = [...editForm['selectedPeoples']]
        originialPeoplsClone[index][`${name}`] = value
        setEditForm({
            ...editForm,
            [`selectedPeoples`]: [...originialPeoplsClone]
        })
    }

    function handleProjectsDropdownChange(value, index, name, existedProjects){
        let originialPeoplsClone = [...editForm['selectedPeoples']]
        let finalResult; 
        if(value?.length === 0) {
            finalResult = refDropdown?.projects?.length ? refDropdown?.projects?.filter(item => item?.server_value == "No Project Required") : []
        } else if (value?.length >1) {
            if(existedProjects.some(item => item?.server_value === "No Project Required")){
                finalResult = value?.filter(item => item?.server_value != "No Project Required")
            } else if(value.some(item => item?.server_value === "No Project Required")) {
                finalResult = value?.filter(item => item?.server_value == "No Project Required")
            } else {
                finalResult = value
            }
        } else {
            finalResult = value
        }
        originialPeoplsClone[index][`${name}`] =  finalResult
        setEditForm({
            ...editForm,
            [`selectedPeoples`]: [...originialPeoplsClone]
        })
    }

    function handleRatingDropdownChange(val, index, name){
        let originialPeoplsClone = [...editForm['selectedPeoples']]
        originialPeoplsClone[index][`${name}`] = val?.length == 0 ? (refDropdown?.ratings?.length ? refDropdown?.ratings?.filter(i => i?.server_value == 0) : []) : val?.length == 1 ? val: val?.length > 1 ? [val[1]] : []
        setEditForm({
            ...editForm,
            [`selectedPeoples`]: [...originialPeoplsClone]
        })
    }

   

    const handleEdit = () => {
        setCurrentPage({
            ...currentPage,
            selectedView: 'edit_reference_page',
        })
        let finalObject = {}
        currentPage?.editContent?.people?.map((i)=> {
            finalObject[`${i?.affinity_id}_isLoading`] = false
        })
        amplitude.logEvent('Reference Edit');
    }

    const handleSave = () => {
        const editedPeople = editForm[`selectedPeoples`]?.map(item => {
            return {
                ...item,
                projects:  item?.projects?.map(i => i?.server_value),
                functions: item?.functions?.map(i => i?.server_value),
                rating: item?.rating[0]?.server_value || 0,
                reference_type: item?.reference_type?.map(i => i?.server_value),
                incomplete: item?.projects?.length && item?.functions?.length && item?.reference_type?.length && item?.rating?.length && item.rating[0].server_value !== 0  ? false : true,
            }
        })
        const allData = {
            ...currentPage?.editContent,
            people: [...editedPeople],
            all_projects: editForm[`selectedPeoples`]?.reduce(function (acc, currentArray) {
                currentArray?.projects?.forEach(function (item) {
                  if (!acc.includes(item?.server_value)) {
                    acc.push(item?.server_value);
                  }
                });
                return acc;
              }, [])
        }
        const header = {
            "Authorization": getAuthToken()
        }
        let requests =  editedPeople?.map((item, index)=> {
            const payload = {
                projects: item?.projects,
                person_id: item?.affinity_id,
                functions: item?.functions,
                rating: item?.rating || 0,
                reference_types: item?.reference_type,
            }
            return axios.post(`${getAddress()}/people-metadata-update`, payload, { headers: header });
        })
        setLoader({ ...loader, isloadingPage: true })
        amplitude.logEvent('Reference Saved');
        dispatch(updateReferences(requests, allData, selectedRowIndex, selectedRefType)).then(res =>{
            if (res?.status === "success") {
                setLoader({ ...loader, isloadingPage: false, message: "Data Saved Successfully", openModal: true,isSuccess: true})
                setCurrentPage({...currentPage, selectedView : "reference_details_page", editContent: allData})
                callbackFn()
            } else {
                setLoader({ ...loader, isloadingPage: false, message: res?.errMsg || "Error Saving Data", openModal: true,isSuccess: false})
            }
        })
    }

    const handleAddToRefContacts = (selectedItem) => {
        const host = window.location.protocol + "//" + window.location.host;
        let payload = {
            email: selectedItem?.email,
            workboard_id: host && (host.includes("orion.telescope.vc") ||  host.includes("awseb-awseb-ij29h3qm1x06-1410843689.us-west-2.elb.amazonaws.com")) ? "aead4d58-b6b3-4a93-aca4-b7195455033f" : "c81a0c87-5727-49de-afc1-aa75c62f48a4"
        }
        const editedPeople = editForm[`selectedPeoples`]?.map(item => {
            return {
                ...item,
                in_reference_contacts: selectedItem?.affinity_id === item?.affinity_id ? !item?.in_reference_contacts : item?.in_reference_contacts,
                projects:  item?.projects?.map(i => i?.server_value),
                functions: item?.functions?.map(i => i?.server_value),
                rating: item?.rating[0]?.server_value || 0,
                reference_type: item?.reference_type?.map(i => i?.server_value),
            }
        })
        const allData = {
            ...currentPage?.editContent,
            people: [...editedPeople],
        }
        let finalObj = {
            [`${selectedItem?.affinity_id}_isLoading`]: true
        }
        setIsAddRefContactLoading(finalObj)
        dispatch(addPersonToRef(axiosAddPersonToRef, payload)).then(res => {
            finalObj = {
                [`${selectedItem?.affinity_id}_isLoading`]: false
            }
            setIsAddRefContactLoading(finalObj)
            if (res?.status === "success") {
                setLoader({ ...loader, isloadingPage: false, message: "Data Saved Successfully", openModal: true,isSuccess: true})
                setCurrentPage({...currentPage, editContent: allData})
                const updatedPeopleForm = editForm[`selectedPeoples`]?.map(item => {
                    return {
                        ...item,
                        in_reference_contacts: selectedItem?.affinity_id === item?.affinity_id ? !item?.in_reference_contacts : item?.in_reference_contacts,
                    }
                })
                setEditForm({
                    ...editForm,
                    selectedPeoples: [...updatedPeopleForm]
                })
                callbackFn()
            } else {
                allData?.people?.map(item => {
                    return {
                        ...item,
                        in_reference_contacts: selectedItem?.affinity_id === item?.affinity_id ? !item?.in_reference_contacts : item?.in_reference_contacts
                    }
                })
                setLoader({ ...loader, isloadingPage: false, message: "Error Saving Data", openModal: true,isSuccess: false})
            }
        })
    } 
    const handleAddToRefContactsFromDetails = (selectedItem) => {
        const host = window.location.protocol + "//" + window.location.host;
        let payload = {
            email: selectedItem?.email,
            workboard_id: host && (host.includes("orion.telescope.vc") ||  host.includes("awseb-awseb-ij29h3qm1x06-1410843689.us-west-2.elb.amazonaws.com")) ? "aead4d58-b6b3-4a93-aca4-b7195455033f" : "c81a0c87-5727-49de-afc1-aa75c62f48a4"
        }
        const editedPeople = currentPage?.editContent?.people?.map(item => {
            return {
                ...item,
                in_reference_contacts: selectedItem?.affinity_id === item?.affinity_id ? !item?.in_reference_contacts : item?.in_reference_contacts,
            }
        })
        const allData = {
            ...currentPage?.editContent,
            people: [...editedPeople],
        }
        let finalObj = {
            [`${selectedItem?.affinity_id}_isLoading`]: true
        }
        setIsAddRefContactLoading(finalObj)
        amplitude.logEvent('Add to Reference Contact Clicked');
        dispatch(addPersonToRef(axiosAddPersonToRef, payload)).then(res => {
            finalObj = {
                [`${selectedItem?.affinity_id}_isLoading`]: false
            }
            setIsAddRefContactLoading(finalObj)
            if (res?.status === "success") {
                setLoader({ ...loader, isloadingPage: false, message: "Data Saved Successfully", openModal: true,isSuccess: true})
                setCurrentPage({...currentPage, editContent: allData})
                callbackFn()
            } else {
                allData?.people?.map(item => {
                    return {
                        ...item,
                        in_reference_contacts: selectedItem?.affinity_id === item?.affinity_id ? !item?.in_reference_contacts : item?.in_reference_contacts
                    }
                })
                setLoader({ ...loader, isloadingPage: false, message: "Error Saving Data", openModal: true,isSuccess: false})
            }
        })
    }
    const renderDetailsView = () => {
        return <>
            <div className='mt-2 mb-2'>
                <span className="card-body-paragraph"><b>Projects: &nbsp;</b>
                    {
                        currentPage?.editContent?.all_projects?.length ? <span className='deatils-container'>
                            {
                                currentPage?.editContent?.all_projects.map((item, index) => (
                                    <span key={index} className='projetcsCss d-inline-flex align-items-center'>{item}&nbsp;</span>
                                ))
                            }
                        </span>:<span className='lightGrey'>&nbsp;NA</span>
                    }
                </span>
            </div>
            <div className='mb-2'>
                <span className="card-body-paragraph"><b>Creator: &nbsp;</b>
                    <span className='deatils-container'><span className='projetcsCss d-inline-flex align-items-center'>{currentPage?.editContent?.creator}</span></span>
                </span>
            </div>
            <div className='mb-2'>
                <span className="card-body-paragraph"><b>People Involved: &nbsp;</b>
                    {
                        currentPage?.editContent?.people?.length ? <span className='deatils-container'>
                            {currentPage?.editContent?.people.map((item, index) => {
                                <span key={index} className='projetcsCss d-inline-flex align-items-center'>
                                    <span className={item?.incomplete ? 'redDot d-inline-block' : 'greenDot d-inline-block'}></span>{item.name}
                                </span>
                            })}
                        </span> : <span className='lightGrey'>&nbsp;NA</span>
                    }
                </span>
            </div>
            <div>
                <span className="card-body-paragraph"><b>Date: &nbsp;</b>
                    <span className='lightGrey'>{moment?.utc(currentPage?.editContent?.created_at).local().format("MMM DD YYYY")}</span>
                </span>
            </div>
            {(<div className='divider-reference'></div>)}
            {
                currentPage?.editContent?.people?.length ?
               currentPage?.editContent?.people?.map((person, i) => {
                return <>
                    <div key={i} className='row'>
                        <div className="col-md-12">
                            <div className='d-flex justify-content-between align-items-center'>
                                <b className='ref-label-name mb-2'>{person.name}</b>
                                {!person?.in_reference_contacts ? (
                                    <span className='add-to-ref d-inline-flex align-items-center' onClick={() => handleAddToRefContactsFromDetails(person)}>
                                        Add to Reference Contact &nbsp;{isAddRefContactLoading && Object.keys(isAddRefContactLoading)?.length && isAddRefContactLoading[`${person?.affinity_id}_isLoading`] ? (
                                            <img src={imgLoader} height={12} alt="spinner" style={{ marginTop: "-1px" }} />
                                        ) : null}
                                    </span>
                                ) : (
                                    <span className='added-to-ref d-inline-flex align-items-center'>Reference Contact</span>
                                )}
                            </div>
                            <div className='mb-1'>
                                <span className='card-body-paragraph'><b>Projects: &nbsp;</b>
                                    {
                                        person?.projects?.length ? person?.projects.map((project, i) => {

                                            if (i == person?.projects?.length - 1) {
                                                return <span className='lightGrey'>{project}&nbsp;&nbsp;</span>
                                            } else return <span className='lightGrey'>{project},&nbsp;&nbsp;</span>
                                        }) : <span className='lightGrey'>&nbsp;NA</span>
                                    }
                                </span>
                            </div>
                            <div className='mb-1'>
                                <span className='card-body-paragraph'><b>Rating: &nbsp;</b>
                                    <span className='lightGrey'>{getRatingValue(person?.rating)}</span>
                                </span>
                            </div>
                            <div className='mb-1'>
                                <span className='card-body-paragraph'><b>Functions: &nbsp;</b>
                                    {
                                        person?.functions?.length ? person?.functions.map((func, i) => {

                                            if (i == person?.functions?.length - 1) {
                                                return <span className='lightGrey'>{func}&nbsp;&nbsp;</span>
                                            } else return <span className='lightGrey'>{func},&nbsp;&nbsp;</span>
                                        }) : <span className='lightGrey'>&nbsp;NA</span>
                                    }
                                </span>
                            </div>
                            <div className='mb-1'>
                                <span className='card-body-paragraph'><b>Reference Type(s): &nbsp;</b>
                                    {
                                        person?.reference_type?.length ? person?.reference_type?.map((ref, i) => {
                                            if (i == person?.reference_type?.length - 1) {
                                                return <span className='lightGrey'>{ref}&nbsp;&nbsp;</span>
                                            } else return <span className='lightGrey'>{ref},&nbsp;&nbsp;</span>
                                        }): <span className='lightGrey'>NA</span>
                                    }
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className='divider-reference'></div>
                </>
            }) : null
            }
            <div className='m-0'>
                <div className="card-body-paragraph m-b-10"><b>Reference:</b></div>
                <div className="reference-box" dangerouslySetInnerHTML={{ __html: currentPage?.editContent?.content }} />
            </div>

        </>
    }

    const renderEditView = () => {
        return <Form className='edit-reference'>
            {loader?.isloadingPage ? <div>
                <LoadingBlock height="70px" width="auto" className="mb-3 " />
                <LoadingBlock height="70px" width="auto" className="mb-3 " />
                <LoadingBlock height="70px" width="auto" className="mb-3 " />
                <LoadingBlock height="70px" width="auto" className="mb-3 " />
                <LoadingBlock height="70px" width="auto" className="mb-3 " />
            </div>: 
            <Row>
                <Col xs={8}>
                    <Form.Group className="mb-4 w-20" controlId="formBasicEmail">
                        <Form.Label>Projects:</Form.Label>
                        <Typeahead
                            labelKey="display_name"
                            id="dropdown"
                            className="typeAhead reference-cd"
                            name={`projects`}
                            onChange={(val) => handleOverallProjectChanges(val)}
                            options={dropdowns?.projects || []}
                            placeholder={`Select Projects`}
                            selected={editForm[`selectedProjects`]}
                            useCache={false}
                            multiple
                            disabled
                        />
                    </Form.Group>
                </Col>
                <Col xs={8}>
                    <Form.Group className="mb-4 w-20" controlId="formBasicEmail">
                        <Form.Label>Creator:</Form.Label>
                        <Typeahead
                            labelKey="full_name"
                            id="dropdown"
                            className="typeAhead"
                            name={`creators`}
                            onChange={(val) => handleScoperChanges(val)}
                            options={dropdowns?.creator || []}
                            placeholder={`Select Projects`}
                            selected={editForm[`selectedScoperArr`]}
                            useCache={false}
                            multiple
                            disabled
                        />
                    </Form.Group>
                </Col>
                <Col xs={8}>
                    <Form.Group className="mb-4 w-20" controlId="formBasicEmail">
                        <Form.Label>Peoples:</Form.Label>
                        <Typeahead
                            labelKey="name"
                            id="dropdown"
                            className="typeAhead"
                            name={`peoples`}
                            onChange={(val) => handlePeoplsChanges(val)}
                            options={dropdowns?.peoples || []}
                            placeholder={`Select Projects`}
                            selected={editForm[`selectedPeoples`]}
                            useCache={false}
                            multiple
                            disabled
                        />
                    </Form.Group>
                </Col>
                {<div className='divider-reference'></div>}
                <Col xs={12}>
                    {editForm?.[`selectedPeoples`]?.length > 0 && editForm?.[`selectedPeoples`].map((item, index) => {
                            return <>
                                <div className=' m-b-10 d-flex justify-content-between align-items-center'>
                                    <span className='main-title'>{item?.name}</span>
                                    {!item?.in_reference_contacts ? <span className='add-to-ref d-inline-flex align-items-center' onClick={()=>handleAddToRefContacts(item)}>Add to Reference Contact &nbsp;{isAddRefContactLoading && Object.keys(isAddRefContactLoading)?.length && isAddRefContactLoading[`${item?.affinity_id}_isLoading`] ? <img src={imgLoader} height={12} alt="spinner" style={{marginTop:"-1px"}}/> : null}</span> : <span className='added-to-ref d-inline-flex align-items-center'>Reference Contact</span>}
                                </div>
                                <Row className=''>
                                <Col xs={12} sm={6}>
                                        <Form.Group className="mb-4 w-20" controlId="formBasicEmail">
                                            <Form.Label>Projects: </Form.Label>
                                            <Typeahead
                                                labelKey="display_name"
                                                id="dropdown"
                                                className="typeAhead"
                                                name={`project`}
                                                onChange={(val) => handleProjectsDropdownChange(val, index, `projects`, editForm?.[`selectedPeoples`]?.[index]?.[`projects`])}
                                                options={refDropdown?.projects || []}
                                                placeholder={`Select projects`}
                                                selected={editForm?.[`selectedPeoples`]?.[index]?.[`projects`]}
                                                useCache={false}
                                                multiple
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col xs={12} sm={6}>
                                        <Form.Group className="mb-4 w-20" controlId="formBasicEmail">
                                            <Form.Label>Rating: </Form.Label>
                                            <Typeahead
                                                labelKey="display_name"
                                                id="dropdown"
                                                className="typeAhead cv-details"
                                                name={`peoples`}
                                                onChange={(val) => handleRatingDropdownChange(val, index, `rating`)}
                                                options={refDropdown?.ratings || []}
                                                placeholder={`Select ratings`}
                                                selected={editForm?.[`selectedPeoples`]?.[index]?.[`rating`] || []}
                                                useCache={false}
                                                multiple
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col xs={12} sm={6}>
                                        <Form.Group className="mb-4 w-20" controlId="formBasicEmail">
                                            <Form.Label>Functions: </Form.Label>
                                            <Typeahead
                                                labelKey="display_name"
                                                id="dropdown"
                                                className="typeAhead"
                                                name={`peoples`}
                                                onChange={(val) => handleDropdownChange(val, index, `functions`)}
                                                options={refDropdown?.functions || []}
                                                placeholder={`Select functions`}
                                                selected={editForm?.[`selectedPeoples`]?.[index]?.[`functions`]}
                                                useCache={false}
                                                multiple
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col xs={12} sm={6}>
                                        <Form.Group className="mb-4 w-20" controlId="formBasicEmail">
                                            <Form.Label>Reference Type(s): </Form.Label>
                                            <Typeahead
                                                labelKey="display_name"
                                                id="dropdown"
                                                className="typeAhead"
                                                name={`peoples`}
                                                onChange={(val) => handleDropdownChange(val, index, `reference_type`)}
                                                options={refDropdown?.reference_types || []}
                                                placeholder={`Select reference type`}
                                                selected={editForm?.[`selectedPeoples`]?.[index]?.[`reference_type`]}
                                                useCache={false}
                                                multiple
                                            />
                                        </Form.Group>
                                    </Col>
                                </Row>
                                {editForm?.[`selectedPeoples`]?.length-1 !== index && <div className='divider-reference m-t-10'></div>}
                            </>
                        })
                    }
                </Col>
                {<div className='divider-reference'></div>}
                <Col xs={12} className='m-b-10'>
                    <div className='main-title m-b-10'>Reference:</div>
                    <div className='reference-box'>
                        <span><div dangerouslySetInnerHTML={{__html:currentPage?.editContent?.content}}/></span>
                    </div>
                </Col>
                <div className='divider-reference  m-b-10'></div>
                <div className="d-flex justify-content-end">
                    <Button variant="default" className="btn-cancel" style={{ marginRight: '20px' }} onClick={e=>{
                        setCurrentPage({...currentPage, selectedView : "reference_details_page", editContent: {}})
                    }}>Cancel</Button>
                    <Button variant="primary" onClick={handleSave}>Save</Button>
                </div>
            </Row>}
        </Form>
    }

    const handleContinue =()=>{}

    return (
        <>
            <Offcanvas
                show={show}
                onHide={onHide}
                placement="end"
                style={{ width: "40pc", padding: "10px" }}
            >
                <Offcanvas.Header className="custom-modal-header">
                    <div className="d-flex align-items-center main-title p-l-3" style={{ gap: "10px" }}>
                        <BackSvg onClick={(e=>{
                                if(currentPage.selectedView === "edit_reference_page"){
                                    setCurrentPage({...currentPage, selectedView : "reference_details_page",})
                                }else onHide()
                        })} style={{ cursor: "pointer" }} />
                        <span>Back</span>
                    </div>
                </Offcanvas.Header>
                <Row className="m-0">
                    <Col lg={12}>
                        <div className="top-header-box d-flex align-items-center justify-content-between">
                            <span className='d-flex align-items-center'>
                                <Offcanvas.Title>{currentPage?.selectedView === "edit_reference_page" ? 'Edit Reference' : 'Reference'}</Offcanvas.Title>
                            </span>
                            {currentPage?.selectedView !== "edit_reference_page" && (<Button className="btnRef" variant='default' onClick={handleEdit}>Edit</Button>)}
                        </div>
                    </Col>
                </Row>
                <Offcanvas.Body className="conversation-custom-modal-body">
                    <br></br>
                    {loader?.openModal && <SubmitCard showToast={loader?.openModal} onHide={() => handleContinue()} isSuccess={loader?.isSuccess} message={loader?.message} />}
                    <Row>
                        <Col xs={12}>
                            {currentPage?.selectedView === "edit_reference_page" ? renderEditView() : renderDetailsView()}
                        </Col>
                    </Row>
                </Offcanvas.Body>
            </Offcanvas>
        </>
    )
}
export default SideNavbar

