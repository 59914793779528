import React from "react";

import ErrorImg from "../../assets/images/error.png";
import "./errorBoundary.scss"

//this is used as a fallback ui for any internal/runtime errors that occur in the application
//in development mode this will be hidden below the react error overlay

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { error: null, errorInfo: null, hasError: false };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({
      error: error,
      errorInfo: errorInfo,
    });
  }

  render() {
    if (this.state.errorInfo) {
      return (
        <div className="main-error-box">
          <div className="d-flex justify-content-center align-items-center  h-100">
            <div className="text-center">
            <img src={ErrorImg} width="100" height="100"/>
              <h2 className="main-heading mt-2">Sorry, it's me, not you</h2>
              <h2 className="main-heading "><b>:(</b></h2>
              <h3 className="main-heading text-underline">Let me try again!</h3>
            </div>
          </div>
        </div>
      );
    }
    return this.props.children;
  }
}

export default ErrorBoundary ;
