import React, { useState } from 'react';
import moment from 'moment';
import { DatePicker } from 'antd';

import 'antd/dist/antd.css';
import "./customDate.scss"


const CustomDate = ({handleDateChange, selectedDate, placeholderText }) => {
  let placeholderFormatedText = placeholderText ? placeholderText : "MMM DD, YYYY"
  const disabledDate = (current) => {
    // Can not select days after today 
    return current && current > moment().endOf('day');
  };
  return (
    <div className='date-picker-container'>
      <DatePicker className='w-100 custome-date' onChange={handleDateChange} value={selectedDate} placeholder={placeholderFormatedText}
       format={"MMM DD, YYYY"} disabledDate={disabledDate}/>
    </div>
  );
};
 export {CustomDate}