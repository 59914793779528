import React from 'react'
import './table.scss'

export default function Table({ className: style, height }) {
  return (
      
        
    <table className={`custom-table ${style}`} style={{height}}>
    <thead>
      <tr className="theader">
        <th className="table-header-cell">
            <div className="cell loader-animation">
            </div>
        </th>
        <th className="table-header-cell"><div className="cell loader-animation"></div></th>
        <th className="table-header-cell  hide-on-small-screens"><div className="cell loader-animation"></div></th>
        <th className="table-header-cell hide-on-small-screens"><div className="cell loader-animation"></div></th>
        {/* <th className="table-header-cell hide-on-small-screens"><div className="cell loader-animation"></div></th> */}
        {/* <th className="table-header-cell hide-on-small-screens"><div className="cell loader-animation"></div></th> */}


      </tr>
    </thead>
    <tbody>
      <tr>
        <td className="table-cell"><div className="cell loader-animation"></div></td>
        <td className="table-cell"><div className="cell loader-animation"></div></td>
        <td className="table-cell  hide-on-small-screens"><div className="cell loader-animation"></div></td>
        <td className="table-cell  hide-on-small-screens"><div className="cell loader-animation"></div></td>
        {/* <td className="table-cell  hide-on-small-screens"><div className="cell loader-animation"></div></td> */}
        {/* <td className="table-cell  hide-on-small-screens"><div className="cell loader-animation"></div></td> */}

      </tr>
      <tr>
        <td className="table-cell"><div className="cell loader-animation"></div></td>
        <td className="table-cell"><div className="cell loader-animation"></div></td>
        <td className="table-cell  hide-on-small-screens"><div className="cell loader-animation"></div></td>
        <td className="table-cell  hide-on-small-screens"><div className="cell loader-animation"></div></td>
        {/* <td className="table-cell  hide-on-small-screens"><div className="cell loader-animation"></div></td> */}
        {/* <td className="table-cell  hide-on-small-screens"><div className="cell loader-animation"></div></td> */}
      </tr> 
      <tr>

        <td className="table-cell"><div className="cell loader-animation"></div></td>
        <td className="table-cell"><div className="cell loader-animation"></div></td>
        <td className="table-cell  hide-on-small-screens"><div className="cell loader-animation"></div></td>
        <td className="table-cell  hide-on-small-screens"><div className="cell loader-animation"></div></td>
        {/* <td className="table-cell  hide-on-small-screens"><div className="cell loader-animation"></div></td> */}
        {/* <td className="table-cell  hide-on-small-screens"><div className="cell loader-animation"></div></td> */}

      </tr> 
      <tr> 
        <td className="table-cell"><div className="cell loader-animation"></div></td>
        <td className="table-cell"><div className="cell loader-animation"></div></td>
        <td className="table-cell  hide-on-small-screens"><div className="cell loader-animation"></div></td>
        <td className="table-cell  hide-on-small-screens"><div className="cell loader-animation"></div></td>
        {/* <td className="table-cell  hide-on-small-screens"><div className="cell loader-animation"></div></td> */}
        {/* <td className="table-cell  hide-on-small-screens"><div className="cell loader-animation"></div></td> */}
      </tr>
   
    </tbody>
  </table>
      
  )
}
