import React, { useState, useEffect } from 'react'
import { Row, Form, Col, Offcanvas, Button } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux'
import { Typeahead } from 'react-bootstrap-typeahead';
import * as amplitude from '@amplitude/analytics-browser';

import { updatePersonInNotes } from "../../../store/actions";
import { axiosUpdatePerson } from "../../../config/appConfig/axiosInstance";
import SubmitCard from "../../../commonComponents/SubmitCard/SubmitCard";
import { LoadingBlock } from "../../../commonComponents/LoadingAnimation/LoaderComponents";


import imgLoader from "../../../assets/images/spinner.gif";
import { ReactComponent as BackSvg } from "../../../assets/images/icon_svg/arrow_back.svg";

import './personEditModal.scss'

const PersonEditModal = ({ show, onHide, content, fathomData, callbackFn }) => {
    const dispatch = useDispatch();
    const [loader, setLoader] = useState({
        isloadingPage: false,
        openModal: false,
        isSuccess: null,
        message: "",
    });
    const [editPerson, setEditPerson] = useState({
        // selectedView: "person_detail_view",
        cloneContent: { ...content }
    })

    const [isAddRefContactLoading, setIsAddRefContactLoading] = useState(false)
    const [refDropdown] = useSelector(({ referenceReducer }) => [referenceReducer.refDropdown])
    const [dropdowns, setDropdowns] = useState({
        functions: [],
        reference_types: [],
        ratings: [],
        projects: [],
    })
    const [form, setForm] = useState({
        selectedProjects: [],
        selectedFunctions: [],
        selectedRatings: [],
        selectedReferenceTypes: []
    })

    useEffect(() => {
        if (refDropdown && Object.keys(refDropdown).length) {
            setDropdowns({
                ...dropdowns,
                functions: [...refDropdown?.functions],
                reference_types: [...refDropdown?.reference_types],
                ratings: [...refDropdown?.ratings],
                projects: [...refDropdown?.projects],
            })
        }

    }, [refDropdown])

    useEffect(() => {
        if (editPerson?.cloneContent) {
            let selectedProjects = refDropdown?.projects?.filter((i, idx) => editPerson?.cloneContent?.projects?.some(item => item === i.display_name))
            let selectedFunction = refDropdown?.functions?.filter((i, idx) => editPerson?.cloneContent?.functions?.some(item => item === i.display_name))
            let selectedReferenceTypes = refDropdown?.reference_types?.filter((i, idx) => editPerson?.cloneContent?.type_of_reference?.some(item => item === i.display_name))
            let selectedRatings = refDropdown?.ratings?.filter((i, idx) => i?.server_value === editPerson?.cloneContent?.rating)
            setForm({
                ...form,
                selectedProjects: selectedProjects?.length ? [...selectedProjects] : refDropdown?.projects?.length ? [refDropdown?.projects[0]] : [],
                selectedFunctions: selectedFunction?.length ? [...selectedFunction] : [],
                selectedRatings: selectedRatings?.length ? [...selectedRatings] : refDropdown?.ratings?.length ? [refDropdown?.ratings[0]] : [],
                selectedReferenceTypes: selectedReferenceTypes?.length ? [...selectedReferenceTypes] : []
            })
        }
    }, [editPerson?.cloneContent])

    const handleSave = () => {
        const apiPayload = {
            person_id: editPerson?.cloneContent.person_id,
            functions: form?.selectedFunctions?.map(i => i?.server_value),
            projects: form?.selectedProjects?.map(i => i?.server_value),
            rating: form?.selectedRatings[0]?.server_value || 0,
            reference_types: form?.selectedReferenceTypes?.map(i => i?.server_value),
            ignore: editPerson?.cloneContent?.is_ignored
        }

        setLoader({ ...loader, isloadingPage: true })
        amplitude.logEvent('People Involved Updated');
        dispatch(updatePersonInNotes(axiosUpdatePerson, apiPayload, fathomData)).then(res => {
            if (res?.status === "success") {
                setLoader({ ...loader, isloadingPage: false, message: "Data Saved Successfully", openModal: true, isSuccess: true })
                callbackFn(true, "Successfully Updated")
            } else {
                setLoader({ ...loader, isloadingPage: false, message: "Error Saving Data", openModal: true, isSuccess: false })
                callbackFn(false, "Failed to Update")
            }
            onHide()
        })
    }

    const handleDropdownChange = (val, name) => {
        setForm({
            ...form,
            [`${name}`]: val
        })
    }

    const handleProjectsDropdownChange = (value, name, existedProjects) => {
        let finalResult;
        if (value?.length === 0) {
            finalResult = refDropdown?.projects?.length ? refDropdown?.projects?.filter(item => item?.server_value == "No Project Required") : []
        } else if (value?.length > 1) {
            if (existedProjects.some(item => item?.server_value === "No Project Required")) {
                finalResult = value?.filter(item => item?.server_value != "No Project Required")
            } else if (value.some(item => item?.server_value === "No Project Required")) {
                finalResult = value?.filter(item => item?.server_value == "No Project Required")
            } else {
                finalResult = value
            }
        } else {
            finalResult = value
        }
        setForm({
            ...form,
            [`${name}`]: finalResult
        })
    }

    const handleRatingDropdownChange = (val) => {
        setForm({
            ...form,
            [`selectedRatings`]: val?.length == 0 ? (refDropdown?.ratings?.length ? refDropdown?.ratings?.filter(i => i?.server_value == 0) : []) : val?.length == 1 ? val : val?.length > 1 ? [val[1]] : []
        })
    }


    const renderEditPersonView = () => {
        return <Form className='edit-person'>
            {loader?.isloadingPage ? <div>
                <LoadingBlock height="70px" width="auto" className="mb-3 " />
                <LoadingBlock height="70px" width="auto" className="mb-3 " />
                <LoadingBlock height="70px" width="auto" className="mb-3 " />
                <LoadingBlock height="70px" width="auto" className="mb-3 " />
                <LoadingBlock height="70px" width="auto" className="mb-3 " />
            </div> : <Row>
                <Col xs={12} sm={9}>
                    <Form.Group className="mb-4 w-20" controlId="formBasicEmail">
                        <Form.Label>Projects: </Form.Label>
                        <Typeahead
                            labelKey="display_name"
                            id="dropdown"
                            className="typeAhead reference-cd"
                            name={`projects`}
                            onChange={(val) => handleProjectsDropdownChange(val, `selectedProjects`, form[`selectedProjects`])}
                            options={dropdowns?.projects || []}
                            placeholder={`Select Projects`}
                            selected={form[`selectedProjects`]}
                            useCache={false}
                            multiple
                        />
                    </Form.Group>
                </Col>
                <Col xs={12} sm={9}>
                    <Form.Group className="mb-4 w-20" controlId="formBasicEmail">
                        <Form.Label>Rating: </Form.Label>
                        <Typeahead
                            labelKey="display_name"
                            id="dropdown"
                            className="typeAhead cv-details"
                            name={`ratings`}
                            onChange={(val) => handleRatingDropdownChange(val)}
                            options={dropdowns?.ratings || []}
                            placeholder={`Select ratings`}
                            selected={form?.[`selectedRatings`] || []}
                            useCache={false}
                            multiple
                        />
                    </Form.Group>
                </Col>
                <Col xs={12} sm={9}>
                    <Form.Group className="mb-4 w-20" controlId="formBasicEmail">
                        <Form.Label>Functions: </Form.Label>
                        <Typeahead
                            labelKey="display_name"
                            id="dropdown"
                            className="typeAhead"
                            name={`functions`}
                            onChange={(val) => handleDropdownChange(val, `selectedFunctions`)}
                            options={dropdowns?.functions || []}
                            placeholder={`Select functions`}
                            selected={form?.[`selectedFunctions`]}
                            useCache={false}
                            multiple
                        />
                    </Form.Group>
                </Col>
                <Col xs={12} sm={9}>
                    <Form.Group className="mb-4 w-20" controlId="formBasicEmail">
                        <Form.Label>Reference Type(s): </Form.Label>
                        <Typeahead
                            labelKey="display_name"
                            id="dropdown"
                            className="typeAhead"
                            name={`reference_types`}
                            onChange={(val) => handleDropdownChange(val, `selectedReferenceTypes`)}
                            options={dropdowns?.reference_types || []}
                            placeholder={`Select reference type`}
                            selected={form?.[`selectedReferenceTypes`]}
                            useCache={false}
                            multiple
                        />
                    </Form.Group>
                </Col>
                <div className='divider  m-b-10'></div>
                <div className="d-flex justify-content-end">
                    {/* <Button variant="default" className="btn-cancel" style={{ marginRight: '20px' }} onClick={e => {
                        // setEditPerson({ ...editPerson, selectedView: "person_detail_view" })
                    }}>Cancel</Button> */}
                    <Button variant="primary" onClick={() => handleSave()}>Save</Button>
                </div>
            </Row>}

        </Form>
    }
    // const handleAddToRefContacts = () => {
    //     const host = window.location.protocol + "//" + window.location.host;
    //     let payload = {
    //         email: editPerson?.cloneContent?.email,
    //         workboard_id: host && (host.includes("orion.telescope.vc") ||  host.includes("awseb-awseb-ij29h3qm1x06-1410843689.us-west-2.elb.amazonaws.com")) ? "aead4d58-b6b3-4a93-aca4-b7195455033f" : "c81a0c87-5727-49de-afc1-aa75c62f48a4"
    //     }
    //     let allData = {
    //         ...editPerson?.cloneContent,
    //         in_reference_contacts: !editPerson?.cloneContent?.in_reference_contacts
    //     }
    //     setIsAddRefContactLoading(true)
    //     amplitude.logEvent('Add to Reference Contact Clicked');
    //     dispatch(addPersonToRef(axiosAddPersonToRef, payload)).then(res => {
    //         setIsAddRefContactLoading(false)
    //         if (res?.status === "success") {
    //             setLoader({ ...loader, isloadingPage: false, message: "Data Saved Successfully", openModal: true,isSuccess: true})
    //             setEditPerson({ ...editPerson, cloneContent: allData })
    //             callbackFn()
    //         } else {
    //             allData = {
    //                 ...editPerson?.cloneContent,
    //                 in_reference_contacts: !editPerson?.cloneContent?.in_reference_contacts
    //             }
    //             setLoader({ ...loader, isloadingPage: false, message: "Error Saving Data", openModal: true,isSuccess: false})
    //         }
    //     })
    // }
    const handleContinue = () => { }

    return (
        <>
            <Offcanvas placement="end" show={show} onHide={onHide} style={{ width: '36pc', padding: "10px" }} className="person-container">
                <Offcanvas.Header className="custom-modal-header">
                    <div className="d-flex align-items-center p-l-3" style={{ gap: "10px" }}>
                        <span onClick={(e => {
                            onHide()
                        })} style={{ cursor: "pointer" }}>
                            <BackSvg />
                            <span>Back</span>
                        </span>
                    </div>
                </Offcanvas.Header>
                <Row className='m-0'>
                    <Col lg={12}>
                        <div className="top-header-box d-flex align-items-center justify-content-between">
                            <span className='d-flex justify-content-between align-items-center'>
                                <Offcanvas.Title>
                                    {`${content.full_name}`}
                                </Offcanvas.Title>
                                {/* {!editPerson?.cloneContent?.in_reference_contacts ? <span className='add-to-ref d-inline-flex align-items-center' onClick={handleAddToRefContacts}>Add to Reference Contact &nbsp;{isAddRefContactLoading ? <img src={imgLoader} height={12} alt="spinner" style={{marginTop:"-1px"}}/> : null}</span> : <span className='added-to-ref d-inline-flex align-items-center'>Reference Contact</span>} */}
                            </span>

                        </div>
                    </Col>
                </Row>
                <Offcanvas.Body>
                    {loader?.openModal && <SubmitCard showToast={loader?.openModal} onHide={() => handleContinue()} isSuccess={loader?.isSuccess} message={loader?.message} />}
                    <Row className='person-container-body'>
                        <Col xs={12}>
                            {renderEditPersonView()}
                        </Col>
                    </Row>
                </Offcanvas.Body>
            </Offcanvas>
        </>
    );
}

export default PersonEditModal;