import React, { useState, useMemo, useEffect, useRef } from "react";
import { Row, Button, Col, Form, FormControl } from "react-bootstrap";
import moment from "moment";
import axios from "axios";
import * as amplitude from '@amplitude/analytics-browser';
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { useSelector, useDispatch } from "react-redux";
import { useGate } from 'statsig-react';
import { ReactComponent as LinkedInSvg } from "../../../assets/images/icon_svg/linkedin.svg";
import { ReactComponent as AffinitySvg } from "../../../assets/images/icon_svg/affinity_new_logo.svg";
import { ReactComponent as CompanyUrlSvg } from "../../../assets/images/icon_svg/url.svg";
import companyLogo from "../../../assets/images/icon_svg/company.svg";
import imgLoader from "../../../assets/images/spinner.gif";
import "./discoverytable.scss";
import { setWatchDiscoveryItem } from "../../../store/actions/discoveryAction";
import { axiosDiscoveryWatch } from "../../../config/appConfig/axiosInstance";
import { getAddress } from "../../../utils";
import Slider from "../Slider/Slider";

let searchText = "";
let isMoreLoading = false;

const DiscoveryTable = () => {
  const dispatch = useDispatch();
  const gridRef = useRef(null);
  const [filteredData] = useSelector(({discoveryReducer}) => [discoveryReducer.discoveryCompanies]);
  const filtersPayloadData = useSelector(
    (state) => state.discoveryReducer.filtersPayload
  );
  const { value: isWatchButtonEnabled } = useGate('process-watch-discovery-search');
  const [searchTerm, setSearch] = useState("");
  const [rowData, setrowData] = useState([]);
  const [columnDefs, setcolumnDefs] = useState([]);
  const [viewAllOwner, setViewAllOwner] = useState({ selectedOwnerIndex: [] });
  const [viewAllCountries, setViewAllCountries] = useState({
    selectedIndex: [],
  });
  const [viewAllDepartments, setViewAllDepartments] = useState({
    selectedIndex: [],
  });
  const [isSearching, setIsSearching] = useState(false);
  const containerStyle = useMemo(() => ({ width: "100%", height: "100%" }), []);
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState("");
  const [cancelToken, setCancelToken] = useState(null);
  const [cursor, setCursor] = useState({
    cursor_id: null,
    query_id: null,
  });
  const [filteredCount, setFilteredCount] = useState(0);
  const [isSliderOpen, setSliderOpen] = useState(false);
  const [sliderContent, setSliderContent] = useState(null);

  useEffect(()=> {
  },[isWatchButtonEnabled]);

  useEffect(() => {
    if (
      filteredData &&
      Object?.keys(filteredData)?.length &&
      filteredData?.results?.length
    ) {
      let headers = rearrangeSeq(
        filteredData?.results[0],
        filteredData?.columns_order
      );
      const item = getColumnDef(headers);
      setcolumnDefs(item);
      setrowData(filteredData?.results);

      if (filteredData?.next_cursor) {
        setCursor({
          ...cursor,
          cursor_id: filteredData?.next_cursor,
          query_id: filteredData?.query_id,
        });
      }
    }
  }, [filteredData]);

  useEffect(() => {
    if (rowData && rowData?.length) {
      let headers = rearrangeSeq(
        filteredData?.results[0],
        filteredData?.columns_order
      );
      const item = getColumnDef(headers);
      setcolumnDefs(item);
    }
  }, [viewAllOwner, viewAllCountries, viewAllDepartments]);

  useEffect(() => {
    // Create a new cancel token source for each effect
    const cancelSource = axios.CancelToken.source();
    setCancelToken(cancelSource);

    const debounceTimer = setTimeout(() => {
      setDebouncedSearchTerm(searchTerm);
    }, 300);
    return () => {
      clearTimeout(debounceTimer);
      cancelSource.cancel("Request canceled by the user.");
    };
  }, [searchTerm]);

  useEffect(() => {
    return () => {
      searchText = "";
    };
  }, []);

  const rearrangeSeq = (originalObject, seq) => {
    let customOrder = seq?.length
      ? [...seq]
      : [
          "company_name",
          "tp_owners",
          "tp_status",
          "growth_6m",
          "growth_1y",
          "growth_2y",
          "employee_count",
          "sales_engineering_percentage",
          "seniority_percentage",
          "total_funding_usd",
          "last_funding_on",
          "first_email_sent",
          "last_email_sent",
          "countries",
          "departments",
          "hq_country",
          "entered_in_priority",
          "exited_from_priority",
          "prestige",
          "status",
        ];

    // Add new keys to the specified position in the sequence
    for (const key in originalObject) {
      if (!customOrder.includes(key)) {
        // Add new key to the third last position
        customOrder.splice(customOrder.length - 1, 0, key);
      }
    }

    // Create a new object with keys arranged according to the customOrder
    const reorderedObject = customOrder.reduce((obj, key) => {
      if (originalObject.hasOwnProperty(key)) {
        obj[key] = originalObject[key];
      }
      return obj;
    }, {});

    return reorderedObject;
  };

  const textFormatter = (value) => {
    const capitalVal = value?.valueFormatted
      ? value?.valueFormatted?.toUpperCase()
      : value?.value?.toUpperCase();
    return (
      <span className="">
        {capitalVal === null ||
        capitalVal === undefined ||
        capitalVal === "" ? (
          <div className="text-center">-</div>
        ) : (
          <div className="projetcsCss">{capitalVal}</div>
        )}
      </span>
    );
  };

  const textFormatterWithoutCapital = (value) => {
    const capitalVal = value?.valueFormatted
      ? value?.valueFormatted
      : value?.value;
    return (
      <span className="">
        {capitalVal === null ||
        capitalVal === undefined ||
        capitalVal === "" ? (
          <div className="text-center">-</div>
        ) : (
          <div className="projetcsCss">{capitalVal}</div>
        )}
      </span>
    );
  };

  const CustomDateComparator = (date1, date2) => {
    const date1Number =
      date1 && date1 !== "n/a" ? new Date(moment?.utc(date1)).getTime() : null;
    const date2Number =
      date2 && date2 !== "n/a" ? new Date(moment?.utc(date2)).getTime() : null;

    if (date1Number === null && date2Number === null) {
      return 0;
    }
    if (date1Number === null) {
      return -1;
    }
    if (date2Number === null) {
      return 1;
    }
    return date1Number - date2Number;
  };

  const formattedTPOwnerFn = (props) => {
    const cellValue = props.value;
    return (
      <div className="tp-owner-container">
        {cellValue?.length > 2 &&
        viewAllOwner?.selectedOwnerIndex?.includes(props?.rowIndex) ? (
          cellValue?.map((item) => {
            return <span className="projetcsCss">{item}</span>;
          })
        ) : cellValue?.length == 1 ? (
          <>
            <span className="projetcsCss">{cellValue[0]}</span>{" "}
          </>
        ) : cellValue?.length >= 2 ? (
          <>
            <span className="projetcsCss">{cellValue[0]}</span>{" "}
            <span className="projetcsCss">{cellValue[1]}</span>
          </>
        ) : (
          <div className="text-center">-</div>
        )}

        {cellValue?.length > 2 &&
        !viewAllOwner?.selectedOwnerIndex?.includes(props?.rowIndex) ? (
          <span
            className="projetcsCss"
            ref={(ref) => {
              if (!ref) return;
              ref.onclick = (e) => {
                e.stopPropagation();
                let newArr = [...viewAllOwner?.selectedOwnerIndex];
                newArr.push(props?.rowIndex);
                setViewAllOwner({
                  ...viewAllOwner,
                  selectedOwnerIndex: [...newArr],
                });
              };
            }}
          >
            +{cellValue?.length - 2}
          </span>
        ) : null}
      </div>
    );
  };

  const formatDateString = (props) => {
    const cellValue = props.valueFormatted ? props.valueFormatted : props.value;
    const formatDate =
      cellValue && cellValue != "n/a"
        ? `${moment?.utc(cellValue)?.format("MMM DD, YYYY ")}`
        : null;
    const date = cellValue && cellValue != "n/a" ? moment(cellValue) : null;
    const daysAgo = cellValue ? moment().diff(date, "days") : null;
    return (
      <span>
        {formatDate === null ||
        formatDate === undefined ||
        formatDate === "" ? (
          <div className="text-center">-</div>
        ) : (
          <div>
            <div
              className="formatedDate"
              style={{ height: daysAgo ? "20px" : "25px" }}
            >
              {formatDate}
            </div>
            {daysAgo ? (
              <div className="blueItalic">
                <i>
                  {daysAgo
                    ? `${daysAgo} ${daysAgo == 1 ? "day" : "days"} ago`
                    : null}
                </i>
              </div>
            ) : null}
          </div>
        )}
      </span>
    );
  };

  function capitalizeFirstLetter(string) {
    if (string) {
      string = string?.replace(/_/g, " ");
      var words = string?.split(" ");
      var capitalizedWords = words.map(function (word) {
        return word.charAt(0).toUpperCase() + word.slice(1);
      });
      var formattedString = capitalizedWords.join(" ");
      return formattedString;
    } else {
      return string;
    }
  }

  const getSelectedFiltersType = (value, name) => {
    if (name == "year_founded") {
      return "agSetColumnFilter";
    }
    return typeof value === "string"
      ? "agTextColumnFilter"
      : typeof value === "number"
      ? "agNumberColumnFilter"
      : "agSetColumnFilter";
  };

  const hyphenValueFormatter = (params) => {
    const value = params.value;
    if (
      value === null ||
      value === undefined ||
      value === "" ||
      value?.length === 0 ||
      value === "n/a" ||
      (typeof value === "object" && !Array.isArray(value))
    ) {
      return <div className="text-center">-</div>;
    } else if (Array?.isArray(value)) {
      return value?.length
        ? value?.map((item, index) => {
            if (index == value?.length - 1) {
              return `${item}` + " ";
            } else return `${item},` + " ";
          })
        : "";
    }
    return value;
  };

  function formatToUSD(props) {
    const cellValue = props.value;
    let usdFormat =
      cellValue && cellValue !== "n/a"
        ? Number(cellValue)?.toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
            minimumFractionDigits: 0,
          })
        : null;

    return (
      <span>
        {usdFormat === null || usdFormat === undefined || usdFormat === "" ? (
          <div className="text-center">-</div>
        ) : (
          usdFormat
        )}
      </span>
    );
  }

  const companyWithDomain = (props) => {
    const cellValue = props.valueFormatted ? props.valueFormatted : props.value;
    const company_logo =
      props?.data &&
      props?.data["company_logo"] &&
      props?.data["company_logo"] != "n/a"
        ? `${props?.data["company_logo"]}`
        : null;
    let hrefTag =
      props?.data &&
      props?.data["company_domain"] &&
      props?.data["company_domain"] !== "n/a"
        ? `https://${props?.data["company_domain"]}`
        : null;
    let affinityUrl =
      props?.data && props?.data["url"] && props?.data["url"] !== "n/a"
        ? props?.data["url"]
        : null;
    let linkedinUrl =
      props?.data &&
      props?.data["linkedin_url"] &&
      props?.data["linkedin_url"] !== "n/a"
        ? props?.data["linkedin_url"]
        : null;
    let yearFounded =
      props?.data &&
      props?.data["year_founded"] &&
      props?.data["year_founded"] !== "n/a"
        ? props?.data["year_founded"]
        : null;
    let hqcountry =
      props?.data &&
      props?.data["hq_country"] &&
      props?.data["hq_country"] !== "n/a"
        ? props?.data["hq_country"]
        : null;
    let hqstate =
      props?.data &&
      props?.data["hq_state"] &&
      props?.data["hq_state"] !== "n/a"
        ? props?.data["hq_state"]
        : null;
    let hqcity =
      props?.data && props?.data["hq_city"] && props?.data["hq_city"] !== "n/a"
        ? props?.data["hq_city"]
        : null;
    let status =
      props?.data && props?.data["status"] && props?.data["status"] !== "n/a"
        ? props?.data["status"]
        : null;
    // let dateAdded = props?.data && props?.data['date_added'] && props?.data['date_added'] !== "n/a" ? moment(props?.data['date_added']) : null
    // const daysAgo = dateAdded ? moment().diff(dateAdded, 'days') : null;

    return (
      <span className="d-flex align-items-center justify-content-between">
        <div className="logo-box d-flex align-items-center justify-content-between">
          {company_logo ? (
            <img src={company_logo} height={`30px`} width={`30px`} />
          ) : (
            <img src={companyLogo} height={`30px`} width={`30px`} />
          )}
        </div>
        <div style={{ width: "100%", overflow: "hidden" }}>
          {cellValue ? (
            <div
              style={{
                width: "95%",
                overflow: "hidden",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
              }}
            >
              <div
                style={{
                  height: yearFounded ? "20px" : "auto",
                  lineHeight: "normal",
                }}
              >
                <b>{cellValue}</b>
              </div>
              {yearFounded ? (
                <div style={{ height: "20px", lineHeight: "normal" }}>
                  <i className="blueItalic2"> Founded: {yearFounded}</i>
                </div>
              ) : null}
              {hqcity || hqstate ? (
                <div style={{ height: "20px", lineHeight: "normal" }}>
                  <i className="headquarters">
                    {hqcity ? `${hqcity}${hqstate ? ", " : ""}` : ""}
                    {hqstate ? `${hqstate}` : ""}
                  </i>
                </div>
              ) : null}
              {hqcountry ? (
                <div style={{ height: "25px", lineHeight: "normal" }}>
                  <i className="headquarters">{hqcountry}</i>
                </div>
              ) : null}

              {/* {daysAgo >=0 ? <div style={{height: "20px", lineHeight: "normal", marginBottom:"4px",  fontSize: "12px", textTransform: "none"}}><i>Added {daysAgo === 0 ? `today` : daysAgo > 0 ? `${daysAgo} ${daysAgo == 1 ? 'day' : 'days'} ago` : null}</i></div> : null} */}
            </div>
          ) : (
            <div className="text-center">-</div>
          )}
        </div>
        <div className="d-flex justify-content-end">
          <div
            style={{
              height: yearFounded ? "24px" : "auto",
              lineHeight: "normal",
            }}
          >
            {linkedinUrl && (
              <span
                ref={(ref) => {
                  if (!ref) return;
                  ref.onclick = (e) => {
                    e.stopPropagation(); // this works
                    window.open(linkedinUrl, "_blank");
                    // put your logic here instead because e.stopPropagation() will
                    // stop React's synthetic event
                  };
                }}
                style={{ cursor: "pointer" }}
              >
                <LinkedInSvg style={{ marginLeft: "6px" }} />
              </span>
            )}

            {affinityUrl && (
              <span
                ref={(ref) => {
                  if (!ref) return;
                  ref.onclick = (e) => {
                    e.stopPropagation(); // this works
                    window.open(affinityUrl, "_blank");
                    // stop React's synthetic event
                  };
                }}
                style={{ cursor: "pointer" }}
              >
                {" "}
                <AffinitySvg
                  style={{ marginLeft: "6px", marginRight: "-2px" }}
                />{" "}
              </span>
            )}

            {hrefTag && (
              <span
                ref={(ref) => {
                  if (!ref) return;
                  ref.onclick = (e) => {
                    e.stopPropagation(); // this works
                    window.open(hrefTag, "_blank");
                    // stop React's synthetic event
                  };
                }}
                style={{ cursor: "pointer" }}
              >
                <CompanyUrlSvg style={{ marginLeft: "6px" }} />
              </span>
            )}
            {status && (
              <div
                style={{
                  height: "20px",
                  marginTop: "4px",
                  textTransform: "uppercase",
                }}
                className="status-display"
              >
                <div className="status">{status}</div>
              </div>
            )}
          </div>
        </div>
      </span>
    );
  };

  const renderCountry = (props) => {
    let countries =
      props?.data &&
      props?.data["countries"] &&
      Object?.keys(props?.data["countries"])?.length
        ? props?.data["countries"]
        : null;
    let cellValue = {},
      sorted = {};
    if (countries && Object?.keys(countries)?.length) {
      sorted = Object.entries(countries);
      sorted.sort((a, b) => b[1] - a[1]);
      cellValue = Object.fromEntries(sorted);
    }
    return (
      <div className="tp-owner-container">
        {cellValue &&
        Object?.keys(cellValue)?.length > 1 &&
        viewAllCountries?.selectedIndex?.includes(props?.rowIndex) ? (
          Object.keys(cellValue)?.map((item) => {
            return (
              <span className="projetcsCss">
                {item}
                {cellValue[item] > 0 ? (
                  <span className="d-flex align-items-center">
                    :<b className="badge">{cellValue[item]} %</b>
                  </span>
                ) : null}
              </span>
            );
          })
        ) : cellValue && Object?.keys(cellValue)?.length >= 1 ? (
          <>
            <span className="projetcsCss">
              {Object.keys(cellValue)[0]}
              {cellValue[Object.keys(cellValue)[0]] > 0 ? (
                <span className="d-flex align-items-center">
                  :
                  <b className="badge">
                    {cellValue[Object.keys(cellValue)[0]]} %
                  </b>
                </span>
              ) : null}
            </span>{" "}
          </>
        ) : (
          <div className="text-center">-</div>
        )}

        {cellValue &&
        Object?.keys(cellValue)?.length > 1 &&
        !viewAllCountries?.selectedIndex?.includes(props?.rowIndex) ? (
          <span
            className="projetcsCss"
            ref={(ref) => {
              if (!ref) return;
              ref.onclick = (e) => {
                e.stopPropagation();
                let newArr = [...viewAllCountries?.selectedIndex];
                newArr.push(props?.rowIndex);
                setViewAllCountries({
                  ...viewAllOwner,
                  selectedIndex: [...newArr],
                });
              };
            }}
          >
            +{cellValue && Object?.keys(cellValue)?.length - 1}
          </span>
        ) : null}
      </div>
    );
  };

  const renderDepartments = (props) => {
    let departments = props?.data?.departments || null;
    let cellValue = {},
      sorted = {};

    if (departments && departments.length) {
      // Sorting departments based on percentage
      sorted = departments.slice().sort((a, b) => b.percentage - a.percentage);
      cellValue = sorted.reduce((acc, department) => {
        acc[department.department] = department.percentage;
        return acc;
      }, {});
    }

    return (
      <div className="tp-owner-container">
        {cellValue &&
        Object.keys(cellValue).length > 1 &&
        viewAllDepartments?.selectedIndex?.includes(props?.rowIndex) ? (
          Object.keys(cellValue).map((item) => (
            <span className="projetcsCss">
              {item}
              {cellValue[item] > 0 ? (
                <span className="d-flex align-items-center">
                  :<b className="badge">{cellValue[item]} %</b>
                </span>
              ) : null}
            </span>
          ))
        ) : cellValue && Object.keys(cellValue).length >= 1 ? (
          <>
            <span className="projetcsCss">
              {Object.keys(cellValue)[0]}
              {cellValue[Object.keys(cellValue)[0]] > 0 ? (
                <span className="d-flex align-items-center">
                  :
                  <b className="badge">
                    {cellValue[Object.keys(cellValue)[0]]} %
                  </b>
                </span>
              ) : null}
            </span>
          </>
        ) : (
          <div className="text-center">-</div>
        )}

        {cellValue &&
        Object.keys(cellValue).length > 1 &&
        !viewAllDepartments?.selectedIndex?.includes(props?.rowIndex) ? (
          <span
            className="projetcsCss"
            ref={(ref) => {
              if (!ref) return;
              ref.onclick = (e) => {
                e.stopPropagation();
                let newArr = [...viewAllDepartments?.selectedIndex];
                newArr.push(props?.rowIndex);
                setViewAllDepartments({
                  ...viewAllDepartments,
                  selectedIndex: [...newArr],
                });
              };
            }}
          >
            +{cellValue && Object.keys(cellValue).length - 1}
          </span>
        ) : null}
      </div>
    );
  };

  const foundedYearComparator = (valueA, valueB, nodeA, nodeB) => {
    const yearA = nodeA?.data?.year_founded && nodeA?.data?.year_founded !== 'n/a' ? parseInt(nodeA?.data?.year_founded) : null;
    const yearB = nodeB?.data?.year_founded && nodeB?.data?.year_founded !== 'n/a' ? parseInt(nodeB?.data?.year_founded) : null;
    
    if (yearA === null && yearB === null) return 0;
    if (yearA === null) return 1;
    if (yearB === null) return -1;
    
    return yearA - yearB;
  };

  const customNumberComparator = (valueA, valueB, ) => {
    // Convert USD currency strings to numbers for sorting
    const numericValueA = valueA  === "n/a" ?  Number.NEGATIVE_INFINITY : parseFloat(valueA);
    const numericValueB = valueB  === "n/a" ?  Number.NEGATIVE_INFINITY : parseFloat(valueB);
  
    if (numericValueA === numericValueB) {
      return 0;
    } else if (numericValueA < numericValueB) {
      return -1;
    } else {
      return 1;
    }
  };

  const getColumnDef = (headers) => {
    let item = [];
    for (let key in headers) {
      if (key === "company_name") item.push({ field: key, headerName: "Company Name", cellRenderer: companyWithDomain, suppressMovable: true, pinned: true, minWidth: 350, autoHeight: true, autoSize: true, filter: 'agTextColumnFilter', filterParams: {valueGetter: (params) => params.data.company_name}, comparator: foundedYearComparator });
      else if (key == "growth_1m" || key == "growth_6m" || key == "growth_1y" || key == "growth_2y" || key == "employee_count" || key == "sales_engineering_percentage" || key == "seniority_percentage" || key == "days_in_priority") 
        item.push({ field: key, headerName: capitalizeFirstLetter(key), autoSize: true, wrapText: true, filter: 'agNumberColumnFilter', comparator: customNumberComparator });
      else if (key == "first_email_sent" || key == "last_email_sent") 
        item.push({ field: key, headerName: key == "first_email_sent" ? "First Email" : "Last Email", autoSize: true, wrapText: true, autoHeight: true, cellRenderer: formatDateString, filter: null, comparator: CustomDateComparator });
      else if (key == "tp_status") 
        item.push({ field: key, headerName: "TP Status", minWidth: 160, autoSize: true, cellRenderer: textFormatter, filter: 'agSetColumnFilter', filterParams: {valueGetter: (params) => params?.data?.tp_status?.toUpperCase()} });
      else if (key == "tp_owners") 
        item.push({ field: key, headerName: "TP Owners", minWidth: 180, autoSize: true, autoHeight: true, wrapText: true, cellRenderer: formattedTPOwnerFn, filter: 'agSetColumnFilter' });
      else if (key == "region") 
        item.push({ field: key, headerName: "Region", cellRenderer: textFormatterWithoutCapital, minWidth: 180, wrapText: true, autoSize: true, filter: null });
      // else if (key == "countries") 
        // item.push({ field: key, headerName: "Countries", cellRenderer: renderCountry, minWidth: 205, autoSize: true, autoHeight: true, wrapText: true, filter: null });
      else if (key == "departments") 
        item.push({ field: key, headerName: "Departments", cellRenderer: renderDepartments, minWidth: 205, autoSize: true, autoHeight: true, wrapText: true, filter: null });
      else if (key == "last_funding_on") 
        item.push({ field: key, headerName: "Last Funding On", cellRenderer: formatDateString, autoSize: true, wrapText: true, autoHeight: true, filter: null });
      else if (key == "total_funding_usd") 
        item.push({ field: key, headerName: "Total Funding USD", cellRenderer: formatToUSD, autoSize: true, wrapText: true, filter: 'agNumberColumnFilter', comparator: customNumberComparator });
      else if (key == "entered_in_priority" || key == "exited_from_priority") 
        item.push({ field: key, headerName: capitalizeFirstLetter(key), autoSize: true, wrapText: true, autoHeight: true, cellRenderer: formatDateString, filter: null, comparator: CustomDateComparator });
      else if (key == "company_domain" || key == "url" || key == "linkedin_url") 
        item.push({ field: key, headerName: key == "url" ? "Affinity Url" : capitalizeFirstLetter(key), hide: true, lockVisible: true });
      else if (key !== "tp_owners_emails" && key !== "workboard_entry_id" && key !== "list_entry_id" && key !== "company_logo" && key !== "description" && key !== "year_founded" && key !== "naics_code" && key !== "date_added" && key !== "facebook_url" && key !== "twitter_url" && key !== "growth_tag" && key != "hq_country" && key != "hq_state" && key != "hq_city" && key != "status" && key !== "countries" && key !== "prestige") 
        item.push({ field: key, headerName: capitalizeFirstLetter(key), filter: getSelectedFiltersType(headers[key], key), autoSize: true, wrapText: true, cellRenderer: hyphenValueFormatter });
    }
    return item;
  };

  const getParams = () => ({
    allColumns: true,
    columnKeys: [
      "company_name",
      "company_domain",
      "linkedin_url",
      "url",
      "tp_owners",
      "growth_1m",
      "growth_6m",
      "growth_1y",
      "growth_2y",
      "employee_count",
      "sales_engineering_percentage",
      "seniority_percentage",
      "total_funding_usd",
      "last_funding_on",
      "first_email_sent",
      "last_email_sent",
      "days_in_priority",
      "entered_in_priority",
      "exited_from_priority",
    ],
    processCellCallback(params) {
      const { value, column } = params;
      if (column?.colId === "last_email_timestamp") {
        return !value ||
          value === undefined ||
          value === null ||
          value === "n/a"
          ? "-"
          : `${moment?.utc(value).local().format("MMM DD, YYYY")}`;
      } else if (
        column?.colId === "first_email_sent" ||
        column?.colId === "last_email_sent" ||
        column?.colId === "created_date" ||
        column?.colId === "entered_in_priority" ||
        column?.colId === "exited_from_priority" ||
        column?.colId === "last_funding_on"
      ) {
        return !value ||
          value === undefined ||
          value === null ||
          value === "n/a"
          ? "-"
          : `${moment?.utc(value).local().format("MMM DD, YYYY")}`;
      } else if (column?.colId === "tp_status") {
        return !value || value === undefined || value === "n/a"
          ? "-"
          : `${value?.toUpperCase()}`;
      } else if (column?.colId === "countries") {
        return !value ||
          value === undefined ||
          value === "n/a" ||
          Object.keys(value)?.length === 0
          ? "-"
          : Object.keys(value)?.length
          ? Object.keys(value)?.map((val) => `${val}`)
          : "-";
      } else if (column?.colId === "departments") {
        return !value ||
          value === undefined ||
          value === "n/a" ||
          value?.length === 0
          ? "-"
          : value?.length
          ? value?.map((item) => `${item?.department}`)
          : "-";
      } else if (column?.colId === "total_funding_usd") {
        return !value || value === undefined || value === "n/a"
          ? "-"
          : Number(value)?.toLocaleString("en-US", {
              style: "currency",
              currency: "USD",
              minimumFractionDigits: 0,
            });
      }
      return value === undefined ||
        value === null ||
        value?.length === 0 ||
        value === "n/a"
        ? "-"
        : `${value}`;
    },
  });

  const processCellForClipboard = (params) => {
    const { value, column } = params;
    if (column?.colId === "countries") {
      return value?.length
        ? value?.map((item, index) => `${item?.country}`)
        : "-";
    } else if (column?.colId === "last_email_timestamp") {
      return !value || value === undefined || value === null || value === "n/a"
        ? "-"
        : `${moment?.utc(value).local().format("MMM DD, YYYY")}`;
    } else if (
      column?.colId === "first_email_sent" ||
      column?.colId === "last_email_sent" ||
      column?.colId === "created_date"
    ) {
      return !value || value === undefined || value === null || value === "n/a"
        ? "-"
        : `${moment?.utc(value).local().format("MMM DD, YYYY")}`;
    } else if (column?.colId === "tp_status") {
      return !value || value === undefined || value === "n/a"
        ? "-"
        : `${value?.toUpperCase()}`;
    } else if (column?.colId === "total_funding_usd") {
      return !value || value === undefined || value === "n/a"
        ? "-"
        : Number(value)?.toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
            minimumFractionDigits: 0,
          });
    }
    return value === undefined ||
      value === null ||
      value?.length === 0 ||
      value === "n/a"
      ? "-"
      : `${value}`;
  };

  const defaultColDef = useMemo(() => {
    return {
      flex: 1,
      minWidth: 140,
      sortable: true,
      suppressMenu: false,
      autoHeaderHeight: true,
      unSortIcon: true,
      filterParams: { buttons: ["reset"] },
      menuTabs: ["filterMenuTab", "columnsMenuTab"],
      resizable: true,
      filter: true
    };
  }, []);

  const defaultExcelExportParams = useMemo(() => {
    return getParams();
  }, []);

  const sideBar = useMemo(() => {
    return {
      toolPanels: [
        {
          id: "columns",
          labelDefault: "Columns",
          labelKey: "columns",
          iconKey: "columns",
          toolPanel: "agColumnsToolPanel",
          toolPanelParams: {
            suppressRowGroups: true,
            suppressValues: true,
            suppressPivots: true,
            suppressPivotMode: true,
            suppressColumnFilter: false,
            suppressColumnSelectAll: false,
            suppressColumnExpandAll: true,
          },
        },
      ],
      defaultToolPanel: "customStats",
    };
  }, [columnDefs]);

  const onFilterNameChanged = async (e) => {
    const searchText = e.target.value.toLowerCase();

    if (searchText === "") {
      setrowData(filteredData?.results);
      setIsSearching(false);
    } else {
   
      // Perform the search and update the rowData state
      const filteredSearchData = filteredData?.results.filter((row) =>
        row.company_name.toLowerCase().includes(searchText)
      );

      await new Promise((resolve) => setTimeout(resolve, 100));
      setrowData(filteredSearchData);
      setIsSearching(true);
      // Update the displayed count based on the filtered rows
      const filteredCount = filteredSearchData ? filteredSearchData.length : 0;
      setFilteredCount(filteredCount);
    }
  };

  const onBodyScrollEndFn = (event) => {
    const gridApi = event.api;
    const elementBody = document.querySelector(
      ".table-container .ag-body-viewport"
    );
    const scrollHeight = elementBody.scrollHeight;
    const scrollPercentage =
      (elementBody.scrollTop + elementBody.offsetHeight) / scrollHeight;
    if (
      event.direction === "vertical" &&
      scrollPercentage >= 0.75 &&
      cursor?.cursor_id
    ) {
      if (isMoreLoading) {
        return;
      }
      loadMoreData(gridApi);
    }
  };

  const loadMoreData = async () => {
    isMoreLoading = true;
    const apiUrl = `${getAddress()}/discovery?cursor=${
      cursor?.cursor_id
    }&query_id=${cursor?.query_id}&${filtersPayloadData}`;
    // Fetch data from the API
    axios
      .get(`${apiUrl}`, {
        headers: {
          Authorization: localStorage.getItem("authData")
            ? "Bearer " + localStorage.getItem("authData")
            : null,
        },
      })
      .then(async (newData) => {
        let { data } = newData;
        if (data) {
          const allRows = [...rowData, ...data?.results];
          setrowData(allRows);
          // handleCountUpdate(allRows?.length)
          setCursor({
            ...cursor,
            cursor_id: data?.next_cursor,
            query_id: data?.query_id,
          });
          isMoreLoading = false;
        } else {
          // No more data to load
          // You can handle this condition as needed
        }
      })
      .catch((error) => {
        console.error("Error loading data:", error);
      });
  };

  const handleRowClick = (props) => {
    const { data } = props;
    const content = (data?.company_domain);
    setSliderContent(content);
    setSliderOpen(true);

    // Handle viewAllCountries click
    let newArrCountries = [...viewAllCountries?.selectedIndex];
    if (newArrCountries?.length) {
      const newFilteredArray = newArrCountries.filter(
        (item) => item !== props?.rowIndex
      );
      setViewAllCountries({
        ...viewAllCountries,
        selectedIndex: [...newFilteredArray],
      });
    }

    // Handle viewAllDepartments click
    let newArrDepartments = [...viewAllDepartments?.selectedIndex];
    if (newArrDepartments?.length) {
      const newFilteredArray = newArrDepartments.filter(
        (item) => item !== props?.rowIndex
      );
      setViewAllDepartments({
        ...viewAllDepartments,
        selectedIndex: [...newFilteredArray],
      });
    }
  };

  const handleCloseSlider = () => {
    setSliderOpen(false);
  };
  
  return (
    <Col xs={12} className="px-0" style={containerStyle}>
      <div className="table-container">
        {/* Search block */}
        <div className="d-flex justify-content-between align-items-center search-field p-r-20 p-l-15">
          {(filteredData?.total_count > 0 || filteredData?.results?.length > 0)&& (
            <>
              <div className=" justify-content-start">
                <div className="">
                  Showing{" "}
                  <b>
                    {" "}
                    { filteredData?.total_count || filteredData?.results?.length }
                  </b>{" "}
                  results
                </div>
              </div>
            </>
          )}
        </div>
        {/* Ag-Grid React component */}
        <div className="ag-theme-alpine">
          <AgGridReact
            className={`custom-ag-grid ${
              rowData?.length < 12 ? "smallerHeight" : ""
            }`}
            columnDefs={columnDefs}
            defaultColDef={defaultColDef}
            rowData={rowData}
            rowHeight={58}
            sideBar={rowData?.length ? sideBar : null}
            onBodyScroll={onBodyScrollEndFn}
            onRowClicked={handleRowClick}
            suppressScrollOnNewData={true}
            enableAdvancedFilter={true}
            defaultExcelExportParams={defaultExcelExportParams}
            defaultCsvExportParams={defaultExcelExportParams}
            processCellForClipboard={processCellForClipboard}
          />
        </div>
      </div>

     {isSliderOpen && (
       <Slider
       isOpen={isSliderOpen}
       onClose={handleCloseSlider}
       companyDomain={sliderContent}
     />
     )}
    </Col>
  );
};

export default DiscoveryTable;
