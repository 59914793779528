import React from 'react';
import moment from 'moment';

function DataTable({ data, headers }) {

  const tableStyle = {
    width: '100%',
    borderCollapse: 'collapse',
    marginBottom: '20px',
  };

  const cellStyle = {
    padding: '10px',
    border: '1px solid #ccc',
    textAlign: 'left',
    // verticalAlign: 'middle',
    // minWidth: "100px",
    maxWidth: "90px",
    // width: '140px',
    overflowWrap: 'break-word',
  };
  const cellContentStyle = {
    padding: '10px',
    border: '1px solid #ccc',
    textAlign: 'left',
    // verticalAlign: 'middle',
    maxWidth: "380px",
    overflowWrap: 'break-word',
  };

  const firstColStyle = {
    padding: '10px',
    border: '1px solid #ccc',
    textAlign: 'left',
    // verticalAlign: 'middle',
    maxWidth: "10px",    
  };

  const cellDateStyle = {
    padding: '10px',
    border: '1px solid #ccc',
    textAlign: 'left',
    // verticalAlign: 'middle',
    maxWidth: "100px",
    width: '80px',
    overflowWrap: 'break-word',
  };

  const headStyle = {
    background: "#E9E9E9",
    color: "#111"
  };


  function capitalizeAllWords(str) {
    // Split the string into words
    const words = str.split("_");
    // Capitalize each word
    const capitalizedWords = words.map(word => {
      return word.charAt(0).toUpperCase() + word.slice(1);
    });
    // Join the capitalized words back into a single string
    return capitalizedWords.join(" ");
  }

  return (
    <div className='dataTable-container' style={{fontFamily: "Avenir-Regular" }}>
      <table style={tableStyle}>
        <thead style={headStyle}>
          <tr>
            <th style={firstColStyle}>#</th>
            {Object.keys(headers)?.map((header, index) => {
              if (header === "content") {
                return (
                  <th key={index} style={cellContentStyle}>{capitalizeAllWords(header)}</th>
                )
              } else if (header === "created_at") {
                return (
                  <th key={index} style={cellDateStyle}>{capitalizeAllWords(header)}</th>
                )
              } else {
                return (
                  <th key={index} style={cellStyle}>{capitalizeAllWords(header)}</th>
                )
              }
            }
            )}
          </tr>
        </thead>
        <tbody style={{ background: "#fff" }}>
          {data?.map((item, index) => (
            <tr key={index}>
              <td style={firstColStyle}><b>{index + 1}</b></td>
              {Object.keys(headers)?.map((header, headerIndex) => {
                if (header === "content") {
                  return (
                    <td key={headerIndex} dangerouslySetInnerHTML={{ __html: item[header] }} style={cellContentStyle}></td>
                  )
                } else if (header === "all_projects" || header === "all_functions" || header === "all_reference_types") {
                  return (<td key={headerIndex} style={cellStyle}>{
                    item[header]?.length ? item[header]?.map((ref, i) => {
                      if (i == item[header]?.length - 1) {
                        return <span >{ref}&nbsp;&nbsp;</span>
                      } else return <span >{ref},&nbsp;&nbsp;</span>
                    }) : <span >NA</span>
                  }</td>)
                } else if (header === "people") {
                  return (<td key={headerIndex} style={cellStyle}>{
                    item[header]?.length ? item[header]?.map((people, i) => {
                      if (i == item[header]?.length - 1) {
                        return <span >{people?.name}&nbsp;&nbsp;</span>
                      } else return <span >{people?.name},&nbsp;&nbsp;</span>
                    }) : <span >NA</span>
                  }</td>)
                } else if (header === "created_at") {
                  return (<td key={headerIndex} style={cellDateStyle}>{item[header] ? `${moment?.utc(item[header]).local().format("MMM DD YYYY")}` : `NA`}</td>)
                } else {
                  return (<td key={headerIndex} style={cellStyle}>{`${item[header]}` ? `${item[header]}` : `NA`}</td>)
                }
              }
              )}
            </tr>
          ))}
        </tbody>
      </table >
    </div>
  );
}

export default DataTable;

