import React, { useState, useRef, useLayoutEffect, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import moment from "moment";
import { Typeahead } from 'react-bootstrap-typeahead';
import { Col, Row, Button, Offcanvas, Form } from 'react-bootstrap';
import axios from 'axios';


import { ReactComponent as BackSvg } from "../../assets/images/icon_svg/back.svg";
import { getAddress } from "../../utils/getServerAddress";

import {updateReferences} from "../../store/actions";
import { LoadingBlock } from "../../commonComponents/LoadingAnimation/LoaderComponents";
import SubmitCard from "../../commonComponents/SubmitCard/SubmitCard";
import { getAuthToken } from '../../utils';


import './sidebar.scss'

const defaultVal = {display_name: "All", server_value: "all"}

const SideNavbar = ({ show, onHide, modalData, selectedRowIndex,  selectedRefType, callbackFn }) => {
    const dispatch = useDispatch();
    const [refDropdown, scopers] = useSelector(({ referenceReducer, commonReducer }) => [referenceReducer?.refDropdown, commonReducer.scopers])

    const [loader, setLoader] = useState({
        isloadingPage: false,
        openModal: false,
        isSuccess: null,
        message: "",
    });
    const [currentPage, setCurrentPage] = useState({
        selectedView: "reference_details_page",
        editContent: {...modalData},
    })
    const [dropdowns, setDropdowns] = useState({
        peoples: [],
        projects:[],
        creator: []
    })

    const [editForm, setEditForm] = useState({
        selectedProjects: [defaultVal],
        selectedScoperArr: [{full_name: "All", email_address : "all", first_name:"all"}],
        selectedPeoples: [{name: "All", email:"all", functions: [], rating: [],reference_types: [], projects: []}]
    })

    // useEffect(()=>{
    //     if(scopers?.data.length || (refDropdown && Object.keys(refDropdown).length) || modalData?.people?.length){
    //         setDropdowns({
    //             ...dropdowns,
    //             creator: [...scopers?.data],
    //             projects: [{display_name: "All", server_value: "all"}, ...refDropdown?.projects],
    //             peoples: [...modalData?.people]
    //         })
    //     }
    // },[scopers?.data, refDropdown])

    useEffect(()=>{
        if(currentPage?.selectedView === "edit_reference_page" ){
            let allPeoplesArr = [...currentPage?.editContent?.people];
            let selectedProjects = refDropdown?.projects?.filter((i, idx) => currentPage?.editContent?.all_projects?.some(it => it === i.display_name))
            let selectedScopers =  scopers?.data?.filter((i) => i?.full_name === currentPage?.editContent?.creator)
            let selectedPeoples = allPeoplesArr?.length ? allPeoplesArr?.map(item => {
                return {
                    ...item,
                    projects: item?.projects?.length ? refDropdown?.projects.filter(item1 =>
                        item?.projects.some(item2 => item2 === item1.server_value)
                      ) : [],
                    functions: item?.functions?.length ? refDropdown?.functions.filter(item1 =>
                        item?.functions.some(item2 => item2 === item1.server_value)
                      ) : [],
                    rating: refDropdown?.ratings.filter(item1 => item?.rating === item1.server_value),
                    reference_type: item?.reference_type?.length ? refDropdown?.reference_types.filter(item1 =>
                        item?.reference_type.some(item2 => item2 === item1.server_value)
                      ) : []
                }
            })  : []
            setEditForm({
                ...editForm,
                selectedProjects : [...selectedProjects],
                selectedScoperArr: selectedScopers?.length ? [...selectedScopers] : currentPage?.editContent?.creator ? [{full_name: `${currentPage?.editContent?.creator}`, email_address : `${currentPage?.editContent?.creator}`, first_name:`${currentPage?.editContent?.creator}`}] : [],
                selectedPeoples: [...selectedPeoples]
            })
        }
    },[currentPage?.selectedView])

    const handleScoperChanges = (value) => {}

    const handlePeoplsChanges = (value) => {}

    const handleOverallProjectChanges = (value) => {}

    const getRatingValue = (ratingNumber) => {
        let rating = "NA";
        if(refDropdown && Object?.keys(refDropdown).length) {
            let filteredItem = refDropdown?.ratings?.filter(i => Number(i.server_value) === Number(ratingNumber))
            rating = filteredItem?.length ? filteredItem[0].display_name : `NA`
        }
        return rating;
    }

    function handleDropdownChange(value, index, name){
        let originialPeoplsClone = [...editForm['selectedPeoples']]
        originialPeoplsClone[index][`${name}`] = value
        setEditForm({
            ...editForm,
            [`selectedPeoples`]: [...originialPeoplsClone]
        })
    }

    function handleRatingDropdownChange(val, index, name){
        let originialPeoplsClone = [...editForm['selectedPeoples']]
        originialPeoplsClone[index][`${name}`] = val?.length <= 1 ? val: val?.length > 1 ? [val[1]] : []
        setEditForm({
            ...editForm,
            [`selectedPeoples`]: [...originialPeoplsClone]
        })
    }

   

    const handleEdit = () => {
        setCurrentPage({
            ...currentPage,
            selectedView: 'edit_reference_page',
        })
    }

    const handleSave = () => {
        const editedPeople = editForm[`selectedPeoples`]?.map(item => {
            return {
                ...item,
                projects:  item?.projects?.map(i => i?.server_value),
                functions: item?.functions?.map(i => i?.server_value),
                rating: item?.rating[0]?.server_value || 0,
                reference_type: item?.reference_type?.map(i => i?.server_value),
                incomplete: item?.projects?.length && item?.functions?.length && item?.reference_type?.length && item?.rating?.length && item.rating[0].server_value !== 0  ? false : true,
            }
        })
        const allData = {
            ...currentPage?.editContent,
            people: [...editedPeople],
            all_projects: editForm[`selectedPeoples`]?.reduce(function (acc, currentArray) {
                currentArray?.projects?.forEach(function (item) {
                  if (!acc.includes(item?.server_value)) {
                    acc.push(item?.server_value);
                  }
                });
                return acc;
              }, [])
        }
        const header = {
            "Authorization": getAuthToken()
        }
        let requests =  editedPeople?.map((item, index)=> {
            const payload = {
                projects: item?.projects,
                person_id: item?.affinity_id,
                functions: item?.functions,
                rating: item?.rating || 0,
                reference_types: item?.reference_type,
            }
            return axios.post(`${getAddress()}/people-metadata-update`, payload, { headers: header });
        })
        setLoader({ ...loader, isloadingPage: true })
        dispatch(updateReferences(requests, allData, selectedRowIndex, selectedRefType)).then(res =>{
            if (res?.status === "success") {
                setLoader({ ...loader, isloadingPage: false, message: "Data Saved Successfully", openModal: true,isSuccess: true})
                setCurrentPage({...currentPage, selectedView : "reference_details_page", editContent: allData})
                callbackFn()
            } else {
                setLoader({ ...loader, isloadingPage: false, message: res?.errMsg || "Error Saving Data", openModal: true,isSuccess: false})
            }
        })
    }

    const renderDetailsView = () => {
        return <>
            <div className='mt-2 mb-2'>
                <span className="card-body-paragraph"><b>Projects: &nbsp;</b>
                    {
                        currentPage?.editContent?.all_projects?.length ? <span className='deatils-container'>
                            {
                                currentPage?.editContent?.all_projects.map((item, index) => {
                                    if (index == currentPage?.editContent.all_projects.length - 1) {
                                        return <span className='projetcsCss'>{item}&nbsp;</span>
                                    } else return <span className='projetcsCss'>{item},&nbsp;</span>
                                })
                            }
                        </span>:<span className='lightGrey'>&nbsp;NA</span>
                    }
                </span>
            </div>
            <div className='mb-2'>
                <span className="card-body-paragraph"><b>Creator: &nbsp;</b>
                    <span className='deatils-container'><span className='projetcsCss'>{currentPage?.editContent?.creator}</span></span>
                </span>
            </div>
            <div className='mb-2'>
                <span className="card-body-paragraph"><b>People Involved: &nbsp;</b>
                    {
                        currentPage?.editContent?.people?.length ? <span className='deatils-container'>
                            {currentPage?.editContent?.people.map((item, index) => {
                                return <><span className='projetcsCss'><span className={item?.incomplete ? 'redDot' : 'greenDot'}></span>{item.name}</span></>
                            })}
                        </span> : <span className='lightGrey'>&nbsp;NA</span>
                    }
                </span>
            </div>
            <div>
                <span className="card-body-paragraph"><b>Date: &nbsp;</b>
                    <span className='lightGrey'>{moment?.utc(currentPage?.editContent?.created_at).local().format("MMM DD YYYY")}</span>
                </span>
            </div>
            {(<div className='divider-reference'></div>)}
            {
                currentPage?.editContent?.people?.length ?
               currentPage?.editContent?.people?.map((person, i) => {
                return <>
                    <div key={i} className='row'>
                        <div className="col-md-12">
                            <div><b className='ref-label-name mb-2'>{person.name}</b></div>
                            <div className='mb-1'>
                                <span className='card-body-paragraph'><b>Projects: &nbsp;</b>
                                    {
                                        person?.projects?.length ? person?.projects.map((project, i) => {

                                            if (i == person?.projects?.length - 1) {
                                                return <span className='lightGrey'>{project}&nbsp;&nbsp;</span>
                                            } else return <span className='lightGrey'>{project},&nbsp;&nbsp;</span>
                                        }) : <span className='lightGrey'>&nbsp;NA</span>
                                    }
                                </span>
                            </div>
                            <div className='mb-1'>
                                <span className='card-body-paragraph'><b>Rating: &nbsp;</b>
                                    <span className='lightGrey'>{getRatingValue(person?.rating)}</span>
                                </span>
                            </div>
                            <div className='mb-1'>
                                <span className='card-body-paragraph'><b>Functions: &nbsp;</b>
                                    {
                                        person?.functions?.length ? person?.functions.map((func, i) => {

                                            if (i == person?.functions?.length - 1) {
                                                return <span className='lightGrey'>{func}&nbsp;&nbsp;</span>
                                            } else return <span className='lightGrey'>{func},&nbsp;&nbsp;</span>
                                        }) : <span className='lightGrey'>&nbsp;NA</span>
                                    }
                                </span>
                            </div>
                            <div className='mb-1'>
                                <span className='card-body-paragraph'><b>Reference Type(s): &nbsp;</b>
                                    {
                                        person?.reference_type?.length ? person?.reference_type?.map((ref, i) => {
                                            if (i == person?.reference_type?.length - 1) {
                                                return <span className='lightGrey'>{ref}&nbsp;&nbsp;</span>
                                            } else return <span className='lightGrey'>{ref},&nbsp;&nbsp;</span>
                                        }): <span className='lightGrey'>NA</span>
                                    }
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className='divider-reference'></div>
                </>
            }) : null
            }
            <div className='m-0'>
                <div className="card-body-paragraph m-b-10"><b>Reference:</b></div>
                <div className="reference-box" dangerouslySetInnerHTML={{ __html: currentPage?.editContent?.content }} />
            </div>

        </>
    }

    const renderEditView = () => {
        return <Form className='edit-reference'>
            {loader?.isloadingPage ? <div>
                <LoadingBlock height="70px" width="auto" className="mb-3 " />
                <LoadingBlock height="70px" width="auto" className="mb-3 " />
                <LoadingBlock height="70px" width="auto" className="mb-3 " />
                <LoadingBlock height="70px" width="auto" className="mb-3 " />
                <LoadingBlock height="70px" width="auto" className="mb-3 " />
            </div>: 
            <Row>
                <Col xs={8}>
                    <Form.Group className="mb-4 w-20" controlId="formBasicEmail">
                        <Form.Label>Projects:</Form.Label>
                        <Typeahead
                            labelKey="display_name"
                            id="dropdown"
                            className="typeAhead reference-cd"
                            name={`projects`}
                            onChange={(val) => handleOverallProjectChanges(val)}
                            options={dropdowns?.projects || []}
                            placeholder={`Select Projects`}
                            selected={editForm[`selectedProjects`]}
                            useCache={false}
                            multiple
                            disabled
                        />
                    </Form.Group>
                </Col>
                <Col xs={8}>
                    <Form.Group className="mb-4 w-20" controlId="formBasicEmail">
                        <Form.Label>Creator:</Form.Label>
                        <Typeahead
                            labelKey="full_name"
                            id="dropdown"
                            className="typeAhead"
                            name={`creators`}
                            onChange={(val) => handleScoperChanges(val)}
                            options={dropdowns?.creator || []}
                            placeholder={`Select Projects`}
                            selected={editForm[`selectedScoperArr`]}
                            useCache={false}
                            multiple
                            disabled
                        />
                    </Form.Group>
                </Col>
                <Col xs={8}>
                    <Form.Group className="mb-4 w-20" controlId="formBasicEmail">
                        <Form.Label>Peoples:</Form.Label>
                        <Typeahead
                            labelKey="name"
                            id="dropdown"
                            className="typeAhead"
                            name={`peoples`}
                            onChange={(val) => handlePeoplsChanges(val)}
                            options={dropdowns?.peoples || []}
                            placeholder={`Select Projects`}
                            selected={editForm[`selectedPeoples`]}
                            useCache={false}
                            multiple
                            disabled
                        />
                    </Form.Group>
                </Col>
                {<div className='divider-reference'></div>}
                <Col xs={12}>
                    {editForm?.[`selectedPeoples`]?.length > 0 && editForm?.[`selectedPeoples`].map((item, index) => {
                            return <>
                                <div className='main-title m-b-10'>{item?.name}</div>
                                <Row className=''>
                                <Col xs={12} sm={6}>
                                        <Form.Group className="mb-4 w-20" controlId="formBasicEmail">
                                            <Form.Label>Projects: </Form.Label>
                                            <Typeahead
                                                labelKey="display_name"
                                                id="dropdown"
                                                className="typeAhead"
                                                name={`project`}
                                                onChange={(val) => handleDropdownChange(val, index, `projects`)}
                                                options={refDropdown?.projects || []}
                                                placeholder={`Select projects`}
                                                selected={editForm?.[`selectedPeoples`]?.[index]?.[`projects`]}
                                                useCache={false}
                                                multiple
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col xs={12} sm={6}>
                                        <Form.Group className="mb-4 w-20" controlId="formBasicEmail">
                                            <Form.Label>Rating: </Form.Label>
                                            <Typeahead
                                                labelKey="display_name"
                                                id="dropdown"
                                                className="typeAhead cv-details"
                                                name={`peoples`}
                                                onChange={(val) => handleRatingDropdownChange(val, index, `rating`)}
                                                options={refDropdown?.ratings || []}
                                                placeholder={`Select ratings`}
                                                selected={editForm?.[`selectedPeoples`]?.[index]?.[`rating`] || []}
                                                useCache={false}
                                                multiple
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col xs={12} sm={6}>
                                        <Form.Group className="mb-4 w-20" controlId="formBasicEmail">
                                            <Form.Label>Functions: </Form.Label>
                                            <Typeahead
                                                labelKey="display_name"
                                                id="dropdown"
                                                className="typeAhead"
                                                name={`peoples`}
                                                onChange={(val) => handleDropdownChange(val, index, `functions`)}
                                                options={refDropdown?.functions || []}
                                                placeholder={`Select functions`}
                                                selected={editForm?.[`selectedPeoples`]?.[index]?.[`functions`]}
                                                useCache={false}
                                                multiple
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col xs={12} sm={6}>
                                        <Form.Group className="mb-4 w-20" controlId="formBasicEmail">
                                            <Form.Label>Reference Type(s): </Form.Label>
                                            <Typeahead
                                                labelKey="display_name"
                                                id="dropdown"
                                                className="typeAhead"
                                                name={`peoples`}
                                                onChange={(val) => handleDropdownChange(val, index, `reference_type`)}
                                                options={refDropdown?.reference_types || []}
                                                placeholder={`Select reference type`}
                                                selected={editForm?.[`selectedPeoples`]?.[index]?.[`reference_type`]}
                                                useCache={false}
                                                multiple
                                            />
                                        </Form.Group>
                                    </Col>
                                </Row>
                                {editForm?.[`selectedPeoples`]?.length-1 !== index && <div className='divider-reference m-t-10'></div>}
                            </>
                        })
                    }
                </Col>
                {<div className='divider-reference'></div>}
                <Col xs={12} className='m-b-10'>
                    <div className='main-title m-b-10'>Reference:</div>
                    <div className='reference-box'>
                        <span><div dangerouslySetInnerHTML={{__html:currentPage?.editContent?.content}}/></span>
                    </div>
                </Col>
                <div className='divider-reference  m-b-10'></div>
                <div className="d-flex justify-content-end">
                    <Button variant="default" className="btn-cancel" style={{ marginRight: '20px' }} onClick={e=>{
                        setCurrentPage({...currentPage, selectedView : "reference_details_page", editContent: {}})
                    }}>Cancel</Button>
                    <Button variant="primary" onClick={handleSave}>Save</Button>
                </div>
            </Row>}
        </Form>
    }

    const handleContinue =()=>{}

    return (
        <>
            <Offcanvas
                show={show}
                onHide={onHide}
                placement="end"
                style={{ width: "40pc", padding: "10px" }}
            >
                <Offcanvas.Header className="custom-modal-header">
                    <div className="d-flex align-items-center main-title p-l-3" style={{ gap: "10px" }}>
                        <BackSvg onClick={(e=>{
                                if(currentPage.selectedView === "edit_reference_page"){
                                    setCurrentPage({...currentPage, selectedView : "reference_details_page",})
                                }else onHide()
                        })} style={{ cursor: "pointer" }} />
                        <span>Back</span>
                    </div>
                </Offcanvas.Header>
                <Row className="m-0">
                    <Col lg={12}>
                        <div className="top-header-box d-flex align-items-center justify-content-between">
                            <span className='d-flex align-items-center'>
                                <Offcanvas.Title>{currentPage?.selectedView === "edit_reference_page" ? 'Edit Reference' : 'Reference'}</Offcanvas.Title>
                            </span>
                            {/* {currentPage?.selectedView !== "edit_reference_page" && (<Button className="btnRef" onClick={handleEdit}>Edit</Button>)} */}
                        </div>
                    </Col>
                </Row>
                <Offcanvas.Body className="conversation-custom-modal-body">
                    <br></br>
                    {/* {loader?.openModal && <SubmitCard showToast={loader?.openModal} onHide={() => handleContinue()} isSuccess={loader?.isSuccess} message={loader?.message} />} */}
                    <Row>
                        <Col xs={12}>
                            {currentPage?.selectedView === "edit_reference_page" ? renderEditView() : renderDetailsView()}
                        </Col>
                    </Row>
                </Offcanvas.Body>
            </Offcanvas>
        </>
    )
}
export default SideNavbar

