import * as actionTypes from '../actionTypes'

const INITIAL_STATE = {
  token: '',
  isTokenRefreshed: false,
  isRefreshTokenInValid: false,
}

const authReducer = function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case actionTypes.SET_LOGIN:
      return {
        ...state,
        token:action.data,
      }
    case actionTypes.SET_TOKEN_REFRESHED:
      return {
        isTokenRefreshed: action.data,
      }
    case actionTypes.IS_REFRESH_TOKEN_VALID:
        return {
          isRefreshTokenInValid: action.data,
        }
    case actionTypes.SET_LOGOUT:
      localStorage.clear();
      return {
        ...INITIAL_STATE,
      }
    default:
      return state
  }
}

export { authReducer }
