import React, { useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Accordion, Row, Col, Form, InputGroup } from "react-bootstrap";
import { getAddress } from "../../utils";
import axios from "axios";
import * as actionTypes from "../../store/actionTypes";
import * as amplitude from '@amplitude/analytics-browser';
import {
  getTpStatusDiscoveryOptions,
  getDiscoveryCompanies,
  getDiscoveryTpOwners,
  getDiscoveryFiltersMetaData,
} from "../../store/actions";
import {
  axiosCompanyPageTpStatusOptions,
  axiosGetWorkboardPeople,
  axiosDiscoveryFiltersData,
  axiosLLMFilters
} from "../../config/appConfig/axiosInstance";
import Nofilter from "../../commonComponents/NoFilter/NoFilter";
import PlainTootip from "../../commonComponents/InfoTooltip/PlainTooltip";
import { setDiscoveryFiltersPayload, getLLMFiltersData } from "../../store/actions/discoveryAction";
import DiscoveryTable from "./DiscoveryTable/DiscoveryTable";
import CommonError from "../../commonComponents/CommonError/CommonError";
import TableLoader from "./TableLoader/TableLoader";

import {
  totalFundingConstans,
  employeeInsightConstants,
  emailConstants,
  infoMessage,
  founderLocationTags,
  totalFundingInfoIcon
} from "./discoveryConstant";
import { tagsInDays } from "../../utils";
import TagsInSlider from "./TagsInSlider/TagsInSlider";
import LocationFilter from "./LocationFilter/LocationFilter";
import FounderLocation from "./FounderLocation/FounderLocation";

import { ReactComponent as ArrowUpSvg } from "../../assets/images/icon_svg/arrows-up.svg";
import { ReactComponent as ArrowDownSvg } from "../../assets/images/icon_svg/arrows-down.svg";
import { ReactComponent as SearchIcon } from "../../assets/images/icon_svg/search_icon.svg";
import { ReactComponent as CrossIcon } from "../../assets/images/icon_svg/X.svg";

import "./discovery.scss";
import InputFields from "./InputFields/InputFields";

const column_order = [
  "tp_owners",
  "tp_status",
  "growth_tags",
  "year_founded",
  "company_description_search",
  "employee_insights",
  "investment_data",
  "communication_with_telescope",
  "founder_location_search",
  "headquarter_search",
  "status",
];
let cancelToken;
let llmInputFilterClicked = false;

const result = column_order.map((col) => {
  let displayName = col
    .replace(/_/g, " ")
    .replace(/\b\w/g, (l) => l.toUpperCase());

  if (col === "tp_owners" || col === "tp_status") {
    displayName = displayName.replace("Tp", "TP");
  }

  if (col === "growth_tags") {
    displayName = "Growth";
  }

  if (col === "communication_with_telescope") {
    displayName = displayName.replace("With", "with");
  }

  if (col === "headquarter_search") {
    displayName = "Headquarter Search";
  }

  return {
    display_name: displayName,
    server_value: col,
    infoMessage:infoMessage?.[`${col}`] ? infoMessage[col] : ""
  };
});

const fieldsWithSearchBox = ["tp_owners", "tp_status", "status"];
const preDaysTags = ["total_funding_usd"];
const employeeInsightsTags = ["employee_count"];
const growthTags = [
  { value: 'growth_6m', displayName: 'Growth 6m' },
  { value: 'growth_1y', displayName: 'Growth 1y' },
  { value: 'growth_2y', displayName: 'Growth 2y' }
];

const Discovery = () => {

  const dispatch = useDispatch();
  const [
    tpStatusOptions,
    tpOwners,
    countries,
    states,
    cities,
    status,
    llmFilterData
  ] = useSelector(({ discoveryReducer }) => [
    discoveryReducer.discoveryTpStatusOptions.data,
    discoveryReducer.tpOwners,
    discoveryReducer.metadata.countries,
    discoveryReducer.metadata.states,
    discoveryReducer.metadata.cities,
    discoveryReducer.metadata.statuses,
    discoveryReducer?.llmFilterData
  ]);

  const [searchTerm, setSearchTerm] = useState("");
  const [filtersApplied, setFiltersApplied] = useState(false);
  const [dropdownsearchTerms, setDropdownSearchTerms] = useState({});
  const [checkboxObject, setCheckboxObject] = useState({});
  const [checkboxObjectVisiblity, setCheckboxObjectVisiblity] = useState({});
  const [tpStatusOperator, setTpStatusOperator] = useState("is");
  const [tpStatusSince, setTpStatusSince] = useState("");
  const [sliderObject, setSliderObject] = useState({});
  const [preTags, setPreTags] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [showAllFilters, setShowAllFilters] = useState(false);
  const [openItems, setOpenItems] = useState([]);
  const [accordionObject, setAccordionObject] = useState({});
  const [currentSelection, setCurrentSelection] = useState("");
  const [llmFounderLocation, setLLmFounderLocation] = useState({});
  const [populateLocation, setPopulateLocation] = useState([]);
  const [selectedGrowthsData, setSelectedGrowthsData] = useState([]);
  const [hqLocation, setHqLocation] = useState({});
  const [selectedTotalFundingData, setSelectedTotalFundingData] = useState({trueFalse: 'false'});
  const [filteredLocationObject, setFilteredLocationObject] = useState({
    keys: "",
    value: "",
  });
  const [yearFounded, setYearFounded] = useState({
    greaterThanValue: '',
    lesserThanValue: ''
  });
  const [countriesData, setCountriesData] = useState([]);
  const [filterCountry, setFilterCountry] = useState("");
  const [founderLocationTag, setFounderLocationTag] = useState({
    displayName: "Countries",
    serverValue: "countries"
  });
  const [selectedFounderLocation, setSelectedFounderLocation] = useState({
    type: "",
    data: []
  });
  const [metaDataLoading, setMetaDataLoading] = useState({
    isLoading: false,
    errorMessage: "",
  });
  const [founderLocationChange, setFounderLocationChange] = useState("")
  const [descriptionState, setDescriptionState] = useState({
    descriptions: [], 
    currentDescription: '',
  });
  const [communicationFilter, setCommunicationFilter] = useState({
    option: "",
    reachedOut: false, 
    notReachedOutValue: "", 
    timeUnit: "days",
    days_of_email_sent: 0,
  }); 
  const [llmFilter, setLLMFilters] = useState({
    enteredValue: "",
    searchTerm:""
  })

  useEffect(() => {
    setMetaDataLoading({
      ...metaDataLoading,
      isLoading: true,
    });
    dispatch(getDiscoveryFiltersMetaData(axiosDiscoveryFiltersData)).then(
      (res) => {
        if (res.status === "success") {
          setMetaDataLoading({
            ...metaDataLoading,
            isLoading: false,
            errorMessage: "",
          });
        amplitude.logEvent(`Discovery Loaded`);
        } else {
          setMetaDataLoading({
            ...metaDataLoading,
            isLoading: false,
            errorMessage: "No metadata available!",
          });
        }
      }
    );
    dispatch(getTpStatusDiscoveryOptions(axiosCompanyPageTpStatusOptions));
    dispatch(getDiscoveryTpOwners(axiosGetWorkboardPeople));
  }, []);

  useEffect(() => {
    if (column_order?.length) {
      let finalObject = {};
      column_order?.forEach((i, inx) => {
        finalObject[i] = {
          name: i,
          index: inx,
          isAccordionOpen: false,
        };
      });
      setAccordionObject(finalObject);
    }
  }, [column_order]);

  useEffect(() => {
    if (fieldsWithSearchBox?.length) {
      let finalObject = {};
      let visbilityObject = {};
      let searchObject = {};
      fieldsWithSearchBox?.map((i) => {
        if (i === "tp_status") {
          finalObject[i] = [];
        } else {
          finalObject[i] = [];
        }
        visbilityObject[i] = {
          count: 20,
          visible: false,
          searchCount: 0,
          isSearching: false,
        };
        searchObject[i] = "";
      });
      setCheckboxObject(finalObject);
      setCheckboxObjectVisiblity(visbilityObject);
      setDropdownSearchTerms(searchObject);
    }
  }, [fieldsWithSearchBox]);

  useEffect(() => {
    if (preDaysTags?.length) {
      let finalObject = {};
      preDaysTags?.map((i) => {
        finalObject[i] = [];
      });
      setPreTags(finalObject);
    }
  }, [preDaysTags]);

  useEffect(() => {
    if (column_order?.length) {
      const filteredColumnOrder = column_order.filter(
        (item) =>
          !fieldsWithSearchBox.includes(item) || !preDaysTags.includes(item)
      );
  
      const finalObject = {};
      filteredColumnOrder?.forEach(
        (i) =>
          (finalObject[i] = {
            greaterThanThumbPosition: null,
            lesserThanThumbPosition: null,
            symbol:
              i === "employee_count"
                ? ""
                : i === "sales_engineering_percentage" ||
                  i === "seniority_percentage"
                ? "%"
                : "days",
            maxValue:
              i === "sales_engineering_percentage" ||
              i === "seniority_percentage"
                ? 100
                : ["employee_count"].includes(i)
                ? 10000
                : 100,
          })
      );
      employeeInsightsTags.forEach((tag) => {
        finalObject[tag] = {
          greaterThanThumbPosition: null,
          lesserThanThumbPosition: null,
          symbol: tag === "employee_count" ? "" : "%",
          maxValue: tag === "employee_count" ? 10000 : 100,
        };
      });
  
      // Exclude year_founded from finalObject
      const sliderObjectKeys = Object.keys(finalObject).filter(key => key !== 'year_founded' || key !== 'company_description_search');
      const newSliderObject = {};
      sliderObjectKeys.forEach(key => {
        newSliderObject[key] = finalObject[key];
      });
  
      setSliderObject(newSliderObject);
    }
  }, [column_order]);

  useEffect(()=> {
    if(llmFilter?.enteredValue) {
      handleResetAll();
      setIsLoading(true);
      dispatch(getLLMFiltersData(axiosLLMFilters, {filter_search: llmFilter?.enteredValue}))
    } else {
      setIsLoading(false);
    }
  },[llmFilter?.enteredValue]);

  useEffect(() => {
    if (llmFilterData && Object.keys(llmFilterData)?.length && !isEmptyObject()) {
        populateFilterFn();
    } else {
      setIsLoading(false);
    }
  }, [llmFilterData]);

  useEffect(() => {
    return() => {
      dispatch({
        type: actionTypes.RESET_LLM_FILTERS
      })
      handleResetAll();
      setLLMFilters({
        enteredValue: "",
        searchTerm:""
      })
    }
  }, []);

  const isEmptyObject = () => {
    let hasEmpty = Object.entries(llmFilterData).every(([key, value]) => {
      if (key === 'uuid') {
        return true;
      } else if (Array.isArray(value)) {
        return value.length === 0;
      } else if (typeof value === 'object' && value !== null) {
        return Object.keys(value).length === 0;
      } else {
        return value === "n/a" || value === null || value === 0 || value === "";
      }
    });
    return hasEmpty;
  }

  const debounce = (delay) => {
    let timeoutId;
    return (...args) => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        handleSubmit();
      }, delay);
    };
  };

  const debouncedHandleSubmit = debounce(handleSubmit, 300);

  useEffect(() => {
    const debounceTimer = setTimeout(() => {
      handleSubmit();
    }, 300);
    return () => {
      clearTimeout(debounceTimer);
    };
  }, [
    checkboxObject,
    sliderObject,
    preTags,
    selectedGrowthsData,
    selectedTotalFundingData,
    hqLocation,
    countriesData,
    yearFounded,
    tpStatusSince,
    selectedFounderLocation,
    descriptionState.descriptions,
    communicationFilter.reachedOut,
    communicationFilter.option,
    communicationFilter.days_of_email_sent,
    communicationFilter.notReachedOutValue
  ]);

  useEffect(() => {
    if (checkboxObject["tp_status"]?.length) debouncedHandleSubmit();
  }, [tpStatusOperator]);

  const handleSearchBoxChange = (field, value, array) => {
    setDropdownSearchTerms((prevSearchTerms) => ({
      ...prevSearchTerms,
      [field]: value,
    }));

    if (value) {
      setCheckboxObjectVisiblity((prevCheckboxObject) => {
        // Copy the previous state to avoid mutating it directly
        const updatedObject = { ...prevCheckboxObject };
        // Check if serverValue exists in the checkboxObject
        if (updatedObject.hasOwnProperty(field)) {
          updatedObject[field]["isSearching"] = true;
          updatedObject[field]["searchCount"] =
            field === "tp_owners"
              ? array?.filter((item) =>
                  item?.full_name?.toLowerCase()?.includes(value?.toLowerCase())
                )?.length
              : array?.filter((item) =>
                  item?.toLowerCase()?.includes(value?.toLowerCase())
                )?.length;
        }
        return updatedObject;
      });
    } else {
      setCheckboxObjectVisiblity((prevCheckboxObject) => {
        // Copy the previous state to avoid mutating it directly
        const updatedObject = { ...prevCheckboxObject };
        // Check if serverValue exists in the checkboxObject
        if (updatedObject.hasOwnProperty(field)) {
          updatedObject[field]["isSearching"] = false;
          updatedObject[field]["searchCount"] = 0;
        }
        return updatedObject;
      });
    }
  };

  const processSliderData = (field, item, setSliderObject) => {
    let itemArr = item.value.split(":");
    let newState = {
      greaterThanValue: "",
      lessThanValue: "",
      selectedTags: []
    };
  
    if (itemArr[0].includes("GREATER_THAN_OR_EQUAL_TO")) {
      newState.greaterThanValue = itemArr[1];
      newState.selectedTags.push({
        value: itemArr[1],
        operator: "greaterThan"
      });
    }
    if (itemArr[0].includes("LESS_THAN_OR_EQUAL_TO")) {
      newState.lessThanValue = itemArr[1];
      newState.selectedTags.push({
        value: itemArr[1],
        operator: "lessThan"
      });
    }
  
    setSliderObject(prevState => {
      let combinedSelectedTags = [
        ...prevState[field]?.selectedTags?.filter(tag => {
          return !newState.selectedTags.some(newTag => newTag.operator === tag.operator);
        }) || [],
        ...newState.selectedTags
      ];
      const updatedFieldData = {
        ...prevState[field],
        greaterThanValue: newState.greaterThanValue || prevState[field]?.greaterThanValue || "",
        lessThanValue: newState.lessThanValue || prevState[field]?.lessThanValue || "",
        selectedTags: combinedSelectedTags
      };
      return {
        ...prevState,
        [field]: updatedFieldData
      };
    });
  };

  const populateFilterFn = () => {
    let updatedAccordionObject = { ...accordionObject };
  
    if (Object.keys(llmFilterData)?.length) {
      Object.keys(llmFilterData)?.forEach(i => {
        if (i === "tp_owner" && llmFilterData[i]?.length) {
          let selectedTP_Owners = tpOwners?.filter(tpOwner => llmFilterData[i].includes(tpOwner.email_address));
          setCheckboxObject(prevState => ({
            ...prevState,
            tp_owners: selectedTP_Owners.map(owner => owner.email_address)
          }));
          if (updatedAccordionObject["tp_owners"]) {
            updatedAccordionObject["tp_owners"].isAccordionOpen = true;
          }
        }
        if (i === "tp_status_value" && llmFilterData[i]?.length) {
          setCheckboxObject(prevState => ({
            ...prevState,
            tp_status: llmFilterData[i]
          }));
          if (llmFilterData["tp_status_operator"]) {
            setTpStatusOperator(llmFilterData["tp_status_operator"].toLowerCase());
          }
          if (llmFilterData["tp_status_since"]) {
            setTpStatusSince(llmFilterData["tp_status_since"]);
          }
          if (updatedAccordionObject["tp_status"]) {
            updatedAccordionObject["tp_status"].isAccordionOpen = true;
          }
        }
  
        if (i === "status" && llmFilterData[i]?.length) {
          setCheckboxObject(prevState => ({
            ...prevState,
            status: llmFilterData[i]
          }));
          if (updatedAccordionObject["status"]) {
            updatedAccordionObject["status"].isAccordionOpen = true;
          }
        }
  
        if (i === "founder_city" && llmFilterData[i]?.length) {
          setFounderLocationTag({
            displayName: "Cities",
            serverValue: "cities"
          });
          setLLmFounderLocation({
            type: 'cities',
            val: llmFilterData[i]
          });
          if (updatedAccordionObject["founder_location_search"]) {
            updatedAccordionObject["founder_location_search"].isAccordionOpen = true;
          }
          setCurrentSelection("founder_location_search");
        }
  
        if (i === "founder_country" && llmFilterData[i]?.length) {
          setFounderLocationTag({
            displayName: "Countries",
            serverValue: "countries"
          });
          setLLmFounderLocation({
            type: 'countries',
            val: llmFilterData[i]
          });
          if (updatedAccordionObject["founder_location_search"]) {
            updatedAccordionObject["founder_location_search"].isAccordionOpen = true;
          }
          setCurrentSelection("founder_location_search");
        }
  
        if (i === "founder_state" && llmFilterData[i]?.length) {
          setFounderLocationTag({
            displayName: "States",
            serverValue: "states"
          });
          setLLmFounderLocation({
            type: 'states',
            val: llmFilterData[i]
          });
          if (updatedAccordionObject["founder_location_search"]) {
            updatedAccordionObject["founder_location_search"].isAccordionOpen = true;
          }
          setCurrentSelection("founder_location_search");
        }
  
        if (i === "hq_city" && llmFilterData[i]?.length) {
          setPopulateLocation(prevState => [
            ...prevState,
            { type: 'cities', val: llmFilterData[i].map(word => word[0].toUpperCase() + word.slice(1)) }
          ]);
          if (updatedAccordionObject["headquarter_search"]) {
            updatedAccordionObject["headquarter_search"].isAccordionOpen = true;
          }
          setCurrentSelection("headquarter_search");
        }
  
        if (i === "hq_state" && llmFilterData[i]?.length) {
          setPopulateLocation(prevState => [
            ...prevState,
            { type: 'states', val: llmFilterData[i].map(word => word[0].toUpperCase() + word.slice(1)) }
          ]);
          if (updatedAccordionObject["headquarter_search"]) {
            updatedAccordionObject["headquarter_search"].isAccordionOpen = true;
          }
          setCurrentSelection("headquarter_search");
        }
  
        if (i === "hq_country" && llmFilterData[i]?.length) {
          setPopulateLocation(prevState => [
            ...prevState,
            { type: 'countries', val: [llmFilterData[i][0].toUpperCase() + llmFilterData[i].slice(1)] }
          ]);
          if (updatedAccordionObject["headquarter_search"]) {
            updatedAccordionObject["headquarter_search"].isAccordionOpen = true;
          }
          setCurrentSelection("headquarter_search");
        }
  
        if (i === "query_filters" && llmFilterData[i]?.length) {
          llmFilterData["query_filters"]?.forEach(item => {
            if (item?.field === "year_founded") {
              let itemArr = item?.value?.split(":");
              let newState = {};
  
              if (itemArr[0]?.includes("GREATER")) {
                newState.greaterThanValue = itemArr[1];
              }
              if (itemArr[0]?.includes("LESS")) {
                newState.lesserThanValue = itemArr[1];
              }
  
              setYearFounded(prevState => ({
                ...prevState,
                ...newState
              }));
              if (updatedAccordionObject["year_founded"]) {
                updatedAccordionObject["year_founded"].isAccordionOpen = true;
              }
            }
  
            if (item?.field.includes("growth")) {
              const growthTag = growthTags.find(tag => item.field.includes(tag.value));
              if (growthTag) {
                let itemArr = item.value.split(":");
                let newState = {
                  displayName: growthTag.displayName,
                  value: growthTag.value,
                  greaterThanValue: "",
                  lessThanValue: ""
                };
  
                if (itemArr[0].includes("GREATER")) {
                  newState.greaterThanValue = itemArr[1];
                }
                if (itemArr[0].includes("LESS")) {
                  newState.lessThanValue = itemArr[1];
                }
  
                setSelectedGrowthsData(prevState => {
                  const existingIndex = prevState.findIndex(growth => growth.value === growthTag.value);
                  if (existingIndex !== -1) {
                    const updatedGrowth = {
                      ...prevState[existingIndex],
                      greaterThanValue: newState.greaterThanValue || prevState[existingIndex].greaterThanValue,
                      lessThanValue: newState.lessThanValue || prevState[existingIndex].lessThanValue
                    };
                    return [
                      ...prevState.slice(0, existingIndex),
                      updatedGrowth,
                      ...prevState.slice(existingIndex + 1)
                    ];
                  } else {
                    return [
                      ...prevState,
                      newState
                    ];
                  }
                });
                if (updatedAccordionObject["growth_tags"]) {
                  updatedAccordionObject["growth_tags"].isAccordionOpen = true;
                }
              }
            }
  
            if (item?.field === "employee_count") {
              processSliderData("employee_count", item, setSliderObject);
              if (updatedAccordionObject["employee_insights"]) {
                updatedAccordionObject["employee_insights"].isAccordionOpen = true;
              }
            }
  
            if (item?.field === "total_funding_usd") {
              processSliderData("investment_data", item, setSliderObject);
              if (updatedAccordionObject["investment_data"]) {
                updatedAccordionObject["investment_data"].isAccordionOpen = true;
              }
            }
          });
        }
  
        if (i === "days_of_email_sent" && llmFilterData[i] !== null) {
          setCommunicationFilter(prevState => ({
            ...prevState,
            days_of_email_sent: llmFilterData[i],
            option: "reached_out",
            reachedOut: true,
            notReachedOutValue: llmFilterData[i],
            timeUnit: "days"
          }));
          if (updatedAccordionObject["communication_with_telescope"]) {
            updatedAccordionObject["communication_with_telescope"].isAccordionOpen = true;
          }
        }
  
        if (i === "has_email_sent" && (llmFilterData[i] === true || llmFilterData[i] === false)) {
          if (llmFilterData[i] === true) {
            setCommunicationFilter(prevState => ({
              ...prevState,
              option: "reached_out",
              reachedOut: true,
              notReachedOutValue: llmFilterData["days_of_email_sent"] || "",
              days_of_email_sent: llmFilterData["days_of_email_sent"] || 0,
              timeUnit: "days"
            }));
          } else if (llmFilterData[i] === false) {
            setCommunicationFilter(prevState => ({
              ...prevState,
              option: "not_reached_out",
              reachedOut: false,
              notReachedOutValue: llmFilterData["days_of_email_sent"] || "",
              days_of_email_sent: llmFilterData["days_of_email_sent"] || 0,
              timeUnit: "days"
            }));
          }
          if (updatedAccordionObject["communication_with_telescope"]) {
            updatedAccordionObject["communication_with_telescope"].isAccordionOpen = true;
          }
        }
  
        if (i === "description" && llmFilterData[i]?.length) {
          setDescriptionState(prevState => ({
            ...prevState,
            descriptions: [...prevState.descriptions, llmFilterData[i]]
          }));
          if (updatedAccordionObject["company_description_search"]) {
            updatedAccordionObject["company_description_search"].isAccordionOpen = true;
          }
        }
      });
    }

    const founderLocationFields = ["founder_city", "founder_state", "founder_country"];
    const headquarterLocationFields = ["hq_city", "hq_state", "hq_country"];
    if (founderLocationFields.every(field => (llmFilterData[field]?.length === 0))) {
      setCurrentSelection("");
    } else if (headquarterLocationFields.every(field => (llmFilterData[field]?.length === 0))) {
      setCurrentSelection("");
    }

    setAccordionObject(updatedAccordionObject);
  };
  

  const getFiltersPayload = () => {
    let params = "";
    // check check box
    if (checkboxObject["tp_owners"]?.length) {
      params =
        `${params && params + "&"}` +
        checkboxObject["tp_owners"]
          .map((value) => `tp_owner_email=${value}`)
          .join("&");
    }
    if (checkboxObject["tp_status"]?.length) {
      params =
        `${params && params + "&"}` + `tp_status_operator=${tpStatusOperator}`;
      params =
        `${params && params + "&"}` +
        checkboxObject["tp_status"]
          ?.map((value) => `tp_status_value=${value?.toLowerCase()}`)
          .join("&");
      if (tpStatusOperator === "was" && tpStatusSince) {
        params += `${params && "&"}tp_status_since=${tpStatusSince}`;
      }
    }
    if (countriesData?.length) {
      params =
        `${params && params + "&"}` +
        countriesData?.map((value) => `country=${value}`).join("&");
    }

    if (hqLocation["countries"]?.selected?.length) {
      params =
        `${params && params + "&"}` +
        hqLocation["countries"]?.selected
          ?.map((value) => `hq_country=${value}`)
          .join("&");
    }
    if (hqLocation["states"]?.selected?.length) {
      params =
        `${params && params + "&"}` +
        hqLocation["states"]?.selected
          ?.map((value) => `hq_state=${value}`)
          .join("&");
    }
    if (hqLocation["cities"]?.selected?.length) {
      params =
        `${params && params + "&"}` +
        hqLocation["cities"]?.selected
          ?.map((value) => `hq_city=${value}`)
          .join("&");
    }

    if (checkboxObject["status"]?.length) {
      params =
        `${params && params + "&"}` +
        checkboxObject["status"]?.map((value) => `status=${value}`).join("&");
    }
    const employeeCount = sliderObject["employee_count"];
    if (employeeCount) {
      if (employeeCount.selectedTags?.length) {
        employeeCount.selectedTags?.forEach((tag) => {
          if (tag?.operator === "greaterThan" && tag?.value) {
            params += `${
              params ? "&" : ""
            }employee_count=GREATER_THAN_OR_EQUAL_TO:${tag.value}`;
          } else if (tag?.operator === "lessThan" && tag?.value) {
            params += `${
              params ? "&" : ""
            }employee_count=LESS_THAN_OR_EQUAL_TO:${tag.value}`;
          } else if (
            tag?.minCount !== undefined &&
            tag?.maxCount !== undefined
          ) {
            params += `${
              params ? "&" : ""
            }employee_count=GREATER_THAN_OR_EQUAL_TO:${tag.minCount}`;
            params += `&employee_count=LESS_THAN_OR_EQUAL_TO:${tag.maxCount}`;
          } else if (tag?.value === "below_20") {
            params += `${
              params ? "&" : ""
            }employee_count=LESS_THAN_OR_EQUAL_TO:20`;
          } else if (tag?.value === "above_1000") {
            params += `${
              params ? "&" : ""
            }employee_count=GREATER_THAN_OR_EQUAL_TO:1000`;
          }
        });
      }
    }
    const investmentData = sliderObject["investment_data"];
    if (investmentData) {
      if (investmentData.selectedTags?.length) {
        investmentData.selectedTags.forEach((tag) => {
          if (tag?.operator === "greaterThan" && tag?.value) {
            params += `${params ? "&" : ""}total_funding_usd=GREATER_THAN_OR_EQUAL_TO:${tag.value}`;
          } else if (tag?.operator === "lessThan" && tag?.value) {
            params += `${params ? "&" : ""}total_funding_usd=LESS_THAN_OR_EQUAL_TO:${tag.value}`;
          }
        });
        if (selectedTotalFundingData.trueFalse) {
          params += `${params && "&"}total_funding_usd_no_data=${selectedTotalFundingData.trueFalse}`;
        } 
      }
    }
    // Growth
    selectedGrowthsData.forEach((selectedTag) => {
      if (selectedTag.greaterThanValue) {
        params +=
          `${params && "&"}` + `${selectedTag.value}=GREATER_THAN_OR_EQUAL_TO:${selectedTag.greaterThanValue}`;
      }
      if (selectedTag.lessThanValue) {
        params +=
          `${params && "&"}` + `${selectedTag.value}=LESS_THAN_OR_EQUAL_TO:${selectedTag.lessThanValue}`;
      }
    });
    // Year Founded
    if (yearFounded.greaterThanValue || yearFounded.lesserThanValue) {
      if (yearFounded.greaterThanValue) {
        params +=
          `${params && "&"}` +
          `year_founded=GREATER_THAN_OR_EQUAL_TO:${yearFounded.greaterThanValue}`;
      }
      if (yearFounded.lesserThanValue) {
        params +=
          `${params && "&"}` +
          `year_founded=LESS_THAN_OR_EQUAL_TO:${yearFounded.lesserThanValue}`;
      }
    }  

    // Founder Location
    if (selectedFounderLocation?.type && selectedFounderLocation?.data?.length > 0) {
      if (selectedFounderLocation?.type === "countries") {
        params += `${params && "&"}` + selectedFounderLocation.data.map(country => `founder_country=${country}`).join("&");
      }
      if (selectedFounderLocation?.type === "states") {
        params += `${params && "&"}` + selectedFounderLocation.data.map(state => `founder_state=${state}`).join("&");
      }
      if (selectedFounderLocation?.type === "cities") {
        params += `${params && "&"}` + selectedFounderLocation.data.map(city => `founder_city=${city}`).join("&");
      }
    }
    // Description
    if (descriptionState.descriptions.length > 0) {
      params +=
          `${params && "&"}` + `description=${encodeURIComponent(
        descriptionState.descriptions.join(' ')
      )}`;
    }

    // Communication Filter
    if (communicationFilter.option === "reached_out" && communicationFilter.reachedOut) {
      params += `${params && "&"}has_email_sent=true`;
    } else if (communicationFilter.option === "not_reached_out" && !communicationFilter.reachedOut) {
      params += `${params && "&"}has_email_sent=false`;
    }
    if (communicationFilter.days_of_email_sent > 0) {
      params += `${params && "&"}days_of_email_sent=${communicationFilter.days_of_email_sent}`;
    }

    // LLM Filter UUID
    if(llmFilterData && llmFilterData?.uuid) {
      params += `${params && "&"}` + `discovery_llm_search_uuid=${llmFilterData?.uuid}`;
    }

    return params;
  };
  
  const formatFiltersPayload = (payload) => {
    const params = new URLSearchParams(payload);
    const formattedPayload = {};
  
    params.forEach((value, key) => {
      const formattedKey = key?.replace(/_/g, ' ')?.replace(/(\b\w)/g, char => char?.toUpperCase());
  
      if (formattedPayload?.hasOwnProperty(formattedKey)) {
        if (!Array?.isArray(formattedPayload[formattedKey])) {
          formattedPayload[formattedKey] = [formattedPayload[formattedKey]];
        }
        formattedPayload[formattedKey]?.push(value);
      } else {
        formattedPayload[formattedKey] = value;
      }
    });
  
    return formattedPayload;
  };

  const handleSubmit = async () => {
    const hasValue = checkLength();

    setFiltersApplied(hasValue);  
    if (!hasValue) {
      dispatch({type: actionTypes.RESET_DISCOVERY_FILTERS, data: {}})
      return;
    }
    if(!llmInputFilterClicked) {
      dispatch({type: actionTypes.RESET_LLM_FILTERS})
    }
    llmInputFilterClicked = false;
    const filtersPayload = await getFiltersPayload();

    // Storing the Filters Payload for DiscoveryTable component
    dispatch(setDiscoveryFiltersPayload(filtersPayload));

    // // Construct the URL with the combined filters
    const apiUrlWithCombinedFilters = filtersPayload
      ? `?${filtersPayload}`
      : "";
      
    const formattedFilters = formatFiltersPayload(filtersPayload);
    amplitude.logEvent(`Discovery Performed`, { ...formattedFilters });
    try {
      setIsLoading(true);
      if(cancelToken) {
        cancelToken.cancel("operation cancelled due to new req")
      }
      const CancelToken = axios.CancelToken;
      cancelToken = CancelToken.source();
      // Make the API call with the constructed URL
      const url = `${getAddress() + `/discovery${apiUrlWithCombinedFilters}`}`;
      const result = await getDiscoveryCompanies(url, cancelToken)(dispatch);
     
      if (result.status === "success") {
        setIsLoading(false);
        setLLMFilters({
          ...llmFilter,
          enteredValue: "",
        })

      } else {
        // setIsLoading(false);
        setError(result.errMsg);
        if (result.status === 'ERR_CANCELED') {
        } else {
          setIsLoading(false);
        }
        // console.error("Error fetching companies:", result.errMsg);
      }
    } catch (error) {
      setIsLoading(false);
      console.error("Error:", error);
    }
  };
  
  const filterDisplayNames = (searchTerm) => {
    return result.filter((item) => {
      return item?.display_name
        .toLowerCase()
        .includes(searchTerm.toLowerCase());
    });
  };

  const checkLength = () => {
    const hasValue =
      Object.entries(checkboxObject).some(([key, val]) => val.length) ||
      Object.values(sliderObject)
        .map(
          ({
            greaterThanThumbPosition,
            lesserThanThumbPosition,
            selectedTags,
          }) => {
            return selectedTags && selectedTags.some((i) => i.value) > 0;
          }
        )
        .some((value) => value) ||
      Object.entries(preTags).some(
        ([key, val]) =>
          val.length ||
          Object.entries(hqLocation).some(
            ([key, val]) => val["selected"]?.length
          )
      ) ||
      countriesData?.length ||
      (yearFounded.greaterThanValue > 0 || yearFounded.lesserThanValue > 0) ||
      selectedFounderLocation.data?.length > 0 ||
      (descriptionState.descriptions.length > 0) ||
      (communicationFilter.reachedOut || communicationFilter.option !== "") ||
      (communicationFilter.days_of_email_sent > 0) ||
      selectedGrowthsData.some(
        (tag) => tag.greaterThanValue || tag.lessThanValue
      );

    return hasValue;
  };

  const handleCheckboxChange = (value, isChecked, serverValue, arrayVal) => {
    setCheckboxObject((prevCheckboxObject) => {
      // Copy the previous state to avoid mutating it directly
      const updatedCheckboxObject = { ...prevCheckboxObject };

      if (value === "Select All") {
        updatedCheckboxObject[serverValue] = isChecked
          ? serverValue === "tp_owners"
            ? arrayVal.map((o) => o.email_address)
            : arrayVal.map((o) => o)
          : [];
      } else {
        // Check if serverValue exists in the checkboxObject
        if (updatedCheckboxObject.hasOwnProperty(serverValue)) {
          // Update the array based on the condition
          updatedCheckboxObject[serverValue] = isChecked
            ? serverValue === "tp_owners"
              ? [...updatedCheckboxObject[serverValue], value.email_address]
              : [...updatedCheckboxObject[serverValue], value]
            : serverValue === "tp_owners"
            ? updatedCheckboxObject[serverValue].filter(
                (checkedItem) => checkedItem !== value.email_address
              )
            : updatedCheckboxObject[serverValue].filter(
                (checkedItem) => checkedItem !== value
              );
        }
      }

      const isLastCheckboxUncheck =
        updatedCheckboxObject[serverValue]?.length === 0;

      if (isLastCheckboxUncheck) {
        setAccordionObject((prevAccordion) => {
          const updatedAccordion = { ...prevAccordion };
          const keyToUpdate = Object.keys(updatedAccordion).find(
            (key) => updatedAccordion[key].name === serverValue
          );

          if (keyToUpdate) {
            updatedAccordion[keyToUpdate].isAccordionOpen = false;
          }
          return updatedAccordion;
        });
      }

      return updatedCheckboxObject;
    });
  };

  const isTotalFunding = (val) => val.startsWith('total_funding_usd_no_data');

  // const isEmailTag = (val) => {
  //   let isEmailTag = false;
  //   isEmailTag = emailConstants.some(
  //     (i) => i.server_value === val?.toLowerCase()
  //   );
  //   return isEmailTag;
  // };

  const isFundingTag = (val) => {
    let isFundingTag = false;
    isFundingTag = tagsInDays.some(
      (i) => i.server_value === val?.toLowerCase()
    );
    return isFundingTag;
  };

  const handleRemoveFilter = (val, display_name) => {
    if (val === "hasPresence") {
      setFilterCountry(display_name);
    } else if (val === "countries" || val === "states" || val === "cities") {
      setFilteredLocationObject({
        keys: val,
        value: display_name?.replace("HQ:", "")?.trim(),
      });
    } else if (selectedFounderLocation?.data?.length > 0) {
      const type = selectedFounderLocation.type;
      const newData = selectedFounderLocation.data.filter(
        (location) => location !== display_name.replace(`Founder ${type.slice(0, -1).charAt(0).toUpperCase() + type.slice(1).toLowerCase()}: `, "").trim()
      );
      setSelectedFounderLocation({ type, data: newData });
      let removeFilter = display_name.split(":")
      setFounderLocationChange(removeFilter[1]?.trim());
    } else if (isTotalFunding(val)) {
      setSelectedTotalFundingData({
        trueFalse: 'false',
      });
    } else if (val === 'tp_status_since' && display_name.includes("TP Status since")) {
      setTpStatusSince('');
    } else if (val.startsWith("company_description_")) {
      const index = parseInt(val.split("_")[2]); 
      handleRemoveDescription(index); 
    } else if (isFundingTag(val)) {
      setPreTags((prevData) => {
        const updatedPreTags = { ...prevData };
        const updatedAb = Object.fromEntries(
          Object.entries(updatedPreTags).map(([key, value]) => [
            key,
            value.filter((item) => item.server_value !== val),
          ])
        );
        return updatedAb;
      });
    } else if (val === "reached_out" || val === "not_reached_out") {
      setCommunicationFilter((prevState) => ({
        ...prevState,
        option: "",
        reachedOut: false,
        notReachedOutValue: "",
        days_of_email_sent: 0,
      }));
    } else if (val === "days_of_email_sent") {
      setCommunicationFilter((prevState) => ({
        ...prevState,
        days_of_email_sent: "",
        notReachedOutValue: ""
      }));
    } else if (val?.includes(">=") || val?.includes("<=")) {
      const [, conditionKey, operator, conditionValueString] = val.match(
        /(.+?)\s*([<>]=?)\s*(\d+)/
      );
      let normalizedConditionKey = conditionKey
        .toLowerCase()
        .replace(/\s+/g, "_");
      normalizedConditionKey = normalizedConditionKey.replace(/_$/, "");

      if (normalizedConditionKey === "year_founded") { 
        setYearFounded((prevYearFounded) => {
          const updatedYearFounded = { ...prevYearFounded };
    
          if (operator === ">=") {
            updatedYearFounded.greaterThanValue = "";
          } else if (operator === "<=") {
            updatedYearFounded.lesserThanValue = "";
          }
    
          return updatedYearFounded;
        });
      } else if (normalizedConditionKey.startsWith("growth_")) {
        setSelectedGrowthsData((prevSelected) => {
          return prevSelected.map((item) => {
            if (item.value === normalizedConditionKey) {
              if (operator === ">=") {
                return { ...item, greaterThanValue: "" };
              } else if (operator === "<=") {
                return { ...item, lessThanValue: "" };
              }
            }
            return item;
          }).filter(item => item.greaterThanValue || item.lessThanValue);
        });
      } 
      else {
        setSliderObject((prevSliderObject) => {
          const updatedObject = { ...prevSliderObject };
          
          if (updatedObject.hasOwnProperty(normalizedConditionKey)) {
            function mapOperator(operator) {
              if (operator === "<=") return "greaterThan";
              if (operator === ">=") return "lessThan";
              return operator;
            }
            // Extracting the operator and value from the string
            const regex = /<=|>=/;
            const [operator] = display_name.match(regex);
            let value;
            let newObject;
            if (
              updatedObject[
                normalizedConditionKey
              ].selectedTags[0]?.hasOwnProperty("displayName")
            ) {
              value =
                value === "below_20" || value === "above_1000"
                  ? null
                  : operator === "<="
                  ? updatedObject[normalizedConditionKey].selectedTags[0]
                      ?.minCount
                  : updatedObject[normalizedConditionKey].selectedTags[0]
                      ?.maxCount;
              newObject = {
                operator: mapOperator(operator),
                value: value,
              };
              updatedObject[normalizedConditionKey].selectedTags = value
                ? [newObject]
                : [];
            } else {
              let filteredTag = updatedObject[
                normalizedConditionKey
              ].selectedTags?.filter((i) => i.operator === mapOperator(operator));
              newObject = {
                ...filteredTag[0],
              };
              updatedObject[normalizedConditionKey].selectedTags = filteredTag[0]
                ?.value
                ? [newObject]
                : [];
            }

            if (selectedTotalFundingData.trueFalse && !selectedTotalFundingData.trueFalse.length) {
              setSelectedTotalFundingData([]);
            }
          }
          return updatedObject;
        });
      }
    } else {
      setCheckboxObject((prevCheckboxObject) => {
        const updatedCheckboxObject = { ...prevCheckboxObject };

        const updatedAb = Object.fromEntries(
          Object.entries(updatedCheckboxObject).map(([key, value]) => [
            key,
            value.filter((item) => item !== val),
          ])
          );
          if (updatedAb.tp_status?.length === 0 && tpStatusOperator === 'was') {
            setTpStatusSince('');
          }
        return updatedAb;
      });
    }

    const isLastFilterForField =
      checkboxObject[val]?.length === 1 ||
      !sliderObject[val]?.selectedTags?.length ||
      (val === "growth_tags" && selectedGrowthsData?.length === 1) ||
      (val === "total_funding_on" && selectedTotalFundingData?.length === 1) ||
      (Object.entries(yearFounded).some(([key, val]) => val.length === 1));
    if (isLastFilterForField) {
      setAccordionObject((prevAccordion) => {
        const updatedAccordion = { ...prevAccordion };
        let keyToUpdate = Object.keys(updatedAccordion).find(
          (key) => updatedAccordion[key].name === val
        );

        if (
          val === "countries" ||
          val === "states" ||
          val === "cities" ||
          val === "hasPresence"
        ) {
          const hqLocationKeys = ["countries", "states", "cities"];
          const selectedCount = hqLocationKeys.reduce(
            (count, key) => count + (hqLocation[key]?.selected.length || 0),
            0
          );
          const countriesDataCount = countriesData?.length;
          const totalCount = selectedCount + countriesDataCount;

          if (totalCount === 1) {
            keyToUpdate = "headquarter_search";
          }
        }

        if (keyToUpdate) {
          updatedAccordion[keyToUpdate].isAccordionOpen = false;
        }
        return updatedAccordion;
      });
      setCurrentSelection("");
    }
  };

  const renderAppliedFilters = () => {
    const maxDisplayedFilters = 5;
    let resultArray = [];
    Object.entries(sliderObject).forEach(([key, { selectedTags }]) => {
      const format = key
        ?.replace(/_/g, " ")
        .replace(/\b\w/g, (l) => l.toUpperCase());
      // Check if selectedTags exist and add them to the resultArray
      if (selectedTags && selectedTags?.length) {
        selectedTags?.forEach((tag) => {
          if (tag?.operator === "greaterThan" && tag?.value !== "") {
            resultArray.push(`${format} >= ${tag?.value}`);
          } else if (tag?.operator === "lessThan" && tag?.value !== "") {
            resultArray.push(`${format} <= ${tag?.value}`);
          } else if (
            tag?.minCount !== undefined &&
            tag?.maxCount !== undefined
          ) {
            resultArray.push(`${format} >= ${tag?.minCount}`);
            resultArray.push(`${format} <= ${tag?.maxCount}`);
          } else if (tag?.value === "below_20") {
            resultArray.push(`${format} <= ${tag?.count}`);
          } else if (tag?.value === "above_1000") {
            resultArray.push(`${format} >= ${tag?.count}`);
          }
        });
      }
    });

    Object.entries(yearFounded).forEach(([key, value]) => {
      // Check if the value exists and add it to the resultArray with appropriate text
      if (value) {
        resultArray.push(
          key === "greaterThanValue"
            ? `Year Founded >= ${value}`
            : `Year Founded <= ${value}`
        );
      }
    });

    let appliedSliderFilters = resultArray?.length
      ? resultArray?.map((i) => {
          return {
            display_name: i,
            value: i,
          };
        })
      : [];

    let appliedFilters = []
      .concat(
        ...Object.entries(checkboxObject).map(([key, val]) => {
          if (val?.length) {
            return val.map((value) => {
              let displayName = value;

              if (key === "tp_owners") {
                const owner = tpOwners.find(
                  (owner) => owner.email_address === value
                );
                displayName = owner ? owner.full_name : value;
              }

              return {
                display_name: displayName,
                value: value,
              };
            });
          }
        })
      )
      .filter(Boolean);

    let appliedTags = []
      .concat(
        ...Object.entries(preTags).map(([key, val]) => {
          if (val?.length) {
            return val.map((item) => {
              return {
                display_name: `Last Funding On: ${item?.displayName}`,
                value: item?.server_value,
              };
            });
          }
        })
      )
      .filter(Boolean);

    let growthFilter = selectedGrowthsData?.flatMap((i) => {
      const filters = [];
      if (i.greaterThanValue) {
        filters.push({
          display_name: `${i.displayName} >= ${i.greaterThanValue}`,
          value:  `${i.displayName} >= ${i.greaterThanValue}`,
        });
      }
      if (i.lessThanValue) {
        filters.push({
          display_name: `${i.displayName} <= ${i.lessThanValue}`,
          value:  `${i.displayName} <= ${i.lessThanValue}`,
        });
      }
      return filters;
    });

    // let totalFunding = [];
    // if (selectedTotalFundingData?.trueFalse) {
    //   totalFunding.push({
    //     display_name: `Funding: ${selectedTotalFundingData.trueFalse === "true" ? "True" : "False"}`,
    //     value: `total_funding_usd_no_data=${selectedTotalFundingData.trueFalse}`,
    //   });
    // }

    let hqLocations = []
      .concat(
        ...Object.entries(hqLocation).map(([key, val]) => {
          if (val?.selected?.length) {
            return val?.selected.map((item) => {
              return {
                display_name: `HQ: ${item}`,
                value: `${key}`,
              };
            });
          }
        })
      )
      .filter(Boolean);

    let countriesD = countriesData?.map((i) => {
      return {
        display_name: `${i}`,
        value: `hasPresence`,
      };
    });

    let tpStatusSinceFilter = tpStatusSince !== null && tpStatusSince !== ''
    ? {
        display_name: `TP Status since ${tpStatusSince} days`,
        value: 'tp_status_since',
      }
    : null;  

    let founderLocation = [];
    if (selectedFounderLocation?.type && selectedFounderLocation?.data) {
      founderLocation = selectedFounderLocation.data.map((location) => ({
        display_name: `Founder ${selectedFounderLocation.type.slice(0, -1).charAt(0).toUpperCase() + selectedFounderLocation.type.slice(1).toLowerCase()}: ${location}`,
        value: `founder_${selectedFounderLocation.type}`,
    }))
    };

    let appliedDescriptions = descriptionState.descriptions.map((description, index) => ({
      display_name: `Company Description: ${description}`,
      value: `company_description_${index}`, 
    }));

    let communicationFilterFilters = [];
    if (communicationFilter.option === "reached_out") {
      communicationFilterFilters.push({
        display_name: "Have we sent an email?",
        value: "reached_out",
      });
    } else if (communicationFilter.option === "not_reached_out") {
      communicationFilterFilters.push({
        display_name: "Have we not sent an email?",
        value: "not_reached_out",
      });
    }
    if (communicationFilter.days_of_email_sent) {
      communicationFilterFilters.push({
        display_name: `Email Sent: ${communicationFilter.notReachedOutValue} ${communicationFilter.timeUnit} ago`,
        value: "days_of_email_sent",
      });
    }

    appliedFilters = [].concat(
      ...appliedFilters,
      appliedSliderFilters,
      appliedTags,
      growthFilter,
      hqLocations,
      countriesD,
      tpStatusSinceFilter ? [tpStatusSinceFilter] : [],
      founderLocation,
      appliedDescriptions,
      communicationFilterFilters
    );

    const visibleFilters = showAllFilters
      ? appliedFilters
      : appliedFilters.slice(0, maxDisplayedFilters);

    return (
      <div className="internal-container d-flex mb-2 align-items-center">
        {visibleFilters?.map((filter, index) => (
          <div key={index} className="custom-badge">
            <div className="badge">{filter?.display_name}</div>
            <span
              className="filter-remove"
              onClick={() =>
                handleRemoveFilter(filter.value, filter.display_name)
              }
            >
              X
            </span>
          </div>
        ))}
        {appliedFilters.length > maxDisplayedFilters && !showAllFilters && (
          <span
            className="custom-badge"
            onClick={() => setShowAllFilters(true)}
          >
            <div className="badge show-more">
              +<b>{appliedFilters.length - maxDisplayedFilters}</b>
            </div>
          </span>
        )}
        {showAllFilters && appliedFilters.length > 5 && (
          <span
            className="custom-badge"
            onClick={() => setShowAllFilters(false)}
          >
            <div className="font-weight-bold show-less badge">Show Less</div>
          </span>
        )}
        {appliedFilters.length > 1 && (
          <span className="custom-badge" onClick={handleResetAll}>
            <div className="font-weight-bold badge clear-top-bar">
              Clear All
            </div>
          </span>
        )}
      </div>
    );
  };

  const handleResetAll = () => {
    setFiltersApplied(false);
    setIsLoading(false);
    // Clear checkboxes
    if (Object.entries(checkboxObject).some(([key, val]) => val.length)) {
      setCheckboxObject((prevCheckboxObject) => {
        const updatedCheckboxObject = { ...prevCheckboxObject };

        for (const key in updatedCheckboxObject) {
          if (updatedCheckboxObject.hasOwnProperty(key)) {
            updatedCheckboxObject[key] = [];
          }
        }
        return updatedCheckboxObject;
      });
      setTpStatusSince('');
    }
    if (
      Object.values(sliderObject).some(
        ({ selectedTags }) => selectedTags?.length > 0
      )
    ) {
      setSliderObject((prevSliderObject) => {
        const updatedSliderObject = { ...prevSliderObject };

        for (const key in updatedSliderObject) {
          if (updatedSliderObject.hasOwnProperty(key)) {
            updatedSliderObject[key] = {
              greaterThanValue: "",
              lessThanValue: "",
              selectedTags: [],
              symbol: updatedSliderObject[key].symbol,
              maxValue: updatedSliderObject[key].maxValue,
            };
          }
        }
        return updatedSliderObject;
      });
    }

    if (Object.entries(preTags).some(([key, val]) => val.length)) {
      setPreTags((prevCheckboxObject) => {
        const updatedCheckboxObject = { ...prevCheckboxObject };

        for (const key in updatedCheckboxObject) {
          if (updatedCheckboxObject.hasOwnProperty(key)) {
            updatedCheckboxObject[key] = [];
          }
        }
        return updatedCheckboxObject;
      });
    }

    if (selectedGrowthsData?.length) {
      setSelectedGrowthsData([]);
    }
    if (Object.entries(selectedTotalFundingData).some(([key, val]) => val?.length)) {
      setSelectedTotalFundingData({
        trueFalse: 'false',
      });
    }
    if (countriesData?.length) {
      setFilterCountry("all");
    }
    if (yearFounded?.greaterThanValue > 0 || yearFounded?.lesserThanValue > 0) {
      setYearFounded({
        greaterThanValue: "",
        lesserThanValue: "",
      });    
    }
    if (
      Object.entries(hqLocation).some(([key, val]) => val["selected"]?.length)
    ) {
      setFilteredLocationObject({
        keys: "all",
        value: "all",
      });
    }
    if(descriptionState.descriptions?.length > 0) {
      setDescriptionState({
        descriptions: [],
        currentDescription: ''
      })
    }
    if(selectedFounderLocation?.data?.length > 0) {
      setSelectedFounderLocation({
        data: [],
        type: ""
      })
      setFounderLocationChange("all")
    } if(communicationFilter?.option === "reached_out" || communicationFilter?.option === "not_reached_out" || communicationFilter?.days_of_email_sent > 0 ) {
      setCommunicationFilter({
        option: "",
        reachedOut: false, 
        notReachedOutValue: "", 
        timeUnit: "days",
        days_of_email_sent: 0,
      })
    }
    setOpenItems([]);
  };

  const handleTpStatusOperatorChange = (operator) => {
    setTpStatusOperator(operator);
  };

  const renderTopButtons = (item) => {
    switch (item) {
      case "tp_status":
        return (
          <div className="flex-wrap mb-3">
            <span
              className={
                tpStatusOperator === "is"
                  ? "selected tp-button btn btn-sm"
                  : "tp-button btn btn-sm"
              }
              onClick={() => handleTpStatusOperatorChange("is")}
            >
              Currently is
            </span>
            <span
              className={
                tpStatusOperator === "was"
                  ? "selected tp-button btn btn-sm"
                  : "tp-button btn btn-sm"
              }
              onClick={() => handleTpStatusOperatorChange("was")}
            >
              Status was
            </span>
          </div>
        );
      default:
        return null;
    }
  };

  const render_founderLocationTags = () => {
      return founderLocationTags?.map((item, id) => {
        return <span
        key={id}
        className={
          item.serverValue === founderLocationTag?.serverValue 
            ? "selected founderLocation-button btn btn-sm"
            : "founderLocation-button btn btn-sm"
        }
        onClick={() => handleFounderLocationChange(item)}
      >
        {item?.displayName}
      </span>
      })
  }

  const handleFounderLocationChange = (val) => {
    setFounderLocationTag(val)
  }

  const handleFounderChange = (founderObj) => {
    setSelectedFounderLocation({
      ...selectedFounderLocation,
      type: founderLocationTag?.serverValue,
      data: founderObj
    })
    setLLmFounderLocation({});
    setFounderLocationChange("");
  }

  const handleTpStatusSinceChange = (value) => {
    if (/^\d*$/.test(value)) {
      setTpStatusSince(value);
    }
  };  

  const handleSliderTagChange = (selectedTag, fieldName) => {
    setSliderObject((prevSliderObject) => {
      const updatedSliderObject = { ...prevSliderObject };
      // Initialize selectedTags if not already present
      if (!updatedSliderObject[fieldName]) {
        updatedSliderObject[fieldName] = {
          selectedTags: [],
          greaterThanValue: "",
          lessThanValue: "",
        };
      }
      if (!updatedSliderObject[fieldName].selectedTags) {
        updatedSliderObject[fieldName].selectedTags = [];
      }

      let selectedTags = updatedSliderObject[fieldName].selectedTags;
      if (selectedTag.value) {
        const isTagSelected = selectedTags?.some(
          (tag) => tag.value === selectedTag?.value
        );
        if (isTagSelected) {
          selectedTags = selectedTags?.filter(
            (tag) => tag.value !== selectedTag?.value
          );
        } else {
          selectedTags = [selectedTag];
        }

        // Clear numeric inputs if a tag is selected
        updatedSliderObject[fieldName].greaterThanValue = "";
        updatedSliderObject[fieldName].lessThanValue = "";
      } else {
        const hasGreaterThan = selectedTag.greaterThan !== undefined;
        const hasLessThan = selectedTag.lessThan !== undefined;
        // Filter out existing greaterThan and lessThan values
        if (hasGreaterThan) {
          selectedTags = selectedTags?.filter(
            (tag) => tag.operator !== "greaterThan"
          );
        }
        if (hasLessThan) {
          selectedTags = selectedTags?.filter(
            (tag) => tag.operator !== "lessThan"
          );
        }
        if (hasGreaterThan) {
          selectedTags.push({
            value: selectedTag.greaterThan,
            operator: "greaterThan",
          });
        }
        if (hasLessThan) {
          selectedTags.push({
            value: selectedTag.lessThan,
            operator: "lessThan",
          });
        }
        if (hasGreaterThan || hasLessThan) {
          selectedTags = selectedTags?.filter((tag) => !tag.displayName);
        }
      }
      updatedSliderObject[fieldName].selectedTags = selectedTags;

      return updatedSliderObject;
    });
  };

  // const handleTagChange = (tagItem, item) => {
  //   setPreTags((prevTags) => {
  //     if (prevTags[item]?.[0]?.server_value === tagItem.server_value) {
  //       return { ...prevTags, [item]: [] };
  //     } else {
  //       return { ...prevTags, [item]: [tagItem] };
  //     }
  //   });
  // };

  const handleTotalFundingClick = (value) => {
    setSelectedTotalFundingData((prevData) => ({
      ...prevData,
      trueFalse: prevData.trueFalse === value ? '' : value
    }));
  };


  const handleShowAll = (item, array) => {
    setCheckboxObjectVisiblity((prevCheckboxObject) => {
      // Copy the previous state to avoid mutating it directly
      const updatedObject = { ...prevCheckboxObject };
      // Check if serverValue exists in the checkboxObject
      if (updatedObject.hasOwnProperty(item)) {
        updatedObject[item]["count"] = array?.length;
      }
      return updatedObject;
    });
  };

  const handlehide = (item) => {
    setCheckboxObjectVisiblity((prevCheckboxObject) => {
      // Copy the previous state to avoid mutating it directly
      const updatedObject = { ...prevCheckboxObject };
      // Check if serverValue exists in the checkboxObject
      if (updatedObject.hasOwnProperty(item)) {
        updatedObject[item]["count"] = 20;
      }
      return updatedObject;
    });
  };

  const getArrayVal = (item) => {
    switch (item.server_value) {
      case "tp_owners":
        return tpOwners ? [...tpOwners] : [];
      case "tp_status":
        return tpStatusOptions ? [...tpStatusOptions] : [];
      case "countries":
        return countries ? [...countries] : [];
      case "states":
        return states ? [...states] : [];
      case "cities":
        return cities ? [...cities] : [];
      case "status":
        return status ? [...status] : [];
      default:
        return [];
    }
  };

  useEffect(() => {
    let indexes = [];
    Object.entries(accordionObject).forEach(([key, val]) => {
      const isEmployeeInsights = key === "employee_insights";
      if (
        (val?.name === currentSelection ||
          checkboxObject[val.name]?.length ||
          sliderObject[val.name]?.selectedTags?.length > 0 ||
          (selectedGrowthsData?.some((item) =>  item.greaterThanValue !== null || item.lessThanValue !== null) && val?.name === "growth_tags") ||
          (countriesData?.length && val?.name === "headquarter_search") ||
          (val?.name === "headquarter_search" &&
            (hqLocation?.countries?.selected.length > 0 ||
              hqLocation?.states?.selected.length > 0 ||
              hqLocation?.cities?.selected.length > 0)) ||
          (isEmployeeInsights &&
            (employeeInsightsTags.some(
              (tag) => sliderObject[tag]?.selectedTags?.length > 0
            ) ||
              employeeInsightsTags.some(
                (tag) => checkboxObject[tag]?.length > 0
              ))) ||
          (val?.name === "communication_with_telescope" &&
            (communicationFilter?.option !== '' || communicationFilter?.days_of_email_sent || communicationFilter?.notReachedOutValue) &&
            val.isAccordionOpen) ||
          (val?.name === "founder_location_search" &&
            selectedFounderLocation.data?.length > 0
          ) ||
          (val?.name === "company_description_search" && 
            (descriptionState.descriptions?.length > 0)) ||
          (val?.name === "year_founded" &&
            (yearFounded.greaterThanValue || yearFounded.lesserThanValue))) &&
            val.isAccordionOpen
      ) {
        indexes.push(`${val?.index}`);
      } else if (val?.name === currentSelection && !val?.isAccordionOpen) {
        indexes = indexes.filter((item, ind) => ind !== val?.index);
      }
    });
    setOpenItems(indexes);
  }, [accordionObject, llmFilterData]);

  const handleAccordionChange = (newOpenItems) => {
    setAccordionObject((prevAccordion) => {
      // Copy the previous state to avoid mutating it directly
      const updatedAccordion = { ...prevAccordion };
      // Find the key based on the provided index
      const keyToUpdate = Object.keys(updatedAccordion).find(
        (key) => updatedAccordion[key].index === Number(newOpenItems)
      );
      // Update hasValue to true for the specified key
      if (keyToUpdate) {
        updatedAccordion[keyToUpdate].isAccordionOpen =
          currentSelection === updatedAccordion[keyToUpdate]?.name
            ? !updatedAccordion[keyToUpdate].isAccordionOpen
            : checkboxObject[keyToUpdate]?.length ||
              sliderObject[keyToUpdate]?.selectedTags?.length > 0 ||
              (keyToUpdate === "employee_insights" &&
                employeeInsightsTags.some(
                  (tag) => sliderObject[tag]?.selectedTags?.length > 0
                )) ||
              (selectedGrowthsData?.some((item) =>  item.greaterThanValue !== null || item.lessThanValue !== null) &&
                keyToUpdate === "growth_tags") ||
              (countriesData?.length && keyToUpdate === "headquarter_search") ||
              (keyToUpdate === "headquarter_search" &&
                (hqLocation?.countries?.selected.length > 0 ||
                  hqLocation?.states?.selected.length > 0 ||
                  hqLocation?.cities?.selected.length > 0)) ||
              (keyToUpdate === "founder_location_search" &&
                selectedFounderLocation.data?.length > 0
              ) ||
              (keyToUpdate === "communication_with_telescope" &&
                (communicationFilter?.option !== '' || communicationFilter?.reachedOut.no || communicationFilter?.days_of_email_sent || communicationFilter?.notReachedOutValue)) ||
              (keyToUpdate === "company_description_search" && 
                (descriptionState.descriptions?.length > 0)) ||
              (keyToUpdate === "year_founded" &&
                (yearFounded.greaterThanValue || yearFounded.lesserThanValue))
            ? !updatedAccordion[keyToUpdate].isAccordionOpen
            : true;
      }
      return updatedAccordion;
    });
    setCurrentSelection(column_order[newOpenItems]);
  };

  const handleGrowthTagChange = (tag, newValues) => {
    setSelectedGrowthsData((prevSelected) => {
      const tagIndex = prevSelected.findIndex((item) => item.value === tag.value);
      if (tagIndex !== -1) {
        // If the tag is already selected, update the values
        const updatedTag = {
          ...prevSelected[tagIndex],
          greaterThanValue: newValues.greaterThan,
          lessThanValue: newValues.lessThan,
        };
        return [
          ...prevSelected.slice(0, tagIndex),
          updatedTag,
          ...prevSelected.slice(tagIndex + 1),
        ];
      } else {
        // Add the tag if it's not already selected
        return [
          ...prevSelected,
          { value: tag.value, displayName: tag.displayName, greaterThanValue: newValues.greaterThan, lessThanValue: newValues.lessThan },
        ];
      }
    });
  };

  const renderGrowthTags = () => {
    return (
      <>
        {growthTags.map((tag, index) => (
          <div key={index} className="growth-tag-container">
           <Row>
              <span className="bold-font">{tag.displayName}</span>
              <InputFields
                onChange={(newValues) => handleGrowthTagChange(tag, newValues)}
                selectedTag={[
                  { operator: "greaterThan", value: selectedGrowthsData.find(item => item.value === tag.value)?.greaterThanValue || "" },
                  { operator: "lessThan", value: selectedGrowthsData.find(item => item.value === tag.value)?.lessThanValue || "" },
                ]}
                fieldName={tag.value}
              />
           </Row>
          </div>
        ))}
      </>
    );
  };
  
  const handleLocation = (form) => {
    setHqLocation(form);
    setFilteredLocationObject({
      keys: "",
      value: "",
    });
    setPopulateLocation([])
  };
  const handleCountriesChange = (val) => {
    setCountriesData(val?.selected);
    setFilterCountry("");
  };

  const render_hq_countries = () => {
    return (
      <LocationFilter
        onChange={handleLocation}
        filteredLocation={filteredLocationObject}
        onCountriesChange={handleCountriesChange}
        filteredCountry={filterCountry}
        populateLocationData={populateLocation}
      />
    );
  };

  const renderTotalFundingTags = () => {
    const investmentData = sliderObject['investment_data'] || {};
    const hasValues = investmentData.selectedTags?.some(tag => tag.value !== '');
  
    return (
      <div className="d-flex flex-wrap mt-1">
       <Row>
       <span className="bold-font">Total Funding</span>
        <InputFields
          onChange={(e) => handleSliderTagChange(e, 'investment_data')}
          selectedTag={ sliderObject['investment_data']?.selectedTags || [] }
          fieldName='investment_data'
        />
       </Row>
        <div className="total-funding-options">
        <div className="d-flex mb-2">
          <span className="bold-font">Include Funding Data Not Available
            <span className="px-1">
              <PlainTootip
                content={totalFundingInfoIcon}
                name= "Investment"
                height={"12px"}
                width={"12px"}
              />
            </span>
          </span>
        </div>
          <div className="mt-2">
            <Form.Check
              type="checkbox"
              label="Yes"
              id="True"
              name="true"
              checked={selectedTotalFundingData.trueFalse === 'true'}
              onChange={() => handleTotalFundingClick('true')}
              disabled={!hasValues}
            />
            <Form.Check
              type="checkbox"
              label="No"
              id="False"
              name="false"
              // checked={selectedTotalFundingData.trueFalse === 'false'}
              checked={selectedTotalFundingData.trueFalse === 'false'}
              onChange={() => handleTotalFundingClick('false')}
              disabled={!hasValues}
            />
          </div>
        </div>
      </div>
    );
  };

  const handleCommunicationOptionChange = (option) => {
    // Deselect the current option if it's already selected
    const newOption = communicationFilter.option === option ? "" : option;
  
    setCommunicationFilter({
      ...communicationFilter,
      option: newOption,
      reachedOut: newOption === "reached_out", // true if reached_out, false otherwise
      notReachedOutValue: newOption === "" ? "" : communicationFilter.notReachedOutValue, // Clear days if deselecting option
      days_of_email_sent: newOption === "" ? 0 : communicationFilter.days_of_email_sent, // Clear days if deselecting option
    });
  };
  
  const handleNotReachedOutChange = (e) => {
    let value = parseInt(e.target.value, 10);
    const maxMonthsValue = 12; 
    
    if (communicationFilter.timeUnit === "months") {
      value = Math.min(value, maxMonthsValue);
    }
    // Calculate days based on timeUnit
    const days = communicationFilter.timeUnit === "months"
      ? value * 30
      : communicationFilter.timeUnit === "years"
      ? value * 365
      : value;
  
    setCommunicationFilter((prevState) => ({
      ...prevState,
      notReachedOutValue: value,
      days_of_email_sent: days !== 0 ? days : 0,
    }));
  };
  
  const handleTimeUnitChange = (e) => {
    const newTimeUnit = e.target.value;
    let currentValue = communicationFilter.notReachedOutValue;

    if (newTimeUnit === "months") {
      currentValue = Math.min(currentValue, 12);
    }
  
    const newDays = newTimeUnit === "months"
      ? currentValue * 30
      : newTimeUnit === "years"
      ? currentValue * 365
      : currentValue;
  
    setCommunicationFilter((prevState) => ({
      ...prevState,
      timeUnit: newTimeUnit,
      notReachedOutValue: currentValue,
      days_of_email_sent: newDays !== 0 ? newDays : 0,
    }));
  };
  
  const renderCommunicationFilter = () => {
    const { option, notReachedOutValue, timeUnit } = communicationFilter;
  
    return (
      <div className="communication-container">
        <div className="flex-wrap mb-2">
          <span
            className={
              option === "reached_out"
                ? "selected tp-button btn"
                : "tp-button btn"
            }
            onClick={() => handleCommunicationOptionChange("reached_out")}
          >
            <div className="d-flex flex-align-center">
              Have we sent an email?
              <div className="px-1">
                <PlainTootip
                  content="Search for companies to whom we have emailed in the past X days"
                  name="Have we sent an email"
                  height={"12px"}
                  width={"12px"}
                  isActive={communicationFilter.option === "reached_out"}
                />
              </div>
            </div>
          </span>
        <span
          className={
            option === "not_reached_out"
              ? "selected tp-button btn"
              : "tp-button btn"
          }
          onClick={() => handleCommunicationOptionChange("not_reached_out")}
        >
          <div className="d-flex flex-align-center">
            Have we not sent an email?
            <div className="px-1">
              <PlainTootip
                content="Search for companies to whom we have not emailed in the past X days"
                name="Have we not sent an email"
                height={"12px"}
                width={"12px"}
                isActive={communicationFilter.option === "not_reached_out"}
              />
            </div>
          </div>
        </span>
      </div>
        {option === "reached_out" || option === "not_reached_out" ? (
          <div className="not-reached-out-options">
            <Row className="mb-2">
              <span className="mb-2 bold-font">Action done X days ago</span>
              <Col xs={7}>
                <Form.Control
                  type="number"
                  value={notReachedOutValue}
                  onChange={handleNotReachedOutChange}
                  min="0"
                  max={timeUnit === "months" ? "12" : ""}
                  placeholder="Enter number"
                />
              </Col>
              <Col xs={5} className="communication-dropdown">
                <Form.Select
                  as="select"
                  value={timeUnit}
                  onChange={handleTimeUnitChange}
                >
                  <option value="days">Days</option>
                  <option value="months">Months</option>
                  <option value="years">Years</option>
                </Form.Select>
              </Col>
            </Row>
          </div>
        ) : null}
      </div>
    );
  };

  // Function to update year founded values
  const handleYearFoundedChange = (fieldName, value) => {
    if (/^\d*$/.test(value)) {

      // Get the current year dynamically
      const currentYear = new Date().getFullYear();
    
      // Adjust the value if it exceeds the current year
      if ((fieldName === 'lesserThanValue' || fieldName === 'greaterThanValue') && value > currentYear) {
        value = currentYear;
      }
    
      // Update the state based on the field being updated
      setYearFounded(prevState => ({
        ...prevState,
        [fieldName]: value,
      }));
    }
  };

  const renderYearFounded = () => {
    return (
      <div className="year-founded-container">
        <Row className="p-0 mt-2">
          <span className="mb-2 bold-font">Between</span>
          <Col xs={12} className="d-flex justify-content-center align-items-center mb-2">
            <Form.Group className="mb-0 mr-2">
              <Form.Control
                type="text"
                placeholder="Greater than"
                value={yearFounded.greaterThanValue}
                onChange={(e) => handleYearFoundedChange('greaterThanValue', e.target.value)}
              />
            </Form.Group>
            <span className="mx-2">-</span>
            <Form.Group className="mb-0">
              <Form.Control
                type="text"
                placeholder="Less than"
                value={yearFounded.lesserThanValue}
                onChange={(e) => handleYearFoundedChange('lesserThanValue', e.target.value)}
              />
            </Form.Group>
          </Col>
        </Row>
      </div>
    );
  };
  
  const handleCompanyDescription = (value) => {
    setDescriptionState((prevState) => ({
      ...prevState,
      currentDescription: value,
    }));
  };

  const handleAddDescription = (e) => {
    if (e.key === 'Enter' && descriptionState.currentDescription.trim()) {
      setDescriptionState((prevState) => ({
        currentDescription: '', 
        descriptions: [
          ...prevState.descriptions,
          prevState.currentDescription.trim(),
        ],
      }));
    }
  };

  const handleRemoveDescription = (index) => {
    setDescriptionState((prevState) => ({
      ...prevState,
      descriptions: prevState.descriptions.filter((_, i) => i !== index),
    }));
  };

  const renderCompanyDescription = () => {
    return (
      <div className="">
        <Row className="p-0 mt-2">
          {/* <div className="d-flex">
            <span className="mb-2 bold-font">Company Description
              <span className="px-1"></span>
          </div> */}
          <Form.Group className="mb-0">
            <Form.Control
              type="text"
              placeholder="Enter company description"
              value={descriptionState.currentDescription}
              onChange={(e) => handleCompanyDescription(e.target.value)}
              onKeyDown={handleAddDescription}
            />
          </Form.Group>
        </Row>
        <ul className="tag-box d-flex mb-0">
          {descriptionState.descriptions.map((desc, index) => (
            <li className="tags d-inline-flex" key={`desc-${index}`}>
              {desc}
              <span
                className="removeTag"
                onClick={() => handleRemoveDescription(index)}
              >
                x
              </span>
            </li>
          ))}
        </ul>
      </div>
    );
  };

  const handlellmchange = (value) => {
    setLLMFilters((prevState) => ({
      ...prevState,
      searchTerm: value,
      enteredValue:""
    }));
  };

  const handlellmKeyDownFn = (e) => {
    if (e.key === 'Enter') {
      setLLMFilters((prevState) => ({
        ...prevState,
        enteredValue: e.target.value,
      }));
      llmInputFilterClicked = true;
    }
  };

  const handleClearllm = () => {
    setLLMFilters({
      enteredValue: "",
      searchTerm:""
    })
  }

  return (
    <>
      <Row className="no-gutters">
        <Row xs={12} className="mb-2">
          <Col xs={12} sm={3} className="main-heading">
            Discovery
          </Col>
          <Col xs={12} sm={9} className="selected-filters-container">
            <div className="llm-search">
              <Form.Group controlId="searchTerm" className="search-group">
                <div className="input-container">
                  <SearchIcon className="search-icon" />
                  <Form.Control
                    type="text"
                    placeholder="Please describe your search criteria or choose your filters"
                    value={llmFilter?.searchTerm}
                    onChange={(e) => handlellmchange(e.target.value)}
                    onKeyDown={handlellmKeyDownFn}
                    className="top-search-box"
                  />
                  <CrossIcon className="cross-icon" onClick={handleClearllm} />
                </div>
              </Form.Group>
            </div>
            {checkLength() ? (
              <div className="flex-wrap internal-container">
                {renderAppliedFilters()}
              </div>
            ) : null}
          </Col>
        </Row>
        <Col xs={12} sm={3} className="" style={{ maxWidth: "350px" }}>
          <Form>
            <Row className="discovery-container">
              <Col xs={12} md={12} className="set-filter">
                <div className="d-flex justify-content-between main-filter-title">
                  Set Your Filter
                </div>

                <div className="filter-container">
                  <Col xs={12} md={12} className="search-box">
                    <Form.Group controlId="searchTerm">
                      <Form.Control
                        type="text"
                        placeholder="Search..."
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        className="top-search-box"
                      />
                    </Form.Group>
                  </Col>
                  <Accordion
                    activeKey={openItems}
                    onSelect={handleAccordionChange}
                    className="accordion-container"
                  >
                    {filterDisplayNames(searchTerm).map((item, index) => {
                      const arrayVal = getArrayVal(item);
                      return (
                        <Accordion.Item key={index} eventKey={index.toString()}>
                          <Accordion.Header className="accordion-header">
                            <span>{item.display_name}{" "}
                             {item.infoMessage && (<span className="growth-tool-tip">
                              <PlainTootip
                                content={item.infoMessage}
                                name={item.display_name}
                                isActive={false}
                              />
                            </span>)}</span>
                            <span className="triangle">
                              {openItems?.includes(index.toString()) ? (
                                <ArrowUpSvg />
                              ) : (
                                <ArrowDownSvg />
                              )}
                            </span>
                          </Accordion.Header>
                          <Accordion.Body>
                            {fieldsWithSearchBox.includes(item.server_value) &&
                              dropdownsearchTerms &&
                              Object.keys(dropdownsearchTerms).length && (
                                <Form.Group
                                  controlId={`accordionSearch${index}`}
                                  className="field-item"
                                >
                                  <Form.Group controlId="searchTerm">
                                    <Form.Control
                                      type="text"
                                      placeholder={`Search ${item.display_name}`}
                                      value={
                                        dropdownsearchTerms[
                                          item.server_value
                                        ] || ""
                                      }
                                      onChange={(e) =>
                                        handleSearchBoxChange(
                                          item.server_value,
                                          e.target.value,
                                          arrayVal
                                        )
                                      }
                                      className="mb-3 custom-box"
                                    />
                                  </Form.Group>

                                  <Form.Group
                                    controlId={`${item.server_value}Dropdown${index}`}
                                  >
                                    {renderTopButtons(item.server_value)}
                                    {arrayVal && arrayVal?.length > 0 && (
                                      <>
                                        <Form.Check
                                          type="checkbox"
                                          label="Select All"
                                          id={`selectAllTpOwners${index}`}
                                          onChange={(e) =>
                                            handleCheckboxChange(
                                              "Select All",
                                              e.target.checked,
                                              item.server_value,
                                              arrayVal
                                            )
                                          }
                                          checked={
                                            checkboxObject[
                                              `${item.server_value}`
                                            ]?.length ===
                                            (arrayVal || []).length
                                          }
                                        />
                                        {arrayVal
                                          ?.filter((val) => {
                                            if (
                                              item.server_value === "tp_owners"
                                            ) {
                                              return val.full_name
                                                .toLowerCase()
                                                .includes(
                                                  (
                                                    dropdownsearchTerms[
                                                      `${item.server_value}`
                                                    ] || ""
                                                  ).toLowerCase()
                                                );
                                            } else {
                                              return val
                                                .toLowerCase()
                                                .includes(
                                                  (
                                                    dropdownsearchTerms[
                                                      `${item.server_value}`
                                                    ] || ""
                                                  ).toLowerCase()
                                                );
                                            }
                                          })
                                          ?.slice(
                                            0,
                                            checkboxObjectVisiblity[
                                              item.server_value
                                            ]?.count
                                          )
                                          ?.map((value, valIndex) => {
                                            let checked =
                                              item.server_value === "tp_owners"
                                                ? value.email_address
                                                : value
                                                ? value
                                                : "";
                                            let name =
                                              item.server_value === "tp_owners"
                                                ? value.full_name
                                                : value
                                                ? value
                                                : "";
                                            return (
                                              <div
                                                key={valIndex}
                                                className="d-flex align-items-center cursor-pointer"
                                              >
                                                <Form.Check
                                                  type="checkbox"
                                                  id={`${item.server_value}${index}_${valIndex}`}
                                                  label={name}
                                                  checked={checkboxObject[
                                                    `${item.server_value}`
                                                  ]?.includes(checked)}
                                                  onChange={(e) =>
                                                    handleCheckboxChange(
                                                      value,
                                                      e.target.checked,
                                                      item.server_value
                                                    )
                                                  }
                                                  className="cursor-pointer"
                                                />
                                              </div>
                                            );
                                          })}
                                      </>
                                    )}
                                    {item.server_value === "tp_status" && (
                                      <div className="flex-wrap mb-3">
                                        {tpStatusOperator === "was" && (
                                          <div className="since-days">
                                            <span className="mb-2 bold-font">Since X days ago</span>
                                            <Form.Group className="mt-2">
                                            <Form.Control
                                              type="text"
                                              placeholder="10"
                                              value={tpStatusSince}
                                              onChange={(e) => handleTpStatusSinceChange(e.target.value)}
                                              disabled={tpStatusOperator !== 'was' || !checkboxObject['tp_status'].length}
                                            />
                                            </Form.Group>
                                          </div>
                                        )}
                                      </div>
                                    )}
                                    {arrayVal.length >
                                      checkboxObjectVisiblity[item.server_value]
                                        ?.count &&
                                      checkboxObjectVisiblity[item.server_value]
                                        ?.isSearching &&
                                      checkboxObjectVisiblity[item.server_value]
                                        ?.searchCount > 20 && (
                                        <span
                                          className="custom-btn-show-more flex-wrap px-3"
                                          size="sm"
                                          onClick={() =>
                                            handleShowAll(
                                              item.server_value,
                                              arrayVal
                                            )
                                          }
                                        >
                                          {`Show All (${
                                            checkboxObjectVisiblity[
                                              item.server_value
                                            ]?.searchCount - 20
                                          })`}
                                        </span>
                                      )}

                                    {arrayVal.length >
                                      checkboxObjectVisiblity[item.server_value]
                                        ?.count &&
                                      !checkboxObjectVisiblity[
                                        item.server_value
                                      ]?.isSearching && (
                                        <span
                                          className="custom-btn-show-more flex-wrap px-3"
                                          size="sm"
                                          onClick={() =>
                                            handleShowAll(
                                              item.server_value,
                                              arrayVal
                                            )
                                          }
                                        >
                                          {`Show All (${arrayVal.length - 20})`}
                                        </span>
                                      )}

                                    {arrayVal.length ==
                                      checkboxObjectVisiblity[item.server_value]
                                        ?.count && (
                                      <span
                                        className="custom-btn-show-more flex-wrap px-3"
                                        size="sm"
                                        onClick={() =>
                                          handlehide(item.server_value)
                                        }
                                      >
                                        {`Hide`}
                                      </span>
                                    )}
                                  </Form.Group>
                                </Form.Group>
                              )}
                            {!fieldsWithSearchBox.includes(item.server_value) &&
                              item.server_value !== "headquarter_search" &&
                              item.server_value !== "growth_tags" &&
                              item.server_value !==
                                "communication_with_telescope" &&
                              item.server_value !== "investment_data" &&
                              item.server_value !== "total_funding_usd" &&
                              item.server_value !== "employee_insights" &&
                              item.server_value !== "founder_location_search" &&
                              item.server_value !== "year_founded" && 
                              item.server_value !== "company_description_search" && (
                                <Form.Group
                                  controlId={`numericValue${index}`}
                                  className="field-item"
                                >
                                  <TagsInSlider
                                    onChange={(e) =>
                                      handleSliderTagChange(
                                        e,
                                        item?.server_value
                                      )
                                    }
                                    selectedTag={
                                      sliderObject[item.server_value]
                                        ?.selectedTags || []
                                    }
                                    type={
                                      item?.server_value === "employee_count"
                                        ? "numeric"
                                        : "percentage"
                                    }
                                  />
                                </Form.Group>
                              )}
                            {!fieldsWithSearchBox.includes(item.server_value) &&
                              item.server_value !== "growth_tags" &&
                              item.server_value !==
                                "communication_with_telescope" &&
                              item.server_value !== "investment_data" &&
                              item.server_value !== "total_funding_usd" &&
                              item.server_value !== "founder_location_search" &&
                              item.server_value !== "headquarter_search" && 
                              item.server_value !== "year_founded" &&
                              item.server_value !== "company_description_search" && (
                                <div>
                                  {employeeInsightsTags.map(
                                    (employeeInsightTag, index) => {
                                      const sliderData =
                                        sliderObject[employeeInsightTag];
                                      const insightDisplayName =
                                        employeeInsightConstants?.find(
                                          (item) =>
                                            item.server_value ===
                                            employeeInsightTag
                                        );
                                      return (
                                        <div key={index}>
                                          <div className="f-s-15 employeeInsight">
                                            <b>
                                              {insightDisplayName
                                                ? insightDisplayName.displayName
                                                : employeeInsightTag}
                                            </b>
                                          </div>
                                          <Form.Group
                                            controlId={`numericValue${index}`}
                                            className="field-item"
                                          >
                                            <TagsInSlider
                                              onChange={(e) =>
                                                handleSliderTagChange(
                                                  e,
                                                  employeeInsightTag
                                                )
                                              }
                                              selectedTag={
                                                sliderData?.selectedTags || []
                                              }
                                              type={
                                                employeeInsightTag ===
                                                "employee_count"
                                                  ? "numeric"
                                                  : "percentage"
                                              }
                                            />
                                          </Form.Group>
                                        </div>
                                      );
                                    }
                                  )}
                                </div>
                              )}
                            {item.server_value == "communication_with_telescope" && (
                              <Form.Group
                              controlId={`tags${index}`}
                              className="field-item"
                              >
                              {renderCommunicationFilter()}
                              </Form.Group>
                            )}
                            {item.server_value === "growth_tags" && (
                              <Form.Group
                                controlId={`tags${index}`}
                                className="field-item"
                              >
                                {renderGrowthTags()}
                              </Form.Group>
                            )}
                            {item.server_value === "investment_data" && (
                              <Form.Group
                                controlId={`tags${index}`}
                                className="field-item"
                              >
                                {renderTotalFundingTags()}
                                {/* <div>
                                  <div className="f-s-15 mb-2">
                                    <b>Last Funding On</b>
                                  </div>
                                  <Form.Group
                                    controlId={`tags${index}`}
                                    className="field-item"
                                  >
                                    <TagsInDays
                                      onChange={(e) =>
                                        handleTagChange(e, "last_funding_on")
                                      }
                                      selectedTag={
                                        preTags["last_funding_on"] || null
                                      }
                                    />
                                  </Form.Group>
                                </div> */}
                              </Form.Group>
                            )}
                            {item.server_value == "founder_location_search" && (
                              <Form.Group
                                controlId={`founder-location${index}`}
                                className="field-item "
                              > 
                                <div className="d-flex flex-wrap ">
                                  {render_founderLocationTags()}
                                </div>
                                <div className="founder-location">
                                  <FounderLocation onChange={(obj) => handleFounderChange(obj)} locationType={founderLocationTag} filterToRemove={founderLocationChange} itemToAdd={llmFounderLocation}/>
                                </div>
                              </Form.Group>
                            )}
                            {item.server_value == "headquarter_search" && (
                              <Form.Group
                                controlId={`tags${index}`}
                                className="field-item"
                              >
                                {render_hq_countries()}
                              </Form.Group>
                            )}
                            {item.server_value == "year_founded" && (
                              <Form.Group
                              controlId={`tags${index}`}
                              className="field-item"
                              >
                              {renderYearFounded()}
                              </Form.Group>
                            )}
                            {item.server_value == "company_description_search" && (
                              <Form.Group
                              controlId={`tags${index}`}
                              className="field-item"
                              >
                              {renderCompanyDescription()}
                              </Form.Group>
                            )}
                          </Accordion.Body>
                        </Accordion.Item>
                      );
                    })}
                  </Accordion>
                </div>
              </Col>
            </Row>
          </Form>
        </Col>
        <Col xs={9} sm className="table-view ">
          {filtersApplied || isLoading? (
            isLoading ? (
              <Col className="loader-table">
                <TableLoader />
              </Col>
            ) : error ? (
              <CommonError errorMessage={error} isBlurredRequired={true} />
            ) : (
              <DiscoveryTable/>
            )
          ) : (
            <Nofilter />
          )}
        </Col>
      </Row>
    </>
  );
};

export default Discovery;
