import Highcharts from 'highcharts';
import Highmaps from 'highcharts/highmaps';
import mapModule from 'highcharts/modules/map';
import accessibilityModule from 'highcharts/modules/accessibility';

// Initialize the Highcharts modules
accessibilityModule(Highcharts);
mapModule(Highcharts);

// Function to generate a bar graph
export function generateBarGraph(container, data) {
    // Ensure that the container exists
    if (!container) {
        console.error(`Container not found`);
        return;
    }

    // Calculate chart height based on the number of departments
    let chartHeight = '240px';
    if (data.length <= 2) {
        chartHeight = '160px';
    } else if (data.length <= 4) {
        chartHeight = '200px';
    }

    const chartOptions = {
        chart: {
            type: 'bar',
            height: chartHeight,
            width: 530, // Set the chart width to 530px
            marginLeft: 150,
            backgroundColor: 'transparent',
        },
        title: {
            text: '' // No title
        },
        yAxis: {
            title: {
                text: null, // No Y-axis title
            },
            labels: {
                enabled: false, // Hide Y-axis labels
            },
            gridLineWidth: 1, // Set the grid line width
            gridLineColor: '#D7D0FFB2', // Set the grid line color
            tickAmount: 9, // Ensure the grid lines are set up to the required amount
            lineWidth: 1, // Show a single line along the y-axis
        },
        xAxis: {
            categories: data.map(d => d.name), // Display department names on the y-axis
            title: {
                text: null, // No Y-axis title
            },
            lineWidth: 1, // Show a single line along the x-axis
            gridLineWidth: 0, // No vertical grid lines
            gridLineColor: 'transparent', // Ensure no grid lines on the x-axis
            labels: {
                style: {
                    fontFamily: 'Avenir-Regular',
                    fontSize: '14px', // Set the font size for department names
                    color: '#454545', // Set the font color for department names
                    fontWeight: 'normal', // Ensure font is not bold
                },
            },
        },
        plotOptions: {
            bar: {
                dataLabels: {
                    enabled: true,
                    format: '{point.y}%', // Display percentage at the end of each bar
                    style: {
                        fontFamily: 'Avenir-Regular',
                        fontSize: '13px', // Set the font size for percentage labels
                        color: '#454545', // Set the font color for percentage labels
                        fontWeight: 500,
                        lineHeight: '21px',
                        letterSpacing: '0.027em',
                    },
                    align: 'left', // Align data labels to the left (outside the bar)
                    verticalAlign: 'middle', // Vertically align data labels in the middle
                    x: 8, // Offset the data label to the right, after the bar end (reduce the value)
                },
                pointWidth: 17, // Set the width of each bar
                groupPadding: 0.2, // Add padding between groups of bars
                borderRadius: [0, 2, 2, 0], // Add rounded corners to the bars
                showInLegend: false, // Remove series button
                states: {
                    hover: {
                        color: 'rgb(25, 183, 251)' // Set the hover color of the bars
                    }
                }
            },
        },
        tooltip: {
            pointFormat: '<b>{point.y}%</b>',
            borderColor: 'rgb(25, 183, 251)' // Change tooltip border color
        },
        series: [{
            data: data,
            colorByPoint: true, // Use the color specified in data
        }],
        credits: {
            enabled: false // Disable Highcharts credits
        }
    };

    Highcharts.chart(container, chartOptions);
}


// Function to generate a line graph
export function generateLineGraph(container, data) {
    // Ensure that the container exists
    if (!container) {
        console.error(`Container not found`);
        return;
    }
    
    // Determine if the new theme is enabled
    const isNewThemeEnabled = false; // Set your condition here

    // Extracting series data for raw_count, raw_inflow, and raw_outflow
    const seriesDataCount = data.map(entry => ({
        x: new Date(entry.metric_recorded_on).getTime(),
        y: entry.raw_count,
    }));

    const seriesDataInflow = data.map(entry => ({
        x: new Date(entry.metric_recorded_on).getTime(),
        y: entry.raw_inflow,
    }));

    const seriesDataOutflow = data.map(entry => ({
        x: new Date(entry.metric_recorded_on).getTime(),
        y: entry.raw_outflow,
    }));

    // Compute min and max for yAxis
    const allValues = seriesDataCount
        .concat(seriesDataInflow)
        .concat(seriesDataOutflow)
        .map(point => point.y);

    const max = Math.max(...allValues);
    const min = Math.min(...allValues);

    const lineChartOptions = {
        chart: {
            type: 'line',
            height: '240px',
            backgroundColor: 'transparent',
            zoomType: 'x', // Added zoomType for consistency with plugin extension
        },
        credits: {
            enabled: false
        },
        title: {
            text: ''
        },
        xAxis: {
            type: 'datetime',
            title: {
                text: '',
                style: {
                    color: isNewThemeEnabled ? "#fff" : '',
                },
            },
            labels: {
                style: {
                    color: isNewThemeEnabled ? "#fff" : '',
                },
            },
            events: {
                afterSetExtremes: function (event) {
                    if (event.trigger === 'rangeSelectorButton') {
                        const xAxis = this; // Reference to xAxis
                        const options = xAxis.options;

                        if (event.min === event.max) { // If zoomed to a single year
                            options.labels = { format: '{value:%Y}' }; // Update labels to display year
                        } else { // If zoomed out or zoomed to multiple years
                            options.labels = { format: '{value:%b %Y}' }; // Update labels to display month and year
                        }

                        xAxis.update(options); // Update xAxis options
                    }
                }
            }
        },
        yAxis: {
            title: {
                text: '',
                style: {
                    color: isNewThemeEnabled ? "#fff" : '',
                },
            },
            labels: {
                style: {
                    color: isNewThemeEnabled ? "#fff" : '',
                },
            },
            min: min, // Set min value of y-axis
            max: max, // Set max value of y-axis
        },
        legend: {
            itemStyle: {
                color: isNewThemeEnabled ? "#fff" : '' // Legend text color
            }
        },
        series: [
            {
                name: 'Total Population',
                data: seriesDataCount,
                color: "#19B7FB",
            },
            {
                name: 'Employee Joined',
                data: seriesDataInflow,
                color: "#108C4A",
            },
            {
                name: 'Employee Left',
                data: seriesDataOutflow,
                color: "#7778D0",
            }
        ],
        rangeSelector: {
            selected: 1, // Show data for 1 year by default
            buttons: [{
                type: 'year',
                count: 1,
                text: '1y'
            }, {
                type: 'all',
                text: 'All'
            }]
        }
    };

    Highcharts.chart(container, lineChartOptions);
}
