import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getReferenceData } from '../../store/actions/mentionsAction';
import './mentionReference.scss';

const MentionReference = () => {
    const dispatch = useDispatch();
    const [searchParams] = useSearchParams();
    const referenceId = searchParams.get('mention_params');

    // Access referenceData from Redux state
    const referenceData = useSelector(({ mentionsReducer }) => mentionsReducer);
    const referenceMention = referenceData?.referenceMention;
    const results = referenceMention?.results;

    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        if (referenceId) {
            dispatch(getReferenceData(referenceId))
                .then(() => setIsLoading(false))
                .catch((err) => {
                    console.error('Error fetching data:', err);
                    setError(err);
                    setIsLoading(false);
                });
        }
    }, [referenceId, dispatch]);

    // Helper function to format date
    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const options = { year: 'numeric', month: 'short', day: 'numeric' };
        return date.toLocaleDateString('en-US', options);
    };

    return (
        <div className="mention-reference-container">
            {/* Reference Header */}
            <div className="reference-header">
                <h4 className="reference-header-title">Reference</h4>
            </div>

            {isLoading ? (
                // Loader block
                <div>
                    <div className={`loader-animation mt-3`} style={{ height: '300px' }}></div>
                    <div className={`loader-animation mt-3`} style={{ height: '200px' }}></div>
                    <div className={`loader-animation mt-3`} style={{ height: '200px' }}></div>
            </div>
            ) : error ? (
                <p>Error fetching data: {error.message}</p>
            ) : (
                <div className="content-container">
                    {/* Projects Block */}
                    <div className="mt-2 mb-2">
                        <span className="card-body-paragraph">
                            <b>Projects: &nbsp;</b>
                            <span className="details-container">
                                {results[0]?.all_projects.map((project, index) => (
                                    <span key={index} className="projectsCss">
                                        {project}
                                    </span>
                                )) || 'No Project Required'}
                            </span>
                        </span>
                    </div>
                    
                    {/* Creator */}
                    <div className="mt-2 mb-2">
                        <span className="card-body-paragraph">
                            <b>Creator: &nbsp;</b>
                            <span className="details-container">
                                {referenceMention?.creator || 'NA'}
                            </span>
                        </span>
                    </div>

                    {/* People Involved */}
                    <div className="mt-2 mb-2">
                        <span className="card-body-paragraph">
                            <b>People Involved: &nbsp;</b>
                            <span className="details-container">
                                {results[0]?.people?.map((person, index) => (
                                    <span key={index} className="info-block">
                                        <span className="redDot"></span>
                                        {person.name}&nbsp;
                                    </span>
                                )) || 'NA'}
                            </span>
                        </span>
                    </div>

                    {/* Date */}
                    <div className="mt-2 mb-2">
                        <span className="card-body-paragraph">
                            <b>Date: &nbsp;</b>
                            <span className="details-container">
                                {formatDate(results[0]?.created_at) || 'NA'}
                            </span>
                        </span>
                    </div>

                    <hr className="horizontal-line" />

                    {/* People Details */}
                    {results[0]?.people?.map((person, index) => (
                        <div key={index} className="person-details">
                            {/* Person's Name and "Add to Reference Contact" link */}
                            <div className="person-header">
                                <span className="person-name">{person.name}</span>
                                {/* <a href="#" className="add-to-reference-contact">Add to Reference Contact</a> */}
                            </div>

                            {/* Details of each person */}
                            <div className="mt-2 mb-2">
                                <span className="card-body-paragraph">
                                    <b>Projects:&nbsp;</b>
                                    <span className="details-container">
                                        {person.projects.join(', ') || 'NA'}
                                    </span>
                                </span>
                            </div>

                            <div className="mt-2 mb-2">
                                <span className="card-body-paragraph">
                                    <b>Rating:&nbsp;</b>
                                    <span className="details-container">
                                        {person.rating || 'NA'}
                                    </span>
                                </span>
                            </div>

                            <div className="mt-2 mb-2">
                                <span className="card-body-paragraph">
                                    <b>Functions:&nbsp;</b>
                                    <span className="details-container">
                                        {person.functions.join(', ') || 'NA'}
                                    </span>
                                </span>
                            </div>

                            <div className="mt-2 mb-2">
                                <span className="card-body-paragraph">
                                    <b>Reference Type(s):&nbsp;</b>
                                    <span className="details-container">
                                        {person.reference_type.join(', ') || 'NA'}
                                    </span>
                                </span>
                            </div>

                            {/* Add a horizontal line between people details (but not after the last person) */}
                            {index < results[0]?.people.length - 1 && (
                                <hr className="horizontal-line" />
                            )}
                        </div>
                    ))}

                    {/* Reference Content */}
                    <hr className="horizontal-line-reference" />
                    <div className="reference-content-container">
                        <div className="card-body-paragraph m-b-10">
                            <b>Reference:</b>
                        </div>
                        <div className="reference-box">
                            <div dangerouslySetInnerHTML={{ __html: results[0]?.content.replace(/<br\/>/g, '<br>') }} />
                        </div>
                    </div>

                </div>
            )}
        </div>
    );
};

export default MentionReference;
