import * as actionTypes from "../actionTypes";

const INITIAL_STATE = {
  scopers: [],
  noteTemplate: {},
  isSliderOpen: false,
  activeWorkboard: "createWorkboard",
  commonTpOwnerOptions: {},
  commonTpStatusOptions: {},
  commonListsBucketsOptions: {},
  companyLists: [],
  companyNoCachedData: {},
  companyCachedData: {},
  founderData: {},
};
const commonReducer = function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case actionTypes.SET_SCOPERS:
      return {
        ...state,
        scopers: action.data.data,
      };
    case actionTypes.SET_NOTE_TEMPLATE:
        return {
          ...state,
          noteTemplate: action.data,
        };
    case actionTypes.SET_SLIDER_TOGGLE:
        return {
          ...state,
          isSliderOpen: action.data,
        };
    case actionTypes.SET_SUB_ACTION_ITEM_SUBMISSIONS:
      return {
        ...state,
        activeSubmissionItem:action.data
      };

    case actionTypes.SET_WORKBOARD_ITEM:
      return {
        ...state,
        activeWorkboard: action.data
      }
    case actionTypes.COMMON_TP_OWNER_OPTIONS:
      return {
        ...state,
        commonTpOwnerOptions: { ...action.data }
      };
    case actionTypes.COMMON_TP_STATUS_OPTIONS:
      return {
        ...state,
        commonTpStatusOptions: { ...action.data }
      };
    case actionTypes.COMMON_LISTS_BUCKETS_OPTIONS:
      return {
        ...state,
        commonListsBucketsOptions: { ...action.data }
      };
    case actionTypes.GET_COMPANY_LISTS:
      return {
        ...state,
        companyLists: action.data,
      };
    case actionTypes.GET_COMPANY_NO_CACHED_DATA:
      return {
        ...state,
        companyNoCachedData: action.data,
      };
    case actionTypes.GET_COMPANY_CACHED_DATA:
      return {
        ...state,
        companyCachedData: action.data,
      };
    case actionTypes.GET_FOUNDER_DATA:
      return {
        ...state,
        founderData: action.data,
      };
    
    default:
      return {
        ...state,
      };
  }
};

export { commonReducer };
