import React, {useState, useEffect } from 'react';
import { AgGridReact } from 'ag-grid-react';
import moment from "moment";
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import './submissionTableView.scss'

function SubmissionTableView({fields,tableData, handleListModal}) {
    const [tableField, setTableField] = useState([])
    const [tableRowData, setTableRowData] = useState([])

    useEffect(()=>{
            populateGridHeader()
            getTableFieldData()
    },[tableData])

    function populateGridHeader(){ 
        let header = fields.filter((item)=> {
            if(item.column_name !== "content" &&  item.column_name !== "year"){
                return item
            }
        })
        
        header = header?.map((item)=> ({ field:item.column_name,headerName:item.title}))
        setTableField(header)
    }

    let data =[]
    function getTableFieldData(){
        
        if(tableData?.length){
            for(let x=0;x<tableData?.length;x++){
                let obj={};
               Object.keys(tableData[x]).map((key, index)=>{
                let val;

                if(fields[index]?.column_name === "quarter"){
                    val = tableData[x][`quarter`] + " " + tableData[x][`year`]
                    obj[`quarter`] = val
                 }
                 else if(fields[index]?.column_name !== "year"){
                    val = fields[index]?.column_name && fields[index]?.column_name === "created_at" ? moment(
                        tableData[x][`${fields[index]?.column_name}`]
                    ).format("MMM DD YYYY") : fields[index]?.column_name && fields[index]?.column_name !== "created_at" ? tableData[x][`${fields[index]?.column_name}`] : "";
                    
                    if(fields[index]?.column_name!== undefined){
                        obj[fields[index]?.column_name] = val
                    }
                }
               })
                data = [...data, {...obj, handleModalListData: tableData[x]}]
            }
            setTableRowData(data)
        } 
    }

    function handleRow(event){
        handleListModal(event.data.handleModalListData, fields)
    }

  

  return (
    <div className={tableData.length > 5 ? 'ag-theme-alpine position-relative submission-table' : 'ag-theme-alpine position-relative '}>
      
        <AgGridReact 
            columnDefs={tableField}  
            rowData={tableRowData}
            onRowClicked={(event) => handleRow(event)}
            onGridReady={params => {
                params.api.sizeColumnsToFit();
            }}
            domLayout={tableData.length < 5 ? 'autoHeight' : ''}
            localeText={{noRowsToShow: 'No data available'}}
        >
        </AgGridReact>
    </div>
  )
}

export default SubmissionTableView