import React from "react";
import { useState } from "react";
import { OverlayTrigger, Tooltip, Popover } from "react-bootstrap";
import { ReactComponent as InfoSvg} from '../../assets/images/icon_svg/info2.svg' 


const PlainTootip = ({ name, content, height, width, isActive}) => {
    const [showTooltip, setShowTooltip] = useState(false);
    const handleMouseEnter = () => setShowTooltip(true);
    const handleMouseLeave = () => setShowTooltip(false);

    return (
        <>
            <span onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                <OverlayTrigger
                    show={showTooltip}
                    placement="auto"
                    overlay={
                        <Popover id={`popover-positioned-${name}`}>
                          {/* <Popover.Header as="h5">{`${name}`}</Popover.Header> */}
                          <Popover.Body>
                            <div className="tooltip-content">{content}</div>
                          </Popover.Body>
                        </Popover>
                      }
                    rootClose={true}
                    trigger={["hover", "focus","click"]}
                >
                    <span className="" style={{pointerEvents: "all"}}>
                        <InfoSvg className={isActive ? "infoIcon active" : "infoIcon"} width={width ||"14px"} height={height || "14"}/>
                    </span>
                </OverlayTrigger>
            </span>
        </>
    );
};

export default PlainTootip;
