import React, { useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as actionTypes from "../../../store/actionTypes";

import "./tpOwnerFilter.scss"

let filters = {}, appliedFilters = {};

const TPOwnerFilterComponent = forwardRef((props, ref) => {

    useImperativeHandle(ref, () => {
        return {
          doesFilterPass(params) {
            return true
          },
          isFilterActive() {
            return false;
          },
          // This example isn't using getModel() and setModel(),
          // so safe to just leave these empty. Don't do this in your code!!!
          getModel() {},
    
          setModel() {},
        };
      });

    const [columnValues, setColumnValues] = useState([]);
    const { filterParams } = props.colDef;
    const { callBackFunc, allDatas, name } = filterParams;
    const [selectedFilterValue, setSelectedFilterValue] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [userSelectedCheckbox, setUserSelectedCheckbox] = useState(false);

    const dispatch = useDispatch()
    const [allAppliedFilters] = useSelector(({workboardReducer}) => [workboardReducer?.allAppliedFilters])

    useEffect(() => {
        if (allDatas) {
            let uniqueOwners = {};
            // Iterate through the data array
            let output = allDatas.reduce((result, item) => {
                // Get the owner from the current item
                let owner = item.tp_owners[0];
                if (owner != null && owner !== '') {
                    if (!uniqueOwners[owner]) {
                        result.push({
                            tp_owner: owner,
                            tp_email: item.tp_owners_emails[0],
                        });
                        uniqueOwners[owner] = true;
                    }
                }

                return result;
            }, []);
            setColumnValues(Array.from(output));
        }
    }, [allDatas]);


    useEffect(() => {
        document.addEventListener('resetFiltersClicked', resetFilterAll);
        return () => {
            document.removeEventListener('resetFiltersClicked', resetFilterAll);
            filters ={};
        };
    }, [selectedFilterValue]);

    
    useEffect(()=>{
        if(userSelectedCheckbox) {
            let finalObject = {...allAppliedFilters}
            filters = {
                type: `tp_owner`,
                selectedFilterValue: selectedFilterValue
            }
            finalObject[`tp_owner`] = filters
            // callBackFunc(finalObject);
            dispatch({ type: actionTypes.SET_WORKBOARDS_FILTERS, data: {...finalObject}});
        }
       },[selectedFilterValue])

    const handleCheckboxChange = (item) => {
        setSelectedFilterValue((prevCheckedItems) => {
            const isChecked = prevCheckedItems.some((checkedItem) => checkedItem.tp_owner === item.tp_owner);
            if (isChecked) {
                // If the item is already in checkedItems, remove it
                return prevCheckedItems.filter((checkedItem) => checkedItem.tp_owner !== item.tp_owner);
            } else {
                // Otherwise, add the item to checkedItems
                return [...prevCheckedItems, item];
            }
        });
        setUserSelectedCheckbox(true)
    };

    const applyFilter = () => {
        let finalObject = {...allAppliedFilters}
        filters = {
            type:  `tp_owner`,
            selectedFilterValue: []
        }
        finalObject[`tp_owner`] = filters
        callBackFunc(finalObject);
        if(finalObject?.hasOwnProperty(`tp_owner`)) {
            delete finalObject?.[`tp_owner`];
        }
        dispatch({ type: actionTypes.SET_WORKBOARDS_FILTERS, data: {...finalObject}});
    };

    const resetFilterAll = () => {
        setSelectedFilterValue([]);
        setSearchTerm('');
        let finalObject = {}
        filters = {
            type:  `tp_owner`,
            selectedFilterValue: []
        }
        finalObject[`tp_owner`] = filters
        callBackFunc(finalObject);
        dispatch({ type: actionTypes.SET_WORKBOARDS_FILTERS, data: {}});
    };

    const resetFilter = () => {
        setSelectedFilterValue([]);
        setSearchTerm('');
        applyFilter();
    };

    const applyFn = () => {
        let finalObject = {...allAppliedFilters}
            filters = {
                type: `tp_owner`,
                selectedFilterValue: selectedFilterValue
            }
            finalObject[`tp_owner`] = filters
            callBackFunc(finalObject);
            dispatch({ type: actionTypes.SET_WORKBOARDS_FILTERS, data: {...finalObject}});
    }

    return (
        <div className='custom-tpOwner-filter'>
            <input
                className='ag-input-field-input ag-text-field-input mb-2 inputStyle'
                type="text"
                placeholder="Search..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
            />
            <div className='checkbox-box'>
                {columnValues?.length && columnValues?.filter((item) => item?.tp_owner?.toLowerCase()?.includes(searchTerm?.toLowerCase()))?.map((value) => (
                    <label className='d-flex' key={value?.tp_email}>
                        <input
                            className='checkbox-input'
                            type="checkbox"
                            value={value?.tp_owner}
                            checked={selectedFilterValue.some((checkedItem) => checkedItem.tp_owner === value.tp_owner)}
                            onChange={() => handleCheckboxChange(value)}
                        />{' '}
                        <span>{value?.tp_owner}</span>
                    </label>
                ))}
            </div>
            <div className='d-flex justify-content-end'>
                <button onClick={applyFn} className='ag-standard-button ag-filter-apply-panel-button'>Apply</button>
                <button onClick={resetFilter} className='ag-standard-button ag-filter-apply-panel-button'>Reset</button>
            </div>
        </div>
    );
});

export default TPOwnerFilterComponent;