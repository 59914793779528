import React from 'react'
import { Row, Col, Form, Card, Button, Modal, ModalFooter } from "react-bootstrap";

export function LoadingBlock({height, width, className}) {
  return (
    <div className={`loader-animation ${className}`} style={{height, width}}></div>
  )
}

export function LoadingSpan({height, width, className}) {
  return (
    <span className={`loader-animation ${className}`} style={{height, width}}></span>
  )
}

export function LoaderSubmission({height, width, className}){
   return <Card>
     <div className={`loader-animation col-md-12`} style={{height:'40px'}}></div>
     <div className={`loader-animation mt-3`} style={{height:'20px', width:'100px'}}></div>
     <div className={`loader-animation mt-3`} style={{height:'300px'}}></div>
     <div className={`loader-animation mt-3`} style={{height:'200px'}}></div>
     <div className={`loader-animation mt-3`} style={{height:'200px'}}></div>
   </Card>
}

