
export const tagsInDays = [
    {
        displayName: '1 Month ago',
        operator: "<=",
        inDays: 30,
        server_value: "1-month-ago"
    },
    {
        displayName: '1-3 Months ago',
        operator: "<=",
        inDays: 90,
        server_value: "1-3-months-ago"
    },
    {
        displayName: '3-6 Months ago',
        operator: "<=",
        inDays: 180,
        server_value: "3-6-months-ago"
    },
    {
        displayName: '6 Months - 1 Year ago',
        operator: "<=",
        inDays: 365,
        server_value: "6-months-1-year-ago"
    },
    {
        displayName: '1 Year - 1.5 Years ago',
        operator: "<=",
        inDays: 730,
        server_value: "1-year-1-5-years-ago"
    },
    {
        displayName: '1.5 Years and above',
        operator: ">=",
        inDays: 730,
        server_value: "1-5-years-and-above"
    },
]