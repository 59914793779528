import React, { useState, useEffect, useRef } from 'react'
import { useSelector } from 'react-redux';
import {Button} from "react-bootstrap"

import "./customFilter.scss"

const CustomFilter = ({ apply, columnDefs, moveColumn }) => {

  const [clonedCoulmns, setClonedCoulmns] = useState([])
  const [result, setResult] = useState({
    searchTerm: "",
    searchResult: []
  })

  const [appliedColumns] = useSelector(({ discoveryReducer }) => [discoveryReducer.appliedColumns]);

  const dragItem = useRef();
  const dragOverItem = useRef();

  const dragStart = (e, position) => {
    dragItem.current = position;
  };
 
  const dragEnter = (e, position) => {
    dragOverItem.current = position;
    return false;
  };
  function dragOver(e) {
    e.preventDefault();
    return false;
  }
 
  const drop = (e) => {
      const copyListItems = [...result?.searchResult];
      const dragItemContent = copyListItems[dragItem.current];
      copyListItems.splice(dragItem.current, 1);
      copyListItems.splice(dragOverItem.current, 0, dragItemContent);
      moveColumn(dragItem?.current, dragOverItem?.current, copyListItems)
      dragItem.current = null;
      dragOverItem.current = null;
      setResult({
        ...result,
        searchResult: copyListItems
      });
      setClonedCoulmns(copyListItems)
  };

  
  const filter = (e) => {
    const keyword = e.target.value;
    if (keyword !== '') {
      const results = clonedCoulmns.filter((item) => {
        return item.headerName.toLowerCase().includes(keyword.toLowerCase());
        // Use the toLowerCase() method to make it case-insensitive
      });
      setResult({
        ...result,
        searchTerm: keyword,
        searchResult: results
      });
    } else {
      setResult({
        ...result,
        searchTerm: keyword,
        searchResult: clonedCoulmns
      });
    }
  };

  useEffect(() => {
    if (columnDefs?.length) {
      let clone = columnDefs?.map(item => {
        return {
          ...item,
          checked: !item.hide,
        }
      })
      setClonedCoulmns(clone)
      setResult({
        ...result,
        searchResult: clone
      });
    }
  }, [columnDefs])

  useEffect(() => {
    if (appliedColumns?.length) {
      setClonedCoulmns(appliedColumns)
      setResult({
        ...result,
        searchResult: appliedColumns
      });
    }
  }, [appliedColumns])
  
  const handleCheck = (e, obj) => {
    const {checked } = e.target;
    let cloneArr = [...clonedCoulmns];
    let selectedIndex = cloneArr?.findIndex(i=>  i?.field === obj?.field)
    cloneArr[selectedIndex]["checked"] = checked
    cloneArr[selectedIndex]["hide"] = !checked
    setClonedCoulmns(cloneArr)
  }

  const handleApply = () => {
    let final = clonedCoulmns?.filter(item => {
      return item?.checked == true
    })
    apply(final, columnDefs)
  }

  return (
    <div className='p-10 h-100 position-relative filter-container'>
      <div>
        <input name={`search`} type="search" placeholder={`Search...`} onChange={filter} value={result?.searchTerm} className="inputBox"/>
      </div>
      <hr/>
      <div className='list-box'>
        {result?.searchResult?.length && (result?.searchResult?.map((type, index) => {
          return <div key={`default-${type?.field}`} className="" 
          onDragStart={(e) => dragStart(e, index)}
          onDragEnter={(e) => dragEnter(e, index)}
          onDragEnd={drop}
          onDragOver={dragOver}
          draggable={!result?.searchTerm}>
            <div className='d-flex align-items-center item-box ' >
              <input type="checkbox" name="checkbox" id={`${type?.field}`} checked={type?.checked} onChange={(e) => handleCheck(e,type)} className="input-checkbox"/>
              <span htmlFor={`${type?.field}`} className={result?.searchTerm ? "m-l-10 m-r-10 " : "m-l-10 m-r-10 draggable"}>::::</span>
              <label htmlFor={`${type?.field}`} className="">{type?.headerName}</label>
            </div>
          </div>
        }))}
      </div>
      
      <Button variant='primary' onClick={handleApply} className="applyBtn">Apply</Button>
    </div>
  )
}

export default CustomFilter
