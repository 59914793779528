import React, { useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as actionTypes from "../../../../store/actionTypes";

import "./customNumberFilter.scss"

let filters = {}, isFilterApplied = false;

const CustomNumberFilter = forwardRef((props, ref) => {

  useImperativeHandle(ref, () => {
    return {
      doesFilterPass(params) {
        return true
      },
      isFilterActive() {
        return false;
      },
      getModel() {},

      setModel() {},
    };
  });

    const [userSelectedCheckbox, setUserSelectedCheckbox] = useState(false);
    const [columnValues, setColumnValues] = useState("");
    const { filterParams } = props.colDef;
    const { callBackFunc, filterOptions, name } = filterParams;
    const [selectedFilterOptions, setSelectedFilterOptions] = useState(filterOptions[0]?.id || "GREATER_THAN_OR_EQUAL_TO");

    const dispatch = useDispatch()
    const [allAppliedFilters] = useSelector(({workboardReducer}) => [workboardReducer?.allAppliedFilters])

    useEffect(() => {
        document.addEventListener('resetFiltersClicked', resetFilterAll);
        return () => {
            document.removeEventListener('resetFiltersClicked', resetFilterAll);
            filters ={};
        };
    }, []);

    useEffect(() => {
        if (userSelectedCheckbox && columnValues) {
            let finalObject = { ...allAppliedFilters }
            filters = {
                type: name,
                selectedFilterOption: selectedFilterOptions,
                selectedFilterValue: columnValues
            }
            finalObject[`${name}`] = filters
            // callBackFunc(finalObject);
            dispatch({ type: actionTypes.SET_WORKBOARDS_FILTERS, data: { ...finalObject }});
        }
    }, [columnValues, selectedFilterOptions])
   
    const handleSubmit = (event) => {
        if (event.key === "Enter" ) {
            event.preventDefault();
            handleApplyClick()
        }
    }

    const handleApplyClick = () => {
        if (columnValues !== "") {
            let finalObject = { ...allAppliedFilters }
            filters = {
                type: name,
                selectedFilterOption: selectedFilterOptions,
                selectedFilterValue: columnValues
            }
            finalObject[`${name}`] = filters
            callBackFunc(finalObject);
            dispatch({ type: actionTypes.SET_WORKBOARDS_FILTERS, data: { ...finalObject }});
        }
    }


    const handleOption1Change = (event) => {
        setSelectedFilterOptions(event.target.value);
        setUserSelectedCheckbox(true)
    };

    const handleChange = (event) => {
        const value = event.target.value;
        setColumnValues(value)
        setUserSelectedCheckbox(true)
    };

    const applyFilter = () => {
        let finalObject = {...allAppliedFilters}
        filters = {
            type: name,
            selectedFilterOption: null,
            selectedFilterValue: null
        }
        finalObject[`${name}`] = filters
        callBackFunc(finalObject);
        if(finalObject?.hasOwnProperty(`${name}`)) {
            delete finalObject?.[`${name}`];
        }
        dispatch({ type: actionTypes.SET_WORKBOARDS_FILTERS, data: {...finalObject}});
    };
    
    const resetFilterAll = () => {
        setColumnValues("")
        setSelectedFilterOptions(filterOptions[0]?.id)
        let finalObject = {}
        filters = {
            type: name,
            selectedFilterOption: null,
            selectedFilterValue: null
        }
        finalObject[`${name}`] = filters
        callBackFunc(finalObject);
        dispatch({ type: actionTypes.SET_WORKBOARDS_FILTERS, data: {}});
    };
    const resetFilter = () => {
        setColumnValues("")
        setSelectedFilterOptions(filterOptions[0]?.id)
        applyFilter();
    };

    return (
        <div className='custom-growth-filter'>
            <div className='select-option-box'>
                <select value={selectedFilterOptions} onChange={handleOption1Change} className='select-option' onKeyDown={handleSubmit}>
                    {filterOptions.map((option) => (
                        <option key={option?.id} value={option?.id}>
                            {option?.value}
                        </option>
                    ))}
                </select>
            </div>
            {/* <div className='divider'></div> */}
            <div className='checkbox-box'>
            <div className='search-section d-flex align-items-center justify-content-end'>
                <div className="search-box-filter">
                    <input value={columnValues} id="filter-text-box" type="number" className="search-input" placeholder="Enter Value" onChange={handleChange} onKeyDown={handleSubmit}/> 
                </div>
            </div>
            </div>
            <div className='d-flex justify-content-end'>
                <button onClick={handleApplyClick} className='ag-standard-button ag-filter-apply-panel-button'>Apply</button>
                <button onClick={resetFilter} className='ag-standard-button ag-filter-apply-panel-button'>Reset</button>
            </div>

        </div>
    );
});

export default CustomNumberFilter;