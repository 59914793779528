
import React from 'react';
import {useLocation} from "react-router"
import { useSelector } from "react-redux";

import {Navigate, Outlet} from 'react-router-dom'

function ProtectedRoute() {
const location = useLocation();
const [isTokenRefreshed] = useSelector(({ authReducer }) => [authReducer.isTokenRefreshed])

const loggedInUser = localStorage.getItem("authData");
  return isTokenRefreshed ? null : loggedInUser?<Outlet/>: <Navigate to="/login" replace state={{from:location}}/>
}

export default ProtectedRoute;