import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form, Nav } from 'react-bootstrap';
import {  useSearchParams, useNavigate } from 'react-router-dom';
import * as amplitude from '@amplitude/analytics-browser';
import { LoadingBlock } from "../../commonComponents/LoadingAnimation/LoaderComponents";
import PlainTootip from "../../commonComponents/InfoTooltip/PlainTooltip";
import StatusTooltip from "./StatusTooltip";
import SubmitCard from "../../commonComponents/SubmitCard/SubmitCard";
import { investorTabs } from "./ReminderTabConstant";
import { getInvestorReminders, getInvestorReminderStatus } from "../../store/actions";
import { axiosInvestorReminders, axiosInvestorReminderStatus } from "../../config/appConfig/axiosInstance";
import { ReactComponent as SearchIcon } from "../../assets/images/icon_svg/search_icon.svg";
import { ReactComponent as InfoIcon } from "../../assets/images/icon_svg/info2.svg";
import imgLoader from "../../assets/images/loader.gif";
import './investorReminder.scss';
 
let initiallCall = false;
let recursiveStatusCount = 0;

const InvestorReminders = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const [activeTab, setActiveTab] = useState(searchParams.get("status") || 'All');
    const [reminders, setReminders] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredReminders, setFilteredReminders] = useState([]);
    const [filterStatus, setFilterStatus] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [selectedReminderId, setSelectedReminderId] = useState();
    const [isActionLoading, setIsActionLoading] = useState(false);
    const [statusSuccess, setStatusSuccess] = useState({
        openModal: false,
        isSuccess: null,
        message: "",
    })
    const [investorReminders ] = useSelector(({investorRemindersReducer})=> [investorRemindersReducer?.investorReminders, ])
 
    useEffect(() => {
        setIsLoading(true);
        dispatch(getInvestorReminders(axiosInvestorReminders)).then(() => {
            setIsLoading(false)
            amplitude.logEvent('Reminders Loaded');
        });
    }, [dispatch]);
 
    useEffect(() => {
        if (Object.keys(investorReminders).length) {
            setReminders(investorReminders?.results);
        }
    }, [investorReminders]);
 
    useEffect(() => {
        filterReminders(activeTab);
        navigate(`/investorReminders?status=${activeTab}`);
    }, [activeTab, reminders]);
 
    useEffect(() => {
        if(initiallCall){
            const debouncedSearch = setTimeout(() => {
                filterReminders(activeTab);
            }, 100);
            return () => clearTimeout(debouncedSearch);
        }
    }, [searchTerm]);
 
    const handleSearchChange = event => {
        initiallCall = true;
        const term = event.target.value;
        setSearchTerm(term);
    };
 
    const handleFilterChange = (status, reminder) => {
        setSelectedReminderId(reminder?.source)
        setFilterStatus(status);
        const payload = {
            reminder_source: reminder.source,
            reminder_status: status
        };
        setIsActionLoading(true);
        amplitude.logEvent('Reminders Actions', {status: reminder?.status});
        dispatch(getInvestorReminderStatus(axiosInvestorReminderStatus, payload))
        .then(response => {
            if (response.status === 'success') {
                handleRecursiveCall(reminder.source, status)
            } 
            else {
                setIsActionLoading(false);
                setStatusSuccess({
                    message: response?.message ||"Error Saving Data",
                    openModal: true,
                    isSuccess: false
                })
            }
        }).catch(()=> {
            setIsActionLoading(false);
            setStatusSuccess({
                message: response?.message || "Error Saving Data",
                openModal: true,
                isSuccess: false,
            })
        })
    };  
    
    const handleRecursiveCall = (sourceId, status) => {
        let isStatusUpdated =  false;
        if (isStatusUpdated || recursiveStatusCount > 5) {
            setIsActionLoading(false);
            return;
        }
        const timeoutId = setTimeout(() => {

            dispatch(getInvestorReminders(axiosInvestorReminders)).then((res) => {

                if(res?.data?.length) {
                    let selectedreminder = res?.data?.find(i => i?.source === sourceId)

                    if(checkStatus(selectedreminder, status, sourceId)) {
                        isStatusUpdated = true;
                        recursiveStatusCount = 0;
                        setIsActionLoading(false);
                        setStatusSuccess({
                            message: "Your Status is saved successfully!",
                            openModal: true,
                            isSuccess: true,
                        })
                    } else {
                        recursiveStatusCount++;
                        handleRecursiveCall(sourceId, status);
                    }

                } else {
                    setIsActionLoading(false);
                    setStatusSuccess({
                        message: "Please refresh the page to see updated status.",
                        openModal: true,
                        isSuccess: true,
                    })
                }

            }).catch(() => {
                setIsActionLoading(false);
                setStatusSuccess({
                    message:  "Please refresh the page to see updated status.",
                    openModal: true,
                    isSuccess: true,
                })
            });

        }, 1000);

        return () => clearTimeout(timeoutId); // Clear timeout when necessary
    };

    const checkStatus = (updatedObject, status, ) => {
        let isUpdated = false;
        if(status ===  updatedObject?.status) {
            isUpdated = true;
        }
        return isUpdated
    }

    const filterReminders = (tab) => {
        let filtered = reminders?.filter(reminder => {
            const isCompanyNameMatch = reminder?.company_name?.toLowerCase()?.includes(searchTerm?.toLowerCase());
            if (!isCompanyNameMatch) return false;
    
            const status = reminder?.status?.toLowerCase();
            switch (tab) {
                case 'All':
                    return true;
                case 'passed':
                    return status === 'passed' || status === 'incomplete';
                case 'upcoming':
                    return status === 'upcoming' || status === 'pending';
                default:
                    return status === tab?.toLowerCase();
            }
        });
        amplitude.logEvent('Investor Reminders Tabs Clicked', {tab_name: tab});
   
        setFilteredReminders(filtered);
    };
    
    const formatDate = (dateString, showTime = false) => {
        const date = new Date(dateString);
        const timeOptions = { 
            hour: '2-digit', 
            minute: '2-digit' 
        };
    
        const day = date.getDate();
        const month = date.toLocaleDateString('en-US', { month: 'long' });
        const year = date.getFullYear();
        const suffix = (day > 3 && day < 21) || (day % 10 > 3) ? 'th' : ['th', 'st', 'nd', 'rd'][day % 10];
        const formattedDate = `${day}${suffix} ${month} ${year}`;
        const formattedTime = date.toLocaleTimeString('en-US', timeOptions);
    
        return showTime ? `${formattedTime} ${formattedDate}` : formattedDate;
    }; 
    
    const openDomain = (domain) => {
        window.open(`https://${domain}`, '_blank');
    };

    const renderReminders = () => {
        return filteredReminders?.length > 0 ? (

                filteredReminders?.map((reminder, index) => {
                    const domainArray = reminder?.company_domain?.split(",");
                    const nameArray = reminder?.company_name?.split(",");
                    const companyMap = nameArray?.reduce((acc, name, idx) => {
                        acc[name?.trim()] = domainArray[idx]?.trim(); // Ensure no extra spaces
                        return acc;
                }, {});

               return <div className="timeline-content" key={index}>
                    <div className="cards outer-card">
                        <div className="card-headers d-flex justify-content-between">
                            <div className="company-name">
                                {reminder?.company_name && (
                                   <>
                                       {nameArray?.map((name, idx) => (
                                           <span
                                               key={idx}
                                               onClick={() => openDomain(companyMap[name?.trim()])}
                                               style={{ cursor: 'pointer', }}
                                           >
                                               {name?.trim()}
                                           </span>
                                       )).reduce((prev, curr) => [prev, ' + ', curr])}
                                       <br />
                                   </>
                                )}
                                {reminder?.schedule_date && (
                                    <>
                                        <span className="sender-email">{formatDate(reminder?.schedule_date)}</span>
                                    </>
                                )}
                            </div>
                        </div>
                        <div className="card-body position-relative">
                            {reminder?.metrics_summary && (
                                <span className="card-title"><strong>Summary: </strong>{reminder?.metrics_summary}</span>
                            )}
                            <div className="next-reminder-status d-flex align-items-center mb-2">
                                <div className="next-reminder">
                                    {((reminder?.next_reminder_at && reminder?.next_reminder_at  !== "n/a") || (reminder?.next_steps && reminder?.next_steps !== "n/a")) ? (<><strong>Next Reminder:</strong> {reminder?.next_reminder_at && `${formatDate(reminder.next_reminder_at)},`} {reminder?.next_steps}<br /> </>) : null}
                                </div>
                                <div className="status-filter">
                                    <StatusTooltip currentStatus={reminder?.status} onChangeStatus={(status) => handleFilterChange(status, reminder)} />
                                    {
                                        isActionLoading && selectedReminderId === reminder?.source ? <img src={imgLoader} height='16px' width='16px' alt="spinner" /> : null
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    {reminder?.email_date && (
                        <div className="date d-flex justify-content-between">
                            <span>{reminder?.source_type === "CompanyCallSummaries" ? "Call summary recorded on" : "Reference created on"} {formatDate(reminder.email_date, true)}</span>
                            <span>{reminder?.source_type?.toLowerCase()}</span>
                        </div>
                    )}
                </div>
    })
        ) : (
            <div className='no-reminders lightGrey d-flex align-items-center justify-content-center mt-5'>You have no reminders</div>
        );
    };    
 
    const renderTabs = () => {
        const tabCounts = {
            All: reminders?.length,
            Completed: reminders?.filter(reminder => reminder?.status === 'completed').length,
            Snoozed: reminders?.filter(reminder => reminder?.status === 'snooze').length,
            Cancelled: reminders?.filter(reminder => reminder?.status === 'delete').length,
            Passed: reminders?.filter(reminder => reminder?.status === 'passed' || reminder?.status === 'incomplete').length,
            Upcoming: reminders?.filter(reminder => reminder?.status === 'upcoming' || reminder?.status === 'pending').length
        };
        const lowerCaseActiveTab = activeTab.toLowerCase();
        return (
            <>
                <Nav className="mr-auto">
                    {investorTabs && investorTabs?.length && 
                        (investorTabs?.map((ele, ind) => {
                            return <Nav.Link key={ind} onClick={() => { setActiveTab(`${ele?.serverValue}`)}} className={`${lowerCaseActiveTab === (ele?.serverValue?.toLowerCase()) ? "activeTab" : ""} ${tabCounts[`${ele?.displayName}`] === 0 ? "disabledTab": ""}`} >
                                {ele?.displayName}
                                {ele?.displayName !== "All" && (<PlainTootip name={`${ele?.displayName}`} content={`${ele?.tooltipMessage}`} />)}
                            </Nav.Link>
                        }))
                    }
                </Nav>
                {filteredReminders?.length ?
                    <span className='investor-reminder-count d-flex align-items-center'>
                        <b>{filteredReminders?.length}</b>&nbsp;results
                    </span> : ''
                }
            </>
        );
    };

    const handleContinue = () => {
        setStatusSuccess({ openModal: false, isSuccess: null, message: "" })
    }
    
    return (
        <>
            {statusSuccess?.openModal && <SubmitCard showToast={statusSuccess?.openModal} onHide={() => handleContinue()} isSuccess={statusSuccess?.isSuccess} message={statusSuccess?.message} />}
            <div className="investor-container">
                <div className='mb-2 d-flex justify-content-between align-items-center'>
                    <span className='title'>Investor Reminders</span>
                    <div className='investor-reminders-header'>
                        <div className='d-flex align-items-center'>
                            <div className="search-box-wrapper">
                                <Form.Control className="search-box" size="lg" type="text" placeholder="Search" style={{marginRight: "15px", height: "36px", fontSize: "14px", width: "230px"}} value={searchTerm} onChange={handleSearchChange}/>
                                <SearchIcon className="search-icon" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className='tabs-header d-flex justify-content-between'>
                    {renderTabs()}
                </div>
                {
                    isLoading ?
                    <div className="p-20">
                        <LoadingBlock height="130px" width="auto" className="mb-3 " />
                        <LoadingBlock height="130px" width="auto" className="mb-3 " />
                        <LoadingBlock height="130px" width="auto" className="mb-3 " />
                    </div>
                    :
                    <div className="reminder-list">
                        {renderReminders()}
                    </div>
                }
            </div>
        </>
    );
}
 
export default InvestorReminders;