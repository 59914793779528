
export const investorTabs = [
    {
        displayName: "All",
        serverValue: "All",
        tooltipMessage: "",
        hasTooltipIcon: false
    },
    {
        displayName: "Cancelled",
        serverValue: "delete",
        tooltipMessage: "Reminders that have been deleted or cancelled.",
        hasTooltipIcon: true
    },
    {
        displayName: "Passed",
        serverValue: "passed",
        tooltipMessage: "Reminders that have not been delivered, as it's in the past.",
        hasTooltipIcon: true
    },
    {
        displayName: "Completed",
        serverValue: "completed",
        tooltipMessage: "Reminders that have been completed by you.",
        hasTooltipIcon: false
    },
    {
        displayName: "Snoozed",
        serverValue: "snooze",
        tooltipMessage: "Reminders that have been snoozed for a week.",
        hasTooltipIcon: false
    },
    {
        displayName: "Upcoming",
        serverValue: "upcoming",
        tooltipMessage:  "Reminders that are going to be delivered to you in the future. Thus no action can be taken yet. This is just a preview of what's coming up in the future.",
        hasTooltipIcon: false
    },
];