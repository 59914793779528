import * as actionTypes from '../../store/actionTypes';

const initialState = {
    referenceMention: null,
};

const mentionsReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_REFERENCE_DATA:
            return {
                ...state,
                referenceMention: { ...action.data }, // Update referenceData
            };
        default:
            return state;
    }
};

export default mentionsReducer;
