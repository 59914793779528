import * as actionTypes from "../actionTypes";

const INITIAL_STATE = {
  referenceData: null,
  allReferences: {},
  trendingReferences: {},
  emailHistory: null,
  searchResult: {},
  emailError: null,
  projects: [],
  isRangePickerOpen: false,
  refDropdown: {},
  refHeader: {},
  incompleteRef: {}
};

const referenceReducer = function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case actionTypes.GET_REFERENCES:
      return {
        ...state,
        allReferences: action.data,
      };
    case actionTypes.SET_REFERENCES_SEARCH:
      let finalData = {};
      if (action.data?.isAppend) {
        finalData = {
          ...action.data,
          results: [...state?.searchResult?.results, ...action.data?.results],
        };
      } else {
        finalData = action.data;
      }
      return {
        ...state,
        searchResult: { ...finalData },
      };
    case actionTypes.SET_ALL_REFERENCES:
      return {
        ...state,
        searchResult: { ...action.data },
      };
    case actionTypes.GET_INVESTOR:
      return {
        ...state,
        allReferences: action.data,
      };
    case actionTypes.SET_INVESTOR_SEARCH:
      return {
        ...state,
        referenceData: { ...action.data },
      };
    case actionTypes.GET_EMAIL_HISTORY:
      return {
        ...state,
        emailHistory: action.data,
        allReferences: action.data,
      };
    case actionTypes.SET_EMAIL_ERROR:
      return {
        ...state,
        emailError: action.data,
      };
    case actionTypes.GET_TP_OWNER:
      return {
        ...state,
        allReferences: action.data,
      };
    case actionTypes.GET_TP_STATUS:
      return {
        ...state,
        allReferences: action.data,
      };
    case actionTypes.SET_PROJECTS:
      return {
        ...state,
        projects: [...action.data],
      };
    case actionTypes.RESET_REFERENCES:
      let final = {
        results: [],
      };
      return {
        ...state,
        searchResult: { ...final }
      };
    case actionTypes.PICKER_OPEN_STATE:
      return {
        ...state,
        isRangePickerOpen: action.data,
      };
    case actionTypes.SET_SINGLE_REFERENCE:
      return {
        ...state,
        singleReference: [...action.data],
      };
    case actionTypes.SET_FUNCTIONS:

      return {
        ...state,
        refDropdown: { ...action.data },
      };
    case actionTypes.SET_REF_HEADER:
      return {
        ...state,
        refHeader: { ...action.data },
      };
    case actionTypes.SET_INCOMPLETE_REF:
      return {
        ...state,
        incompleteRef: { ...action.data },
      };
    case actionTypes.SET_TRENDING_REFERENCES:
      return {
        ...state,
        trendingReferences: { ...action.data },
      };
    case actionTypes.UPDATE_REFERENCE:
      if (action?.selectedRefType === "all") {
        let updatedResults = [...state?.searchResult?.results]
        // Update the data at the specified rowIndex and itemIndex
        updatedResults[action.rowIndex] = {
          ...updatedResults[action.rowIndex], // Copy the existing object properties
          people: [
            ...updatedResults[action.rowIndex].people // Copy the existing people array
          ]
        };
        updatedResults[action.rowIndex].people[action.itemIndex] = action.data;
        return {
          ...state,
          searchResult: {
            ...state.searchResult,
            results: updatedResults,
          }
        };
      } else if (action?.selectedRefType === "incomplete") {
        let incompleteResults = [ ...state?.incompleteRef?.results ]
        incompleteResults[action.rowIndex] = {
          ...incompleteResults[action.rowIndex], // Copy the existing object properties
          people: [
            ...incompleteResults[action.rowIndex].people // Copy the existing people array
          ]
        };
        incompleteResults[action.rowIndex].people[action.itemIndex] = action.data;
        return {
          ...state,
          incompleteRef: {
            ...state.incompleteRef,
            results: incompleteResults,
          }
        };
      } else if (action?.selectedRefType === "trending") {
        let trendingResults = [...state?.trendingReferences?.results]
        trendingResults[action.rowIndex] = {
          ...trendingResults[action.rowIndex], // Copy the existing object properties
          people: [
            ...trendingResults[action.rowIndex].people // Copy the existing people array
          ]
        };
        trendingResults[action.rowIndex].people[action.itemIndex] = action.data;
        return {
          ...state,
          trendingReferences: {
            ...state.trendingReferences,
            results: trendingResults,
          }
        };
      } else {
        return {
          ...state,
        };
      }
    case actionTypes.UPDATE_REFERENCE_ROW:
      if (action?.selectedRefType === "all") {
        let allresults = [...state?.searchResult?.results ]
        allresults[action.rowIndex] = action.data;
        return {
          ...state,
          searchResult: {
            ...state.searchResult,
            results: allresults,
          }
        };
      } else if (action?.selectedRefType === "incomplete") {
        let incompleteSingleRow = [...state?.incompleteRef?.results]
        incompleteSingleRow[action.rowIndex] = action.data;
        return {
          ...state,
          incompleteRef: {
            ...state.incompleteRef,
            results: incompleteSingleRow,
          }
        };
      } else if (action?.selectedRefType === "trending") {
        let trendingRow = [...state?.trendingReferences?.results]
        trendingRow[action.rowIndex] = action.data;
        return {
          ...state,
          trendingReferences: {
            ...state.trendingReferences,
            results: trendingRow,
          }
        };
      } else {
        return {
          ...state,
        };
      }
    default:
      return {
        ...state,
      };
  }
};

export { referenceReducer };

