import * as actionTypes from "../actionTypes";
import {getAuthToken} from "../../utils"

import axios from "axios";

export const investorsearchReferences =
  (axiosInstance, data, scoper) => (dispatch) => {
    return axiosInstance({
      method: "GET",
      // data: {},
      params: {
        ...data,
      },
      headers: {
        Authorization: getAuthToken()
      },
    })
      .then((res) => {
        dispatch(setInvestorReferencesSearch(res.data));
        dispatch({type: actionTypes.COMPANY_PROFILE_LOADING, data: false})
        if (res.hasOwnProperty("error")) {
          return { status: "failed" };
        } else {
          return { status: "success" };
        }
      })
      .catch((err) => {
        dispatch({type: actionTypes.COMPANY_PROFILE_LOADING, data: false})
        console.warn("Response - error -> ", err);
        dispatch(setInvestorReferencesSearch({ error: true }));

        return { status: "failed" };
      });
  };

const setInvestorReferencesSearch = (data) => ({
  type: actionTypes.SET_INVESTOR_SEARCH,
  data,
});

export const searchReferences =
  (axiosInstance, data, appendMore, isSubmitClicked) => (dispatch) => {
    return axiosInstance({
      method: "GET",
      params: {
        ...data,
      },
      headers: {
        Authorization: getAuthToken()
      },
    })
      .then((res) => {
        if (res.hasOwnProperty("error")) {
          return { status: "failed" };
        } else {
          let appendedVal = Object.assign(res.data, { isAppend: isSubmitClicked ? false : appendMore });
          dispatch(setReferencesSearch(appendedVal));
          return { status: "success", resLength: res?.data?.results?.length };
        }
      })
      .catch((err) => {
        console.warn("Response - error -> ", err?.response?.data?.errors);
        return { status: "failed" };
      });
  };

const setReferencesSearch = (data) => ({
  type: actionTypes.SET_REFERENCES_SEARCH,
  data,
});

export const getemailhistory = (axiosInstance, data, scoper) => (dispatch) => {
  return axiosInstance({
    method: "GET",
    // data: {},
    params: {
      ...data,
    },
    headers: {
      Authorization: getAuthToken(),
    },
  })
    .then((res) => {
      dispatch(setemailhistory(res.data));
      dispatch({type: actionTypes.COMPANY_PROFILE_LOADING, data: false})
    })
    .catch((err) => {
      dispatch({type: actionTypes.COMPANY_PROFILE_LOADING, data: false})
      dispatch(setEmailError(err));
      console.warn("Response - error -> ", err);
      return { status: "failed" };
    });
};
const setemailhistory = (data) => ({
  type: actionTypes.GET_EMAIL_HISTORY,
  data,
});

const setEmailError = ({ response }) => ({
  type: actionTypes.SET_EMAIL_ERROR,
  data: response.data.message,
});

export const gettpowner = (axiosInstance, data, scoper) => (dispatch) => {
  return axiosInstance({
    method: "GET",
    // data: {},
    params: {
      ...data,
    },
    headers: {
      Authorization: getAuthToken(),
    },
  })
    .then((res) => {
      dispatch(settpowner(res.data));
    })
    .catch((err) => {
      console.warn("Response - error -> ", err);
      return { status: "failed" };
    });
};

const settpowner = (data) => ({
  type: actionTypes.GET_TP_OWNER,
});


export const getProjects = (axiosInstance) => (dispatch) => {
  axiosInstance({
    method: "GET",
    data: {},
    params: {},
    headers: {
      Authorization: getAuthToken(),
    },
  })
    .then((res) => {
      dispatch(setProjects(res?.data?.results));
    })
    .catch((err) => {
      console.warn("Response - error -> ", err);
    });
};

const setProjects = (data) => ({
  type: actionTypes.SET_PROJECTS,
  data,
});

export const getFunction = (axiosInstance) => (dispatch) => {
  axiosInstance({
    method: "GET",
    data: {},
    params: {},
    headers: {
      Authorization: getAuthToken(),
    },
  })
    .then((res) => {
      dispatch({ type: actionTypes.SET_FUNCTIONS, data:res?.data?.results});
    })
    .catch((err) => {
      console.warn("Response - error -> ", err);
    });
};

export const getRefHeaderFn = (axiosInstance) => (dispatch) => {
  return axiosInstance({
    method: "GET",
    data: {},
    params: {},
    headers: {
      Authorization: getAuthToken(),
    },
  })
    .then((res) => {
      dispatch({ type: actionTypes.SET_REF_HEADER, data:res?.data?.results});
      return{status: "success"}
    })
    .catch((err) => {
      return{status: "failed"}
      console.warn("Response - error -> ", err);
    });
};

export const conversationSingleReference = (axiosInstance, data) =>(dispatch)=>{
  axiosInstance({
    method:"GET",
    params:{...data},
    headers:{
      Authorization: getAuthToken(),
    }
  })
  .then((res)=>{
    dispatch(setSingleReference(res?.data?.results))
  })
  .catch((err) => {
    console.warn("Response Reference - Error  -> ", err);
  });
}

const setSingleReference = (data) =>{ 
  return {
  type: actionTypes.SET_SINGLE_REFERENCE,
  data,
}};

export const getIncompleteFn = (axiosInstance) => (dispatch) => {
  return axiosInstance({
    method: "GET",
    data: {},
    params: {},
    headers: {
      Authorization: getAuthToken(),
    },
  })
    .then((res) => {
      if (res.hasOwnProperty("error")) {
        return { status: "failed", errMsg:res?.error};
      } else if (res.hasOwnProperty("code") && res?.code === 422) {
        return { status: "failed", errMsg:res?.name};
      }else {
        dispatch({ type: actionTypes.SET_INCOMPLETE_REF, data:res?.data});
        return { status: "success", data:res?.data?.results};
      }
    })
    .catch((err) => {
      console.warn("Response - error -> ", err?.response?.data);
        if(err?.response?.data?.hasOwnProperty("code") && err?.response?.data?.code === 422){
          return { status: "failed", errMsg:err?.response?.data?.name};
        } else {
          return { status: "failed" };
        }
    });
};

// export const searchAllReferences =
//   (axiosInstance, data) => (dispatch) => {
//     return axiosInstance({
//       method: "GET",
//       params: {
//         ...data,
//       },
//       headers: {
//         Authorization: getAuthToken()
//       },
//     })
//       .then((res) => {
//         if (res.hasOwnProperty("error")) {
//           return { status: "failed", errMsg:res?.error};
//         } else if (res.hasOwnProperty("code") && res?.code === 422) {
//           return { status: "failed", errMsg:res?.name};
//         } else {
//           dispatch(setAllReference(res.data));
//           return { status: "success", data:res?.data?.results};
//         }
//       })
//       .catch((err) => {
//         console.warn("Response - error -> ", err?.response?.data);
//         if(err?.response?.data?.hasOwnProperty("code") && err?.response?.data?.code === 422){
//           return { status: "failed", errMsg:err?.response?.data?.name};
//         } else {
//           return { status: "failed" };
//         }
//       });
//   };

export const searchAllReferences =
(url) => (dispatch) => {
    return axios.get(url, {headers: {
              Authorization: getAuthToken()
            }})
    .then((res) => {
      if (res.hasOwnProperty("error")) {
        return { status: "failed", errMsg:res?.error};
      } else if (res.hasOwnProperty("code") && res?.code === 422) {
        return { status: "failed", errMsg:res?.name};
      } else {
        dispatch(setAllReference(res.data));
        return { status: "success", data:res?.data?.results};
      }
    })
    .catch((err) => {
      console.warn("Response - error -> ", err?.response?.data);
      if(err?.response?.data?.hasOwnProperty("code") && err?.response?.data?.code === 422){
        return { status: "failed", errMsg:err?.response?.data?.name};
      } else {
        return { status: "failed" };
      }
    });
};


const setAllReference = (data) => ({
  type: actionTypes.SET_ALL_REFERENCES,
  data,
});

export const getTrendingProjects =
  (axiosInstance, data) => (dispatch) => {
    return axiosInstance({
      method: "GET",
      params: {
        ...data,
      },
      headers: {
        Authorization: getAuthToken()
      },
    })
      .then((res) => {
        if (res.hasOwnProperty("error")) {
          return { status: "failed", errMsg:res?.error};
        } else if (res.hasOwnProperty("code") && res?.code === 422) {
          return { status: "failed", errMsg:res?.name};
        }else {
          dispatch({type: actionTypes.SET_TRENDING_REFERENCES, data: res?.data});
          return { status: "success", data:res?.data?.results};
        }
      })
      .catch((err) => {
        console.warn("Response - error -> ", err?.response?.data);
        if(err?.response?.data?.hasOwnProperty("code") && err?.response?.data?.code === 422){
          return { status: "failed", errMsg:err?.response?.data?.name};
        } else {
          return { status: "failed" };
        }
      });
  };

  export const updatePerson =
  (axiosInstance, apiPayload, ) => (dispatch) => {
    return axiosInstance({
      method: "POST",
      data: {
        ...apiPayload,
      },
      headers: {
        Authorization: getAuthToken()
      },
    })
      .then((res) => {
        if (res.hasOwnProperty("error")) {
          return { status: "failed", errMsg:res?.error};
        } else if (res.hasOwnProperty("code") && res?.code === 422) {
          return { status: "failed", errMsg:res?.name};
        }else {
          // dispatch({type: actionTypes.UPDATE_REFERENCE, data:dataObject, selectedRefType:selectedRefType, rowIndex:rowIndex, itemIndex: itemIndex });
          return { status: "success",};
        }
      })
      .catch((err) => {
        console.warn("Response - error -> ", err?.response?.data);
        if(err?.response?.data?.hasOwnProperty("code") && err?.response?.data?.code === 422){
          return { status: "failed", errMsg:err?.response?.data?.name};
        } else {
          return { status: "failed" };
        }
      });
  };

export const updateReferences = (arr, allData, selectedRowIndex, selectedRefType) => (dispatch) => {
  return Promise.all([...arr]).then((res) => {
    if (res.hasOwnProperty("error")) {
      return { status: "failed", errMsg:res?.error};
    } else if (res.hasOwnProperty("code") && res?.code === 422) {
      return { status: "failed", errMsg:res?.name};
    }else {
      // dispatch({type: actionTypes.UPDATE_REFERENCE_ROW, data:allData, selectedRefType:selectedRefType, rowIndex:selectedRowIndex,});
      return { status: "success",};
    }
  }).catch(err => {
    if(err?.response?.data?.hasOwnProperty("code") && err?.response?.data?.code === 422){
      return { status: "failed", errMsg:err?.response?.data?.name};
    } else {
      return { status: "failed" };
    }
  })
}

export const addPersonToRef =
  (axiosInstance, apiPayload, ) => (dispatch) => {
    return axiosInstance({
      method: "POST",
      data: {
        ...apiPayload,
      },
      headers: {
        Authorization: getAuthToken()
      },
    })
      .then((res) => {
        if (res.hasOwnProperty("error")) {
          return { status: "failed", errMsg:res?.error};
        } else if (res.hasOwnProperty("code") && res?.code === 422) {
          return { status: "failed", errMsg:res?.name};
        }else {
          // dispatch({type: actionTypes.UPDATE_REFERENCE, data:dataObject, selectedRefType:selectedRefType, rowIndex:rowIndex, itemIndex: itemIndex });
          return { status: "success",};
        }
      })
      .catch((err) => {
        console.warn("Response - error -> ", err?.response?.data);
        if(err?.response?.data?.hasOwnProperty("code") && err?.response?.data?.code === 422){
          return { status: "failed", errMsg:err?.response?.data?.name};
        } else {
          return { status: "failed" };
        }
      });
  };


