export const addOrRemoveAllFromDropdowns = (value, name, state) => {
    let finalResult; 
    if(value?.length === 0) {
        finalResult = [{display_name: "All", server_value: "all"}]
    } else if (value?.length >1) {
        if(state[`${name}`].some(item => item?.server_value === "all")){
            finalResult = value?.filter(item => item?.server_value != "all")
        } else if(value.some(item => item?.server_value === "all")) {
            finalResult = value?.filter(item => item?.server_value == "all")
        } else {
            finalResult = value
        }
    } else {
        finalResult = value
    }
    return finalResult;
}

export const addOrRemoveAllFromScopersDropdowns = (value, name, state) => {
    let finalResult; 
    if(value?.length === 0) {
        finalResult = [{full_name: "All", email_address : "all", first_name:"all"}]
    } else if (value?.length >1) {
        if(state[`${name}`].some(item => item?.email_address === "all")){
            finalResult = value?.filter(item => item?.email_address != "all")
        } else if(value.some(item => item?.email_address === "all")) {
            finalResult = value?.filter(item => item?.email_address == "all")
        } else {
            finalResult = value
        }
    } else {
        finalResult = value
    }
    return finalResult;
}

export const addOrRemoveAllFromPeoplesDropdowns = (value, name, state) => {
    const defaultVal = {display_name: "All", server_value: "all"};
    let finalResult; 
    if(value?.length === 0) {
        finalResult = [{email: "all", functions: [defaultVal], name: "All",  rating: [defaultVal],reference_type: [defaultVal]}]
    } else if (value?.length >1) {
        if(state[`${name}`].some(item => item?.email === "all")){
            finalResult = value?.filter(item => item?.email != "all")
        } else if(value.some(item => item?.email === "all")) {
            finalResult = value?.filter(item => item?.email == "all")
        } else {
            finalResult = value
        }
    } else {
        finalResult = value
    }
    return finalResult;
}