'use strict';

import React, { useMemo, useRef, useState, useEffect, useCallback } from 'react';
import { AgGridReact } from 'ag-grid-react';
import { useDispatch, useSelector } from 'react-redux';
import moment from "moment";
import axios from 'axios';


import { getAddress, filterOps } from "../../../utils";

import * as actionTypes from "../../../store/actionTypes";


import { ModuleRegistry, ClientSideRowModelModule } from 'ag-grid-community';
import { FiltersToolPanelModule, SetFilterModule, ServerSideRowModelModule } from 'ag-grid-enterprise';
// import CustomFilter from "./CustomColumnFiters";
import TPStatusFilterComponent from '../Filters/Tpstatus';
import TPOwnerFilterComponent from '../Filters/TpOwner';
import CustomNumberFilter from '../Filters/Compaines/CustomNumberFilter';
import CustomSearchComponent from '../Filters/Compaines/CustomSearchFilter';
import CustomCountryComponent from '../Filters/Compaines/CountryCustomFilter';

import FunctionFilterComponent from '../Filters/FunctionFilters';
import ProjectsFilterComponent from '../Filters/ProjectsFilters';
import RefFilterComponent from '../Filters/ReferenceFilters';
import RatingsFilterComponent from '../Filters/RatingFilters';



import { ReactComponent as LinkedInSvg } from '../../../assets/images/icon_svg/linkedin.svg';
import { ReactComponent as URLSvg } from '../../../assets/images/icon_svg/url.svg';
import { ReactComponent as AffinitySvg } from "../../../assets/images/icon_svg/affinity_new_logo.svg";
import search  from '../../../assets/images/icon_svg/search_icon.svg';
import loaderSpinner  from '../../../assets/images/spinner.gif';
import companyLogo from '../../../assets/images/icon_svg/company.svg';
// import imgLoader from "../../../assets/images/spinner.gif";
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import "./wrokboardTable.scss"


// Register the required feature modules with the Grid
ModuleRegistry.registerModules([ClientSideRowModelModule, SetFilterModule, FiltersToolPanelModule, ServerSideRowModelModule]);

let isMoreLoading = false
let childValues = {};
let hasCalled = true;
let searchText = "";

const rearrangeSeq = (originalObject, selectedWorkboard, seq) => {
    let customOrder
    if(selectedWorkboard?.category === "People") {
        customOrder = seq?.length ? [...seq] : [ "full_name", "email_address","projects","first_email_sent", "functions", "rating", "type_of_reference",  "last_email_timestamp"];
        for (const key in originalObject) {
            if (!customOrder.includes(key)){
                customOrder.push(key); // Add new key to the third last position
            }
        }
    } else {
        customOrder = seq?.length ? [...seq] : ["company_name", "tp_owners", "tp_status", "growth_6m", "growth_1y", "growth_2y", "employee_count",
        "sales_engineering_percentage", "seniority_percentage", "total_funding_usd", "last_funding_on", "first_email_sent", "last_email_sent", "region", "countries", "prestige", "status"];
        for (const key in originalObject) {
            if (!customOrder.includes(key)){
                customOrder.splice(customOrder.length - 0, 0, key); // Add new key to the third last position
            }
        }
    }
    // Check if any new keys are present in originalObject
    const reorderedObject = customOrder.reduce((obj, key) => {
        if (originalObject?.hasOwnProperty(key)) {
            obj[key] = originalObject[key];
        }
        return obj;
    }, {});
    return reorderedObject;
}

const CustomCurrencyComparator = (valueA, valueB, ) => {
    // Convert USD currency strings to numbers for sorting
    const numericValueA = valueA  === "n/a" ?  Number.NEGATIVE_INFINITY : parseFloat(valueA);
    const numericValueB = valueB  === "n/a" ?  Number.NEGATIVE_INFINITY : parseFloat(valueB);
  
    if (numericValueA === numericValueB) {
      return 0;
    } else if (numericValueA < numericValueB) {
      return -1;
    } else {
      return 1;
    }
  };

const CustomDateComparator = (date1, date2) => {
    const date1Number = date1 && date1 !== "n/a"  ?  new Date(moment?.utc(date1)).getTime() : null
    const date2Number = date2 && date2 !== "n/a" ?  new Date(moment?.utc(date2)).getTime() : null

    if (date1Number === null && date2Number === null) {
        return 0;
    }
    if (date1Number === null) {
        return -1;
    }
    if (date2Number === null) {
        return 1;
    }
    return date1Number - date2Number;
}


function capitalizeFirstLetter(string) {
    if (string) {
        string = string?.replace(/_/g, " ");
        var words = string?.split(" ");
        var capitalizedWords = words.map(function (word) {
            return word.charAt(0).toUpperCase() + word.slice(1);
        });
        var formattedString = capitalizedWords.join(" ");
        return formattedString;
    } else {
        return string
    }
}

const getSelectedFiltersType = (value, name) => { 
    if(name == 'year_founded') {
        return 'agSetColumnFilter'
    }
    return typeof value === "string" ? 'agTextColumnFilter' : typeof value === "number"? 'agNumberColumnFilter' : 'agSetColumnFilter'
} 

  const hyphenValueFormatter = (params) => {
    const value = params.value;
    if (value === null || value === undefined || value === '' || value?.length === 0 || value === "n/a") {
      return <div className='text-center'>-</div>
    } else if(Array?.isArray(value)) {
        return value?.length ? value?.map((item, index) => {
            if (index == value?.length - 1) {
                return `${item}` + " ";
            } else return `${item},` + " ";
        }) : ''
    }
    return value;
  };

function formatToUSD(props) {
    const cellValue = props.value;
    let usdFormat = cellValue && cellValue !=="n/a" ? Number(cellValue)?.toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 0
    }) : null

    return <span>
        {usdFormat === null || usdFormat === undefined || usdFormat === '' ? <div className='text-center'>-</div> : usdFormat}
    </span>;
}

  const getParams = () => ({
    // processHeaderCallback: (params) => {
    //     if (params.column.colId == '0') {
    //       // Modify the header name for 'colToExclude' during export
    //       return 'S.No.';
    //     }
    //     return params.column.getColDef().headerName; // Use the original header name
    //   },
    processCellCallback(params) {
      const {value, column} = params;  
      if(column?.colId === 'last_email_timestamp') {
        return !value || value === undefined || value === null || value === "n/a" ? '-' : `${moment?.utc(value).local().format("MMM DD, YYYY")}`
      } else if(column?.colId === 'first_email_sent' || column?.colId === 'last_email_sent' || column?.colId === 'created_date') {
        return !value || value === undefined || value === null || value === "n/a" ? '-' : `${moment?.utc(value).local().format("MMM DD, YYYY")}`
      } else if(column?.colId === 'tp_status') {
        return !value || value === undefined || value === "n/a" ? '-' : `${value?.toUpperCase()}`
      } else if(column?.colId === 'countries') {
        return !value || value === undefined || value === "n/a" || value?.length === 0 ? '-' : value?.length ? value?.map((item) => `${item?.country}`) : '-';
      } else if (column?.colId === 'total_funding_usd') {
        return !value || value === undefined || value === "n/a" ? '-' : Number(value)?.toLocaleString('en-US', {
            style: 'currency',
            currency: 'USD',
            minimumFractionDigits: 0
        })
    }
      return  value === undefined || value === null || value?.length === 0 || value === "n/a" ? '-' : `${value}`;
    },
  });

const processCellForClipboard = (params) => {
    const { value, column } = params;
    if (column?.colId === "countries") {
        return value?.length ? value?.map((item, index) => `${item?.country}`) : '-';
    } else if (column?.colId === 'last_email_timestamp') {
        return !value || value === undefined || value === null || value === "n/a" ? '-' : `${moment?.utc(value).local().format("MMM DD, YYYY")}`
    } else if (column?.colId === 'first_email_sent' || column?.colId === 'last_email_sent' || column?.colId === 'created_date') {
        return !value || value === undefined || value === null || value === "n/a" ? '-' : `${moment?.utc(value).local().format("MMM DD, YYYY")}`
    } else if (column?.colId === 'tp_status') {
        return !value || value === undefined || value === "n/a" ? '-' : `${value?.toUpperCase()}`
    } else if (column?.colId === 'total_funding_usd') {
        return !value || value === undefined || value === "n/a" ? '-' : Number(value)?.toLocaleString('en-US', {
            style: 'currency',
            currency: 'USD',
            minimumFractionDigits: 0
        })
    }
    return  value === undefined || value === null || value?.length === 0 || value === "n/a" ? '-' : `${value}`;
};


const WorkboardTable = ({ selectedWorkboard, handleCountUpdate }) => {
    const gridRef = useRef(null);
    const dispatch = useDispatch();
    const containerStyle = useMemo(() => ({ width: '100%', height: '100%' }), []);
    const [rowData, setRowData] = useState([]);
    const [columnDefs, setColumnDefs] = useState([]);
    const [debouncedSearchTerm, setDebouncedSearchTerm] = useState('');
    const [searchTerm, setSearch] = useState("");
    const [isSearching, setIsSearching] = useState(false);
    const [firstRender, setFirstRender] = useState(false); 
    const [cancelToken, setCancelToken] = useState(null);

    const [viewAllOwner, setViewAllOwner] = useState({
        selectedOwnerIndex: [],
    })
    const [viewAllCountries, setViewAllCountries] = useState({
        selectedIndex: [],
    })

    const [viewAllFunction, setViewAllFunction] = useState({
        selectedIndex: [],
    })
    const [viewAllProject, setViewAllProject] = useState({
        selectedIndex: [],
    })
    const [viewAllReference, setViewAllReference] = useState({
        selectedIndex: [],
    })
    
    // const [previousFilterModels, setPreviousFilterModels] = useState({});
    const [gridApi, setGridApi] = useState(null);
    const [cursor, setCursor] = useState({
        cursor_id: null,
        query_id: null
    });
    const [myWorkboardsCompanies, allAppliedFilters] = useSelector(({ workboardReducer }) => [workboardReducer?.myWorkboardsCompanies, workboardReducer?.allAppliedFilters])

    useEffect(()=> {
        addCustomButtonToFiltersToolPanel()
    },[allAppliedFilters])
    
    useEffect(() => {
        return () => {
            searchText = "";
        }
    }, [])

    const defaultExcelExportParams = useMemo(() => {
        return getParams();
      }, []);
  
    const defaultColDef = useMemo(() => {
        return {
            flex: 1,
            minWidth: 140,
            sortable: true,
            suppressMenu: false,
            autoHeaderHeight: true,
            unSortIcon: true,
            filterParams: {buttons: ["reset"]},
            menuTabs: ['columnsMenuTab'],
            resizable: true
            // floatingFilter: true,
        };
    }, []);

    // const filteredColumnDefs = () => {
    //     let filtered =  columnDefs.filter(column => {
    //         return column.field;
    //     });
    //     // console.log("filtered>>::", filtered)
    //     return filtered
    // };

    const onGridReady = useCallback((params) => {
        setGridApi(params.api);
        addCustomButtonToFiltersToolPanel();
    }, []);


    const sideBar = useMemo(() => {
        return {
            toolPanels: [
                {
                    id: 'columns',
                    labelDefault: 'Columns',
                    labelKey: 'columns',
                    iconKey: 'columns',
                    toolPanel: "agColumnsToolPanel",
                    toolPanelParams: {
                        suppressRowGroups: true,
                        suppressValues: true,
                        suppressPivots: true,
                        suppressPivotMode: true,
                        suppressColumnFilter: false,
                        suppressColumnSelectAll: false,
                        suppressColumnExpandAll: true,
                      }
                },
                {
                    id: 'filters',
                    labelDefault: 'Filters',
                    labelKey: 'filters',
                    iconKey: 'filter',
                    toolPanel: 'agFiltersToolPanel',
                }
            ],
            defaultToolPanel: 'customStats',
        };
    }, [columnDefs]);

    useEffect(() => {
        if (myWorkboardsCompanies && Object?.keys(myWorkboardsCompanies)?.length && myWorkboardsCompanies?.results?.length) {
            let headers = rearrangeSeq(myWorkboardsCompanies?.results[0], selectedWorkboard, myWorkboardsCompanies?.columns_order)
            const item = getColumnDef(headers)
            setColumnDefs(item);
            setRowData(myWorkboardsCompanies?.results);

            if (myWorkboardsCompanies?.next_cursor) {
                setCursor({
                    ...cursor,
                    cursor_id: myWorkboardsCompanies?.next_cursor,
                    query_id: myWorkboardsCompanies?.query_id
                })
            }
        }

    }, [myWorkboardsCompanies])

    useEffect(() => {
        if (rowData && rowData?.length ) {
            let headers = rearrangeSeq(myWorkboardsCompanies?.results[0], selectedWorkboard, myWorkboardsCompanies?.columns_order)
            const item = getColumnDef(headers)
            setColumnDefs(item);
        }
    }, [viewAllOwner, viewAllCountries, viewAllFunction, viewAllProject, viewAllReference])

    const getColumnDef = (headers) => {
        let item = [];
        for (let key in headers) {
            if (key === "company_name") {
                item?.push({ field: key, headerName: 'Company Name', cellRenderer: companyWithDomain, suppressMovable: true, pinned: true, minWidth: 350, autoHeight: true, autoSize: true, filter: null })
            } else if (key == "growth_6m" || key == "growth_1y" || key == "growth_2y" || key == "employee_count" || key == "prestige" || key == "sales_engineering_percentage" || key == "seniority_percentage" || key == "year_founded") {
                item?.push({ field: key, headerName: capitalizeFirstLetter(key), autoSize: true, wrapText: true, filter: CustomNumberFilter, filterParams: { filterOptions: [...filterOps], callBackFunc: applyFiltersCallback, name: key } })
            } else if (key == "first_email_sent") {
                item?.push({ field: key, headerName: 'First Email', autoSize: true, wrapText: true, autoHeight: true, cellRenderer: formatDateString, filter: CustomNumberFilter, filterParams: { filterOptions: [...filterOps], callBackFunc: applyFiltersCallback, name: key }, comparator: CustomDateComparator })
            } else if (key == "last_email_sent") {
                item?.push({ field: key, headerName: 'Last Email', autoSize: true, wrapText: true, autoHeight: true, cellRenderer: formatDateString, filter: CustomNumberFilter, filterParams: { filterOptions: [...filterOps], callBackFunc: applyFiltersCallback, name: key }, comparator: CustomDateComparator })
            } else if (key == "tp_status") {
                item?.push({ field: key, headerName: 'TP Status', minWidth: 160, autoSize: true, cellRenderer: textFormatter, filter: TPStatusFilterComponent, filterParams: { filterOptions: ["is", "was"], callBackFunc: applyFiltersCallback, name: key }, wrapText: true, })
            } else if (key == "tp_owners") {
                item?.push({ field: key, headerName: 'TP Owners', minWidth: 180, autoSize: true, autoHeight: true, wrapText: true, cellRenderer: formattedTPOwnerFn, filter: TPOwnerFilterComponent, filterParams: { callBackFunc: applyFiltersCallback, allDatas: myWorkboardsCompanies?.results, name: key } })
            } else if (key == "region") {
                item?.push({ field: key, headerName: 'Region', cellRenderer: textFormatterWithoutCapital, minWidth: 180, wrapText: true, autoSize: true, filter: CustomSearchComponent, filterParams: { filterOptions: myWorkboardsCompanies?.workboard_metadata?.regions?.length ? [...myWorkboardsCompanies?.workboard_metadata?.regions] : [], allDatas: myWorkboardsCompanies?.results, callBackFunc: applyFiltersCallback, name: key } })
            } else if (key == "status") {
                item?.push({ field: key, headerName: 'Status', cellRenderer: textFormatter, autoSize: true, wrapText: true, filter: CustomSearchComponent, filterParams: {  filterOptions: myWorkboardsCompanies?.workboard_metadata?.statuses?.length ? [...myWorkboardsCompanies?.workboard_metadata?.statuses] : [], allDatas: myWorkboardsCompanies?.results, callBackFunc: applyFiltersCallback, name: key } })
            } else if (key == "countries") {
                item?.push({ field: key, headerName: 'Countries', cellRenderer: renderCountry, minWidth: 205, autoSize: true, autoHeight: true, wrapText: true, filter: CustomCountryComponent, filterParams: { filterOptions: myWorkboardsCompanies?.workboard_metadata?.countries?.length ? [...myWorkboardsCompanies?.workboard_metadata?.countries] : [], allDatas: myWorkboardsCompanies?.results, callBackFunc: applyFiltersCallback, name: key } })
            } else if (key == "last_funding_on") {
                item?.push({ field: key, headerName: 'Last Funding On', cellRenderer: formatDateString, autoSize: true, wrapText: true, autoHeight: true, filter: CustomNumberFilter, filterParams: { filterOptions: [...filterOps], callBackFunc: applyFiltersCallback, name: key } })
            } else if (key == "total_funding_usd") {
                item?.push({ field: key, headerName: 'Total Funding USD', cellRenderer: formatToUSD, autoSize: true, wrapText: true, filter: CustomNumberFilter, filterParams: { filterOptions: [...filterOps], callBackFunc: applyFiltersCallback, name: key }, comparator: CustomCurrencyComparator })
            } else if (key == "email_address") {
                item?.push({ field: key, headerName: 'Email Address', cellRenderer: textWithoutCapital, filter: "agTextColumnFilter", autoSize: true, minWidth: 180, })
            } else if (key == "last_email_timestamp") {
                item?.push({ field: key, headerName: 'Latest Email', cellRenderer: formatDateString, filter: "agDateColumnFilter", wrapText: true, autoSize: true, minWidth: 180, autoHeight: true, })
            } else if (key == "functions") {
                item?.push({ field: key, headerName: 'Functions', cellRenderer: renderFunction, filter: FunctionFilterComponent, filterParams: { callBackFunc: applyPeopleCallback, allDatas: myWorkboardsCompanies?.results }, minWidth: 165, wrapText: true, autoSize: true, autoHeight: true, })
            } else if (key == "projects") {
                item?.push({ field: key, headerName: 'Projects', cellRenderer: renderProject, filter: ProjectsFilterComponent, filterParams: { callBackFunc: applyPeopleCallback, allDatas: myWorkboardsCompanies?.results }, minWidth: 190, wrapText: true, autoSize: true, autoHeight: true, })
            } else if (key == "type_of_reference") {
                item?.push({ field: key, headerName: 'Type Of Reference', cellRenderer: renderRef, filter: RefFilterComponent, filterParams: { callBackFunc: applyPeopleCallback, allDatas: myWorkboardsCompanies?.results }, minWidth: 165, wrapText: true, autoSize: true, autoHeight: true, })
            } else if (key == "rating") {
                item?.push({ field: key, headerName: 'Rating', cellRenderer: hyphenValueFormatter, filter: RatingsFilterComponent, filterParams: { callBackFunc: applyPeopleCallback, allDatas: myWorkboardsCompanies?.results }, wrapText: true, })
            } else if (key !== "company_domain" && key !== "url" && key !== "linkedin_url" && key !== 'tp_owners_emails' && key !== "workboard_entry_id" && key !== "list_entry_id" && key !== "company_logo" && key !== "description" && key !== "year_founded" && key !== "naics_code" && key !== "date_added") {
                item?.push({ field: key, headerName: capitalizeFirstLetter(key), filter: getSelectedFiltersType(headers[key], key), autoSize: true, wrapText: true, cellRenderer: hyphenValueFormatter, })
            }
        }
        return item;
    }

    const renderFunction = (props) => {
        const cellValue = props.value;
        return <div className='tp-owner-container'>
            {cellValue?.length > 1 && viewAllFunction?.selectedIndex?.includes(props?.rowIndex) ? cellValue?.map(item => {
                return <span className="projetcsCss"
                >{item}</span>
            }) : cellValue?.length >= 1 ? <><span className="projetcsCss"
            >{cellValue[0]}</span> </> : <div className='text-center'>-</div>}

            {cellValue?.length > 1 && !viewAllFunction?.selectedIndex?.includes(props?.rowIndex) ? <span className="projetcsCss" ref={(ref) => {
                if (!ref) return;
                ref.onclick = (e) => {
                    e.stopPropagation();
                    let newArr = [...viewAllFunction?.selectedIndex]
                    newArr.push(props?.rowIndex)
                    setViewAllFunction({ ...viewAllOwner, selectedIndex: [...newArr] })
                };
            }}>+{cellValue?.length - 1}</span> : null}
        </div>
    }

    const renderRef = (props) => {
        const cellValue = props.value;
        return <div className='tp-owner-container'>
            {cellValue?.length > 1 && viewAllReference?.selectedIndex?.includes(props?.rowIndex) ? cellValue?.map(item => {
                return <span className="projetcsCss"
                >{item}</span>
            }) : cellValue?.length >= 1 ? <><span className="projetcsCss"
            >{cellValue[0]}</span> </> : <div className='text-center'>-</div>}

            {cellValue?.length > 1 && !viewAllReference?.selectedIndex?.includes(props?.rowIndex) ? <span className="projetcsCss" ref={(ref) => {
                if (!ref) return;
                ref.onclick = (e) => {
                    e.stopPropagation();
                    let newArr = [...viewAllReference?.selectedIndex]
                    newArr.push(props?.rowIndex)
                    setViewAllReference({ ...viewAllOwner, selectedIndex: [...newArr] })
                };
            }}>+{cellValue?.length - 1}</span> : null}
        </div>
    }

    const renderProject = (props) => {
        const cellValue = props.value;
        return <div className='tp-owner-container'>
            {cellValue?.length > 1 && viewAllProject?.selectedIndex?.includes(props?.rowIndex) ? cellValue?.map(item => {
                return <span className="projetcsCss"
                >{item}</span>
            }) : cellValue?.length >= 1 ? <><span className="projetcsCss"
            >{cellValue[0]}</span> </> : <div className='text-center'>-</div>}

            {cellValue?.length > 1 && !viewAllProject?.selectedIndex?.includes(props?.rowIndex) ? <span className="projetcsCss" ref={(ref) => {
                if (!ref) return;
                ref.onclick = (e) => {
                    e.stopPropagation();
                    let newArr = [...viewAllProject?.selectedIndex]
                    newArr.push(props?.rowIndex)
                    setViewAllProject({ ...viewAllOwner, selectedIndex: [...newArr] })
                };
            }}>+{cellValue?.length - 1}</span> : null}
        </div>
    }

    const renderCountry = (props) => {
        let countries = props?.data && props?.data['countries'] && Object?.keys(props?.data['countries'])?.length ? props?.data['countries'] : null
        let cellValue = {}, sorted={};
        if (countries && Object?.keys(countries)?.length) {
            sorted = Object.entries(countries);
            sorted.sort((a, b) => b[1] - a[1]);
            cellValue = Object.fromEntries(sorted)
        }
        return <div className='tp-owner-container'>
            { cellValue && Object?.keys(cellValue)?.length > 1 && viewAllCountries?.selectedIndex?.includes(props?.rowIndex) ? Object.keys(cellValue)?.map((item, index) => {
                return <span key={index} className="projetcsCss"
                >{item}{cellValue[item] > 0 ? <span className='d-flex align-items-center'>:<b className="badge d-flex align-items-center justify-content-center">{cellValue[item]}</b></span> : null}</span>
            }) : cellValue &&  Object?.keys(cellValue)?.length >= 1 ? <><span className="projetcsCss"
            >{Object.keys(cellValue)[0]}{cellValue[Object.keys(cellValue)[0]] > 0 ? <span className='d-flex align-items-center'>:<b className="badge d-flex align-items-center justify-content-center">{cellValue[Object.keys(cellValue)[0]]}</b></span> : null}</span> </> : <div className='text-center'>-</div>}

            {cellValue && Object?.keys(cellValue)?.length > 1 && !viewAllCountries?.selectedIndex?.includes(props?.rowIndex) ? <span className="projetcsCss" ref={(ref) => {
                if (!ref) return;
                ref.onclick = (e) => {
                    e.stopPropagation();
                    let newArr = [...viewAllCountries?.selectedIndex]
                    newArr.push(props?.rowIndex)
                    setViewAllCountries({ ...viewAllOwner, selectedIndex: [...newArr] })
                };
            }}>+{cellValue && Object?.keys(cellValue)?.length - 1}</span> : null}
        </div>
    }

    const formattedTPOwnerFn = (props) => {
        const cellValue = props.value;
        return <div className='tp-owner-container'>
            {cellValue?.length > 2 && viewAllOwner?.selectedOwnerIndex?.includes(props?.rowIndex) ? cellValue?.map(item => {
                return <span className="projetcsCss"
                >{item}</span>
            }) : cellValue?.length == 1 ? <><span className="projetcsCss"
            >{cellValue[0]}</span> </> : cellValue?.length >= 2 ? <><span className="projetcsCss"
            >{cellValue[0]}</span> <span className="projetcsCss"
            >{cellValue[1]}</span></> : <div className='text-center'>-</div>}

            {cellValue?.length > 2 && !viewAllOwner?.selectedOwnerIndex?.includes(props?.rowIndex) ? <span className="projetcsCss" ref={(ref) => {
                if (!ref) return;
                ref.onclick = (e) => {
                    e.stopPropagation();
                    let newArr = [...viewAllOwner?.selectedOwnerIndex]
                    newArr.push(props?.rowIndex)
                    setViewAllOwner({ ...viewAllOwner, selectedOwnerIndex: [...newArr] })
                };
            }}>+{cellValue?.length - 2}</span> : null}
        </div>
    }

    // const dateFormatter = (params) => {
    //     let fieldName = params?.colDef?.field
    //     return params.data[`${fieldName}`] ? `${moment?.utc(params.data[`${fieldName}`]).local().format("MMM DD YYYY | HH:MM:SS")}` : <div className='text-center'>-</div>
    // }

    const textFormatter = (value) => {
        const capitalVal = value?.valueFormatted ? value?.valueFormatted?.toUpperCase() : value?.value?.toUpperCase()
        return <span className=''>
            {capitalVal === null || capitalVal === undefined || capitalVal === '' ? <div className='text-center'>-</div> : <div className='projetcsCss'>{capitalVal}</div> }
        </span>
    } 

    const textFormatterWithoutCapital = (value) => {
        const capitalVal = value?.valueFormatted ? value?.valueFormatted : value?.value
        return <span className=''>
            {capitalVal === null || capitalVal === undefined || capitalVal === '' ? <div className='text-center'>-</div> : <div className='projetcsCss'>{capitalVal}</div> }
        </span>
    } 

    const textWithoutCapital = (value) => {
        const capitalVal = value?.valueFormatted ? value?.valueFormatted : value?.value
        return <span className=''>
            {capitalVal === null || capitalVal === undefined || capitalVal === '' ? <div className='text-center'>-</div> : <div style={{textTransform: "none"}}>{capitalVal}</div> }
        </span>
    } 
    // const dateStringFormat = (props) => {
    //     const cellValue = props.valueFormatted ? props.valueFormatted : props.value;
    //     const formatDate = cellValue && cellValue !== "n/a" ? `${moment?.utc(cellValue).local().format("MMM DD, YYYY ")}` : '';
    //     return <span>
    //         {formatDate === null || formatDate === undefined || formatDate === '' ? <div className='text-center'>-</div> : formatDate}
    //     </span>
    // }
    
    const formatDateString = (props) => {
        const cellValue = props.valueFormatted ? props.valueFormatted : props.value;
        const formatDate = cellValue && cellValue != "n/a" ? `${moment?.utc(cellValue)?.format("MMM DD, YYYY ")}` : null;
        const date = cellValue && cellValue != "n/a" ?  moment(cellValue) : null;
        const daysAgo = cellValue ? moment().diff(date, 'days') : null;
        return <span>
            {formatDate === null || formatDate === undefined || formatDate === '' ? <div className='text-center'>-</div> :
             <div>
                <div className='formatedDate' style={{ height: daysAgo ? "20px" : "25px"}}>{formatDate}</div>
               {daysAgo ? <div className='blueItalic'><i>{daysAgo ? `${daysAgo} ${daysAgo == 1 ? 'day' : 'days'} ago` : null}</i></div> : null} 
            </div>}
        </span>
    }

    const companyWithDomain = (props) => {
        const cellValue = props.valueFormatted ? props.valueFormatted : props.value;
        const company_logo = props?.data && props?.data['company_logo']  && props?.data['company_logo'] != "n/a" ? `${props?.data['company_logo']}` : null
        let hrefTag = props?.data && props?.data['company_domain'] && props?.data['company_domain'] !== "n/a" ? `https://${props?.data['company_domain']}` : null
        let affinityUrl = props?.data && props?.data['url'] && props?.data['url'] !== "n/a" ? props?.data['url'] : null
        let linkedinUrl = props?.data && props?.data['linkedin_url'] && props?.data['linkedin_url'] !== "n/a" ? props?.data['linkedin_url'] : null
        let yearFounded = props?.data && props?.data['year_founded'] && props?.data['year_founded'] !== "n/a" ? props?.data['year_founded'] : null
        let dateAdded = props?.data && props?.data['date_added'] && props?.data['date_added'] !== "n/a" ? moment(props?.data['date_added']) : null
        const daysAgo = dateAdded ? moment().diff(dateAdded, 'days') : null;

        return <span className='d-flex align-items-center justify-content-between'>
            <div className="logo-box d-flex align-items-center justify-content-between">
                {company_logo ? <img src={company_logo} height={`30px`} width={`30px`}/> : <img src={companyLogo} height={`30px`} width={`30px`}/> } 
            </div>
            <div style={{  width:"100%", overflow: "hidden",}}>{cellValue ? <div style={{width:"95%", overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis",}}>
                <div style={{height : yearFounded ? "20px" : "auto", lineHeight: "normal"}}><b>{cellValue}</b></div>
                {yearFounded ? <div style={{height : "20px", lineHeight: "normal"}}><i className='blueItalic2'> Founded: {yearFounded}</i></div> : null}
                {daysAgo >=0 ? <div style={{height: "20px", lineHeight: "normal", marginBottom:"4px",  fontSize: "12px", textTransform: "none"}}><i>Added {daysAgo === 0 ? `today` : daysAgo > 0 ? `${daysAgo} ${daysAgo == 1 ? 'day' : 'days'} ago` : null}</i></div> : null}
            </div> : <div className='text-center'>-</div>}</div> 
            <div className='d-flex justify-content-end'>
                <div style={{ height: yearFounded ? "24px" : "auto", lineHeight: "normal" }}>
                    {linkedinUrl && (<span ref={(ref) => {
                        if (!ref) return;
                        ref.onclick = (e) => {
                            e.stopPropagation(); // this works
                            window.open(linkedinUrl, '_blank');
                            // put your logic here instead because e.stopPropagation() will
                            // stop React's synthetic event
                        };
                    }}
                        style={{ cursor: "pointer" }} ><LinkedInSvg style={{marginLeft: "6px"}} /></span>)}

                    {affinityUrl && (<span ref={(ref) => {
                        if (!ref) return;
                        ref.onclick = (e) => {
                            e.stopPropagation(); // this works
                            window.open(affinityUrl, '_blank');
                            // stop React's synthetic event
                        };
                    }} style={{ cursor: "pointer" }}> <AffinitySvg style={{ marginLeft: "6px", marginRight: "-2px"}} /> </span>)}

                    {hrefTag && (<span ref={(ref) => {
                        if (!ref) return;
                        ref.onclick = (e) => {
                            e.stopPropagation(); // this works
                            window.open(hrefTag, '_blank');
                            // stop React's synthetic event
                        };
                    }} style={{ cursor: "pointer" }}><URLSvg style={{ marginLeft: "6px" }} /></span>)}
                </div>
            </div>
        </span>
    }

    const addCustomButtonToFiltersToolPanel = () => {
        // Find the filter tool panel element
        const filtersToolPanel = document.querySelector('#workboard-table .ag-filter-toolpanel');
        if (filtersToolPanel) {
            // Create a custom button element
            const customButtonApply = document.createElement('button');
            customButtonApply.classList.add('ag-standard-button', 'ag-filter-apply-panel-button', 'custom-apply-button');
            customButtonApply.style.margin = '15px 10px';
            customButtonApply.innerText = 'Apply All';
            customButtonApply.addEventListener('click', () => {
                 filteredList({...allAppliedFilters},  selectedWorkboard?.category?.toLowerCase() === "companies" ? 'company' : "people")
            });
            const applyButton = document.querySelector('#workboard-table .ag-filter-toolpanel .custom-apply-button');
            if (applyButton) {
                filtersToolPanel.replaceChild(customButtonApply, applyButton)
            } else {
                filtersToolPanel.appendChild(customButtonApply);
            }
            const customButton = document.createElement('button');
            customButton.classList.add('ag-standard-button', 'ag-filter-apply-panel-button', 'custom-reset-button');
            customButton.style.margin = '15px 10px';
            customButton.innerText = 'Reset All';
            customButton.addEventListener('click', () => {
                    dispatch({ type: actionTypes.SET_WORKBOARDS_FILTERS, data: { }});
                    // Reset all filters using the ag-Grid API
                    gridRef?.current?.api?.setFilterModel(null);
                    handleCountUpdate(rowData?.length)
                    const event = new Event('resetFiltersClicked');
                    document.dispatchEvent(event);
                   
            });
            const resetButton = document.querySelector('#workboard-table .ag-filter-toolpanel .custom-reset-button');
            if (resetButton) {
                filtersToolPanel.replaceChild(customButton, resetButton)
            } else {
                filtersToolPanel.appendChild(customButton);
            };
            
        }
    };

    const applyFiltersCallback = (filters) => {
        childValues = filters;
        if (hasCalled) {
            hasCalled = false
            setTimeout(() => {
                filteredList(filters, 'company')
            }, 100)
            return
        }
    }   

    const applyPeopleCallback = (filters) => {
        childValues = filters;
        if (hasCalled) {
            hasCalled = false
            setTimeout(() => {
                filteredList(filters, 'people')
            }, 120)
            return
        }
    }

    const appendCustomRowForLoader = (type, ) => {
        try {
            let length = type === "company" ? 15 : 8;
            const containerPinned = document.querySelector('#workboard-table .ag-pinned-left-cols-container');
            const customRowsPinnedConatainer = document.createElement('div');
            customRowsPinnedConatainer.classList.add("custom-rows-loader-pinned");
            customRowsPinnedConatainer.style.height = '224px';
            for (var i = 0; i < 4; i++) {
                const customRows = document.createElement('div');
                customRows.classList.add("w-100", "d-flex", "justify-content-space-between");
                customRows.style.borderBottom = '1px solid #ccc';
                customRows.style.height = '56px';
    
                for(var j = 0; j<1; j++) {
                    const newCell = document.createElement("div");
                    newCell.style.width = '320px';
                    newCell.style.margin = '16px 30px';
                    customRows.appendChild(newCell);
                    // Apply the "loader-animation" class to the cells
                    newCell.classList.add("loader-animation");
                }
                customRowsPinnedConatainer.appendChild(customRows)
              }
            containerPinned.appendChild(customRowsPinnedConatainer);
    
            const container = document.querySelector('#workboard-table .ag-center-cols-container');
            const customRowsConatainer = document.createElement('div');
            customRowsConatainer.classList.add("custom-rows-loader");
            customRowsConatainer.style.height = '224px';
            for (var i = 0; i < 4; i++) {
                const customRows = document.createElement('div');
                customRows.classList.add("w-100", "d-flex", "justify-content-space-between");
                customRows.style.borderBottom = '1px solid #ccc';
                customRows.style.height = '56px';
    
                for(var j = 0; j<=length; j++) {
                    var newCell = document.createElement("div");
                    newCell.style.width = '200px';
                    newCell.style.margin = '16px 30px';
                    customRows.appendChild(newCell);
                    // Apply the "loader-animation" class to the cells
                    newCell.classList.add("loader-animation");
                }
                customRowsConatainer.appendChild(customRows)
              }
            container.appendChild(customRowsConatainer);
            const containerNoRows = document.querySelector('#workboard-table .ag-overlay-no-rows-center');
            if(containerNoRows) {
                containerNoRows.style.visibility = 'hidden'
            }
            return true
          } catch (error) {
            return true;
          }
    }

    const removeCustomRowForLoader = () => {
        try {
            const containerPinned = document.querySelector('#workboard-table .ag-pinned-left-cols-container');
            const customRowsPinned = containerPinned.querySelector('#workboard-table .custom-rows-loader-pinned');
            if (customRowsPinned) {
                // Remove the customRows element
                containerPinned.removeChild(customRowsPinned);
            }

            const container = document.querySelector('#workboard-table .ag-center-cols-container');
            const customRows = container.querySelector('#workboard-table .custom-rows-loader');
            if (customRows) {
                // Remove the customRows element
                container.removeChild(customRows);
                const containerNoRows = document.querySelector('#workboard-table .ag-overlay-no-rows-center');
                if (containerNoRows) {
                    containerNoRows.style.visibility = 'inherit'
                }

            }
            return true
        } catch (error) {
            return true;
        }
    }
    
    const filteredList = async (inputs, type) => {
        let params = "", apiUrl = "";
        if (type === "company") {
            params = getCompanyParams(inputs)
            apiUrl = `${getAddress()}/workboards/${selectedWorkboard?.workboard_id}/companies${params ? '?' + params : ''}`;
        } else {
            params = getPeopleParams(inputs);
            apiUrl = `${getAddress()}/workboards/${selectedWorkboard?.workboard_id}/people${params ? `?` + params : ''}`;
        }
        isMoreLoading = true
        await gridRef.current.api.setRowData([]);
        handleCountUpdate(0)
        // gridRef.current.api.setSideBarVisible(false)
        await appendCustomRowForLoader(type);
        axios.get(`${apiUrl}`, {
            headers: {
                "Authorization": localStorage.getItem('authData') ? "Bearer " + localStorage.getItem('authData') : null
            },
        }).then(async (newData) => {
            await removeCustomRowForLoader()
            // gridRef.current.api.setSideBarVisible(true)
            hasCalled = true;
            let { data } = newData
            if (data) {
                const allRows = [...data?.results]
                gridRef.current.api.setRowData(allRows, { suppressFlash: true });
                handleCountUpdate(type === "company" ? data?.total_count : data?.counts)
                setCursor({
                    ...cursor,
                    cursor_id: data?.next_cursor,
                    query_id: data?.query_id
                })

                let anyFilterApplied = gridRef?.current.api.isAnyFilterPresent();
                if (anyFilterApplied) {
                    gridRef?.current?.api?.onFilterChanged();
                }
                const applyButton = document.querySelector('#workboard-table .ag-filter-toolpanel .custom-apply-button');
                const resetButton = document.querySelector('#workboard-table .ag-filter-toolpanel .custom-reset-button');
                if (!resetButton || !applyButton) {
                    addCustomButtonToFiltersToolPanel();
                }
                
                isMoreLoading = false
            } else {
                removeCustomRowForLoader()
                // No more data to load
                // You can handle this condition as needed
            }

        })
            .catch((error) => {
                removeCustomRowForLoader()
                hasCalled = true;
                // childValues= [];
                console.error('Error loading data:', error);
            });
    }

    const getCompanyParams = (inputs) => {
        let params = "";
        if (inputs?.hasOwnProperty(`tp_owner`)) {
            params = inputs?.tp_owner?.selectedFilterValue?.length ? `${params && params + "&"}` + inputs?.tp_owner?.selectedFilterValue.map(param => `tp_owner_email=${param?.tp_email}`).join('&') : params
        }
        if (inputs?.hasOwnProperty("tp_status") && inputs?.tp_status?.selectedFilterValue?.length) {
            params = inputs?.tp_status?.selectedFilterOption?.length ? `${params && params + "&"}` + `tp_status_operator=${inputs?.tp_status?.selectedFilterOption}` : params
            params = inputs?.tp_status?.selectedFilterValue?.length ? `${params && params + "&"}` + inputs?.tp_status?.selectedFilterValue.map(param => `tp_status_value=${param ? param?.toLowerCase() : ''}`).join('&') : params
        }
        if (inputs?.hasOwnProperty(`growth_6m`) ) {
            params = inputs?.growth_6m?.selectedFilterOption ? `${params && params + "&"}` + `growth_6m=${inputs?.growth_6m?.selectedFilterOption}:${inputs?.growth_6m?.selectedFilterValue}` : params
        }
        if (inputs?.hasOwnProperty(`growth_1y`)) {
            params = inputs?.growth_1y?.selectedFilterOption ? `${params && params + "&"}` + `growth_1y=${inputs?.growth_1y?.selectedFilterOption}:${inputs?.growth_1y?.selectedFilterValue}` : params
        }
        if (inputs?.hasOwnProperty(`growth_2y`)) {
            params = inputs?.growth_2y?.selectedFilterOption ? `${params && params + "&"}` + `growth_2y=${inputs?.growth_2y?.selectedFilterOption}:${inputs?.growth_2y?.selectedFilterValue}` : params
        } 
        if (inputs?.hasOwnProperty(`employee_count`)) {
            params = inputs?.employee_count?.selectedFilterOption ? `${params && params + "&"}` + `employee_count=${inputs?.employee_count?.selectedFilterOption}:${inputs?.employee_count?.selectedFilterValue}` : params
        }
        if (inputs?.hasOwnProperty(`total_funding_usd`) ) {
            params = inputs?.total_funding_usd?.selectedFilterOption ? `${params && params + "&"}` + `total_funding_usd=${inputs?.total_funding_usd?.selectedFilterOption}:${inputs?.total_funding_usd?.selectedFilterValue}` : params
        }
        if (inputs?.hasOwnProperty(`prestige`)) {
            params = inputs?.prestige?.selectedFilterOption ? `${params && params + "&"}` + `prestige=${inputs?.prestige?.selectedFilterOption}:${inputs?.prestige?.selectedFilterValue}` : params
        }
        if (inputs?.hasOwnProperty(`year_founded`)) {
            params = inputs?.year_founded?.selectedFilterOption ? `${params && params + "&"}` + `year_founded=${inputs?.year_founded?.selectedFilterOption}:${inputs?.year_founded?.selectedFilterValue}` : params
        }
        if (inputs?.hasOwnProperty(`sales_engineering_percentage`)) {
            params = inputs?.sales_engineering_percentage?.selectedFilterOption ? `${params && params + "&"}` + `sales_engineering_percentage=${inputs?.sales_engineering_percentage?.selectedFilterOption}:${inputs?.sales_engineering_percentage?.selectedFilterValue}` : params
        } 
        if (inputs?.hasOwnProperty(`seniority_percentage`)) {
            params = inputs?.seniority_percentage?.selectedFilterOption ? `${params && params + "&"}` + `seniority_percentage=${inputs?.seniority_percentage?.selectedFilterOption}:${inputs?.seniority_percentage?.selectedFilterValue}` : params
        }
        if (inputs?.hasOwnProperty(`last_funding_on`) ) {
            params = inputs?.last_funding_on?.selectedFilterOption ? `${params && params + "&"}` + `last_funding_on=${inputs?.last_funding_on?.selectedFilterOption}:${inputs?.last_funding_on?.selectedFilterValue}` : params
        }
        if (inputs?.hasOwnProperty(`first_email_sent`)) {
            params = inputs?.first_email_sent?.selectedFilterOption ? `${params && params + "&"}` + `first_email_sent=${inputs?.first_email_sent?.selectedFilterOption}:${inputs?.first_email_sent?.selectedFilterValue}` : params
        }
        if (inputs?.hasOwnProperty(`last_email_sent`)) {
            params = inputs?.last_email_sent?.selectedFilterOption ? `${params && params + "&"}` + `last_email_sent=${inputs?.last_email_sent?.selectedFilterOption}:${inputs?.last_email_sent?.selectedFilterValue}` : params
        } 
        if (inputs?.hasOwnProperty(`region`)) {
            params = inputs?.region?.selectedFilterValue?.length ? `${params && params + "&"}` + inputs?.region?.selectedFilterValue.map(param => `region=${param}`).join('&') : params
        }
        if (inputs?.hasOwnProperty(`status`)) {
            params = inputs?.status?.selectedFilterValue?.length ? `${params && params + "&"}` + inputs?.status?.selectedFilterValue.map(param => `status=${param}`).join('&') : params
        } 
        if (inputs?.hasOwnProperty(`countries`)) {
            params = inputs?.countries?.selectedFilterValue?.length ? `${params && params + "&"}` + inputs?.countries?.selectedFilterValue.map(param => `country=${param}`).join('&') : params
        } 
        if(searchText) {
            params = searchText ? `${params && params + "&"}` + `search=${searchText}` : params
        }
        return params
    }

    const getPeopleParams = (inputs) => {
        let params = "";
        if (inputs?.hasOwnProperty("functions")) {
            params = inputs?.functions?.selectedFilterValue?.length ? `${params && params + "&"}` + inputs?.functions?.selectedFilterValue.map(param => `functions=${param?.server_value}`).join('&') : params
        }  
        if (inputs?.hasOwnProperty("projects")) {
            params = inputs?.projects?.selectedFilterValue?.length ? `${params && params + "&"}` + inputs?.projects?.selectedFilterValue.map(param => `projects=${param?.server_value}`).join('&') : params
        } 
        if (inputs?.hasOwnProperty("reference_types")) {
            params = inputs?.reference_types?.selectedFilterValue?.length ? `${params && params + "&"}` + inputs?.reference_types?.selectedFilterValue.map(param => `reference_types=${param?.server_value}`).join('&') : params
        } 
        if (inputs?.hasOwnProperty("ratings")) {
            params = inputs?.ratings?.selectedFilterValue?.length ? `${params && params + "&"}` + inputs?.ratings?.selectedFilterValue.map(param => `ratings=${param?.server_value}`).join('&') : params
        }
        return params
    }

    const loadMoreData = async () => {
        isMoreLoading = true
        const apiUrl = `${getAddress()}/workboards/${selectedWorkboard?.workboard_id}/companies?cursor=${cursor?.cursor_id}&query_id=${cursor?.query_id}`;
        // Fetch data from the API
        axios.get(`${apiUrl}`, {
            headers: {
                "Authorization": localStorage.getItem('authData') ? "Bearer " + localStorage.getItem('authData') : null
            },
        })
            .then(async (newData) => {
                let { data } = newData
                if (data) {
                    const allRows = [...rowData, ...data?.results]
                    setRowData(allRows);
                    // handleCountUpdate(allRows?.length)
                    setCursor({
                        ...cursor,
                        cursor_id: data?.next_cursor,
                        query_id: data?.query_id
                    })
                    isMoreLoading = false
                } else {
                    // No more data to load
                    // You can handle this condition as needed
                }
            })
            .catch((error) => {
                console.error('Error loading data:', error);
            });
    };


    const onFilterChanged = (e) => {
        const filteredData = [];
        let anyFilterApplied = e.api.isAnyFilterPresent();
        e.api.forEachNodeAfterFilter((node) => {
            filteredData.push(node.data);
        });
        if(anyFilterApplied) {
            handleCountUpdate(filteredData?.length)
        }  else if(cursor?.cursor_id) {
            handleCountUpdate(myWorkboardsCompanies?.total_count)
        } else {
            handleCountUpdate(rowData?.length)
        }
    
        // const changedFilterModels = {};
        // let filterModel = {}
        // columnDefs.forEach((columnDef) => {
        //     const colId = columnDef.colId;
        //     const filterInstance = gridRef.current.api.getFilterInstance(colId);
        //     if (filterInstance) {
        //         filterModel = filterInstance.getModelFromUi();
        //         if (filterModel && Object.keys(filterModel)?.length) {
        //             changedFilterModels[colId] = filterModel;
        //             // if(!(changedFilterModels && Object.keys(changedFilterModels)?.length && changedFilterModels?.hasOwnProperty('tp_owners'))) {
        //             //     gridRef.current.api.setFilterModel({
        //             //         [colId]: filterModel,
        //             //     });
        //             // }
        //         }
        //     }
        // });
        
    }

    const onFilterNameChanged = (e) => {
        const searchText = e.target.value.toLowerCase();
        const filteredData = rowData.filter((row) =>
            row.full_name.toLowerCase().includes(searchText)
        );
        gridRef.current.api.setRowData(filteredData);
        handleCountUpdate(filteredData?.length)
    }

    useEffect(() => {
        // Create a new cancel token source for each effect
        const cancelSource = axios.CancelToken.source();
        setCancelToken(cancelSource);

        const debounceTimer = setTimeout(() => {
            setDebouncedSearchTerm(searchTerm);
        }, 300);
        return () => {
            clearTimeout(debounceTimer);
            cancelSource.cancel('Request canceled by the user.');
        };
    }, [searchTerm]);

    useEffect(() => {
        setFirstRender(true)
        if (firstRender) {
            let params = allAppliedFilters && Object.keys(allAppliedFilters)?.length ? getCompanyParams({...allAppliedFilters}) : ""
            if (debouncedSearchTerm) {
                const apiUrl = params ? `${getAddress()}/workboards/${selectedWorkboard?.workboard_id}/companies${params ? '?' + params : ''}` :`${getAddress()}/workboards/${selectedWorkboard?.workboard_id}/companies?search=${debouncedSearchTerm}`;
                getCompanySearchResults(apiUrl)
            } else {
                setIsSearching(true)
                const apiUrl = `${getAddress()}/workboards/${selectedWorkboard?.workboard_id}/companies${params ? '?' + params : ''}`;
                getCompanySearchResults(apiUrl)
            }
        }
    }, [debouncedSearchTerm]);

    const getCompanySearchResults = (url) => {
        setIsSearching(true)
        axios.get(`${url}`, {
            cancelToken: cancelToken.token,
            headers: {
                "Authorization": localStorage.getItem('authData') ? "Bearer " + localStorage.getItem('authData') : null
            },
        })
            .then(async (newData) => {
                setIsSearching(false)
                let { data } = newData
                if (data) {
                    const allRows = [...data?.results]
                    setRowData(allRows);
                    handleCountUpdate(data?.total_count)
                    setCursor({
                        ...cursor,
                        cursor_id: data?.next_cursor,
                        query_id: data?.query_id
                    })
                    isMoreLoading = false
                } else {
                    
                }
            })
            .catch((error) => {
                setIsSearching(false)
                console.error('Error loading data:', error);
            });
    }
    const onBodyScrollEndFn = (event) => {
        const gridApi = event.api;
        const elementBody = document.querySelector("#workboard-table .ag-body-viewport");
        const scrollHeight = elementBody.scrollHeight;
        const scrollPercentage = (elementBody.scrollTop + elementBody.offsetHeight) / scrollHeight;  
        const isQuickFilterActive = gridApi.isQuickFilterPresent();      
        if (event.direction === 'vertical' && scrollPercentage >= .75 && (selectedWorkboard?.category?.toLowerCase() === "companies") && cursor?.cursor_id && !isQuickFilterActive) {
            if (isMoreLoading) {
                return
            }
            loadMoreData(gridApi)
        }
    }

    const handleRowClick = (props) => { 

        if((selectedWorkboard?.category?.toLowerCase() === "companies")) {
            let newArr = [...viewAllCountries?.selectedIndex]
            if(newArr?.length) {
                const newFilteredArray =  newArr.filter(item => item !== props?.rowIndex);
                setViewAllCountries({ ...viewAllCountries, selectedIndex: [...newFilteredArray] })
            }
            
            let newArrTPOwner = [...viewAllOwner?.selectedOwnerIndex]; 
            if(newArrTPOwner?.length) {
                const newFilteredTPOwnerArray = newArrTPOwner.filter(item => item !== props?.rowIndex);
                setViewAllOwner({ ...viewAllOwner, selectedOwnerIndex: [...newFilteredTPOwnerArray]})
            }
            
        } else {
            const newArrFunction = [...viewAllFunction?.selectedIndex]
            if(newArrFunction?.length) {
                const newFilteredArray =  newArrFunction.filter(item => item !== props?.rowIndex);
                setViewAllFunction({ ...viewAllFunction, selectedIndex: [...newFilteredArray] })
            }
            
            const newArrProject = [...viewAllProject?.selectedIndex]; 
            if(newArrProject?.length) {
                const newFilteredArray = newArrProject.filter(item => item !== props?.rowIndex);
                setViewAllProject({ ...viewAllProject, selectedIndex: [...newFilteredArray]})
            }

            const newArrRef = [...viewAllReference?.selectedIndex]; 
            if(newArrRef?.length) {
                const newFilteredArray = newArrRef.filter(item => item !== props?.rowIndex);
                setViewAllReference({ ...viewAllReference, selectedIndex: [...newFilteredArray]})
            }
        }
    }

    return (
        <div style={containerStyle} className="ag-grid-box-ref" id={`workboard-table`}>
            <div className='top-section-workboard d-flex align-items-center justify-content-end'>
                <div className="search-box-workboard">
                    {(selectedWorkboard?.category?.toLowerCase() === "companies") ? <input id="filter-text-box" type="text" className="search-input-workboard" placeholder="Quick Search..." onChange={(e)=> {setSearch(e.target.value); searchText= e.target.value;}} autoComplete='off'/> : <input id="filter-text-box" type="text" className="search-input-workboard" placeholder="Quick Search..." onChange={onFilterNameChanged} autoComplete='off'/>}
                    {isSearching ? <img className="search-icon" src={loaderSpinner} alt="Search" />  : <img className="search-icon" src={search} alt="Search" /> }
                </div>
            </div>
            <div className="ag-theme-alpine position-relative" style={{ textTransform: "capitalize" }}>
                <AgGridReact
                    className={`custom-ag-grid ${rowData?.length < 12 ? "smallerHeight" : ""}`}
                    onGridReady={onGridReady}
                    ref={gridRef}
                    rowData={rowData}
                    columnDefs={columnDefs}
                    defaultColDef={defaultColDef}
                    onRowClicked={handleRowClick}
                    // onToolPanelVisibleChanged={handleVisibleChange}
                    sideBar={rowData?.length ? sideBar : null}
                    rowHeight={58}
                    suppressScrollOnNewData={true}
                    onFilterChanged={(e) => onFilterChanged(e)}
                    onBodyScroll={onBodyScrollEndFn}
                    enableAdvancedFilter={true}
                    defaultExcelExportParams={defaultExcelExportParams}
                    defaultCsvExportParams={defaultExcelExportParams}
                    processCellForClipboard={processCellForClipboard}
                    // onColumnVisible={handleColumnVisibilityChange}
                >
                </AgGridReact>
            </div>
        </div>
    );
};

export { WorkboardTable };
