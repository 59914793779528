import axios from 'axios';
import { getAuthToken } from '../../utils';
import * as actionTypes from '../actionTypes';

export const getInvestorReminders = (axiosInstance) => (dispatch) => {
    return axiosInstance({
      method: "GET",
      data: {},
      params: {},
      headers: {
        "Authorization": getAuthToken()
      }
    })
    .then((res) => {
      dispatch({ type: actionTypes.SET_INVESTOR_REMINDERS, data: res?.data });
      return { status: 'success', data: res?.data?.results};
    })
    .catch((err) => {
      console.warn('Response - error -> ', err?.response?.data);
      if (err?.response?.data?.hasOwnProperty('code') && err?.response?.data?.code === 422) {
        return { status: 'failed', errMsg: err?.response?.data?.name };
      } else {
        return { status: 'failed' };
      }
    });
};

export const getInvestorReminderStatus = (axiosInstance, payload) => (dispatch) => {
  return axiosInstance({
    method: "POST",
    data: { ...payload },
    headers: {
      Authorization: getAuthToken()
    },
  })
  .then((res) => {
    dispatch(setInvestorReminderStatus(res.data));

    if (res?.data?.hasOwnProperty("error") || res?.data?.result === "failure") {
      return { status: "failed", message: res.data.error };
    } else {
      return { status: "success", message: res.data.message };
    }
  })
  .catch((err) => {

    dispatch(setInvestorReminderStatus({ error: true }));
    return { status: "failed", message: "Something went wrong, Please try again later." };
  });
};

const setInvestorReminderStatus = (data) => ({
  type: actionTypes.SET_INVESTOR_REMINDER_STATUS,
  data,
});

export const setUrlReminderParams = (source, status) => ({
  type: actionTypes.SET_URL_REMINDER_PARAMS,
  data: { source, status }, 
});

