import React, { useMemo, useRef, useState, useEffect, useCallback } from 'react';
import { AgGridReact } from 'ag-grid-react';
import { useDispatch, useSelector } from 'react-redux';
import { OverlayTrigger, Button, Popover, Form } from 'react-bootstrap';
import { Typeahead } from 'react-bootstrap-typeahead';

import moment from "moment";

import "./notesTable.scss"

const NotesTable = ({ tableData, filters, handleRowClick, handleAssciateTrigger }) => {
  const gridRef = useRef(null);
  const [rowData, setrowData] = useState([]);

  const formatDateString = (props) => {
    const cellValue = props.valueFormatted ? props.valueFormatted : props.value;
    const formatDate =
      cellValue && cellValue !== "n/a"
        ? `${moment.utc(cellValue).format("MMM DD, YYYY")}`
        : null;
    const date = cellValue && cellValue !== "n/a" ? moment(cellValue) : null;
    const daysAgo = cellValue ? moment().diff(date, "days") : null;

    return (
      <span>
        {formatDate ? (
          <div>
            <div className="formattedDate" style={{ height: daysAgo ? "20px" : "25px" }}>
              {formatDate}
            </div>
            {daysAgo ? (
              <div className="blueItalic">
                <i>{daysAgo} {daysAgo === 1 ? "day" : "days"} ago</i>
              </div>
            ) : null}
          </div>
        ) : (
          <div className="text-center">-</div>
        )}
      </span>
    );
  };

  const [columnDefs, setcolumnDefs] = useState([
    { headerName: 'Meeting Name', field: 'meeting_title', suppressMovable: true, filter: "agTextColumnFilter", minWidth: 340, cellClass: 'meeting-name-cell' },
    { headerName: 'Date of Meeting', field: 'meeting_start_time', cellRenderer: formatDateString, filter: null, },
    { headerName: 'Creator Email', field: 'scoper', filter: "agSetColumnFilter", },
    { headerName: 'No. of Attendees', field: 'attendee_count', filter: "agNumberColumnFilter", },
    { headerName: 'No. of Companies', field: 'company_count', filter: "agNumberColumnFilter", },
  ]);

  const defaultColDef = useMemo(() => {
    return {
      flex: 1,
      minWidth: 140,
      sortable: true,
      suppressMenu: false,
      autoHeaderHeight: true,
      unSortIcon: true,
      //   filterParams: { buttons: ["reset"] },
      //   menuTabs: ["filterMenuTab", "columnsMenuTab"],
      resizable: true,
      filter: true,
    };
  }, []);

  useEffect(() => {
    setrowData(tableData?.results);
  }, [tableData]);

  const filteredRowData = useMemo(() => {
    if (!filters.searchTerm) return rowData;

    return rowData.filter(row =>
      row.meeting_title?.toLowerCase().includes(filters.searchTerm.toLowerCase())
    );
  }, [rowData, filters.searchTerm]);

  return (
    <div className='notes-table'>
      <div className="ag-theme-alpine">
        <AgGridReact
          ref={gridRef}
          className={`custom-ag-grid ${rowData?.length < 12 ? "smallerHeight" : ""
            }`}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          rowData={filteredRowData}
          rowHeight={60}
          // sideBar={rowData?.length ? sideBar : null}
          onRowClicked={(e) => {handleRowClick(e.data)}}
          onGridReady={(params) => {
            gridRef.current = params.api;
          }}
        />
      </div>
      <div className='notes-table-pagination'>
      </div>
    </div>
  );
}

export default NotesTable;