import axios from "axios";
import * as actionTypes from "../actionTypes";
import { history } from "../../config";
import * as amplitude from '@amplitude/analytics-browser';

const host = window.location.protocol + "//" + window.location.host;
let redirectURL = window.location.protocol + "//" + window.location.host + "/login";

export const checkAuth = (currentPath) => (dispatch) => {
  const authData = localStorage.getItem("authData");
  if (authData) {
    dispatch(loginAction(authData));
  } else {
    history.push("/login");
  }
};

export const checkRefreshToken = () => (dispatch) => {
  const authData = localStorage.getItem("authData");
  if (authData) {
    const tokenProvidedTime = localStorage.getItem("token_Provided_Time");
    const expiresIn = localStorage.getItem("expires_in") * 1000;
    const expireTime = Number(tokenProvidedTime) + Number(expiresIn);
    const currentTime = new Date().getTime();
    const remainder = expireTime - currentTime;
    if (remainder <= 10*60*1000) {
      dispatch(refreshTokenFn());
      dispatch({type:actionTypes.SET_TOKEN_REFRESHED, data: true });
    } else{
      dispatch({type:actionTypes.SET_TOKEN_REFRESHED, data: false });
    }
  }
};

export const checkAutoRefreshToken = () => (dispatch) => {
  const authData = localStorage.getItem("authData");
  if (authData) {
    const tokenProvidedTime = localStorage.getItem("token_Provided_Time");
    const expiresIn = localStorage.getItem("expires_in") * 1000;
    const expireTime = Number(tokenProvidedTime) + Number(expiresIn);
    const currentTime = new Date().getTime();
    const remainder = expireTime - currentTime;
    if (remainder <=  10*60*1000) {
      dispatch(refreshTokenFn());
    }
  }
};

const loginAction = (data) => ({
  type: actionTypes.SET_LOGIN,
  data,
});

export const initializeLogout = () => (dispatch) => {
  dispatch(logoutAction());
  dispatch({type:actionTypes.IS_REFRESH_TOKEN_VALID, data: true });
};
const logoutAction = () => ({
  type: actionTypes.SET_LOGOUT,
});

 const refreshTokenFn = () => (dispatch) => {
  const refreshToken = localStorage.getItem("refresh_token");
  const params = new URLSearchParams();
  params.append("grant_type", "refresh_token");
  params.append("refresh_token", refreshToken);
  params.append("client_id", "4lq9qj2uf363q0d1vav5nrdg1h");
  params.append(
    "client_secret",
    "15cvcvnh7j2joe159tdfu1uoeqc344vudqis6liv5rhivsmrhg8"
  );
  axios
    .post(
      `https://orion-portal.auth.us-east-1.amazoncognito.com/oauth2/token`,
      params
    )
    .then((res) => {
      localStorage.setItem("authData", res.data.id_token);
      localStorage.setItem("accessToken", res.data.access_token);
      localStorage.setItem("token_Provided_Time", new Date().getTime());
      localStorage.setItem("expires_in", Number(res.data.expires_in));
      dispatch(loginAction(res.data.id_token));
      dispatch({type:actionTypes.SET_TOKEN_REFRESHED, data: false });
    })
    .catch((error) => {
      console.error('Error refreshing token:', error);
      dispatch(initializeLogout());
    });
};

export const getAccessTokenReq = (code) => (dispatch) => {
  const params = new URLSearchParams();
  params.append("grant_type", "authorization_code");
  params.append("code", `${code}`);
  params.append("redirect_uri", `${redirectURL}`);
  params.append("client_id", "4lq9qj2uf363q0d1vav5nrdg1h");
  params.append(
    "client_secret",
    "15cvcvnh7j2joe159tdfu1uoeqc344vudqis6liv5rhivsmrhg8"
  );
  return axios
    .post(
      `https://orion-portal.auth.us-east-1.amazoncognito.com/oauth2/token`,
      params
    )
    .then((res) => {
      localStorage.setItem("authData", res.data.id_token);
      localStorage.setItem("accessToken", res.data.access_token);
      localStorage.setItem("refresh_token", res.data.refresh_token);
      localStorage.setItem("token_Provided_Time", new Date().getTime());
      localStorage.setItem("expires_in", Number(res.data.expires_in));
      dispatch(loginAction(res.data.id_token));
      return { status: "success" };
    })
    .catch((error) => {
      console.error('Error logging in:', error);
      return { status: "error", error };
    });
};