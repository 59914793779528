import React, { useEffect, useState, useRef } from 'react';
import { Button, Row, Col, Popover, OverlayTrigger, Form, Offcanvas } from 'react-bootstrap';
import { useDispatch, useSelector } from "react-redux";
import * as actionTypes from '../../../store/actionTypes';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { Menu, MenuItem, Typeahead } from 'react-bootstrap-typeahead';
import axios from 'axios';
import { getNotesDetails, getFunction, postAssociateFathomNote } from '../../../store/actions';
import { axiosGetNotesDetails, axiosGetFunction, axiosPostAssociateNotes } from '../../../config/appConfig/axiosInstance';
import PersonEditModal from '../PersonEditModal/PersonEditModal';
import CommonError from '../../../commonComponents/CommonError/CommonError';

import { ReactComponent as BackIcon } from "../../../assets/images/icon_svg/arrow_back.svg";
import { ReactComponent as WebIcon } from "../../../assets/images/icon_svg/url.svg";
import { ReactComponent as LinkedInIcon } from "../../../assets/images/icon_svg/linkedin.svg";
import { ReactComponent as AffinityIcon } from "../../../assets/images/icon_svg/affinity_new_logo.svg";
import { ReactComponent as UpArrowIcon} from '../../../assets/images/icon_svg/growthUp.svg';
import { ReactComponent as DownArrowIcon} from '../../../assets/images/icon_svg/growthDown.svg';
import { ReactComponent as RightArrowIcon} from '../../../assets/images/icon_svg/growthRight.svg';
import { ReactComponent as ViewDetailsIcon} from '../../../assets/images/icon_svg/view-arrow.svg';
import spinnerLoader from "../../../assets/images/spinner.gif";
import Slider from '../../Discovery/Slider/Slider';
import SubmitCard from "../../../commonComponents/SubmitCard/SubmitCard";

import './noteDetails.scss'

const NotesDetails = ({ onHide }) => {
    const containerRef = useRef(null);
    const dispatch = useDispatch();
    const { id } = useParams();
    const [noteDetails, refDropdown] = useSelector(({ notesReducer, referenceReducer }) => [notesReducer.details, referenceReducer.refDropdown]);
    const [isExpanded, setIsExpanded] = useState(false);
    const [isDescriptionExpanded, setIsDescriptionExpanded] = useState({});
    const [isCompanyExpanded, setIsCompanyExpanded] = useState(false);
    const [fanthomDetails, setFanthomDetails] = useState({});

    const [personInvolved, setPersonInvolved] = useState({
        modal: false,
        content: {},
    })
    const [toast, setToast] = useState({
        message: '',
        isSuccess: null,
        show: false
    });

    const [isLoading, setIsLoading] = useState(false);
    const [loading, setLoading] = useState({
        isloadingPage: false,
        openModal: false,
        isSuccess: null,
        message: "",
    });
    const [isSliderOpen, setSliderOpen] = useState(false);
    const [sliderContent, setSliderContent] = useState(null);
    const [selectedCompany, setSelectedCompany] = useState(null);
    const [showPopover, setShowPopover] = useState(false);
    const [error, setError] = useState("");
    const [isAssociated, setIsAssociated] = useState(false);
    const [dropdownList, setDropdownList] = useState({
        companyOptions: [],
        personsOptions: [],
        isAssociatedOptions: [],
    });
    const [selectedRadio, setSelectedRadio] = useState(""); 
    const [typeaheadOptions, setTypeaheadOptions] = useState([]);
    const [isNoteSaved, setIsNoteSaved] = useState(false); 
    const [redirectUrl, setRedirectUrl] = useState("");
    const [isActionLoading, setIsActionLoading] = useState(false);

    useEffect(() => {
        setIsLoading(true);
        dispatch(getNotesDetails(axiosGetNotesDetails, id))
        .then((res) => {
            setIsLoading(false);
            if (res.status === 'failed') {
                setError(res.errMsg || "Failed to fetch notes details.");
            }
        })
        .catch((err) => {
            setIsLoading(false);
            setError(err.errMsg || "Failed to fetch notes details.");
        });
        if(!(refDropdown && Object.keys(refDropdown)?.length)) dispatch(getFunction(axiosGetFunction));
        return () => {
            dispatch({ type: actionTypes.RESET_NOTES_DETAILS });
        };
    }, [id]);

    useEffect(() => {
        if (noteDetails) {
            setFanthomDetails(noteDetails);
        }
    }, [noteDetails]);

    useEffect(() => {
        if(fanthomDetails && Object.keys(fanthomDetails)?.length) {
            let companies = fanthomDetails?.meeting_base?.domain_details?.map((company) => {
                return {
                    name: company.company_name,
                    external_id: Number(company.external_id),
                    type: "companies",
                }
            }).filter((company) => company.name && company.external_id);
 
            let persons = fanthomDetails?.meeting_base?.attendee_details?.map((person) => {
                    return {
                        name: person?.name,
                        external_id: Number(person?.external_id),
                        type: "persons",
                    };
                }) .filter((person) => person.name && person.external_id);
            let isAssociated = [...companies, ...persons];
 
            setDropdownList({
                ...dropdownList,
                companyOptions: [...companies],
                personsOptions: [...persons],
                isAssociatedOptions: [...isAssociated],
            })
        }
 
    }, [fanthomDetails]);

    useEffect(() => {
        if (selectedRadio === 'company') {
            setTypeaheadOptions(dropdownList.companyOptions);
        } else if (selectedRadio === 'attendee') {
            setTypeaheadOptions(dropdownList.personsOptions);
        } else if (selectedRadio === 'associated') {
            setTypeaheadOptions(dropdownList.isAssociatedOptions);
        }
    }, [selectedRadio, dropdownList]);

    useEffect(() => {
        if (noteDetails) {
            setIsAssociated(noteDetails?.isAssociated);
        }
    }, [noteDetails]);

    // Function to handle the "View More" click
    const handleToggleView = () => {
        setIsExpanded(prev => !prev);
    };

    const handleDescriptionToggleView = (index) => {
        setIsDescriptionExpanded(prev => ({
            ...prev,
            [index]: !prev[index]
        }));
    };

    const handleCompanyToggleView = () => {
        setIsCompanyExpanded(prev => !prev);
    };

    const togglePopover = () => setShowPopover(!showPopover);

    const closePopover = () => setShowPopover(false);

    const handleRadioChange = (e) => {
        setSelectedRadio(e.target.id);
        setSelectedCompany([]);
    };

    const handleSave = () => {
        setIsActionLoading(true);
        setLoading({ ...loading, isloadingPage: true })
        if (!selectedCompany) {
            console.warn("No option selected.");
            return;
        }
    
        const company = selectedCompany[0];

        const payload = {
            external_id: company.external_id,
            external_type: company.type,
            fathom_id: Number(fanthomDetails.meeting_base.fathom_id)
        };

        dispatch(postAssociateFathomNote(axiosPostAssociateNotes, payload))
            .then((response) => {
                if (response.status === 'success') {
                    setLoading({ ...loading, isloadingPage: false, openModal: true, message: "Data Saved Successfully", isSuccess: true })
                    setIsNoteSaved(true);
                    setIsActionLoading(false);
                    setRedirectUrl(response.data?.hyperlink);
                } else {
                    setLoading({ ...loading, isloadingPage: false, openModal: true, message: "Error Saving Data", isSuccess: false })
                    setIsNoteSaved(false);
                    setIsActionLoading(false);
                }
            });
    };

    const popover = (
        <Popover id="associate-popover-notes" className="associate-popover-notes">
            <Popover.Body className="associate-popover-body">
                <div className="sub-title"><b>Associate notes to:</b></div>
                <div className="d-flex mb-3">
                    <Form.Check
                        type="radio"
                        id="company"
                        label="Company"
                        className="marg-between"
                        checked={selectedRadio === "company"}
                        onChange={handleRadioChange}
                        disabled={dropdownList?.companyOptions?.length === 0}
                    />
                    <Form.Check
                        type="radio"
                        id="attendee"
                        label="Attendee"
                        className="marg-between"
                        checked={selectedRadio === "attendee"}
                        onChange={handleRadioChange}
                        disabled={dropdownList?.personsOptions?.length === 0}
                    />
                    <Form.Check
                        type="radio"
                        id="associated"
                        label="Already Associated"
                        className="marg-between"
                        checked={selectedRadio === "associated"}
                        onChange={handleRadioChange}
                        disabled={dropdownList?.isAssociatedOptions?.length === 0}
                    />
                </div>
                <div className="select-company-container">
                    <label htmlFor="company-select" className="required-field mb-1">
                        Select {selectedRadio === 'company' ? "Company" : selectedRadio === 'attendee' ? "Attendee" : selectedRadio === 'associated' ? "Already Associated" : "Option"}
                        <span className="select-a-company">*</span>
                    </label>
                    <Typeahead
                    id="company-select"
                    options={typeaheadOptions}
                    labelKey="name"
                    selected={selectedCompany}
                    onChange={setSelectedCompany}
                    placeholder={`Select a ${selectedRadio === 'company' ? "company" : selectedRadio === 'attendee' ? "attendee" : "option"}`}
                    disabled={!selectedRadio}
                    renderMenu={(results, menuProps) => {
                        const companies = results.filter(option => option.type === 'companies');
                        const people = results.filter(option => option.type === 'persons');
                        return (
                            <div {...menuProps} className='typeahead-dropdown'>
                            {companies.length > 0 && (
                            <>
                                <div className="category-heading">Company</div>
                                {companies.map(company => (
                                <div key={company.external_id} className="option-item" onClick={() => {
                                    setSelectedCompany([company]);
                                    document.querySelector('.associate-popover-body').dispatchEvent(new MouseEvent("click")); // Example manual closing
                                }}>
                                    {company.name}
                                </div>
                                ))}
                            </>
                            )}
                            {people.length > 0 && (
                            <>
                                <div className="category-heading">People</div>
                                {people.map(person => (
                                <div key={person.external_id} className="option-item" onClick={() => {
                                    setSelectedCompany([person]);
                                    document.querySelector('.associate-popover-body').dispatchEvent(new MouseEvent("click")); // Example manual closing
                                }}>
                                    {person.name}
                                </div>
                                ))}
                            </>
                            )}
                            </div>
                        );
                    }}
                    filterBy={() => true}
                    />
                </div>
                <div className="lightDivider mb-2 mt-4"></div>
                <div className="associate-button-container">
                    <span className="cancel-button" onClick={closePopover}>Cancel</span>
                    <span className="save-button" onClick={() => {closePopover(); handleSave();}}>Save</span>
                </div>
            </Popover.Body>
        </Popover>
    );

    const handleViewDetailsClick = (company) => {
        setSliderContent(company.website);
        setSliderOpen(true);
    };

    const handleCloseSlider = () => {
        setSliderOpen(false);
    };

    // Helper function to format the meeting date and time
    function formatMeetingDateTime(originalTimestamp) {
        const date = new Date(originalTimestamp);
        // Format the day with suffix (st, nd, rd, th)
        const day = date.getDate();
        const dayWithSuffix = day + getDaySuffix(day);

        // Format the date as "Oct 2024"
        const formattedDate = date.toLocaleDateString("en-GB", { month: "short", year: "numeric" });

        // Format time in 12-hour format with "am/pm"
        const formattedTime = date.toLocaleTimeString("en-US", {
            hour: "2-digit",
            minute: "2-digit",
            hour12: true
        });

        return `${dayWithSuffix} ${formattedDate} • ${formattedTime}`;
    }

    function getDaySuffix(day) {
        if (day > 3 && day < 21) return "th";
        switch (day % 10) {
            case 1: return "st";
            case 2: return "nd";
            case 3: return "rd";
            default: return "th";
        }
    }

    const truncateDescription = (description) => {
        if (!description) return '';
        return description.length > 150 ? `${description.slice(0, 60)}...` : description;
    };

    const handleLogoClick = (url) => {
        const fullUrl = url.startsWith('http://') || url.startsWith('https://') ? url : `https://www.${url}`;
        window.open(fullUrl, '_blank');
    };

    const handlePersonEditClick = (person) => () => {
        setPersonInvolved({
            ...personInvolved,
            modal: true,
            content: person,
        })
    }

    const GrowthIcon = ({ value }) => {
        if (value > 0) return <UpArrowIcon />;
        if (value < 0) return <DownArrowIcon />;
        return <RightArrowIcon />;
    };
    
    const CompanyGrowth = ({ growth_1m, growth_6m, growth_1y, growth_2y }) => {
        // Check if there's at least one valid growth value to display the section
        const hasValidGrowthData = [growth_1m, growth_6m, growth_1y, growth_2y].some(value => value !== null && value !== 'n/a');
    
        if (!hasValidGrowthData) return null; // Hide the section if no valid data
    
        const growthData = [
            { label: '1 Month', value: growth_1m },
            { label: '6 Months', value: growth_6m },
            { label: '1 Year', value: growth_1y },
            { label: '2 Years', value: growth_2y },
        ];
    
        return (
            <div className='growth-sec d-flex flex-wrap justify-content-between mb-3'>
                {growthData.map(({ label, value }, index) => (
                    <span key={index} className='growth-box d-inline-block'>
                        <div className='growth-value d-flex align-items-center justify-content-center'>
                            <span className='growth-icon'>
                                <GrowthIcon value={value} />
                            </span>
                            <span>
                                <b className='growth-val'>
                                    {value !== null && value !== 'n/a' ? `${value}%` : 'N/A'}
                                </b>
                            </span>
                        </div>
                        <div className='growth-text'>{label}</div>
                    </span>
                ))}
            </div>
        );
    };
    const mapRatings = (rating) => {
        // Ensure ratings are available in refDropdown
        const ratings = refDropdown?.ratings?.length ?  refDropdown?.ratings :  [];
    
        // Find the rating object that matches the server_value
        const matchedRating = ratings?.find(item => item.server_value === rating);
    
        // Return the display_name if found, otherwise return "No Ratings"
        return matchedRating ? matchedRating?.display_name : "No Ratings";
    }

    const handleSuccessCallback = (isSuccess, message) => {
        setToast({
            show: true,
            isSuccess,
            message,
        })
    }

    useEffect(() => {
        if (containerRef.current) {            
          // Select all h1, h2, tags and apply font size
          containerRef.current.querySelectorAll('h1, h2').forEach((element) => {
            element.style.fontSize = '20px';
          });
          // Select all h3, h4, tags and apply font size
          containerRef.current.querySelectorAll('h3, h4').forEach((element) => {
            element.style.fontSize = '18px';
          });
          // Select all h5, h6, tags and apply font size
          containerRef.current.querySelectorAll('h5, h6').forEach((element) => {
            element.style.fontSize = '16px';
          });
        }
      }, [fanthomDetails?.meeting_base?.html_summary]);
      
    const handleAffinityClick = (e) => {
        if (redirectUrl) {
            e.stopPropagation();
            window.open(redirectUrl, '_blank');
        }
    };

    const handleContinue = () => {
        setLoading({
            isloadingPage: false,
            openModal: false,
            isSuccess: null,
            message: "",
        });
     }

    return (
        <>  
            {toast?.show && <SubmitCard showToast={toast?.show} onHide={() => setToast({...toast, show: false, isSuccess: null, message: ""})} isSuccess={toast?.isSuccess} message={toast?.message} />}
            {personInvolved?.modal && <PersonEditModal show={personInvolved?.modal} onHide={() => setPersonInvolved({ ...personInvolved, modal: false, content:{}})} content={personInvolved?.content} fathomData={fanthomDetails} callbackFn={ handleSuccessCallback}/>}
            {
                isSliderOpen && (
                    <Slider
                        isOpen={isSliderOpen}
                        onClose={handleCloseSlider}
                        companyDomain={sliderContent}
                    />
                )
            }
            {isLoading ? (
                <div className="notes-details-loader">
                    <div className="d-flex justify-content-between mb-4">
                        <div>
                            <div className="loader-animation-block" style={{ height: '20px', width: '350px' }}></div>
                            <div className="loader-animation-block" style={{ height: '20px', width: '450px'}}></div>
                            <div className="loader-animation-block" style={{ height: '20px', width: '500px'}}></div>
                        </div>
                        <div className="loader-animation-block" style={{ height: '30px', width: '180px' }}></div>
                    </div>
                    
                    <div className="meeting-sec mb-4">
                        <div className="d-flex justify-content-between align-items-center mb-3">
                            <div className="loader-animation-block" style={{ height: '30px', width: '150px' }}></div>
                        </div>
                        <Row className="d-flex">
                            {Array.from({ length: 4 }).map((_, index) => (
                                <Col key={index} xs={12} sm={12} md={6} lg={3}>
                                    <div className="loader-animation-block" style={{ height: '220px' }}></div>
                                </Col>
                            ))}
                        </Row>
                    </div>

                    <div className="companies-sec mb-4">
                        <div className="d-flex justify-content-between align-items-center mb-3">
                            <div className="loader-animation-block" style={{ height: '30px', width: '150px' }}></div>
                        </div>
                        <Row className="d-flex">
                            {Array.from({ length: 4 }).map((_, index) => (
                                <Col key={index} xs={12} sm={12} md={6} lg={3}>
                                    <div className="loader-animation-block" style={{ height: '220px' }}></div>
                                </Col>
                            ))}
                        </Row>
                    </div>
                    <div className="notes-sec">
                        <div className="loader-animation-block" style={{ height: '500px', width: '100%' }}></div>
                    </div>
                </div>
            ) : error ? (
                <>
                <div className='notes-details-error'>
                    <div className='d-flex align-items-center mb-3'>
                        <div className='back-btn'>
                            <span className='back-icon' onClick={() => onHide()}><BackIcon /> <span className='lightGrey'>Back to fathom</span></span>
                        </div>
                    </div>
                    <CommonError errorMessage={error} isBlurredRequired={true} />
                </div>
                </>
            ) 
            : (<div className='notes-details-container'>
                <div className='d-flex align-items-center mb-3'>
                    <div className='back-btn'>
                        <span className='back-icon' onClick={() => onHide()}><BackIcon /> <span className='lightGrey'>Back to fathom</span></span>
                    </div>
                </div>

                <div className='notes-detail-header d-flex justify-content-between mb-4'>
                    <div className=''>
                        <div className='title'>{fanthomDetails?.meeting_base?.meeting_title} </div>
                        <div className='notesTime lightGrey mt-1 mb-1'>{formatMeetingDateTime(fanthomDetails?.meeting_base?.meeting_start_time)}</div>
                        <div className='scoper_email'> <span>Employee email :</span> <span className='lightGrey'>{fanthomDetails?.meeting_base?.scoper}</span></div>
                    </div>
                    <OverlayTrigger trigger="click" placement="auto" overlay={popover} show={showPopover} onToggle={togglePopover} rootClose onHide={closePopover}>
                    {isAssociated ? (
                        <Button variant="primary" className='associateBtn' onClick={togglePopover}>
                            Edit association
                        </Button>
                    ) : isNoteSaved ? (
                        <AffinityIcon style={{ marginLeft: "6px", height: "20px", width: "24px" }} onClick={(e) => handleAffinityClick(e)}/>
                    ) : (
                        <Button variant="primary" className='associateBtn' onClick={togglePopover}>
                            Associate note {isActionLoading && <img src={spinnerLoader} height='18px' width='18px' alt="loader" className="loader-icon" />}
                        </Button>
                    )}
                    </OverlayTrigger>
                </div>
                {noteDetails?.isAssociated && noteDetails?.associated_company && (
                    <div className='associated-company-message d-flex justify-content-center mb-2'>
                        <div className="associated-message">
                            <span className='associated-message-text'>The meeting notes is associated with</span> <b>“{noteDetails.associated_company}”</b>
                        </div>
                    </div>
                )}
                <div className='lightdivider mb-4'></div>
                {fanthomDetails?.people?.length > 0 && ( <div className='meeting-sec mb-4'>
                    <div className='d-flex justify-content-between align-items-center  mb-3'>
                        <div className='sub-title'><b>Meeting Attendees</b></div>
                        {fanthomDetails?.people?.length > 4 && (
                            <span className='viewMoreBtn' onClick={handleToggleView}>
                                {isExpanded ? "View Less" : "View More"}
                            </span>
                        )}
                    </div>
                    <div className='meeting-attendee-list'>
                        <div className='m-0 d-flex gap-3 justify-content-start flex-wrap'>
                            {(isExpanded && fanthomDetails ? fanthomDetails?.people : fanthomDetails?.people?.slice(0, 4))?.map((attendee, index) => (
                                <div key={index} className='cardContent mb-3' onClick={handlePersonEditClick(attendee)}>
                                    <div className='d-flex justify-content-between align-items-center'>
                                        <div className='card-wrapper'>
                                            <div className='emp-name'>{attendee.full_name}</div>
                                            <div className='emp-email lightGrey'>{attendee.email}</div>
                                        </div>
                                        {attendee.in_reference_contacts ? <div className='reference-contact'>
                                            <div>Reference Contact</div>
                                        </div> : null}
                                    </div>
                                    <div className='lightdivider mb-3 mt-2'></div>
                                    <div className='mb-3'>
                                        <div className='d-flex justify-content-between align-items-center flex-wrap mb-3'>
                                            <span className='meetingTitle'>Project</span>
                                            <span className='lightGrey'>{attendee?.projects?.length ? attendee?.projects?.toString() : "-"}</span>
                                        </div>
                                        <div className='d-flex justify-content-between align-items-center flex-wrap mb-3'>
                                            <span className='meetingTitle'>Rating</span>
                                            <span className='lightGrey'>{mapRatings(attendee?.rating) || "No Ratings"}</span>
                                        </div>
                                        <div className='d-flex justify-content-between align-items-center flex-wrap mb-3'>
                                            <span className='meetingTitle'>Function</span>
                                            <span className='lightGrey'>{attendee?.functions?.length ? attendee?.functions?.toString() : "NA"}</span>
                                        </div>
                                        <div className='d-flex justify-content-between align-items-center flex-wrap mb-3'>
                                            <span className='meetingTitle'>Reference Type(s)</span>
                                            <span className='lightGrey'>{attendee.type_of_reference?.length ? attendee.type_of_reference?.toString() : "NA"}</span>
                                        </div>
                                    </div>
                                    <div>
                                        <span className='viewBtn' onClick={handlePersonEditClick(attendee)}>Edit details </span>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>)}
                {fanthomDetails?.companies?.length > 0 && (
                    <div className='company-sec mb-4'>
                    <div className='d-flex justify-content-between align-items-center  mb-3'>
                        <div className='sub-title'><b>Companies Attended.</b></div>
                        {noteDetails?.companies?.length > 4 && (
                            <span className='viewMoreBtn' onClick={handleCompanyToggleView}>
                                {isCompanyExpanded ? "View Less" : "View More"}
                            </span>
                        )}
                    </div>
                    <div className='companies-attendee-lis'>
                        <div className='m-0 d-flex gap-3 justify-content-start flex-wrap'>
                            {(isCompanyExpanded ? noteDetails?.companies : noteDetails?.companies?.slice(0, 4))?.map((company, index) => (
                                <div className='cardContent' key={index}>
                                    <div className='company-header d-flex justify-content-between '>
                                        <div className='company-name'>
                                            <div className='company-header-name mb-2'>{company.name}</div>
                                            <div className='tp-stats-heading mb-2'><i>TP Status: </i><span className="tp-status-value">{company.tpStatus}</span></div>
                                            <div className='tp-stats-heading mb-2'><i>TP Owner: </i><span className="tp-owner-value">{company.tpOwner}</span></div>
                                        </div>
                                        <div className="company-logos">
                                            {company.website && (
                                                <span onClick={() => handleLogoClick(company.website)} ><WebIcon /></span>
                                            )}
                                            {company.affinity_url && (
                                                <span onClick={() => handleLogoClick(company.affinity_url)}><AffinityIcon style={{ marginLeft: "6px" }} /></span>
                                            )}
                                            {company.linkedin_url && (
                                                <span onClick={() => handleLogoClick(company.linkedin_url)} ><LinkedInIcon /></span>
                                            )}
                                        </div>
                                    </div>
                                    <div className="lightdivider mb-2"></div>
                                    <CompanyGrowth {...company} />
                                    <div className='d-flex justify-content-between align-items-center emp-stats'></div>
                                    <div className="desc mb-2">
                                        <span className="description-text">
                                            {isDescriptionExpanded[index] ? company.description : truncateDescription(company.description)}
                                            <span className="view-more-description" onClick={() => handleDescriptionToggleView(index)}>
                                                {isDescriptionExpanded[index] ? ' View Less' : 'View More'}
                                            </span>
                                        </span>
                                    </div>
                                    <div className='viewBtn' onClick={() => handleViewDetailsClick(company)}> View Details <ViewDetailsIcon/></div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                )}
                {fanthomDetails?.meeting_base?.html_summary &&  ( <div className='note-template mb-4'>
                    <div className='sub-title mb-2'><b>Notes</b></div>
                    <div className='note-content'>
                        <div className='template-container' ref={containerRef} dangerouslySetInnerHTML={{ __html: fanthomDetails?.meeting_base?.html_summary }} />
                    </div>
                </div>
                )}
                <Offcanvas.Body>
                    {loading?.openModal && <SubmitCard showToast={loading?.openModal} onHide={() => handleContinue()} isSuccess={loading?.isSuccess} message={loading?.message} />}
                </Offcanvas.Body>
            </div>
            )}
            
        </>
    );
}

export default NotesDetails;