import * as actionTypes from '../actionTypes'

const INITIAL_STATE = {
  template : {},
  fileMatrics : {},
  allTemplates: [],
  graphOption: {},
  tableData:[],
  moreCallData: [],
  summaryObj:{},
  draftList: [],
  draftDetail: {}
}

const submissionsReducer = function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case actionTypes.SET_FILE_MATRICS_TEMPLATE:
      return {
        ...state,
        template: action.data.data,
      }
    case actionTypes.RESET_FILE_MATRICS_TEMPLATE:
      return {
        ...state,
        template: {},
      }
    case actionTypes.SET_FILE_MATRICS:
      return {
        ...state,
        fileMatrics: action.data,
      }
    case actionTypes.SET_COOKIE_STORE:
      return {
        ...state,
        fileMatrics: action.data,
      }
    case actionTypes.SET_TEMPLATES:
      return {
        ...state,
        allTemplates: action.data,
      }
    case actionTypes.SET_GRAPH_OPTION:
      return {
        ...state,
        graphOption: {...state?.graphOption, ...action.data}
      };
    case actionTypes.SET_TABLE:
      return {
        ...state,
        tableData: action.data
      };
    case actionTypes.RESET_TABLE:
        return {
          ...state,
          tableData: []
        };
    case actionTypes.SET_MORE_CALL_DATA:
        return {
          ...state,
          moreCallData: [...state?.moreCallData, action.data]
        };
    case actionTypes.REMOVE_MORE_CALL_DATA:
            let filtered = state?.moreCallData?.filter(itm => {
              return itm?.uuid !== action.data
            })
          return {
            ...state,
            moreCallData: [...filtered]
          };
    case actionTypes.RESET_MORE_CALL_DATA:
        return {
          ...state,
          moreCallData: []
        };
    case actionTypes.UPDATE_MORE_CALL_DATA:
        return {
          ...state,
          moreCallData: [...action.data]
        };
    case actionTypes.RESET_CALLS:
        return {
          ...state,
          summaryObj: {...action.data}
        };
    case actionTypes.DRAFT_LIST:
        return {
          ...state,
          draftList: [...action.data]
        };
    case actionTypes.UPDATE_DRAFT_LIST:
      let filteredList = state?.draftList?.filter(i=>i?.draft_id !== action?.id)
          return {
            ...state,
            draftList: [...filteredList]
          };
    case actionTypes.DRAFT_DETAIL:
        return {
          ...state,
          draftDetail: {...action.data}
        };
    case actionTypes.RESET_DRAFT_DETAIL:
        return {
          ...state,
          draftDetail: {}
        };
    default:
      return {
        ...state,
      }
  }
}

export { submissionsReducer }
