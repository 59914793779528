import React, { useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as actionTypes from "../../../store/actionTypes";

import "./tpStatusFilter.scss"

let filters = {};

const TPStatusFilterComponent = forwardRef((props, ref) => {
    useImperativeHandle(ref, () => {
        return {
          doesFilterPass(params) {
            return true
          },
          isFilterActive() {
            return false;
          },
          // This example isn't using getModel() and setModel(),
          // so safe to just leave these empty. Don't do this in your code!!!
          getModel() {},
    
          setModel() {},
        };
      });

    const [columnValues, setColumnValues] = useState([]);
    const { filterParams } = props.colDef;
    const { callBackFunc, filterOptions, name } = filterParams;
    const [selectedFilterOptions, setSelectedFilterOptions] = useState('is');
    const [selectedFilterValue, setSelectedFilterValue] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [userSelectedCheckbox, setUserSelectedCheckbox] = useState(false);

    const dispatch = useDispatch()
    const [allAppliedFilters, companyTpStatus] = useSelector(({workboardReducer, companyProfileReducer}) => [workboardReducer?.allAppliedFilters, companyProfileReducer?.companyPageTpStatusOptions])

    useEffect(()=>{
        if(companyTpStatus?.data) {
            const uniqueArray = [...new Set(companyTpStatus?.data)];
            setColumnValues(uniqueArray);
        }
    }, [companyTpStatus])


    useEffect(() => {
        document.addEventListener('resetFiltersClicked', resetFilterAll);
        return () => {
            document.removeEventListener('resetFiltersClicked', resetFilterAll);
            filters ={};
        };
    }, []);

   useEffect(()=>{
    if(userSelectedCheckbox) {
        let finalObject = {...allAppliedFilters}
        filters = {
            type: `${name}`,
            selectedFilterOption: selectedFilterOptions,
            selectedFilterValue: selectedFilterValue
        }
        finalObject[`${name}`] = filters
        // callBackFunc(finalObject);
        dispatch({ type: actionTypes.SET_WORKBOARDS_FILTERS, data: {...finalObject}});
    }
   },[selectedFilterValue, selectedFilterOptions])


    const handleOption1Change = (event) => {
        setSelectedFilterOptions(event.target.value);
    };

    const handleCheckboxChange = (event) => {
        const checkboxValue = event.target.value;
        setSelectedFilterValue((prevSelectedCheckboxes) => {
            if (prevSelectedCheckboxes.includes(checkboxValue)) {
                return prevSelectedCheckboxes.filter((item) => item !== checkboxValue);
            } else {
                return [...prevSelectedCheckboxes, checkboxValue];
            }
        });
        setUserSelectedCheckbox(true)
    };

    const applyFilter = () => {
        let finalObject = {...allAppliedFilters}
        filters = {
            type: `${name}`,
            selectedFilterOption: null,
            selectedFilterValue: []
        }
        finalObject[`${name}`] = filters
        callBackFunc(finalObject);
        if(finalObject?.hasOwnProperty(`${name}`)) {
            delete finalObject?.[`${name}`];
        }
        dispatch({ type: actionTypes.SET_WORKBOARDS_FILTERS, data: {...finalObject}});
    };

    const resetFilterAll = () => {
        setSelectedFilterValue([]);
        setSelectedFilterOptions("is")
        setSearchTerm('')
        let finalObject = {}
        filters = {
            type: `${name}`,
            selectedFilterOption: null,
            selectedFilterValue: []
        }
        finalObject[`${name}`] = filters
        callBackFunc(finalObject);
        dispatch({ type: actionTypes.SET_WORKBOARDS_FILTERS, data: {}});
    };

    const resetFilter = () => {
        setSelectedFilterValue([]);
        setSelectedFilterOptions("is")
        setSearchTerm('')
        applyFilter();
    };

    const applyFn = () => {
        let finalObject = {...allAppliedFilters}
        filters = {
            type: `${name}`,
            selectedFilterOption: selectedFilterOptions,
            selectedFilterValue: selectedFilterValue
        }
        finalObject[`${name}`] = filters
        callBackFunc(finalObject);
        dispatch({ type: actionTypes.SET_WORKBOARDS_FILTERS, data: {...finalObject}});
    }

    return (
        <div className='custom-tp-filter'>
            <div className='select-option-box'>
                <select value={selectedFilterOptions} onChange={handleOption1Change} className='select-option'>
                    {filterOptions.map((option) => (
                        <option key={option} value={option}>
                            {option}
                        </option>
                    ))}
                </select>
            </div>
            <div className='divider'></div>
            <input
                className='ag-input-field-input ag-text-field-input mb-2 mt-2 inputStyle'
                type="text"
                placeholder="Search..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
            />
            <div className='checkbox-box'>
                {columnValues?.length ? columnValues?.filter((item) => item?.toLowerCase()?.includes(searchTerm?.toLowerCase()))?.map((value) => (
                    <label className='d-flex' key={value}>
                        <input  
                            className='checkbox-input'
                            type="checkbox"
                            value={value}
                            checked={selectedFilterValue.includes(value)}
                            onChange={handleCheckboxChange}
                        />{' '}
                        <span>{value}</span>
                    </label>
                )): `No Options Available`}
            </div>
            <div className='d-flex justify-content-end'>
                <button onClick={applyFn} className='ag-standard-button ag-filter-apply-panel-button'>Apply</button>
                <button onClick={resetFilter} className='ag-standard-button ag-filter-apply-panel-button'>Reset</button>
            </div>

        </div>
    );
});

export default TPStatusFilterComponent;