import React, { useState, useEffect } from "react";
import { Row, Col, Form } from "react-bootstrap";
import "../TagsInSlider/tagsSlider.scss";

const InputFields = ({ selectedTag, onChange, fieldName }) => {
  const [greaterThanValue, setGreaterThanValue] = useState("");
  const [lessThanValue, setLessThanValue] = useState("");

  useEffect(() => {
    const greaterThanTag = selectedTag.find((tag) => tag.operator === "greaterThan");
    const lessThanTag = selectedTag.find((tag) => tag.operator === "lessThan");

    if (!greaterThanTag) {
      setGreaterThanValue("");
    } else {
      setGreaterThanValue(greaterThanTag.value);
    }

    if (!lessThanTag) {
      setLessThanValue("");
    } else {
      setLessThanValue(lessThanTag.value);
    }
  }, [selectedTag]);

  const handleNumericChange = (e, operator) => {
    const value = e.target.value;
    if (/^\d*$/.test(value)) {
      if (operator === "greaterThan") {
        setGreaterThanValue(value);
        onChange({ greaterThan: value, lessThan: lessThanValue }, fieldName);
      } else if (operator === "lessThan") {
        setLessThanValue(value);
        onChange({ greaterThan: greaterThanValue, lessThan: value }, fieldName);
      }
    }
  };

  return (
    <div className="mb-2 tags-slider-container">
      <Row className="p-0">
        <Col
          xs={12}
          className="d-flex justify-content-center align-items-center mb-2"
        >
          <Form.Group className="mb-0">
            <Form.Control
              type="text"
              placeholder="Greater than"
              value={greaterThanValue}
              onChange={(e) => handleNumericChange(e, "greaterThan")}
            />
          </Form.Group>
          <span className="mx-2">-</span>
          <Form.Group className="mb-0">
            <Form.Control
              type="text"
              placeholder="Less than"
              value={lessThanValue}
              onChange={(e) => handleNumericChange(e, "lessThan")}
            />
          </Form.Group>
        </Col>
      </Row>
    </div>
  );
};

export default InputFields;
