import * as actionTypes from "../actionTypes";
import axios from 'axios';
import {getAuthToken} from "../../utils"

export const getCompanyProfileFavorite =
  (axiosInstance, data = {}) =>
    (dispatch) => {
      return axiosInstance({
        method: "POST",
        data: { ...data},
        headers: {
          Authorization: getAuthToken(),
        },
      })
        .then((res) => {
          dispatch(setCompanyProfileFav(res.data));
          return {status:"success"}
        })
        .catch((err) => {
          console.log("Company Updates response - error -> ", err);
          return { status: "failed" };
        });
    };

const setCompanyProfileFav = (data) => ({
  type: actionTypes.COMPANY_PROFILE_FAVORITE,
  data,
});
export const getCompanyProfileFileUploads =
  (axiosInstance, data) => (dispatch) => {
    return axiosInstance({
      method: "GET",
      params: { ...data },
      headers: {
        Authorization: getAuthToken(),
      },
    })
      .then((res) => {
        
        dispatch(setCompanyProfileFileUploads(res?.data));
        return { status: "success" };
      })
      .catch((err) => {
        console.log("Company File Uploads response - error -> ", err);
        return { status: "failed" };
      });
  };

const setCompanyProfileFileUploads = (data) => ({
  type: actionTypes.COMPANY_PROFILE_FILE_UPLOADS,
  data,
});

export const getCompanyProfileDocumentTypes =
  (axiosInstance, data = {}) =>
    (dispatch) => {
      return axiosInstance({
        method: "GET",
        params: { ...data },
        headers: {
          Authorization: getAuthToken(),
        },
      })
        .then((res) => {
          dispatch(setCompanyProfileDocumentTypes(res?.data));
        })
        .catch((err) => {
          console.log("Company document types response - error -> ", err);
          return { status: "failed", message:err?.response?.data?.errors};
        });
    };

const setCompanyProfileDocumentTypes = (data) => ({
  type: actionTypes.COMPANY_PROFILE_DOCUMENT_TYPES,
  data,
});

export const uploadFile =
  (axiosInstance, data = {}) =>
    (dispatch) => {
      return axiosInstance({
        method: "POST",
        data: data,
        headers: {
          Authorization: getAuthToken(),
        },
      })
        .then((res) => {
          dispatch(setFileUploaded(res?.data));
          return {status: "success", data: res?.data}
        })
        .catch((err) => {
          console.log("Company document types response - error -> ", err);
          return { status: "failed", message: typeof err?.response?.data?.errors === "string"? err?.response?.data?.errors : `Something went wrong!`};
        });
    };

const setFileUploaded = (data) => ({
  type: actionTypes.COMPANY_PROFILE_FILE_UPLOADED,
  data,
});

export const saveMetadata =
  (axiosInstance, data = {}) =>
    (dispatch) => {
      return axiosInstance({
        method: "POST",
        data: data,
        headers: {
          Authorization: getAuthToken(),
        },
      })
        .then((res) => {
          return {status: "success", }
        })
        .catch((err) => {
          console.log("Company document types response - error -> ", err?.response?.data?.errors);
          return { status: "failed", message: typeof err?.response?.data?.errors === "string"? err?.response?.data?.errors : `Something went wrong!`};
        });
    };

export const getCompanyAlerts =
  (axiosInstance, data = {}) =>
    (dispatch) => {
      return axiosInstance({
        method: "GET",
        params: { ...data },
        headers: {
          Authorization: getAuthToken(),
        },
      })
        .then((res) => {
          dispatch(setCompanyAlerts(res?.data?.response));
          return {status: "success"}
        })
        .catch((err) => {
          dispatch(setCompanyAlerts({ error: true }));
          console.log("Companyc Alerts response - error -> ", err);
          return { status: "failed" };
        });
    };

const setCompanyAlerts = (data) => ({
  type: actionTypes.COMPANY_PROFILE_ALERT,
  data,
});

export const getCompanyFavourite =
  (axiosInstance, data = {}) =>
    (dispatch) => {
      return axiosInstance({
        method: "GET",
        params: { ...data },
        headers: {
          Authorization: getAuthToken(),
        },
      })
        .then((res) => {
          dispatch(setCompanyFavourite(res?.data));
          dispatch({type: actionTypes.COMPANY_PROFILE_LOADING, data: false})
        })
        .catch((err) => {
          dispatch({type: actionTypes.COMPANY_PROFILE_LOADING, data: false})
          console.log("Companyc Alerts response - error -> ", err);
          return { status: "failed" };
        });
    };

const setCompanyFavourite = (data) => ({
  type: actionTypes.COMPANY_PROFILE_FAVOURITE,
  data,
});

export const saveCompanyAlert =
  (axiosInstance, data = {}) =>
    (dispatch) => {
      return axiosInstance({
        method: "POST",
        data: { ...data },
        headers: {
          Authorization: getAuthToken(),
        },
      })
        .then((res) => {
          return { status: "success" }
        })
        .catch((err) => {
          console.log("Company Updates response - error -> ", err);
          return { status: "failed", errorMessage: err?.response?.data?.message || "Error saving data!" }
        });
    };

  export const applyCompanyAlert =
  (axiosInstance, data = {}) =>
    (dispatch) => {
      return axiosInstance({
        method: "POST",
        data: { ...data },
        headers: {
          Authorization: getAuthToken(),
        },
      })
        .then((res) => {
          return { status: "success" }
        })
        .catch((err) => {
          console.log("Company Updates response - error -> ", err);
          return { status: "failed", errorMessage: err?.response?.data?.message || "Error saving data!" }
        });
    };

export const getCompanyPage = (axiosInstance,payload={})=>(dispatch) => {
  return axiosInstance({
    method:"GET",
    params:{...payload},
    headers:{
      Authorization: getAuthToken(),
    }
  })
  .then((res) => {
    dispatch(setCompanyPage(res.data))
    return {status:"success", data: res.data}
  })
  .catch((err) => {
    console.log("Companyc Alerts response - error -> ", err);
    return { status: "failed" };
  })
}

const setCompanyPage =(data) => ({
  type:actionTypes.COMPANY_PAGE_DETAILS,
  data
})

export const getCompanyStat = (axiosInstance,data={}) => (dispatch) => {
  return axiosInstance({
    method:"GET",
    params:{...data},
    headers:{
      Authorization: getAuthToken(),
    }
  })
  .then((res) => {
    dispatch(setCompanyStat(res.data))
    return {status:"success"}
  })
  .catch((err) => {
    console.log("Companyc Alerts response - error -> ", err);
    return { status: "failed" };
  })
}


const setCompanyStat = (data) => ({
  type:actionTypes.COMPANY_PAGE_MEETING_STAT,
  data
})

export const getTpStatusHistory = (axiosInstance,data={}) => (dispatch) => {
  return axiosInstance({
    method:"GET",
    params:{...data},
    headers:{
      Authorization: getAuthToken(),
    }
  })
  .then((res) => {
    dispatch(setTpStatusHistory(res.data))
    return {status:"success"}
  })
  .catch((err) => {
    console.log("Companyc Alerts response - error -> ", err);
    return { status: "failed" };
  })
}

const setTpStatusHistory = (data) => ({
  type:actionTypes.COMPANY_PAGE_TP_STATUS_HISTORY,
  data
})

export const getTpStatusOptions = (axiosInstance,data={}) => (dispatch) => {
  return axiosInstance({
    method:"GET",
    params:{...data},
    headers:{
      Authorization: getAuthToken(),
    }
  })
  .then((res) => {
    dispatch(setTpStatusOptions(res.data))
    return {status:"success"}
  })
  .catch((err) => {
    console.log("Companyc Alerts response - error -> ", err);
    return { status: "failed" };
  })
}
const setTpStatusOptions = (data) => ({
  type:actionTypes.COMPANY_PAGE_TP_STATUS_OPTIONS,
  data
})

export const getPipelineTpOwner = (axiosInstance,data={}) => (dispatch) => {
  return axiosInstance({
    method:"GET",
    params:{...data},
    headers:{
      Authorization: getAuthToken(),
    }
  })
  .then((res) => {
    dispatch(setPipelineTpOwner(res.data))
    return {status:"success"}
  })
  .catch((err) => {
    console.log("Companyc Alerts response - error -> ", err);
    return { status: "failed" };
  })
}

const setPipelineTpOwner = (data) => ({
  type:actionTypes.COMPANY_PAGE_PIPELINE_TP_OWNER,
  data
})


export const getCompanyProject =
  (axiosInstance, payload = {},params={}) =>
    (dispatch) => {
      return axiosInstance({
        method: "POST",
        data: { ...payload },
        params:{...params},
        headers: {
          Authorization: getAuthToken(),
        },
      })
        .then((res) => {
          dispatch(setCompanyProject(res.data));
          return { status: "success" }
        })
        .catch((err) => {
          console.log("Company Updates response - error -> ", err);
          return { status: "failed", message: err?.response?.data?.message || "Something went wrong!"};
        });
    };

const setCompanyProject = (data) => ({
  type: actionTypes.COMPANY_PROFILE_PROJECT,
  data,
});

export const updateAffinityList =
  (axiosInstance, payload = {},params={}) =>
    (dispatch) => {
      return axiosInstance({
        method: "POST",
        data: { ...payload },
        params:{},
        headers: {
          Authorization: getAuthToken(),
        },
      })
        .then((res) => {
          dispatch(setAffinity(res.data));
          return { status: "success" }
        })
        .catch((err) => {
          console.log("Company Updates response - error -> ", err);
          return { status: "failed", message: err?.response?.data?.message || "Something went wrong!"};
        });
    };

const setAffinity = (data) => ({
  type: actionTypes.UPDATE_AFFINITY_LIST,
  data,
});

export const getCompanyTpOwner = (axiosInstance, payload = {}, params = {}) => (dispatch) => {
  return axiosInstance({
    method: "POST",
    data: { ...payload },
    params: { ...params },
    headers: {
      Authorization: getAuthToken(),
    },
  })
    .then((res) => {
      dispatch(setCompanyTpOwner(res.data));
      return { status: "success" };
    })
    .catch((err) => {
      return { 
        status: "failed", 
        errorMsg: err?.response?.data?.message || "Something went wrong!" 
      };
    });
};

const setCompanyTpOwner = (data) => ({
  type: actionTypes.COMPANY_ADD_TP_OWNER,
  data,
});

export const getCompanyTpStatus = (axiosInstance, payload = {}, params = {}) => (dispatch) => {
  return axiosInstance({
    method: "POST",
    data: { ...payload },
    params: { ...params },
    headers: {
      Authorization: getAuthToken(),
    },
  })
    .then((res) => {
      dispatch(setCompanyTpStatus(res.data));
      return { status: "success" };
    })
    .catch((err) => {
      return { 
        status: "failed", 
        errorMsg: err?.response?.data?.message || "Something went wrong!" 
      };
    });
};

const setCompanyTpStatus = (data) => ({
  type: actionTypes.COMPANY_ADD_TP_STATUS,
  data,
});



export const getEmailChart = (axiosInstance,param={}) => (dispatch) => {
  axiosInstance({
    method:"GET",
    params:{...param},
    headers:{
      Authorization: getAuthToken(),
    }
  })
  .then((res) => {
    let results= {
      results: res?.data?.results,
      hasError: false,
      errorMessage: ""
    }
    dispatch({
      type: actionTypes.COMPANY_EMAIL_CHART,
      data: results
    })
  })
  .catch((err) => {
    let results= {
      results: {},
      hasError: true,
      errorMessage: "Error Fetching Data!"
    }
    dispatch({type: actionTypes.COMPANY_EMAIL_CHART,data: results})
  })
}

export const getEmailDetails = (axiosInstance,param={}) => (dispatch) => {
  return axiosInstance({
    method:"GET",
    params:{...param},
    headers:{
      Authorization: getAuthToken(),
    }
  })
  .then((res) => {
    dispatch({
      type: actionTypes.COMPANY_EMAIL_DETAILS,
      data: res?.data?.results
    })
    return {status:"success"}
  })
  .catch((err) => {
    console.log("Companyc Alerts response - error -> ", err);
    return { status: "failed" };
  })
}

export const getAllResponse = (arr) => (dispatch)=> {
  return Promise.allSettled([...arr]).then(axios.spread((...responses) => {
    if(responses[0]?.status === "fulfilled") {
      dispatch(setCompanyPage(responses[0]?.value?.data))
      
    }
    if(responses[1]?.status === "fulfilled") {
      let results= {
        results: responses[1]?.value?.data?.results,
        hasError: false,
        errorMessage: ""
      }
      dispatch({
        type: actionTypes.COMPANY_EMAIL_CHART,
        data: results
      })
    }else if (responses[1]?.status === "rejected") {
      let results= {
        results: {},
        hasError: true,
        errorMessage: responses[1]?.reason?.response?.data?.message
      }
      dispatch({
        type: actionTypes.COMPANY_EMAIL_CHART,
        data: results
      })
    }

    if(responses[2]?.status === "fulfilled") {
      dispatch({
        type: actionTypes.COMPANY_EMAIL_DETAILS,
        data: responses[2]?.value?.data?.results
      })
    }
    if(responses[3]?.status === "fulfilled") {
      dispatch(setCompanyStat(responses[3]?.value?.data))
    }
    if(responses[4]?.status === "fulfilled") {
      dispatch(setTpStatusHistory(responses[4]?.value?.data))
    }
    if(responses[5]?.status === "fulfilled") {
      dispatch(setCompanyProfileFileUploads(responses[5]?.value?.data));
    }
    if(responses[6]?.status === "fulfilled") {
      dispatch(setSelectedList(responses[6]?.value?.data));
    }
    return {res: responses}
  })).catch(errors => {
    console.log("errors", errors)
    // react on errors.
  })
}

const setSelectedList = (data) => ({
  type: actionTypes.GET_SELECTED_LIST,
  data,
});

export const addCompanyToList = (axiosInstance, payload = {}) => (dispatch) => {
  return axiosInstance({
    method: "POST",
    params: {},
    data: { ...payload },
    headers: {
      Authorization: getAuthToken(),
    },
  })
    .then((res) => {
      return { status: "success" };
    })
    .catch((err) => {
      return { 
        status: "failed", 
        errorMsg: err?.response?.data?.message || "Something went wrong!" 
      };
    });
};

export const getAffinityList = (axiosInstance) => (dispatch) => {
  return axiosInstance({
    method:"GET",
    params:{},
    headers:{
      Authorization: localStorage.getItem("authData")
      ? "Bearer " + localStorage.getItem("authData")
      : null,
    }
  })
  .then((res) => {
    dispatch({
      type: actionTypes.GET_AFFINITY_LIST,
      data: res?.data?.data
    })
    return {status:"success"}
  })
  .catch((err) => {
    console.log("Companyc Alerts response - error -> ", err);
    return { status: "failed" };
  })
}