import React, { useState, useEffect, useCallback } from 'react';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Nav } from 'react-bootstrap';
import * as actionTypes from "../../../store/actionTypes";
import * as amplitude from '@amplitude/analytics-browser';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import MyWorkboard from './MyWorkboard';
import { LoadingBlock } from "../../../commonComponents/LoadingAnimation/LoaderComponents";
import { getMyWorkboard, getLoggedInUser } from "../../../store/actions";
import { axiosMyWorkboards } from "../../../config/appConfig/axiosInstance";
import CommonError from "../../../commonComponents/CommonError/CommonError";
import { axiosUserInfoInstance } from '../../../config/appConfig/axiosInstance';
import './myWorkboards.scss';

function MyWorkboards() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { id } = useParams();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const selectedCategory = queryParams.get('param');
    const workboard_name = queryParams.get('name');

    const [view, setView] = useState({
        isLoading: false,
        hasError: false,
        errorMsg: ""
    });
    const [workboard, setWorkboard] = useState({
        show: "list_view",
        selectedWorkboard: {}
    });
    const [filteredType, setFilteredType] = useState("all");
    const [filteredList, setFilteredList] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [activeTab, setActiveTab] = useState("myWorkboardsTab");
    const [searchTermTab, setSearchTermTab] = useState('');
    
    const [myWorkboards, userInfo] = useSelector(({ workboardReducer, dashBoardReducer }) => [workboardReducer?.myWorkboards?.results, dashBoardReducer?.userInfo]);
    const [myWorkboardsCount, setMyWorkboardsCount] = useState(0);
    const [otherWorkboardsCount, setOtherWorkboardsCount] = useState(0);
    const [myFilteredWorkboards, setMyFilteredWorkboards] = useState([]);
    const [otherFilteredWorkboards, setOtherFilteredWorkboards] = useState([]);

    useEffect(() => {
       if(!userInfo) dispatch(getLoggedInUser(axiosUserInfoInstance));
    }, [])

    useEffect(() => {
        if (myWorkboards?.length) {
            const myWorkboardsFiltered = myWorkboards.filter(workb => workb?.creator_email === userInfo?.email);
            const otherWorkboards = myWorkboards.filter(workb => workb?.creator_email !== userInfo?.email);
            setMyFilteredWorkboards(myWorkboardsFiltered);
            setOtherFilteredWorkboards(otherWorkboards);
            setMyWorkboardsCount(myWorkboardsFiltered.length);
            setOtherWorkboardsCount(otherWorkboards.length);
            if (myWorkboardsFiltered.length === 0) {
                setActiveTab("otherWorkboards");
                amplitude.logEvent(`Other Workboards Tab Clicked`);
            } else {
                setActiveTab("myWorkboardsTab");
                amplitude.logEvent(`My Workboards Tab Clicked`);
            }
        }
    }, [myWorkboards, userInfo]);

    useEffect(() => {
        if (id) {
            navigate(`/workboard/${id}?name=${workboard_name}&param=${selectedCategory}`);
            setWorkboard({
                ...workboard,
                show: "details_view",
            });
            amplitude.logEvent(`Workboard Item Clicked`, {selected_workboard: workboard_name});
            if (localStorage.getItem('workboadData')) {
                const workboardAllData = JSON.parse(localStorage.getItem('workboadData'));
                setFilteredList(workboardAllData?.filteredList);
                setFilteredType(workboardAllData?.filterType);
                setSearchTermTab(workboardAllData?.searchTerm);
                dispatch({ type: actionTypes.UPDATE_MY_WORKBOARDS, data: workboardAllData?.data });
            }
        } else {
            setView({ ...view, isLoading: true });
            dispatch(getMyWorkboard(axiosMyWorkboards)).then(res => {
                if (res?.status === "success") {
                    setView({ ...view, isLoading: false });
                } else {
                    setView({ ...view, isLoading: false, hasError: true, errorMsg: res?.errorMsg || "Something went wrong. Please try again later" });
                }
            });
        }
        return () => {
            localStorage.removeItem("workboadData");
        };
    }, [id, navigate, dispatch, selectedCategory, workboard_name]);

    const debouncedSearch = useCallback(
        (term, type) => {
            clearTimeout(debounceTimer);
            const debounceTimer = setTimeout(() => {
                let filtered;
                if (activeTab === "myWorkboardsTab") {
                    filtered = myFilteredWorkboards
                        .filter(item => (item.category === type || type === "all") && 
                            (item.workboard_name?.toLowerCase().includes(term.toLowerCase()) || 
                            item.creator_name?.toLowerCase().includes(term.toLowerCase()) || 
                            item.creator_email?.toLowerCase().includes(term.toLowerCase())));
                } else {
                    filtered = otherFilteredWorkboards
                        .filter(item => (item.category === type || type === "all") && 
                            (item.workboard_name?.toLowerCase().includes(term.toLowerCase()) || 
                            item.creator_name?.toLowerCase().includes(term.toLowerCase()) || 
                            item.creator_email?.toLowerCase().includes(term.toLowerCase())));
                }
                setFilteredList(filtered);
            }, 100);
        },
        [myFilteredWorkboards, otherFilteredWorkboards, activeTab]
    );

    useEffect(() => {
        debouncedSearch(searchTermTab, filteredType);
    }, [searchTermTab, filteredType, activeTab, debouncedSearch]);

    const handleSearchChange = event => {
        const term = event.target.value;
        setSearchTerm(term);
        setSearchTermTab(term);
    };

    const handleFilterChange = event => {
        const type = event.target.value;
        setFilteredType(type);
    };

    function handleMyworkboard(item) {
        navigate(`/workboard/${item.workboard_id}?name=${item?.workboard_name}&param=${item?.category}`);
        setWorkboard({
            ...workboard,
            show: "details_view",
        });
        localStorage.setItem("workboadData", JSON.stringify({ filterType: filteredType, filteredList: filteredList, searchTerm: searchTermTab, data: myWorkboards }));
    }

    const renderDetailsView = () => {
        return (
            <>
                <MyWorkboard selectedWorkboard={workboard?.selectedWorkboard} onHide={() => { setWorkboard({ ...workboard, show: "list_view", selectedWorkboard: {} }) }} />
            </>
        );
    };

    const renderTabs = () => {
        return (
            <>
                <Nav className="mr-auto">
                    <Nav.Link onClick={() => setActiveTab("myWorkboardsTab")} disabled={myWorkboardsCount === 0} className={`myWorkboardsTab ${activeTab === "myWorkboardsTab" ? "activeTab" : ""}`}>
                        My Workboards
                    </Nav.Link>
                    <Nav.Link onClick={() => setActiveTab("otherWorkboards")} disabled={otherWorkboardsCount === 0} className={`otherWorkboards ${activeTab === "otherWorkboards" ? "activeTab" : ""}`}>
                        Other Workboards
                    </Nav.Link>
                </Nav>
                <span className='wrokboard_count d-flex align-items-center'>
                    <b>{filteredList?.length}</b>&nbsp;results
                </span>
            </>
        );
    };

    const renderListView = () => {
        return (
            <>
                {view?.isLoading ? (
                    <div>
                        <LoadingBlock height="40px" width="60" className="mb-3 " />
                        <LoadingBlock height="40px" width="auto" className="mb-4 " />
                        <LoadingBlock height="130px" width="auto" className="mb-3 " />
                        <LoadingBlock height="130px" width="auto" className="mb-3 " />
                        <LoadingBlock height="130px" width="auto" className="mb-3 " />
                        <LoadingBlock height="130px" width="auto" className="mb-3 " />
                        <LoadingBlock height="130px" width="auto" className="mb-3 " />
                    </div>
                ) : (
                    <div>
                        <div className='mb-2 d-flex justify-content-between'>
                            <span className='title'>Workboards</span>
                            <div className='workboard-header'>
                                <div className='d-flex'>
                                    <Form.Control size="lg" type="text" placeholder="Search" style={{ marginRight: "8px", height: "40px", fontSize: "1rem" }} value={searchTerm} onChange={handleSearchChange} />
                                    <Form.Select aria-label="Default select example" value={filteredType} onChange={e => handleFilterChange(e)} style={{ height: "40px" }}>
                                        <option value="all">Categories</option>
                                        <option value="Companies">Companies</option>
                                        <option value="People">People</option>
                                    </Form.Select>
                                </div>
                            </div>
                        </div>
                        <div className='workboard-uploads mt-4'>
                            {view?.hasError ? (
                                <CommonError errorMessage={view?.errorMsg} isBlurredRequired={true} />
                            ) : (
                                <>
                                    <div className='tabs-header d-flex justify-content-between'>
                                        {renderTabs()}
                                    </div>
                                    {filteredList?.length > 0 ? (
                                        filteredList.map((workb, index) => (
                                            <div key={index} className='d-flex justify-content-between flex-wrap position-relative my-rows mb-2 justify-content-start-xs cursor-pointer' onClick={e => handleMyworkboard(workb)}>
                                                <div className='d-flex align-items-center '>
                                                    <b><span>{workb.workboard_name}</span></b>
                                                </div>
                                                <div className='d-flex align-items-center '>
                                                    <div>
                                                        <span>Last Updated On</span>
                                                        <div>
                                                            <b><span> {moment.utc(workb.updated_at).local().format("DD/MMM/YYYY")} |  {moment.utc(workb.updated_at).local().format("hh:mm:ss")}</span></b>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='d-flex align-items-center '>
                                                    <div>
                                                        <span>Size</span>
                                                        <div>
                                                            <b><span>{workb?.total_entries}</span></b>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='d-flex align-items-center '>
                                                    <div>
                                                        <span>Category</span>
                                                        <div>
                                                            <b><span>{workb.category}</span></b>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='d-flex align-items-center '>
                                                    <div>
                                                        <span>Created By</span>
                                                        <div>
                                                            <b><span>{workb.creator_name}</span></b>
                                                            <div className='' style={{ fontSize: '13px' }}><i>{workb.creator_email}</i></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))
                                    ) : (
                                        <div className='no-workboards mt-5'>You have no Workboards</div>
                                    )}
                                </>
                            )}
                        </div>
                    </div>
                )}
            </>
        );
    };

    return <div className='my-workboards'>
        {workboard?.show === "details_view" ? renderDetailsView() : renderListView()}
    </div>;
}

export default MyWorkboards;
