import * as actionTypes from '../actionTypes'

const initialState = {
  investorReminders: {},
  urlReminderParams: { source: '', status: '' },
};

const investorRemindersReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_INVESTOR_REMINDERS:
      return {
        ...state,
        investorReminders: action.data,
      };
    case actionTypes.SET_URL_REMINDER_PARAMS:
      return {
        ...state,
        urlReminderParams: action.data,
      };
    default:
      return state;
  }
};

export default investorRemindersReducer;
