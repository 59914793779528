import * as actionTypes from '../../store/actionTypes';
import { getAuthToken } from '../../utils';
import axios from 'axios';
import { getAddress } from '../../utils';

export const getReferenceData = (referenceId) => async (dispatch) => {
    const baseUrl = getAddress();
    const url = `${baseUrl}/conversations/?reference_id=${referenceId}`;

    try {
        const response = await axios.get(url, {
            headers: {
                Authorization: `${getAuthToken()}`,
            },
        });

        // Dispatch the action with the data
        dispatch({ type: actionTypes.SET_REFERENCE_DATA, data: response.data });
    } catch (err) {
        console.error('Response - error -> ', err?.response?.data?.message);
        throw err;
    }
};
