import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Tabs, Tab, Offcanvas, FormControl, Accordion, Col, Row, Card, Button, Form, Spinner } from 'react-bootstrap';
import { getAddress } from '../../../utils';
import {
  getPipelineTpStatusOptions,
  getTpOwnerOptions,
  getListsAndBucketsOptions,
  getCompanyLists,
  getCompanyNoCachedData,
  getCompanyCachedData,
  getFounderData,
  getAdvertisingStats,
  getCompanyTpOwner,
  getCompanyCompetitors,
  getNewAdvertisingStats,
  getNewCompetitors,
  getNewCompanyFounders,
  getCompanyTpStatus,
  addCompanyToList,
  removeCompanyFromList,
  assignBucketToCompany,
  getQuipsData,
  getKnowledgeSource,
  getMentionsData
} from '../../../store/actions';
import {
  axiosPipelineTpOwner,
  axiosTpStatusOptions,
  axiosListsAndBucketsOptions,
  axiosCompanyLists,
  axiosCompanyNoCachedData,
  axiosCompanyCachedData,
  axiosFindFounderData,
  axiosAdvertisingStats,
  axiosaddTpOwner,
  axiosCompanyCompetitors,
  axiosAddAdvertisingStats,
  axiosAddCompanyCompetitors,
  axiosFindCompanyFounders,
  axiosAddTpStatus,
  axiosAddCompanyToList,
  axiosAddBucket,
  axiosAddNewCompanyList,
  axiosGetQuipsData,
  axiosGetKnowledgeSource,
  axiosGetMentionsData,
  axiosGetCtoLunchesData
} from '../../../config/appConfig/axiosInstance';

// Component Imports
import Dropdown from '../Dropdowns/Dropdown';
import Carousel from './Carousel';
import './slider.scss';

// Importing Graphs
import {  generateBarGraph, generateLineGraph } from '../Highchart/Highchart';

// Importing SVGs
import linkedinIcon from '../../../assets/images/icon_svg/linkedinbig.svg';
import googleMapsIcon from "../../../assets/images/icon_svg/google-maps.svg";
import affinityIcon from '../../../assets/images/icon_svg/affinity_new_logo.svg';
import calenderIcon from '../../../assets/images/icon_svg/calender-icon.svg';
import locationIcon from '../../../assets/images/icon_svg/location.svg';
import UpArrowIcon from '../../../assets/images/icon_svg/up-arrow.svg';
import DownArrowIcon from '../../../assets/images/icon_svg/down-arrow.svg';
import RightArrowIcon from '../../../assets/images/icon_svg/right-arrow.svg';
import employeeCountIcon from '../../../assets/images/icon_svg/employeeCount.svg';
import InfoIcon from '../../../assets/images/icon_svg/info-icon.svg';
import NoDataIcon from '../../../assets/images/icon_svg/no-data-icon.svg';
import ArrowRightIcon from '../../../assets/images/icon_svg/arrow_forward.svg';
import ClockIcon from '../../../assets/images/icon_svg/clock.svg';
import PersonsIcon from '../../../assets/images/icon_svg/persons.svg';
import PersonIcon from '../../../assets/images/icon_svg/person.svg';
import ArrowUpIcon from '../../../assets/images/icon_svg/arrow_upward.svg';
import AlertIcon from '../../../assets/images/icon_svg/alert-icon.svg';
import backIcon from '../../../assets/images/icon_svg/back.svg';
import attachmentIcon from '../../../assets/images/icon_svg/attachment.svg';
import { getCTOLunchesData } from '../../../store/actions/commonAction';

let monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
let isComponentMounted = true;

const Slider = ({ isOpen, onClose, companyDomain }) => {
  const dispatch = useDispatch();
  const barGraphRef = useRef(null);
  const lineGraphRef = useRef(null);
  const [showFullDescription, setShowFullDescription] = useState(false);
  const [showAllCountries, setShowAllCountries] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [showAllFounders, setShowAllFounders] = useState(false);
  const [selections, setSelections] = useState({
    tpOwner: [],
    tpStatus: null,
    bucket: null
  });
  const [advertisingStats, setAdvertisingStats] = useState({}); 
  const [isLoading, setIsLoading] = useState({
    advertisingStats: false,
    competitors: false,
    companyCacheData: false,
    companyNoCachedData: false,
    companyLists: false,
    founders: false,
    founderEmail: {},
    quips: false,
    mentions: false
  });
  const [companyCachedData, setCompanyCachedData] = useState({});
  const [competitors, setCompetitors] = useState({ seo: [], ppc: [] });
  const [companyNoCachedData, setCompanyNoCachedData] = useState({});
  const [companyLists, setCompanyLists] = useState({ lists: [],  suggested: []});
  const [founderData, setFounderData] = useState({
    founders: [],
    emailData: {},
    isEmailClicked: {},
  });
  const [expandedParticipants, setExpandedParticipants] = useState({});
  const [activeTab, setActiveTab] = useState(null);
  const [alert, setAlert] = useState(null);
  const [fetchStatus, setFetchStatus] = useState(null);
  const [quipsData, setQuipsData] = useState([]);
  const [quipsState, setQuipsState] = useState({
    expandedAccordion: null,
    activeSortedType: 'newest',
    searchTerm: '',
  });
  const [mentionsData, setMentionsData] = useState([]);
  const [mentionsState, setMentionsState] = useState({
    expandedAccordion: null,
    activeSortedType: 'newest',
    searchTerm: '',
    ctoLunchesData: null,
    isLoadingCTO: false,
  });
  const [activeKey, setActiveKey] = useState('company_profile');
  const [viewingCTOLunches, setViewingCTOLunches] = useState(false);
  const [
    tpStatusOptions,
    tpOwnerOptions,
    listOptions,
    bucketOptions,
  ] = useSelector(({ commonReducer }) => [
    commonReducer.commonTpStatusOptions.data || [],
    commonReducer.commonTpOwnerOptions.results || [],
    commonReducer.commonListsBucketsOptions.lists || [],
    commonReducer.commonListsBucketsOptions.buckets || [],
  ]);

  useEffect(() => {
    if (companyNoCachedData.meetings) {
      if (companyNoCachedData.meetings.latest_meeting) {
        setActiveTab('latest_meeting');
      } else if (companyNoCachedData.meetings.first_meeting) {
        setActiveTab('first_meeting');
      } else if (companyNoCachedData.meetings.next_meeting) {
        setActiveTab('next_meeting');
      }
    } 
  }, [companyNoCachedData.meetings]);

  useEffect(() => {
    isComponentMounted = true;

    return () => {
      isComponentMounted = false; 
    };
  }, []);

  useEffect(() => {
    dispatch(getPipelineTpStatusOptions(axiosTpStatusOptions));
    dispatch(getTpOwnerOptions(axiosPipelineTpOwner));
    dispatch(getListsAndBucketsOptions(axiosListsAndBucketsOptions));
    if (companyDomain) {
      setIsLoading(prevState => ({ ...prevState, companyLists: true }));
      dispatch(getCompanyLists(axiosCompanyLists, companyDomain)).then(response => {
        if(response?.status === "success") {
          setCompanyLists({
            lists: response.data?.data || [],
            suggested: response.data?.suggested_lists || []
          });
        }
      }).finally(() => setIsLoading(prevState => ({ ...prevState, companyLists: false })));

      setIsLoading(prevState => ({ ...prevState, companyNoCachedData: true }));
      dispatch(getCompanyNoCachedData(axiosCompanyNoCachedData, companyDomain)).then(response => {
        if (response?.status === "success") {
          setCompanyNoCachedData(response.data);
          setFounderData(prevData => ({
            ...prevData,
            founders: response.data?.founders || []
          }));

          setSelections(prevData => ({
            ...prevData, 
            tpOwner: response.data.tp_owners,
            tpStatus: response.data.tp_status !== 'n/a' ? response.data.tp_status?.toUpperCase() : null,
            bucket: response.data.bucket !== 'n/a' ? response.data.bucket : null
          }));
        }
      }).finally(() => setIsLoading(prevState => ({ ...prevState, companyNoCachedData: false })));

      setIsLoading(prevState => ({ ...prevState, companyCacheData: true }));
      dispatch(getCompanyCachedData(axiosCompanyCachedData, companyDomain)).then(response => {
        if (response?.status === "success") {
            setCompanyCachedData(response.data);
        }
      }).finally(() => setIsLoading(prevState => ({ ...prevState, companyCacheData: false })));;

      setIsLoading(prevState => ({ ...prevState, advertisingStats: true, competitors: true }));
      dispatch(getAdvertisingStats(axiosAdvertisingStats, companyDomain)).then(response => {
        if (response?.status === "success") {
          setAdvertisingStats(response.data);
        }
      }).finally(() => setIsLoading(prevState => ({ ...prevState, advertisingStats: false })));

      dispatch(getCompanyCompetitors(axiosCompanyCompetitors, companyDomain)).then(response => {
        if (response?.status === "success") {
            setCompetitors({
                seo: response.data?.seo_competitors || [],
                ppc: response.data?.ppc_competitors || []
            });
        }
      }).finally(() => setIsLoading(prevState => ({ ...prevState, competitors: false })));

      setIsLoading(prevState => ({ ...prevState, quips: true }));
      dispatch(getQuipsData(axiosGetQuipsData, companyDomain)).then(response => {
        if (response?.status === "success") {
          setQuipsData(response.data?.quips || []);
        }
      }).finally(() => setIsLoading(prevState => ({ ...prevState, quips: false })));

      setIsLoading(prevState => ({ ...prevState, mentions: true }));
      dispatch(getMentionsData(axiosGetMentionsData, companyDomain)).then(response => {
        if (response?.status === "success") {
          setMentionsData(response.data?.mentions || []);
        }
      }).finally(() => setIsLoading(prevState => ({ ...prevState, mentions: false })));
    }
  }, [dispatch, companyDomain]);

  useEffect(() => {
    setSelections(prevData => ({ ...prevData, tpOwner: selectedTpOwners }));
    setSelections(prevData => ({ ...prevData, tpStatus: selectedTpStatus?.toUpperCase() }));
    setSelections(prevData => ({ ...prevData, bucket: selectedBucket }));
  }, [selectedTpOwners, selectedTpStatus, selectedBucket]);

  useEffect(() => {
    if (barGraphRef.current && companyCachedData.departments) {
        if (companyCachedData?.departments?.length) {
            const departmentsData = companyCachedData.departments?.map((item) => ({
                name: item.department,
                y: item.percentage,
                color: '#AAA'
            }));
            generateBarGraph(barGraphRef.current, departmentsData);
        }
    }
    if (lineGraphRef.current && companyCachedData?.employee_stats?.length) {
        generateLineGraph(lineGraphRef.current, companyCachedData.employee_stats);
    }
  }, [companyCachedData.departments, companyCachedData.employee_stats]);

  useEffect(() => {
    if (alert) {
      const timer = setTimeout(() => {
        setAlert(null);
      }, alert.timer || 2500);
      return () => clearTimeout(timer);
    }
  }, [alert]);

  // Extract selected options from companyNoCachedData
  const selectedTpStatus = companyNoCachedData.tp_status !== "n/a" ? companyNoCachedData.tp_status?.toUpperCase() : null;
  const selectedTpOwners = Array.isArray(companyNoCachedData.tp_owners) ? companyNoCachedData.tp_owners : [];
  const selectedBucket = companyNoCachedData.bucket !== "n/a" ? companyNoCachedData.bucket : null;
  const meetings = companyNoCachedData?.meetings;
  const countries = companyCachedData.countries || {};
  const countryList = Object.entries(countries)?.map(([countryName, data]) => ({
    name: countryName,
    count: data.count,
    flag: data.flag_svg
  }));

  const alertCustom = ({ icon, text, timer }) => {
    setAlert({ icon, text, timer });
  };

  const toggleDescription = () => {
    setShowFullDescription(prevState => !prevState);
  };

  const toggleCountryVisibility = () => {
    setShowAllCountries(prevState => !prevState);
  };

  const toggleParticipantsView = (meetingKey) => {
    setExpandedParticipants((prevState) => ({
      ...prevState,
      [meetingKey]: !prevState[meetingKey],
    }));
  };

  const formatMeetingDate = (originalTimestamp) => {
    const date = new Date(originalTimestamp);
    const formattedDate = date.toLocaleDateString('en-GB', { month: 'short' });
    const dayWithSuffix = date.getDate() + getDaySuffix(date.getDate());
    const year = date.getFullYear();
    function getDaySuffix(day) {
      if (day > 3 && day < 21) return 'th';
      switch (day % 10) {
        case 1:  return "st";
        case 2:  return "nd";
        case 3:  return "rd";
        default: return "th";
      }
    }
    return `${dayWithSuffix} ${formattedDate}, ${year}`;
  }

  const formatMeetingsTime = (time) => {
    if (!time || time === "n/a") {
        return null;
    }
    const date = new Date(time);
    const formattedTime = date.toLocaleTimeString('en-US', {hour: '2-digit', minute: '2-digit',  hour12: true})
    return `${formattedTime} `;
  }

  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'short', day: 'numeric' };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  const formatTime = (time) => {
    if (!time || time === "n/a") {
        return null;
    }
    const date = new Date(time);
    const formattedTime = date?.toLocaleTimeString('en-US', {hour: '2-digit', minute: '2-digit',  hour12: true})
    return `${formattedTime} `;
  }

  const formatQuipsDate = (dateString) => {
    const date = new Date(dateString);
  
    let hours = date.getHours();
    const minutes = String(date.getMinutes()).padStart(2, '0');
  
    // Determine AM/PM
    const amPm = hours >= 12 ? 'PM' : 'AM';
  
    hours %= 12;
    hours = hours || 12; 
  
    const formattedHours = String(hours).padStart(2, '0');
  
    const formattedTime = `${formattedHours}:${minutes}${amPm}`;
  
    const day = date.getDate();
    const month = date.toLocaleString('en-US', { month: 'long' });
    const year = date.getFullYear();
  
    const ordinalSuffix = ['th', 'st', 'nd', 'rd'][((day % 100) - 20) % 10] || 'th';
  
    const formattedDate = `${formattedTime} ${day}${ordinalSuffix} ${month} ${year}`;
  
    return formattedDate;
  }
  
  const capitalizeAllFirstLetter = (input) => {
    return input?.replace(/_/g, ' ')?.replace(/\b\w/g, function (match) {
        return match?.toUpperCase();
    });  
  }

  const getFirstChar = (input) => {
    if (!input) {
      return '';
    }
    return input.charAt(0);
  };

  const getDaysAgo = (dateString) => {
    const date = dateString && dateString !== "n/a" ? new Date(dateString) : null;
    const currentDate = new Date();
  
    if (!date) {
      return "n/a";
    }
    // Check if the date is today
    const isToday =
      date.getDate() === currentDate.getDate() &&
      date.getMonth() === currentDate.getMonth() &&
      date.getFullYear() === currentDate.getFullYear();
  
    if (isToday) {
      return "Today";
    }
    // Calculate the time difference in milliseconds
    const timeDifference = currentDate.getTime() - date.getTime();
    const daysAgo = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
    return daysAgo;
  };

  const handleSortOrderChange = (order) => {
    setQuipsState(prevState => ({
      ...prevState,
      activeSortedType: order
    }));
  };

  const filterQuips = () => {
    return quipsData
      ?.filter(quip =>
        (quip.quip_text?.toLowerCase().includes(quipsState.searchTerm.toLowerCase()) ||
        quip.quip_type?.toLowerCase().includes(quipsState.searchTerm.toLowerCase()))
      )
      ?.sort((a, b) => {
        if (quipsState.activeSortedType === 'newest') {
          return new Date(b.date) - new Date(a.date);
        } else if (quipsState.activeSortedType === 'oldest') {
          return new Date(a.date) - new Date(b.date);
        }
        return 0;
      });
  };

  const handleViewFullDescription = (quip) => {
    const { source_type, source } = quip;
  
    if (source_type.toLowerCase() === 'newsletter') {
      dispatch(getKnowledgeSource(axiosGetKnowledgeSource, source_type, source)).then((response) => {
        if (response.status === 'success') {
          const htmlContent = response.data;
  
          if (typeof htmlContent === 'string') {
            if (htmlContent.includes('<html') || htmlContent.includes('<div')) {
              const newWindow = window.open('', '_blank');
              newWindow.document.write(htmlContent);
              newWindow.document.close();
            }
          } else if (response.data?.newsletter_url) {
            window.open(response.data.newsletter_url, '_blank');
          } else {
            alertCustom('The content format is not recognized.');
          }
        } else {
          alertCustom(response.errorMsg || 'Something went wrong. Please try again later!');
        }
      }).catch((error) => {
        alertCustom('Failed to fetch the full description. Please try again later!');
        console.error('Error fetching full description:', error);
      });
    } else {
    }
  };
  
  const renderQuipsAccordion = () => {
    const filteredQuips = filterQuips();
    const apiQuipTypes = [...new Set(filteredQuips.map(quip => quip.quip_type))].sort();
  
    return apiQuipTypes.map(type => {
      const quipsByType = filteredQuips.filter(quip => quip.quip_type?.toLowerCase() === type?.toLowerCase());
  
      return (
        <div key={type} className="quip-main-container">
          {quipsByType.length > 0 && (
            <div className="accordion-container">
              <div
                className="accordion-header"
                onClick={() => setQuipsState(prevState => ({
                  ...prevState,
                  expandedAccordion: prevState.expandedAccordion === type ? null : type
                }))}
              >
                <span className="quip-header">{`${capitalizeAllFirstLetter(type)} (${quipsByType.length})`}</span>
                <span className="accordion-icon">
                  {quipsState.expandedAccordion === type ? '▲' : '▼'}
                </span>
              </div>
              {quipsState.expandedAccordion === type && (
                <div className="accordion-body">
                  {quipsByType.map((quip, index) => (
                    <div key={index} className="quip">
                      <div className="quip-date mb-2">{formatQuipsDate(quip.date)}</div>
                      <div className="quip-text">{quip.quip_text}</div>
                      {quip.source_type.toLowerCase() === 'newsletter' && (
                        <div
                          className="view_description_btn"
                          onClick={() => handleViewFullDescription(quip)}
                        >
                          View full description
                        </div>
                      )}
                      <div className="quip-divider f-mt-4px"></div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          )}
        </div>
      );
    });
  };

  const getSourceTypeDisplayName = (sourceType) => {
    switch (sourceType) {
      case 'REFERENCE':
        return 'References recorded by Telescope';
      case 'CTO_LUNCHES':
        return 'CTO Lunches';
      default:
        return capitalizeAllFirstLetter(sourceType);
    }
  }

  const handlementionsSortChange = (order) => {
    setMentionsState((prevState) => ({
      ...prevState,
      activeSortedType: order,
    }));
  };

  const filterMentions = () => {
    return mentionsData
      ?.filter((mention) =>
        mention.summary_text?.toLowerCase().includes(mentionsState.searchTerm.toLowerCase()) ||  mention.standouts_text?.toLowerCase().includes(mentionsState.searchTerm.toLowerCase()) ||  mention.mentions_type?.toLowerCase().includes(mentionsState.searchTerm.toLowerCase())
      )
      ?.sort((a, b) => {
        if (mentionsState.activeSortedType === 'newest') {
          return new Date(b.date) - new Date(a.date);
        } else if (mentionsState.activeSortedType === 'oldest') {
          return new Date(a.date) - new Date(b.date);
        }
        return 0;
      });
  };

  const handleViewFullDescriptionClick = async (source, sourceType) => {
    const baseURL = '/references';
  
    const sourceUrl = `${baseURL}?mention_params=${source}&type=${sourceType}`;
  
    if (sourceType === 'CTO_LUNCHES') {
      setMentionsState(prevState => ({ ...prevState, isLoadingCTO: true }));
      const response = await dispatch(getCTOLunchesData(axiosGetCtoLunchesData, source));
      setMentionsState({
        ...mentionsState,
        ctoLunchesData: response?.data,
        isLoadingCTO: false,
      });
      setViewingCTOLunches(true);
    } else {
      window.open(sourceUrl, '_blank');
    }
  };

  const renderCTOLunchesData = () => {
    const { ctoLunchesData } = mentionsState;
    if (!ctoLunchesData) return null;
  
    return (
      <div className="cto-lunches-data">
        {ctoLunchesData.messages && ctoLunchesData.messages.length === 0 ? (
          <div className="no-data-message">No data available for this CTO Lunches.</div>
        ) : (
          <>
            <span className="back-button" onClick={() => { setMentionsState(prevState => ({ ...prevState, ctoLunchesData: null })); setViewingCTOLunches(false); }}>
              <img src={backIcon} height="24px" width="22px" />
            </span>
            <div className="subject">
              {ctoLunchesData.subject !== 'n/a' ? ctoLunchesData.subject : ''}
            </div>
            <div className="divider"></div>
            <ul className="timeline">
              {ctoLunchesData.messages?.map((item, index) => {
                const timelineInitial = (item?.sender_name && item.sender_name !== 'n/a') 
                  ? item.sender_name.charAt(0).toUpperCase() 
                  : (item?.sender_email && item.sender_email !== 'n/a') 
                  ? item.sender_email.charAt(0).toUpperCase() 
                  : '';
                return (
                  <li key={index} className="timeline-item">
                    {index <= ctoLunchesData.messages.length - 2 && <div className="line"></div>}
                    <span className="timeline-icon">
                      <div className="initial-circle">{timelineInitial}</div>
                    </span>
                    <div className="timeline-body">
                      <div className="timeline-content">
                        <div className={`cards ${index % 2 === 0 ? 'outer-card' : 'inner-card'}`}>
                          <div className="card-header">
                            {item.sender_name && item.sender_name !== 'n/a' && <span>{item.sender_name}</span>} <br />
                            {item.sender_email && item.sender_email !== 'n/a' && <span className="sender-email">{item.sender_email}</span>}
                          </div>
                          <div className="card-body">
                            <span className="card-title">
                              {item.body && item.body !== 'n/a' ? item.body : ''}
                            </span>
                            {item.files && item.files.length > 0 && (
                              <>
                                <hr className="divider" />
                                <div className="file-section">
                                  {item.files.map(file => (
                                    <a
                                      key={file.filename}
                                      href={`data:${file.content_type};base64,${file.content_id}`}
                                      download={file.filename}
                                      className="file-link"
                                    >
                                      <img src={attachmentIcon} height="11px" width="10px" style={{ marginRight: '5px' }} />
                                      {file.filename}
                                    </a>
                                  ))}
                                </div>
                              </>
                            )}
                          </div>
                        </div>
                        <div className="date">
                          {item.received_at && item.received_at !== 'n/a' ? item.received_at : ''}
                        </div>
                      </div>
                    </div>
                  </li>
                );
              })}
            </ul>
          </>
        )}
      </div>
    );
  };

  const renderMentionsAccordion = () => {
    const filteredMentions = filterMentions();
    const mentionTypes = [...new Set(filteredMentions?.map((mention) => mention.source_type))].sort();

    return mentionTypes.map((type) => {
      const mentionsByType = filteredMentions?.filter(
        (mention) => mention.source_type?.toLowerCase() === type?.toLowerCase()
      );

      return (
        <div key={type} className="mention-main-container">
          {mentionsByType?.length > 0 && (
            <div className="accordion-container">
              <div
                className="accordion-header"
                onClick={() =>
                  setMentionsState((prevState) => ({
                    ...prevState,
                    expandedAccordion: prevState.expandedAccordion === type ? null : type,
                  }))
                }
              >
                <span className="mention-header">{`${getSourceTypeDisplayName(type)} (${mentionsByType?.length})`}</span>
                <span className="accordion-icon">
                  {mentionsState.expandedAccordion === type ? '▲' : '▼'}
                </span>
              </div>
              {mentionsState.expandedAccordion === type && (
                <div className="accordion-body">
                  {mentionsByType.map((mention, index) => (
                    <div key={index} className="mention">
                      <div className="mentioned-icon">
                        <div className="three-lines">
                          <span className="position-434"></span>
                          <span className="position-433"></span>
                          <span className="position-432"></span>
                        </div>
                        <div className="mb-2">
                          Mentioned <b>{mention.mentions}</b> {mention.mentions > 1 ? "times" : "time"}
                        </div>
                      </div>
                      <div className="d-flex align-items-center mb-2">
                        <span className="mention-type">{capitalizeAllFirstLetter(mention.mentions_type)}</span>
                      </div>
                      <div className="mention-date mb-1">{formatQuipsDate(mention.date)}</div>
                      <div className="mention-text mb-2">{mention.standouts_text}</div>
                      <div className="mention-text-summary">{mention.summary_text}</div>
                      {(mention.source_type === 'REFERENCE' || mention.source_type === 'CTO_LUNCHES') && (
                        <span
                          className="view_description_btn"
                          onClick={() => handleViewFullDescriptionClick(mention.source, mention.source_type)}
                        >
                          View Full Description
                        </span>
                      )}
                      <div className="mention-divider mt-2"></div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          )}
        </div>
      );
    });
  };

  const fetchFounderData = async (index) => {

    try {
      setIsLoading((prevState) => ({
        ...prevState,
        founderEmail: { ...prevState.founderEmail, [index]: true },
      }));
  
      const response = await dispatch(
        getFounderData(axiosFindFounderData, companyDomain, `${founderData.founders[index].first_name} ${founderData.founders[index].last_name}`)
      );
  
      if (response.status === "success") {

        setFounderData((prevData) => {
          const updatedFounders = [...prevData.founders];
          updatedFounders[index].email = response?.data?.email;
  
          return {
            ...prevData,
            founders: updatedFounders,
            isEmailClicked: {
              ...prevData.isEmailClicked,
              [index]: response?.data?.email === 'n/a'
            },
          };
        });

        
      } else {
        setFounderData((prevData) => {
          const updatedFounders = [...prevData.founders];
          updatedFounders[index].email = null;
  
          return {
            ...prevData,
            founders: updatedFounders,
            isEmailClicked: {
              ...prevData.isEmailClicked,
              [index]: true
            },
          };
        });
      }
    } catch (error) {
      setFounderData((prevData) => {
        const updatedFounders = [...prevData.founders];
        updatedFounders[index].email = null;
  
        return {
          ...prevData,
          founders: updatedFounders,
          isEmailClicked: {
            ...prevData.isEmailClicked,
            [index]: true
          },
        };
      });
    } finally {
      // Reset the loading state for the specific founder
      setIsLoading((prevState) => ({
        ...prevState,
        founderEmail: { ...prevState.founderEmail, [index]: false },
      }));
    }
  };

  const handleCopyToClipboard = (email, emailElement) => {
    navigator.clipboard.writeText(email)
      .then(() => {
        showTooltip("Email copied to clipboard!", emailElement);
      })
      .catch((err) => {
        console.error("Failed to copy email to clipboard: ", err);
      });
  };
  
  const showTooltip = (message, ele) => {
    const tooltip = document.createElement('div');
    tooltip.className = 'founder_tooltip';
    tooltip.textContent = message;
    ele.appendChild(tooltip);
  
    setTimeout(() => {
      ele.removeChild(tooltip);
    }, 1000); 
  };

  const formatNumber = (number) => {
    if (number === "n/a") return "n/a";
    if (number >= 1000000000) return (number / 1000000000).toFixed(2) + 'B';
    if (number >= 1000000) return (number / 1000000).toFixed(2) + 'M';
    if (number >= 1000) return (number / 1000).toFixed(2) + 'K';
    return number?.toString();
  };

  const formatDateString = (dateString) => {
    const date = dateString && dateString != "n/a" ? new Date(dateString) : null;
    if (!date) {
      return "n/a";
    }
    const day = date?.getDate();
    const monthIndex = date?.getMonth();
    const year = date?.getFullYear();
    const formattedDate = dateString && dateString != "n/a" ? `${day} ${monthNames[monthIndex]}, ${year}` : "n/a";
    return formattedDate
  }

  const toCamelCase = (str) => {
    return str
      .toLowerCase()
      .replace(/(?:^|_|\s)\S/g, match => match.toUpperCase())
      .replace(/_/g, ' ');
  };

  const getGrowthDescriptionClass = (growthDesc) => {
    if (growthDesc?.includes("more")) return "growth_dec positive";
    if (growthDesc?.includes("less")) return "growth_dec negative";
    return "";
  };

  const isCompanyStatsDataAvailable = () => {
    const { employee_count, growth_1m, growth_6m, growth_1y, growth_2y, departments } = companyCachedData;
    return (
      employee_count && employee_count !== 'n/a' && 
      departments && departments?.length > 0 &&
      (growth_1m && growth_1m !== 'n/a' ||
      growth_6m && growth_6m !== 'n/a' ||
      growth_1y && growth_1y !== 'n/a' ||
      growth_2y && growth_2y !== 'n/a')
    );
  }

  const shouldDisplayEmailBox = (emailMetadata) => {
    if (!emailMetadata || Object.keys(emailMetadata).length === 0) return false;
  
    // Check if any valid data exists in keys other than 'unique_threads'
    return Object.entries(emailMetadata).some(([key, value]) => {
      if (key !== 'unique_threads') {
        return value !== 'n/a' && value !== null && value !== 0;
      }
      return false;
    });
  };
  
  const shouldDisplayFundingSection = (companyData) => {
    return (
      companyData?.total_funding_usd &&
      companyData?.total_funding_usd !== 'n/a' ||
      companyData?.last_funding_on &&
      companyData?.last_funding_on !== 'n/a'
    );
  };

  const getTooltipMessage = (emailMetadata, fundingData, meetingsData) => {
    const hasEmailData = shouldDisplayEmailBox(emailMetadata);
    const hasFundingData = shouldDisplayFundingSection(fundingData);
    const hasMeetingsData = meetingsData && Object.keys(meetingsData).length > 0;
  
    if (!hasFundingData && !hasEmailData && !hasMeetingsData) {
      return 'Funding data not available, no email interactions recorded yet and no meetings scheduled yet.';
    } else if (!hasFundingData && !hasEmailData && hasMeetingsData) {
      return 'Funding data not available and no email interactions recorded yet.';
    } else if (!hasFundingData && hasEmailData && !hasMeetingsData) {
      return 'Funding data not available and no meetings scheduled yet.';
    } else if (hasFundingData && !hasEmailData && !hasMeetingsData) {
      return 'No email interactions recorded yet and no meetings scheduled yet.';
    } else if (!hasFundingData && hasEmailData && hasMeetingsData) {
      return 'Funding data not available.';
    } else if (hasFundingData && !hasEmailData && hasMeetingsData) {
      return 'No email interactions recorded yet.';
    } else if (hasFundingData && hasEmailData && !hasMeetingsData) {
      return 'No meetings scheduled yet.';
    } else if (hasEmailData && hasFundingData && hasMeetingsData) {
      return null; 
    } else {
      return null; 
    }
  };

  const handleOptionSelect = (selectedOption) => {
    const { server_value } = selectedOption;
  
    setSelections(prev => ({ ...prev, tpOwner: [...prev.tpOwner, selectedOption.display_name] }));
  
    const payload = {
      domain: companyDomain,
      scopers: [...selections.tpOwner.map(owner => owner), selectedOption.display_name],
      ...(companyCachedData.external_id !== 'n/a' && { affinity_id: companyCachedData.external_id })
    };
  
    dispatch(getCompanyTpOwner(axiosaddTpOwner, payload))
      .then(response => {
        if (response.status !== "success") {
          setSelections(prev => ({ 
            ...prev, 
            tpOwner: prev.tpOwner.filter(name => name !== selectedOption.display_name) 
          }));
          alertCustom({ icon: 'error', text: response.errorMsg });
        }
      })
      .catch(error => {
        setSelections(prev => ({ 
          ...prev, 
          tpOwner: prev.tpOwner.filter(name => name !== selectedOption.display_name) 
        }));
        alertCustom({ icon: 'error', text: error.message });
      });
  };
  
  const handleTpStatusChange = (newStatus) => {
    setSelections(prev => ({ ...prev, tpStatus: newStatus }));
  
    const payload = {
      domain: companyDomain,
      tp_status: newStatus || null,
      ...(companyCachedData.external_id && companyCachedData.external_id !== 'n/a' && { affinity_id: companyCachedData.external_id })
    };
  
    dispatch(getCompanyTpStatus(axiosAddTpStatus, payload))
      .then(response => {
        if (response.status !== 'success') {
          setSelections(prev => ({ ...prev, tpStatus: prev.tpStatus === newStatus ? null : prev.tpStatus }));
          alertCustom({ icon: 'error', text: response.errorMsg });
        }
      })
      .catch(error => {
        setSelections(prev => ({ ...prev, tpStatus: prev.tpStatus === newStatus ? null : prev.tpStatus }));
        alertCustom({ icon: 'error', text: error.message });
      });
  };
  
  const handleRemoveTpOwner = (removedOption) => {
    const remainingOptions = selections.tpOwner.filter(option => option !== removedOption);
  
    setSelections(prev => ({ ...prev, tpOwner: remainingOptions }));
  
    const payload = {
      domain: companyDomain,
      scopers: remainingOptions.map(option => tpOwnerOptions.find(opt => opt.display_name === option)?.server_value).filter(Boolean),
      ...(companyCachedData.external_id !== 'n/a' && { affinity_id: companyCachedData.external_id })
    };
  
    dispatch(getCompanyTpOwner(axiosaddTpOwner, payload))
      .then(response => {
        if (response.status !== 'success') {
          setSelections(prev => ({ ...prev, tpOwner: [...prev.tpOwner, removedOption] }));
          alertCustom({ icon: 'error', text: response.errorMsg });
        }
      })
      .catch(error => {
        setSelections(prev => ({ ...prev, tpOwner: [...prev.tpOwner, removedOption] }));
        alertCustom({ icon: 'error', text: error.message });
      });
  };
  
  const handleListSelect = selectedList => {
    const { list_id } = selectedList;
    const isExistingList = !!list_id;
  
    // Optimistically update the company's lists
    setCompanyLists(prev => ({
      ...prev,
      lists: isExistingList ? [...prev.lists, selectedList] : [...prev.lists, { name: selectedList }],
    }));
  
    const payload = {
      domain: companyDomain,
      ...(isExistingList ? { list_id } : { name: selectedList }),
      ...(companyCachedData.external_id !== 'n/a' && { affinity_id: companyCachedData.external_id || companyNoCachedData.external_id }),
    };
  
    const apiCall = isExistingList ? axiosAddCompanyToList : axiosAddNewCompanyList;
  
    dispatch(addCompanyToList(apiCall, payload))
      .then(response => {
        if (response.status === 'success') {
          if (!isExistingList) {
            setTimeout(() => {
              dispatch(getCompanyLists(axiosCompanyLists, companyDomain))
                .then(updatedLists => {
                  const listsArray = Array.isArray(updatedLists.data?.data) ? updatedLists.data?.data : [];
                  const newlyAddedList = listsArray.find(list => list.name === selectedList);
                  if (newlyAddedList) {
                    setCompanyLists(prev => ({
                      ...prev,
                      lists: [
                        ...prev.lists.filter(list => list.name !== selectedList),
                        newlyAddedList
                      ]
                    }));
                  } else {
                    console.error("Newly added list not found in the updated lists.");
                  }
                })
                .catch(error => alertCustom({ icon: 'error', text: error.message }));
            }, 2000);
          }
        } else {
          setCompanyLists(prevLists => ({
            ...prevLists,
            lists: prevLists.lists.filter(list => list.list_id !== list_id && list.name !== selectedList),
          }));
          alertCustom({ icon: 'error', text: response.errorMsg });
        }
      })
      .catch(error => {
        setCompanyLists(prevLists => ({
          ...prevLists,
          lists: prevLists.lists.filter(list => list.list_id !== list_id && list.name !== selectedList),
        }));
        alertCustom({ icon: 'error', text: error.message });
      });
  };
  
  const handleRecentListSelect = (selectedList) => {
    const { list_id } = selectedList;
  
    setCompanyLists(prev => ({
      ...prev,
      lists: [...prev.lists, selectedList],
      suggested: prev.suggested.filter(list => list.list_id !== list_id)
    }));
  
    const payload = {
      domain: companyDomain,
      list_id,
      ...(companyCachedData.external_id !== 'n/a' && { affinity_id: companyCachedData.external_id || companyNoCachedData.external_id })
    };
  
    dispatch(addCompanyToList(axiosAddCompanyToList, payload))
      .then(response => {
        if (response.status !== 'success') {
          // Revert changes if the API call fails
          setCompanyLists(prevLists => ({
            ...prevLists,
            lists: prevLists.lists.filter(list => list.list_id !== list_id),
            suggested: [...prevLists.suggested, selectedList]
          }));
          alertCustom({ icon: 'error', text: response.errorMsg });
        }
      })
      .catch(error => {
        // Revert changes if there's an error
        setCompanyLists(prevLists => ({
          ...prevLists,
          lists: prevLists.lists.filter(list => list.list_id !== list_id),
          suggested: [...prevLists.suggested, selectedList]
        }));
        alertCustom({ icon: 'error', text: error.message });
      });
  };
  
  const handleBucketChange = (newBucket) => {
    setSelections(prev => ({ ...prev, bucket: newBucket }));
  
    const payload = {
      domain: companyDomain,
      bucket_name: newBucket,
      ...(companyCachedData.external_id !== 'n/a' && { affinity_id: companyCachedData?.external_id || companyNoCachedData.external_id })
    };
  
    dispatch(assignBucketToCompany(axiosAddBucket, payload))
      .then(response => {
        if (response.status !== 'success') {
          setSelections(prev => ({ ...prev, bucket: null }));
          alertCustom({ icon: 'error', text: response.errorMsg });
        }
      })
      .catch(error => {
        setSelections(prev => ({ ...prev, bucket: null }));
        alertCustom({ icon: 'error', text: error.message });
      });
  };
  
  const handleRemoveBucket = () => {
    const previousBucket = selections.bucket;
    setSelections(prev => ({ ...prev, bucket: null }));
  
    const payload = {
      domain: companyDomain,
      ...(companyCachedData.external_id !== 'n/a' && { affinity_id: companyCachedData?.external_id || companyNoCachedData.external_id })
    };
  
    dispatch(assignBucketToCompany(axiosAddBucket, payload))
      .then(response => {
        if (response.status !== 'success') {
          setSelections(prev => ({ ...prev, bucket: previousBucket }));
          alertCustom({ icon: 'error', text: response.errorMsg });
        }
      })
      .catch(error => {
        setSelections(prev => ({ ...prev, bucket: previousBucket }));
        alertCustom({ icon: 'error', text: error.message });
      });
  };

  const handleRemoveList = (removedList) => {
    const { list_id } = removedList;
  
    setCompanyLists(prevLists => ({
      ...prevLists,
      lists: prevLists.lists.filter(list => list.list_id !== list_id),
    }));
  
    const url = getAddress() + `/remove-company-from-list/${companyDomain}/${list_id}`;
  
    dispatch(removeCompanyFromList(url))
      .then(response => {
        if (response.status !== 'success') {
          setCompanyLists(prevLists => ({
            ...prevLists,
            lists: [...prevLists.lists, removedList]
          }));
          alertCustom({ icon: 'error', text: response.errorMsg });
        }
      })
      .catch(error => {
        setCompanyLists(prevLists => ({
          ...prevLists,
          lists: [...prevLists.lists, removedList]
        }));
        alertCustom({ icon: 'error', text: error.message });
      });
  };
  
  const handleShowAdvertisingStats = async () => {
    setIsLoading(prevState => ({ ...prevState, advertisingStats: true }));
    try {
      const response = await dispatch(getNewAdvertisingStats(axiosAddAdvertisingStats, companyDomain));
      if (response.status === "success") {
        setAdvertisingStats(response?.data?.domain_stats);
      } else {
        setAdvertisingStats(null);
      }
    } catch (error) {
      console.error("Failed to fetch advertising stats:", error);
      setAdvertisingStats(null);
    } finally {
        setIsLoading(prevState => ({ ...prevState, advertisingStats: false }));
    }
  };

  const handleShowCompetitors = async () => {
    setIsLoading(prevState => ({ ...prevState, competitors: true }));
    setFetchStatus(null);

    try {
      const response = await dispatch(getNewCompetitors(axiosAddCompanyCompetitors, companyDomain));

      if (response.status === "success") {
        setCompetitors({
          seo: response?.data?.seo_competitors || [],
          ppc: response?.data?.ppc_competitors || []
        });
        await recursiveFetchCompetitors(10);
      } else {
        await recursiveFetchCompetitors(10);
      }
    } catch (error) {
      await recursiveFetchCompetitors(10);
    }
  };

  const recursiveFetchCompetitors = async (retriesLeft) => {
    if (retriesLeft <= 0 || !isComponentMounted) {
      if (isComponentMounted) {
        setIsLoading(prevState => ({ ...prevState, competitors: false }));
        setFetchStatus('error');
      }
      return;
    }

    try {
      const response = await dispatch(getCompanyCompetitors(axiosCompanyCompetitors, companyDomain));

      const hasData = response?.data?.seo_competitors?.length > 0 || response?.data?.ppc_competitors?.length > 0;

      if (response.status === "success" && hasData) {
        if (isComponentMounted) {
          setCompetitors({
            seo: response?.data?.seo_competitors || [],
            ppc: response?.data?.ppc_competitors || []
          });
          setIsLoading(prevState => ({ ...prevState, competitors: false }));
          setFetchStatus(null);
        }
      } else {
        setTimeout(() => recursiveFetchCompetitors(retriesLeft - 1), 5000);
      }
    } catch (error) {
      setTimeout(() => recursiveFetchCompetitors(retriesLeft - 1), 5000);
    }
  };
  
  const handleShowBothStats = async () => {
    await Promise.all([
      handleShowAdvertisingStats(),
      handleShowCompetitors()
    ]);
  };

  const handleShowFounders = async () => {
    try {
      setIsLoading(prevState => ({ ...prevState, founders: true }));
      setHasError(false);
      const response = await dispatch(getNewCompanyFounders(axiosFindCompanyFounders, companyDomain));
      if (response?.status === "success") {
        setFounderData(prevData => ({...prevData, founders: response.data.founders || [] }));
      } else {
        setHasError(true);
      }
    } catch (error) {
        setHasError(true);
    } finally {
      setIsLoading(prevState => ({ ...prevState, founders: false }));
    }
  };

  const renderCompetitorsSection = () => {
    if (isLoading.competitors) {
      return (
        <div className="loader" id="competitorsLoader">
          <div className="loader-animation-block" style={{ height: "80px" }}></div>
        </div>
      );
    }

    const noSeoOrPpcData = !competitors.seo.length && !competitors.ppc.length;
    const noAdvertisingStats = !advertisingStats || !Object.keys(advertisingStats).length;

    if (fetchStatus === 'error') {
      return (
        <div className="carousel-container2" id="seo-carousal">
          <div className="d-flex justify-content-center grey-box">
            Error in fetching SEO PPC data. Please try again later.
          </div>
        </div>
      );
    }
  
    if (fetchStatus === 'empty') {
      return (
        <div className="carousel-container2" id="seo-carousal">
          <div className="d-flex justify-content-center grey-box">
            No SEO PPC data available.
          </div>
        </div>
      );
    }

    if (noAdvertisingStats && noSeoOrPpcData) {
        return (
        <div className="advertisement-button-box d-flex justify-content-center">
            <div className="advertisement-container">
            <div className="divider"></div>
            <div className="advertisement-button-title d-flex mb-2">
                Would you like to look at the advertising stats and SEO/PPC company details?
            </div>
            <button className="advertisement-button" id="bothStatsButton" onClick={handleShowBothStats}>
                Yes, Show me!
            </button>
            </div>
        </div>
        );
    }

    if (noSeoOrPpcData && !noAdvertisingStats) {
      return (
        <div className="advertisement-button-box d-flex justify-content-center">
          <div className="advertisement-container">
            <div className="divider"></div>
            <div className="advertisement-button-title d-flex mb-2">
              Would you like to look at the SEO Companies and PPC Companies?
            </div>
            <button className="advertisement-button" id="seoButton" onClick={handleShowCompetitors}>
              Yes, Show me!
            </button>
          </div>
        </div>
      );
    }

    return <Carousel seoCompetitors={competitors.seo} ppcCompetitors={competitors.ppc} />;
  };
  
  const renderAdvertisingStats = () => {
    if (isLoading.advertisingStats) {
      return (
        <div className="advertisingloader" id="advertisingloader">
          <div className="loader-animation-block" style={{ height: "80px" }}></div>
        </div>
      );
    }

    const noSeoOrPpcData = !competitors.seo.length && !competitors.ppc.length;
    const noAdvertisingStats = !advertisingStats || !Object.keys(advertisingStats).length;

    if (noAdvertisingStats && !noSeoOrPpcData) {
      return (
        <>
          <div
            className="advertisement-button-box d-flex justify-content-center"
            id="advertisingStatsButton"
          >
            <div className="advertisement-container">
              <div className="divider"></div>
              <div className="advertisement-button-title d-flex mb-2">
                Would you like to look at the advertising stats?
              </div>
              <button className="advertisement-button" id="advertisementButton" onClick={handleShowAdvertisingStats}>
                Yes, Show me!
              </button>
            </div>
          </div>
        </>
      );
    }

    if(!noAdvertisingStats) {
        const { monthly_budget, monthly_budget_growth_desc, monthly_organic_clicks, monthly_organic_clicks_growth_desc, monthly_paid_clicks, monthly_paid_clicks_growth_desc, strength, strength_growth_desc, total_ads_purchased, total_ads_purchased_growth_desc, total_organic_results, total_organic_results_growth_desc } = advertisingStats;
        return (
        <div className="advertising-stats">
            <div className="advertising-stats-heading d-flex mb-2">Advertising Stats</div>
            <div className="row mb-4">
            {[
                { title: 'Monthly Budget', value: monthly_budget, growthDesc: monthly_budget_growth_desc },
                { title: 'Strength', value: strength, growthDesc: strength_growth_desc },
                { title: 'Total Ads Purchased', value: total_ads_purchased, growthDesc: total_ads_purchased_growth_desc },
                { title: 'Monthly Organic Clicks', value: monthly_organic_clicks, growthDesc: monthly_organic_clicks_growth_desc },
                { title: 'Monthly Paid Clicks', value: monthly_paid_clicks, growthDesc: monthly_paid_clicks_growth_desc },
                { title: 'Total Organic Results', value: total_organic_results, growthDesc: total_organic_results_growth_desc }
            ]?.map((stat, index) => (
                <div className="col-md-4 mb-3" key={index}>
                <div className="attribute-container">
                    <div className="attribute-box">
                    <div className="stats-title">{stat.title}</div>
                    <div className="stats-value">
                        {formatNumber(stat.value)}
                    </div>
                    </div>
                </div>
                {stat.growthDesc !== "n/a" && (
                    <div className={getGrowthDescriptionClass(stat.growthDesc)}>
                    {stat.growthDesc}
                    </div>
                )}
                </div>
            ))}
            </div>
        </div>
        );
    }
  };

  const renderFoundersSection = () => {
    if (isLoading.founders) {
      return (
        <div className="founder-loader" id="founderLoader">
          <div className="loader-animation-block" style={{ height: '80px' }}></div>
        </div>
      );
    }
  
    if (hasError) {
      return (
        <div className="founder-button-box hidden d-flex justify-content-center f-mb-22px f-mt-4" id="founderErrorBox">
          <div className="founder-container">
            <div className="potential-title"> Potential Founder Emails</div>
            <div className="founder-button-title d-flex f-mb-8px"> Oh-oh! We are working on collecting data for you. Please come back later! </div>
          </div>
        </div>
      );
    }
  
    if (!founderData?.founders?.length) {
      return (
        <div className="founder-button-box hidden d-flex justify-content-center f-mb-22px f-mt-4" id="founderStatsButton">
          <div className="founder-container">
            <div className="potential-title"> Potential Founder Emails</div>
            <div className="founder-button-title d-flex f-mb-8px"> We might have a few potential founder email addresses. Would you like to view them? </div>
            <button className="founder-button" id="founderButton" onClick={handleShowFounders}>
              Yes, Please!
            </button>
          </div>
        </div>
      );
    }
  
    return (
      <div className="founders-section mb-3">
        <div className="founder-stats-subheading d-flex f-mb-8px">
          <span>Notable People</span>
          {founderData.founders.length > 2 && !showAllFounders && (
            <span id="viewAllFounders" className="founders-viewAll" onClick={() => setShowAllFounders(true)}>
              View all
            </span>
          )}
          {showAllFounders && (
            <span id="viewLessFounders" className="founders-viewLess" onClick={() => setShowAllFounders(false)}>
              View less
            </span>
          )}
        </div>
  
        <div className="founders-row" id="founders">
          {founderData.founders.slice(0, showAllFounders ? founderData.founders.length : 2).map((founder, index) => {
            const {
              state,
              country,
              email,
              first_name,
              last_name,
              job_title,
              profile_picture,
              linkedin_url
            } = founder;
  
            const displayLocation = `${state && state !== 'n/a' ? state + ', ' : ''}${country && country !== 'n/a' ? country : ''}`;
            const showGetEmailButton = !founder.email || founder.email === 'n/a';
  
            return (
              <div key={index} className="founder-details-box d-flex justify-content-start align-items-center col-4-8">
                <div className="founder-image">
                  {profile_picture && profile_picture !== 'n/a' ? (
                    <div className="founder-profile">
                      <img src={profile_picture} alt={`${first_name} ${last_name}`} height="100%" width="100%" />
                    </div>
                  ) : (
                    <div className="founder-initials">
                      {first_name.charAt(0)}{last_name.charAt(0)}
                    </div>
                  )}
                </div>
                <div className="founder-details">
                  {((first_name && first_name != 'n/a') || (last_name && last_name != 'n/a')) && (
                    <div className="founder-name">
                      <span id="founder-name">
                        {first_name} {last_name}
                      </span>
                      {linkedin_url && linkedin_url != 'n/a' && (
                        <span className="founder-linkedin">
                          <span onClick={() =>{window.open(`${linkedin_url}`, '_blank')}} rel="noopener noreferrer">
                            <img className='founder-linkedin-icon' src={linkedinIcon} alt="LinkedIn" />
                          </span>
                        </span>
                      )}
                    </div>
                  )}
                  {job_title && job_title !== 'n/a' && (
                    <div className="founder-designation">
                      <i id="jobTitle">{job_title}</i>
                    </div>
                  )}
                  {displayLocation && (
                    <div className="notable-founder-location" id="founder-location">
                      {displayLocation}
                    </div>
                  )}
                  <div>
                    {!showGetEmailButton && email && (
                      <span className="founder-email" id="founderEmail" onClick={(e) => handleCopyToClipboard(email, e.currentTarget)}>
                        {email}
                      </span>
                    )}
                    {showGetEmailButton && !founderData?.isEmailClicked[index] && !isLoading.founderEmail[index] && (
                      <span className="get-email-button" onClick={() => fetchFounderData(index)}>
                        Get Email
                      </span>
                    )}
                    {showGetEmailButton && isLoading.founderEmail[index] && (
                      <div className="founder-email-loader p-10">
                        <div className="loader-animation-block" style={{ height: '20px' }}></div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  const renderGrowthBlock = () => {
    const { employee_count, growth_1m, growth_6m, growth_1y, growth_2y } = companyCachedData;
    
    const hasValidGrowthData = ![growth_1m, growth_6m, growth_1y, growth_2y].every(value => value === null || value === "n/a");
    const displayEmployeeCount = employee_count && employee_count !== "n/a";
  
    return (
      <div className="data-modal-box">
        {displayEmployeeCount && (
          <span className="d-flex align-items-center justify-content-center emp_count" id="size">
            <img
              className="icon_employeeCount"
              src={employeeCountIcon}
            />
            <span className="overview-value" id="company-size">
              {formatNumber(employee_count)} Employees
            </span>
          </span>
        )}
        {hasValidGrowthData && (
          <div className="f-row emp-growth">
            <div className="growth-container d-flex justify-content-between">
              {[{label: "1 Month", value: growth_1m}, {label: "6 Months", value: growth_6m}, {label: "1 Year", value: growth_1y}, {label: "2 Years", value: growth_2y}]?.map((growth, index) => {
                if (growth.value !== null && growth.value !== "n/a") {
                  let iconSrc;
                  if (growth.value > 0) {
                    iconSrc = UpArrowIcon;
                  } else if (growth.value < 0) {
                    iconSrc = DownArrowIcon;
                  } else {
                    iconSrc = RightArrowIcon;
                  }
  
                  return (
                    <div className="growth-item" key={index}>
                      <span className="growth-header">{growth.label}</span>
                      <div className="d-flex justify-content-center">
                        <div className="growth-icon" id={`growth_icon_${growth.label.toLowerCase().replace(/\s/g, '')}`}>
                          <span className="">
                            <img
                              className="dynamic-svg-icons"
                              src={iconSrc}
                              height="17px"
                              width="17px"
                            />
                          </span>
                        </div>
                        <div className="growth-value" id={`growth_${growth.label.toLowerCase().replace(/\s/g, '')}`}>
                          {growth.value}%
                        </div>
                      </div>
                    </div>
                  );
                }
                return null;
              })}
            </div>
          </div>
        )}
      </div>
    );
  };
  
  const renderParticipants = (participants, meetingKey) => {
    const isExpanded = expandedParticipants[meetingKey];
    const displayedParticipants = isExpanded ? participants : participants.slice(0, 3);
  
    return (
      <div className="participants-container d-flex">
        {displayedParticipants?.map((participant, index) => (
          <span className="participant-card" key={index}>
            <span className="person">
              <div className={isExpanded ? "participants-email" : "d-flex justify-content-center align-items-center name-initial"}>
                {isExpanded ? (
                  participant.email
                ) : participant.name ? (
                  getFirstChar(participant.name)
                ) : (
                  <img
                    src={PersonIcon}
                    height="auto"
                    width="12px"
                    alt="default person"
                  />
                )}
              </div>
            </span>
          </span>
        ))}
        {participants.length && (
          <div className={`d-flex justify-content-center align-items-center ${isExpanded ? 'hide-all-button ' : 'view-all-button'}`} onClick={() => toggleParticipantsView(meetingKey)}>
            <img
              src={isExpanded
                ? ArrowUpIcon
                : ArrowRightIcon}
              height="12px"
              width="12px"
              alt={isExpanded ? 'Collapse' : 'Expand'}
            />
          </div>
        )}
      </div>
    );
  };

  return (
    <Offcanvas show={isOpen} onHide={onClose} placement='end' style={{ width: "40pc",}} className='off-canvas-body'>
      <Offcanvas.Body className="slider-content ">
      {viewingCTOLunches ? (
      <>
        {renderCTOLunchesData()}
        </>
        ) : (
        <>
        {alert && (
          <div className="custom-alert">
            <p className="alert-text">{alert.text}</p>
            <img
              className="alert-icon"
              src={AlertIcon}
            />
          </div>
        )}
        <div className="header">
          {isLoading.companyCacheData ? (
            <div className="header-loader" id="headerLoader">
              <div className="loader-animation-block header-loader-block" style={{ height: '120px' }}></div>
            </div>
          ) : (
            <>
              <div className="top-slider-header-box mb-2">
                <div className="container-fluid">
                  <div>
                    <div className="logo-box">
                      <a href={`https://${companyCachedData.company_domain}`} target="_blank" rel="noopener noreferrer">
                        <div className="logo-container">
                            <img
                              className="company-logo"
                              id="company-logo-id"
                              alt="company-logo"
                              src={companyCachedData.company_logo}
                            />
                        </div>
                      </a>
                    </div>
                    <div className="company-social d-flex">
                      {companyCachedData.linkedin_url && (
                        <div className="followers d-flex align-items-center">
                          <a href={companyCachedData.linkedin_url} target="_blank" rel="noopener noreferrer">
                            <img src={linkedinIcon} alt="LinkedIn" />
                          </a>
                        </div>
                      )}
                      {companyCachedData.hq_address && (
                        <div className="google-map d-flex align-items-center">
                          <a href={`https://maps.google.com/?q=${companyCachedData.hq_address}`} target="_blank" rel="noopener noreferrer">
                            <img src={googleMapsIcon} alt="Google Maps" />
                          </a>
                        </div>
                      )}
                      {companyCachedData.url && (
                        <div className="affinity d-flex align-items-center">
                          <a href={companyCachedData.url} target="_blank" rel="noopener noreferrer">
                            <img src={affinityIcon} height="21px" width="25px" alt="Affinity" />
                          </a>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="row d-flex justify-content-between align-items-center w-100">
                    <div className="col-12 company-info">
                      <div className="row d-flex align-items-center">
                        <span  className="company-title col-9" id="company-title">
                          <a href={`https://${companyCachedData.company_domain}`} target="_blank" rel="noopener noreferrer" className='company-title-window'>
                            {companyCachedData.company_name && companyCachedData.company_name !== 'n/a' ? (
                              <span>
                                {companyCachedData.company_name}
                              </span>
                            ) : (
                              <span>
                                {companyCachedData.company_domain}
                              </span>
                            )}
                          </a>
                        </span>
                        {!isLoading.companyCacheData && !isLoading.companyNoCachedData && (() => {
                          const tooltipMessage = getTooltipMessage(
                            companyNoCachedData.email_metadata,
                            companyCachedData,
                            companyNoCachedData.meetings
                          );
                          return tooltipMessage ? (
                            <span className="col-2 noEmail" id="noEmails">
                              <span className="tooltip-header">
                                <img
                                  className="icon_noEmailFund"
                                  height="24px"
                                  width="24px"
                                  src={NoDataIcon}
                                />
                                <span className="tooltiptext" id="tooltipTextMsg">{tooltipMessage}</span>
                              </span>
                            </span>
                          ) : null;
                        })()}
                      </div>
                      <div className="overview-container">
                        {companyCachedData.year_founded && companyCachedData.year_founded !== 'n/a' && (
                          <span className="d-flex align-items-center" id="year_founded">
                            <img
                              className="icon_employeeCount dynamic-svg-icons"
                              alt="year"
                              src={calenderIcon}
                            />
                            <span className="overview-value" id="founded-year">
                              {companyCachedData.year_founded}
                            </span>
                          </span>
                        )}
                        {companyCachedData.hq_address && companyCachedData.hq_address !== 'n/a' && (
                          <span className="d-flex align-items-center" id="head-quarters">
                            <img
                              className="icon_employeeCount dynamic-svg-icons"
                              src={locationIcon}
                            />
                            <span className="overview-value" id="hq-address">
                              {companyCachedData.hq_address}
                            </span>
                          </span>
                        )}
                      </div>
                      <div className="company-social-block mt-1">
                        <div className="row d-flex align-items-start w-100 dropdown-box-header">
                          <div className="col-7 tp-owner d-flex align-items-center">
                            <span className="form-group w-100" id="tpOwner-div">
                              <Dropdown
                                label="TP Owner"
                                options={tpOwnerOptions}
                                selectedOption={selections.tpOwner}
                                onOptionSelect={handleOptionSelect}
                                onOptionRemove={handleRemoveTpOwner}
                                isTpOwner={true}
                              />
                            </span>
                          </div>
                          <div className="col-5 tp-status d-flex align-items-center">
                            <span className="form-group w-100" id="status-div">
                              <Dropdown
                                label="TP Status"
                                options={tpStatusOptions}
                                selectedOption={selections.tpStatus}
                                onOptionSelect={handleTpStatusChange}
                                isTpOwner={false}
                                isTpStatus={true}
                              />
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='growth-tag-block'>
                {companyCachedData.growth_tags && companyCachedData.growth_tags.length > 0 && companyCachedData.growth_tags[0].tag && companyCachedData.growth_tags[0].tag !== 'n/a' && (
                  <div className="growth-tag-container">
                    <span className="growth-tags" id="growthTags">
                      {toCamelCase(companyCachedData?.growth_tags[0]?.tag)}
                      <span className="growth_info_icon tooltip-icon">
                        <img
                          src={InfoIcon}
                          height="10px"
                          width="10px"
                          className="info-icon"
                        />
                        <div className="tooltiptext">
                          {companyCachedData.growth_tags[0].description}
                        </div>
                      </span>
                    </span>
                    <span className="growth-tag-date" id="growthDate">
                      Since {formatDateString(companyCachedData?.growth_tags[0]?.date_observed)}
                    </span>
                  </div>
                )}
              </div>
            </>
          )}
        </div>
        <div className="mb-3 company-details">
          <div className='description-block'>
            {isLoading.companyCacheData ? (
              <div className="description-loader" id="descriptionLoader">
                <div className="loader-animation-block" style={{ height: '80px' }}></div>
              </div>
            ) : (
              <>
                {companyCachedData.description && companyCachedData.description !== 'n/a' && (
                  <>
                    <div className={`full-description-text ${showFullDescription ? '' : 'clamp-text'}`} id="fullDescription">
                      {companyCachedData.description}
                    </div>
                    <div className="view-description-btn" id="viewDescriptionBtn" onClick={toggleDescription}>
                      {showFullDescription ? 'View Less' : 'View Full Description'}
                    </div>
                  </>
                )}
              </>
            )}
          </div>
          <div className="tabs-container">
              {isLoading.companyCacheData ? (
                  <div className="funding-loader" id="fundingLoader">
                    <div className="loader-animation-block" style={{ height: '30px' }}></div>
                    <div className="loader-animation-block" style={{ height: '200px' }}></div>
                    <div className="loader-animation-block" style={{ height: '200px' }}></div>
                    <div className="loader-animation-block" style={{ height: '200px' }}></div>
                  </div>
                  ) : <Tabs activeKey={activeKey} onSelect={(k) => setActiveKey(k)} transition={false} id="company-profile-tab" className="mb-3 company-profile-tabs">
                  <Tab eventKey="company_profile" title="Company Profile" tabClassName={`company-tab ${activeKey === 'company_profile' ? 'active' : ''}`} >
                    <div className='funding-section mb-2'>
                      {isLoading.companyCacheData ? (
                        <div className="funding-loader" id="fundingLoader">
                          <div className="loader-animation-block" style={{ height: '30px' }}></div>
                        </div>
                      ) : (
                        shouldDisplayFundingSection(companyCachedData) && (
                          <div className="company-overview d-flex f-w-100" id="companyOverview">
                            {companyCachedData.total_funding_usd && companyCachedData.total_funding_usd !== 'n/a' && (
                              <div className="company-overview-pair d-flex">
                                <div className="overview-heading">Total Funded:</div>
                                <div className="overview-value" id="investment">${(companyCachedData.total_funding_usd / 1e6).toFixed(2)}M</div>
                              </div>
                            )}
                            {companyCachedData.last_funding_on && companyCachedData.last_funding_on !== 'n/a' && (
                              <div className="company-overview-pair d-flex ml-4">
                                <div className="overview-heading">Last Funding On:</div>
                                <div className="overview-value" id="revenue">{formatDate(companyCachedData.last_funding_on)}</div>
                              </div>
                            )}
                          </div>
                        )
                      )}
                    </div>
                    <div className="email-container mb-2">
                      {isLoading.companyNoCachedData ? (
                        <div className="emailSec-loader mt-2 mb-1" id="emailSecLoader">
                          <div className="loader-animation-block" style={{ height: '80px' }}></div>
                        </div>
                      ) : (
                        <>
                          {companyNoCachedData.email_metadata && shouldDisplayEmailBox(companyNoCachedData.email_metadata) && (
                            <div className='email-section'>
                              <div className="email-box d-flex justify-content-between" id="emailSec">
                                <div className="email-card">
                                  <div className="col-12 p-1">
                                    <div>
                                      <div className="stats-title email-header">
                                        First interacted <span id="first-email-days">{getDaysAgo(companyNoCachedData.email_metadata?.first_email_sent)} days ago</span>
                                      </div>
                                      <div className="stats-value" id="first-email">
                                        {formatDateString(companyNoCachedData.email_metadata?.first_email_sent)}
                                      </div>
                                      <div className="days-ago" id="total_interaction">
                                        Total {companyNoCachedData.email_metadata?.interactions_us_to_them} emails till today
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="email-card">
                                  <div className="col-12 p-1">
                                    <div>
                                      <div className="stats-title email-header">
                                        Last interacted <span id="last-email-days">{getDaysAgo(companyNoCachedData.email_metadata?.last_email_sent)} days ago</span>
                                      </div>
                                      <div className="stats-value" id="last-email">
                                        {formatDateString(companyNoCachedData.email_metadata?.last_email_sent)}
                                      </div>
                                      <div className="days-ago" id="interaction_in_month">
                                        {companyNoCachedData.email_metadata?.monthly_email_interactions > 0 && `${companyNoCachedData.email_metadata?.monthly_email_interactions} interactions this month`}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="email-card">
                                  <div className="col-12 p-1">
                                    <div>
                                      <div className="stats-title email-header">Most interacted with</div>
                                      <div className="stats-value" id="email-interaction-with">
                                        {companyNoCachedData.email_metadata?.top_company_sender}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                        </>
                      )}
                    </div>
                    <div className='comp_meeting mb-2'>
                      {isLoading.companyNoCachedData ? (
                        <div className="meetingSec-loader" id="meetingSecLoader">
                          <div className="loader-animation-block" style={{ height: '80px' }}></div>
                        </div>
                      ) : (
                        <>
                          {companyNoCachedData.meetings && Object.keys(companyNoCachedData.meetings).length > 0 && (
                            <>
                              <div className="calender-heading mb-2 align-items-center" id="calender-heading">Company Meetings</div>
                              <Tabs
                                activeKey={activeTab}
                                onSelect={(k) => setActiveTab(k)}
                                transition={false}
                                id="noanim-tab-example"
                                className="meetings-tab"
                              >
                                {['first_meeting', 'latest_meeting', 'next_meeting'].map((meetingKey) => {
                                  const meeting = meetings?.[meetingKey];
                                  if (!meeting || Object.keys(meeting).length === 0) return null;
    
                                  return (
                                    <Tab
                                      eventKey={meetingKey}
                                      title={capitalizeAllFirstLetter(meetingKey.replace('_', ' '))}
                                      key={meetingKey}
                                      tabClassName={meetingKey === activeTab ? 'tab-active' : 'tab-inactive'}
                                    >
                                      <div className="content">
                                        <div className="calendar-card">
                                          <div className="calendar-container d-flex">
                                            <div className="date-sec">
                                              <div className="title">{capitalizeAllFirstLetter(meetingKey.replace('_', ' '))}</div>
                                              <div className="date">{formatMeetingDate(meeting.created_at)}</div>
                                              <div className="time">{formatMeetingsTime(meeting.created_at)}</div>
                                            </div>
                                            <div className="ver-divider"></div>
                                            <div className="summary-sec">
                                              <div className="participants-sec d-flex align-items-center">
                                                <span className="since_day d-flex align-items-center">
                                                  {meetingKey === 'first_meeting' && meetings.days_since_first_email > 0 && (
                                                    <>
                                                      <img src={ClockIcon} height="10px" width="12px" className="clockImg" />
                                                      <span className="ml-4 mr-6"> {meetings.days_since_first_email} days since first email </span>
                                                    </>
                                                  )}
                                                  <img src={PersonsIcon} height="auto" width="13px" alt="persons" />
                                                  <span className="ml-4 mr-3"> {meeting.participants.length} Participants </span>
                                                </span>
                                              </div>
                                              <div className="subjects">{meeting.subject}</div>
                                              <div className="d-flex participants-box">
                                                {renderParticipants(meeting.participants, meetingKey)}
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </Tab>
                                  );
                                })}
                              </Tabs>
                            </>
                          )}
                        </>
                      )}
                    </div>
                    <div className="lists-buckets-section mb-2">
                      {isLoading.companyLists ? (
                        <div className="lists-buckets-loader" id="listsBucketsLoader">
                          <div className="loader-animation-block" style={{ height: '80px' }}></div>
                        </div>
                      ) : (
                        <>
                          <div className="f-row mb-4 col-12 p-0">
                            <label className="multiSelect-label">Lists</label>
                            {companyLists?.suggested?.length > 0 && (
                              <>
                                <label className="recent-list-label">Recent Lists</label>
                                <div className="recent-list-container">
                                  {companyLists.suggested.map(list => (
                                    <span
                                      key={list.list_id}
                                      className="recent-list-item"
                                      data-list-id={list.list_id}
                                      onClick={() => handleRecentListSelect(list)}
                                    >
                                      {list.name}
                                    </span>
                                  ))}
                                </div>
                              </>
                            )}
                            <Dropdown
                              options={listOptions}
                              selectedOption={companyLists.lists?.map(list => ({ label: list.name, list_id: list.list_id }))}
                              onOptionSelect={handleListSelect}
                              onOptionRemove={handleRemoveList}
                              isTpOwner={false}
                              isTpStatus={false}
                              isBucket={false}
                            />
                          </div>
                          <div className="f-row mb-4 col-12 p-0">
                            <Dropdown
                              label="Buckets"
                              options={bucketOptions}
                              selectedOption={selections.bucket}
                              onOptionSelect={handleBucketChange}
                              onOptionRemove={handleRemoveBucket}
                              isTpOwner={false}
                              isTpStatus={false}
                              isBucket={true}
                            />
                          </div>
                        </>
                      )}
                    </div>
                    <div className="graph-section mb-2">
                      {isLoading.companyCacheData ? (
                        <div className="company-stats-loader" id="countriesLoader">
                          <div className="loader-animation-block" style={{ height: '120px' }}></div>
                        </div>
                      ) : isCompanyStatsDataAvailable() ? (
                        <div className="company-stats mb-2">
                          <div className="company-composition-graph" id="companyCompositionSection">
                            <div className="stats-heading f-mb-12px d-flex justify-content-center" id="company-title">
                              <span>Company Stats</span>
                            </div>
                            {renderGrowthBlock()}
                            <div id="empStatsSec" className="empStatsSec">
                              <div className="data-modal-box">
                                <div className="f-row stats" ref={lineGraphRef} id="employees_stats"></div>
                              </div>
                            </div>
                            <div id="departmentsSec" className="mb-2">
                              <div className="stats-subheading d-flex f-mb-8px justify-content-center align-items-center">
                                <span id="composition-department">Composition by Department</span>
                              </div>
                              <div className="graph-container" ref={barGraphRef} id="graph-container-id"></div>
                            </div>
                          </div>
                        </div>
                      ) : null
                      }
                    </div>
                    <div className='countries-container mb-2'>
                      {isLoading.companyCacheData ? (
                        <div className="company-stats-loader" id="countriesLoader">
                          <div className="loader-animation-block" style={{ height: '180px' }}></div>
                        </div>
                      ) : (
                        <>
                          {countryList.length > 0 && (
                            <>
                              <div className="country-section f-mb-16px" id="countrySec">
                                <div className="stats-subheading d-flex f-mb-8px">
                                  <span>Company Composition by Geography</span>
                                  {countryList.length > 4 && (
                                    <><span id="viewAllBtn" className={`country-viewAll ${showAllCountries ? 'hidden' : ''}`} onClick={toggleCountryVisibility}>View all</span>
                                      <span id="viewLessBtn" className={`country-viewLess ${showAllCountries ? '' : 'hidden'}`} onClick={toggleCountryVisibility}>View less</span>
                                    </>
                                  )}
                                </div>
                                <div className={`country-cards-container ${showAllCountries ? 'expanded' : 'collapsed'}`} id="countryCards">
                                  <div className='country-row'>
                                    {countryList.slice(0, showAllCountries ? countryList.length : 4)?.map((country, index) => (
                                      <div className="country-card" key={index}>
                                        <div className="country-info">
                                          {country.flag && country.flag !== "n/a" && (
                                            <img
                                              className="country-flag"
                                              src={country.flag}
                                              alt={`${country.name} flag`}
                                              height="15.75px"
                                              width="21px"
                                            />
                                          )}
                                          <span className="country-name">{country.name}</span>
                                          {index === 0 && <span className="hq-country">HQ</span>}
                                        </div>
                                        <span className="country-count">{country.count.toFixed(2)}%</span>
                                      </div>
                                    ))}
                                  </div>
                                </div>
                              </div>
                            </>
                          )}
                        </>
                      )}
                    </div>
                    <div className="founders-container mb-3">
                      {renderFoundersSection()}
                    </div>
                    <div className="similar-company mb-4">
                      {renderCompetitorsSection()}
                    </div>
                    <div className="advertising-stats-section">
                      {renderAdvertisingStats()}
                    </div>
                  </Tab>
                  <Tab eventKey="quips" title={quipsData.length === 0 ? 'No Quips' : `Quips ${quipsData.length ? `${quipsData.length}` : ''}`} tabClassName={`company-tab ${activeKey === 'quips' ? 'active' : ''} ${quipsData?.length === 0 ? 'tab-disabled' : ''}`}>
                    {isLoading.quips ? (
                      <div className="d-flex justify-content-center">
                        <Spinner animation="border" />
                      </div>
                    ) : (
                      <Row>
                        <Col>
                          <div className="sortDiv" id="quip-sort">
                            <div className="sort-container">
                              <span
                                className={`sort-span ${quipsState.activeSortedType === 'newest' ? 'active' : ''}`}
                                onClick={() => handleSortOrderChange('newest')}
                              >
                                New to Old
                              </span>
                              <span
                                className={`sort-span ${quipsState.activeSortedType === 'oldest' ? 'active' : ''}`}
                                onClick={() => handleSortOrderChange('oldest')}
                              >
                                Old to New
                              </span>
                              <input
                                type="text"
                                placeholder="Search..."
                                className="search-input"
                                value={quipsState.searchTerm}
                                onChange={(e) => setQuipsState(prevState => ({ ...prevState, searchTerm: e.target.value }))}
                              />
                            </div>
                          </div>
                          {renderQuipsAccordion()}
                        </Col>
                      </Row>
                    )}
                  </Tab>
                  <Tab eventKey="mentions" title={mentionsData.length === 0 ? 'No Mentions' : `Mentions ${mentionsData.length ? `${mentionsData.length}` : ''}`} tabClassName={`company-tab ${activeKey === 'mentions' ? 'active' : ''} ${mentionsData?.length === 0 ? 'tab-disabled' : ''}`}>
                    {isLoading.mentions ? (
                      <div className="d-flex justify-content-center">
                        <Spinner animation="border" />
                      </div>
                    ) : (
                      <Row>
                        <Col>
                          <div className="sortDiv" id="mention-sort">
                            <div className="sort-container">
                              <span
                                className={`sort-span ${mentionsState.activeSortedType === 'newest' ? 'active' : ''}`}
                                onClick={() => handlementionsSortChange('newest')}
                              >
                                New to Old
                              </span>
                              <span
                                className={`sort-span ${mentionsState.activeSortedType === 'oldest' ? 'active' : ''}`}
                                onClick={() => handlementionsSortChange('oldest')}
                              >
                                Old to New
                              </span>
                              <input
                                type="text"
                                placeholder="Search..."
                                className="search-input"
                                value={mentionsState.searchTerm}
                                onChange={(e) =>
                                  setMentionsState((prevState) => ({
                                    ...prevState,
                                    searchTerm: e.target.value,
                                  }))
                                }
                              />
                            </div>
                          </div>
                          {renderMentionsAccordion()}
                        </Col>
                      </Row>
                    )}
                  </Tab>
                </Tabs>
              }
          </div>
        </div>
        </>
        )}
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export default Slider;
