import React from 'react';
import ReactDOM from 'react-dom/client';

import App from './component/App';
import { Provider } from 'react-redux';
import * as Sentry from "@sentry/react";

import reducers from './store/reducers';
import { configureStore } from './config';

import 'antd/dist/antd.css';
import './assets/scss/styles.scss';

const store = configureStore(reducers);

if (!host.includes('localhost')) {
  const env = host &&
    (host.includes("orionui-prod") ||
      host.includes("awseb-awseb-ij29h3qm1x06-1410843689.us-west-2.elb.amazonaws.com") ||
      host.includes("orion.telescope.vc"))
    ? "PROD"
    : "DEV";

  if (env === "PROD") {
    Sentry.init({
      dsn: process.env.sentry_key_dsn,
      autoSessionTracking: true,
      integrations: [
        new Sentry.BrowserTracing({
          // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        }),
        new Sentry.Replay(),
      ],
      // Performance Monitoring
      tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
      // Session Replay
      replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
      replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
      release:"v182",
    });
  }
}

const jsx = (
  <Provider store={store}>
    <App />
  </Provider>
);

// Use createRoot instead of ReactDOM.render
const root = ReactDOM.createRoot(document.getElementById('root')); 
root.render(jsx);
