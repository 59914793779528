import React, { useEffect, useRef } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { history } from "../config";
import OfflineView from "./OfflineView/OfflineView";
import ErrorBoundary from "./ErrorBoundary/ErrorBoundary";
import { checkAuth, checkAutoRefreshToken } from "../store/actions";
import MainRoutes from "./MainRoutes/MainRoutes";
import AjaxSetup from "./AjaxSetup/AjaxSetup";
import * as amplitude from '@amplitude/analytics-browser';
import {LicenseManager} from "ag-grid-enterprise";
import CheckAuthExpire from "./CheckAuthExpire/CheckAuthExpire";

LicenseManager.setLicenseKey(process.env.ag_license_key);

function App() {
  const dispatch = useDispatch();
  const [token, isRefreshTokenInValid] = useSelector(({ authReducer }) => [authReducer.token, authReducer.isRefreshTokenInValid])
  const checkAuthIntervalRef = useRef(null);

  function onMount(){
    if(process.env.npm_config_env === "PROD"){
      amplitude.init(process.env.amplitude_analytics_key, {defaultTracking: {pageViews: false,}});
    }
  }
  
  useEffect(onMount,[]);
  
  useEffect(() => {
    const currenntPath = history.location.pathname;
    dispatch(checkAuth(currenntPath))
  }, [token])

  const startCheckAuthInterval = () => {
    checkAuthIntervalRef.current = setInterval(() => {
      dispatch(checkAutoRefreshToken());
    }, 2*60*1000); 
  };

  const clearCheckAuthInterval = () => {
    clearInterval(checkAuthIntervalRef.current);
  };

  useEffect(() => {
    startCheckAuthInterval();

    return () => {
      clearCheckAuthInterval();
    };
  }, [dispatch]);

  useEffect(()=> {
    if(isRefreshTokenInValid && checkAuthIntervalRef.current) {
      clearCheckAuthInterval();
    }
  },[isRefreshTokenInValid])

  return (
    <>
      
        <OfflineView>
          <ErrorBoundary>
            <AjaxSetup>
              <CheckAuthExpire>
                <Router history={history}>
                  <MainRoutes />
                </Router>
              </CheckAuthExpire>
            </AjaxSetup>
          </ErrorBoundary>
        </OfflineView>
    </>
  )
}

export default App