import React from "react";
import appConfig from "@appConfig";
import "./oauth.scss"
import { Row } from "react-bootstrap";
import { ReactComponent as GoogleSvg } from "../../assets/images/icon_svg/google.svg";



const host = window.location.protocol + "//" + window.location.host;
let redirectURL= window.location.protocol + "//" + window.location.host +"/login";
// if(host.includes("orion.telescope.vc") || host.includes("orion.telescopepartners.com") || host.includes("awseb-awseb-ij29h3qm1x06-1410843689.us-west-2.elb.amazonaws.com")) {
//   redirectURL = host.includes("orion.telescope.vc") ? "https://orion.telescope.vc/login" : host.includes("orion.telescopepartners.com") ? "https://orion.telescopepartners.com/login" : host.includes("awseb-awseb-ij29h3qm1x06-1410843689.us-west-2.elb.amazonaws.com") ? "https://awseb-awseb-ij29h3qm1x06-1410843689.us-west-2.elb.amazonaws.com/login" : ""
// } else {
//   const env = host && (host.includes("orion-dev") || host.includes("orionui-dev") || host.includes("awseb-awseb-18dslmfsdfneb-821444353.us-east-1.elb.amazonaws.com")) ? "STAGING" :  host && host.includes("orionui-prod") ? "PROD" : "DEV"
//    redirectURL = appConfig.redirect_uri[env].CALLBACK_URL  
// }

const handleClick = () => {
  window.location.href = `https://orion-portal.auth.us-east-1.amazoncognito.com/login?client_id=4lq9qj2uf363q0d1vav5nrdg1h&response_type=code&scope=email+openid&redirect_uri=${redirectURL}`
}

const Oauth = () => {
  return (
    <Row className="m-0">
    <div className="app">
      <div className="anchor-box d-flex justify-content-center align-items-center gap-3" onClick={handleClick}>
      <span><GoogleSvg/></span>
      </div>
    </div>
    </Row>
  );
}
export { Oauth }