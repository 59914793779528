import { combineReducers } from "redux";
import { authReducer } from "./authReducer";
import { referenceReducer } from "./referencesReducer";
import { commonReducer } from "./commonReducer";
import { dashBoardReducer, companyUpdateReducer } from "./dashboardReducer";
import { submissionsReducer } from "./submissionsReducer";
import { submissionsSliderReducer } from "./submissionsSliderReducer";
import { discoveryReducer } from "./discoveryReducer";
import { companyProfileReducer } from "./companyProfileReducer";
import { workboardReducer } from "./workboardReducer";
import mentionsReducer from "./mentionsReducer";
import investorRemindersReducer from "./investorRemindersReducer";
import { notesReducer } from "./notesReducer";

export default combineReducers({
  authReducer,
  dashBoardReducer,
  referenceReducer,
  commonReducer,
  submissionsReducer,
  companyUpdateReducer,
  discoveryReducer,
  companyProfileReducer,
  submissionsSliderReducer,
  workboardReducer,
  mentionsReducer,
  investorRemindersReducer,
  notesReducer
});
