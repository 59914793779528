import React, { useEffect, useState } from "react";
import { ReactComponent as SuccessSvg } from "../../assets/images/icon_svg/view-investor.svg";
import { ReactComponent as FailedSvg } from "../../assets/images/icon_svg/failedStatus.svg";
import { Navigationbar } from "../../commonComponents/Navbar";
import "./reminders.scss";
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useDispatch } from "react-redux";
import { getInvestorReminderStatus, setUrlReminderParams } from "../../store/actions";
import { axiosInvestorReminderStatus } from "../../config/appConfig/axiosInstance";
import { LoadingBlock } from "../../commonComponents/LoadingAnimation/LoaderComponents";

const Reminders = () => {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const dispatch = useDispatch();
    const [page, setPage] = useState({
        errorStatus: false,
        message: '',
        isLoading: false
    });
    const [params, setParams] = useState({
        source: searchParams.get("source"),
        status: searchParams.get("status")
    });

    useEffect(() => {
        if (params.source && params.status) {
            setPage({
                ...page, 
                isLoading: true
            });
            dispatch(setUrlReminderParams(params.source, params.status))
            dispatch(getInvestorReminderStatus(axiosInvestorReminderStatus, { reminder_source: params.source, reminder_status: params.status }))
                .then(response => {
                    if (response.status === 'success') {
                        setPage({
                            ...page, 
                            errorStatus: false,
                            message: response.message,
                            isLoading: false
                        });
                    } else {
                        setPage({
                            ...page, 
                            errorStatus: true,
                            message: response.message,
                            isLoading: false
                        });
                    }
                });
        }
    }, [searchParams, dispatch, params.source, params.status]);

    const navigateReminders = () => {
        navigate(`/investorReminders?status=${params.status}`);
    };

    return (
        <>
            <Navigationbar />
            {page.isLoading ? <div className="p-20">
                <LoadingBlock height="130px" width="auto" className="mb-3 " />
                <LoadingBlock height="130px" width="auto" className="mb-3 " />
                <LoadingBlock height="130px" width="auto" className="mb-3 " />
                <LoadingBlock height="130px" width="auto" className="mb-3 " />
            </div>
            :
                <div className="reminders-container h-100 d-flex align-items-center justify-content-center">
                    <div className="reminders-content text-center">
                        {page.errorStatus ? <FailedSvg style={{ marginLeft: "6px", marginRight: "-2px" }} /> : <SuccessSvg style={{ marginLeft: "6px", marginRight: "-2px" }} />}
                        <h2 className="reminder-updated">{page.errorStatus ? 'Reminder Update Failed!' : 'Reminder Updated!'}</h2>
                        <p className="status-updated">{page.message}</p>
                        <button className="view-reminders-button btn btn-primary" onClick={navigateReminders}>View Reminders</button>
                    </div>
                </div>
            }
        </>
    );
};

export default Reminders;
